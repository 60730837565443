import { Flex, useDisclosure, IconButton, Image, Text } from "@chakra-ui/react";
import { ReactComponent as BackArrow } from "assets/svgs/back_arrow.svg";
import { ReactComponent as HamBurgerMenu } from "assets/svgs/hamburger.svg";
import UnboxdLogoWhite from "assets/unboxd-logo-white.svg";
import { UserAvatar } from ".";
import { logout } from "utils/logout";
import { useAppSelector } from "hooks";
import { dashboardSelectors } from "store/slices";
// import { useIntercom } from "react-use-intercom";

interface IHeader {
  pageTitle?: string;
  hasInnerPage?: boolean;
  setMobileDisplay?: () => void;
}

const Header: React.FC<IHeader> = ({
  pageTitle,
  hasInnerPage,
  setMobileDisplay,
}) => {
  const { onOpen } = useDisclosure();
  // const { shutdown } = useIntercom();
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      height="48px"
      alignItems="center"
      bg={"#22242C"}
      justifyContent={{ base: "space-between" }}
    >
      {hasInnerPage ? (
        <IconButton
          display={{ base: "flex", lg: "none" }}
          onClick={setMobileDisplay}
          variant="outline"
          aria-label="close menu"
          bg="transparent"
          _active={{ background: "transparent" }}
          _focus={{ background: "transparent" }}
          _hover={{ background: "transparent" }}
          icon={<BackArrow />}
          style={{
            border: 0,
            paddingLeft: 0,
            minWidth: 20,
          }}
        />
      ) : (
        <IconButton
          display={{ base: "flex", lg: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<HamBurgerMenu />}
          style={{
            border: 0,
            paddingLeft: 0,
            minWidth: 20,
          }}
        />
      )}

      {hasInnerPage ? (
        <Text
          display={"flex"}
          justifySelf={"unset"}
          m={"0 auto"}
          fontSize={"25px"}
          lineHeight={"36px"}
          fontFamily={"Plus Jakarta Sans"}
        >
          {pageTitle}
        </Text>
      ) : (
        <Image
          display={{ base: "flex", lg: "none" }}
          src={UnboxdLogoWhite}
          alt="unboxd"
        />
      )}

      <UserAvatar
        avatar={userDetails?.avatar}
        logOut={() => {
          logout();
          // shutdown intercom on logout
          // shutdown();
        }}
        username={userDetails?.username!}
      />
    </Flex>
  );
};
export default Header;
