import { ISVGS } from "./types";

const DashboardIcon = (props: ISVGS) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.201 6.78883L9.50211 0.138448C9.25714 -0.0484135 8.91392 -0.0460346 8.67216 0.145056L0.251394 6.79518C0.0929005 6.92045 0 7.11102 0 7.31215V19.3379C0 19.704 0.30012 20 0.670652 20H5.67631C6.04631 20 6.34697 19.7037 6.34697 19.3379V11.9747H12.1138V19.3379C12.1138 19.704 12.4144 20 12.7844 20H17.7909C18.1609 20 18.4615 19.7037 18.4615 19.3379V7.31215C18.4618 7.10732 18.3654 6.91411 18.201 6.78883Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_dashboard"
        x1="9.23077"
        y1="0"
        x2="9.23077"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.fill || "#8DB782"} />
        <stop offset="1" stopColor={props.fill || "#8DB782"} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default DashboardIcon;
