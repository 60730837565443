import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  Image,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Container,
  Link,
  DrawerOverlay,
  // Show,
} from "@chakra-ui/react";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactNode, useEffect } from "react";
import {
  Navigate,
  Outlet,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import { logout } from "utils/logout";
import { useAppDispatch, useAppSelector } from "hooks";
import { ROUTES } from "routes/links";
import {
  authSelectors,
  dashboardActions,
  dashboardSelectors,
} from "store/slices";
import UnboxdLogoWhite from "assets/unboxd-logo-white.svg";
import { ReactComponent as Instagram } from "assets/instagram.svg";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Twitter } from "assets/twitter.svg";
import { ReactComponent as HamBurgerMenu } from "assets/svgs/hamburger.svg";
import AvatarImg from "assets/avatar.svg";
import {
  DashboardIcon,
  WalletIcon,
  SettingsIcon,
  HelpIcon,
  // GrowIcon,
} from "assets/svgs";
import WeddingIcon from "assets/svgs/wedding.svg.react";
import { ISVGS } from "assets/svgs/types";
import createPageName from "utils/createPageName";
// import { useIntercom } from "react-use-intercom";
// import ButtonCmp from "components/ButtonCmp/ButtonCmp";
// import { IoIosShareAlt } from "react-icons/io";
import SharePageModal from "components/Modals/SharePageModal";
import WishlistIcon from "assets/svgs/wishlist.svg.react";

interface SVGIconType extends IconType, ISVGS {}
interface LinkItemProps {
  name: string;
  icon: SVGIconType;
  url?: string;
  external?: boolean;
  onClick?: (data: any) => void;
  dropdownMenus?: [{ name: string; url: string }];
}

const DashboardLayout = () => {
  // const { boot } = useIntercom();
  const userID = localStorage.getItem("userID");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const accessToken = useAppSelector(authSelectors.getAccessToken);
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useAppDispatch();
  const authContext = localStorage.getItem("authContext");

  const { getUser } = dashboardActions;

  const pageName: string =
    location.pathname === "/" ? "Dashboard" : createPageName(location.pathname);

  if (!localStorage.getItem("accessToken") || !accessToken) {
    // goto login screen
    navigate(ROUTES.login);
  }

  useEffect(() => {
    dispatch(getUser(userID!));
  }, [dispatch, getUser, userID]);

  // const launchIntercom = useCallback(
  //   (userDetails: any) => {
  //     boot({
  //       name: userDetails?.fullname,
  //       email: userDetails?.email,
  //       userId: userDetails?._id,
  //     });
  //   },
  //   [boot, userDetails]
  // );

  //boot up intercom after login
  // useEffect(() => {
  //   if (userDetails) {
  //     boot({
  //       name: userDetails?.fullname,
  //       email: userDetails?.email,
  //       userId: userDetails?._id,
  //     });
  //   }
  // }, [boot, userDetails]);

  // let userHasWedding = !!localStorage.getItem("userHasWedding");
  let shouldDisplay = localStorage.getItem("userType") === "Individual";

  const LinkItems: Array<LinkItemProps | null> = [
    {
      name: "Dashboard",
      icon: DashboardIcon,
      url: "/",
    },
    {
      name:
        authContext === "gift" ? "My \u00A0 Wedding" : "My \u00A0 Wishlists",
      icon: authContext === "gift" ? WeddingIcon : WishlistIcon,
      url: authContext === "gift" ? "/my-wedding" : "/my-wishlist",
    },
    { name: "Wallet", icon: WalletIcon, url: "/wallet" },
    { name: "Settings", icon: SettingsIcon, url: "/settings" },
    shouldDisplay
      ? {
          name: "Get help",
          icon: HelpIcon,
          url: "https://unboxd.co/faq",
          // onClick: launchIntercom(userDetails)!,
          external: true,
        }
      : null,
    // { name: "Analytics", icon: SettingsIcon, url: "/analytics" },
    // { name: "secretSanta", icon: WeddingIcon, url: "/secret-santa" },
    // {
    //   name: "More",
    //   icon: SettingsIcon,
    //   url: "#dropdown",
    //   dropdownMenus: [{ name: "Wedding", url: "/my-wedding" }],
    // },
    // { name: "Help us grow", icon: GrowIcon, url: "/help-us-grow" },
  ];

  // const WeddingLinkItems: Array<LinkItemProps | null> = [
  //   { name: "My \u00A0 Wedding", icon: WeddingIcon, url: "/my-wedding" },
  // ];

  const MenuItems = LinkItems.filter((item) => item) as LinkItemProps[];

  return accessToken ? (
    <>
      <Container minW={"full"} w={"full"} bg={"#22242C"} p={0}>
        <Box
          minH="100vh"
          maxW={"1200px"}
          m={"0 auto"}
          overflow={"hidden"}
          h={"full"}
        >
          <SidebarContent
            onClose={() => onClose}
            display={{ base: "none", lg: "block" }}
            userID={userID as string}
            menu={MenuItems}
          />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={() => onClose}
            size={{ base: "full", md: "xs" }}
          >
            <DrawerOverlay />
            <DrawerContent>
              <SidebarContent
                onClose={onClose}
                userID={userID as string}
                menu={MenuItems}
              />
            </DrawerContent>
          </Drawer>
          {/* mobilenav */}
          <MobileNav
            onOpen={onOpen}
            logOut={logout}
            page={pageName}
            avatar={userDetails?.avatar}
            username={userDetails?.username!}
          />
          <Box
            className="no-scrollbar"
            ml={{ base: 0, lg: 60 }}
            p={{ base: "4", lg: "20px 20px 80px 40px" }}
            bg={"transparent"}
            h={"calc(100vh - 80px)"}
            overflowY={"auto"}
            overflowX="hidden"
          >
            <Outlet />
          </Box>
        </Box>
      </Container>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

interface SidebarProps extends BoxProps {
  onClose: () => void;
  userID?: string;
  menu: any;
}

const SidebarContent = ({ menu, onClose, userID, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={"#22242C"}
      w={{ base: "full", md: 80, lg: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex flexDir={"column"} h={"full"}>
        <Flex
          h="20"
          alignItems="center"
          mx="8"
          justifyContent="space-between"
          mb={"80px"}
        >
          <Image src={UnboxdLogoWhite} alt="unboxd" />
          <CloseButton
            display={{ base: "flex", lg: "none" }}
            onClick={onClose}
          />
        </Flex>
        {menu.map((link: any) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            link={link.url}
            onClose={onClose}
            external={link.external}
            dropdownMenus={link.dropdownMenus}
          >
            {link.name}
          </NavItem>
        ))}
        <Box mt={"auto"} mx="8" mb={"80px"}>
          <Text fontFamily={"Plus Jakarta Sans"} mb={"4"}>
            Find unboxd online
          </Text>
          <Flex justifyContent={"space-between"} w={"150px"}>
            <Link href="https://www.instagram.com/unboxdgifts/" isExternal>
              <Instagram />
            </Link>
            <Link href="https://www.facebook.com/unboxdgifts" isExternal>
              <Facebook />
            </Link>
            <Link href="https://twitter.com/unboxdgifts" isExternal>
              <Twitter />
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: any;
  children: ReactNode;
  link: string;
  external?: boolean;
  onClose?: () => void;
  dropdownMenus?: [{ name: string; url: string }];
}

interface NavLinkItemProps extends Omit<NavItemProps, "link" | "external"> {
  active?: boolean;
}

const NavLinkItem = ({ active, children, icon, ...rest }: NavLinkItemProps) => (
  <Flex
    align="center"
    p="4"
    mx="4"
    borderRadius="lg"
    role="group"
    cursor="pointer"
    color={active ? "#ffffff" : "#ffffff7a"}
    fontFamily={"Plus Jakarta Sans"}
    fontSize={"20px"}
    lineHeight={"29px"}
    fontWeight={400}
    _hover={{
      color: "white",
    }}
    {...rest}
  >
    {icon && <Icon mr="4" fontSize="16" active={active} as={icon} />}
    {children}
  </Flex>
);
const NavItem = ({
  external,
  icon,
  children,
  link,
  onClose,
  dropdownMenus,
}: NavItemProps) => {
  const navigate = useNavigate();
  return external ? (
    <a href={link} target="_blank" rel="noreferrer">
      <NavLinkItem icon={icon}>{children}</NavLinkItem>
    </a>
  ) : link === "#dropdown" ? (
    <>
      <Menu>
        <MenuButton
          // py={2}
          transition="all 0.3s"
          _focus={{ boxShadow: "none" }}
          _hover={{
            color: "white",
          }}
        >
          <HStack>
            <NavLinkItem icon={icon}>{children}</NavLinkItem>
          </HStack>
        </MenuButton>
        <MenuList bg={"#16181D"} borderColor={"#22242c"} zIndex="100">
          {dropdownMenus?.map((v, i) => {
            return (
              <MenuItemCmp
                key={i}
                onClick={() => navigate(v.url)}
                name={v.name}
              />
            );
          })}
        </MenuList>
      </Menu>
    </>
  ) : (
    <NavLink
      to={link}
      style={{ textDecoration: "none" }}
      onClick={() => {
        if (onClose) {
          onClose();
        }
      }}
    >
      {({ isActive }) => (
        <NavLinkItem active={isActive} icon={icon}>
          {children}
        </NavLinkItem>
      )}
    </NavLink>
  );
};

interface IMenuItemCmp {
  name: string;
  onClick?: () => void;
}

export const MenuItemCmp = ({ name, onClick }: IMenuItemCmp) => {
  return (
    <MenuItem
      style={{ fontFamily: "Plus Jakarta Sans", color: "#ffffff80" }}
      _hover={{ bg: "transparent", color: "#ffffff" }}
      _active={{ bg: "transparent", color: "#ffffff" }}
      _focus={{ bg: "transparent", color: "#ffffff" }}
      onClick={onClick}
    >
      {name}
    </MenuItem>
  );
};

interface IUserAvatar {
  avatar?: string;
  logOut: () => void;
  username: string;
}

export const UserAvatar: React.FC<IUserAvatar> = ({
  avatar,
  logOut,
  username,
}) => {
  const navigate = useNavigate();
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  return (
    <HStack spacing={{ base: "0", lg: "6" }}>
      <Flex alignItems={"center"}>
        <Menu>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <HStack>
              <Avatar
                style={{
                  border: "1px solid #CEFABB",
                  background: "#0F441E",
                }}
                size={"sm"}
                src={avatar || AvatarImg}
              />
              {!userDetails?.isOrganisation && (
                <Box display={{ base: "none", md: "flex" }}>
                  <Text fontFamily={"Plus Jakarta Sans"} fontSize="17px">
                    @{username}
                  </Text>
                </Box>
              )}
              <Box display={{ base: "none", lg: "flex" }}>
                <FiChevronDown />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList bg={"#16181D"} borderColor={"#22242c"} zIndex="100">
            <MenuItemCmp onClick={() => navigate("/wallet")} name={"Wallet"} />
            <MenuItemCmp
              onClick={() => navigate("/settings")}
              name={"Settings"}
            />
            {/* <MenuItemCmp name={"Profile"} /> */}
            {/* <MenuItemCmp name={"Billing"} /> */}
            <MenuDivider />
            <MenuItemCmp onClick={logOut} name={"Sign out"} />
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  );
};

interface MobileProps extends FlexProps {
  avatar?: string;
  onOpen: () => void;
  logOut: () => void;
  page: string;
  username: string;
}
const MobileNav = ({
  avatar,
  onOpen,
  logOut,
  page,
  username,
  ...rest
}: MobileProps) => {
  const [sharePageModal, setSharePageModal] = useState(false);
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  // const { shutdown } = useIntercom();
  return (
    <Flex
      ml={{ base: 0, lg: 60 }}
      px={{ base: 4, lg: 4 }}
      height="20"
      alignItems="center"
      bg={"#22242C"}
      justifyContent={{ base: "space-between" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", lg: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<HamBurgerMenu />}
        style={{
          border: 0,
          paddingLeft: 0,
          minWidth: 20,
        }}
      />

      <Image
        display={{ base: "flex", lg: "none" }}
        src={UnboxdLogoWhite}
        alt="unboxd"
      />

      <Text
        display={{ base: "none", lg: "flex" }}
        justifySelf={"unset"}
        m={"0 auto"}
        fontSize={"25px"}
        lineHeight={"36px"}
        fontFamily={"Plus Jakarta Sans"}
      >
        {page}
      </Text>

      <Flex gap="20px">
        {/* <Show breakpoint="(min-width: 768px)">
          <ButtonCmp
            text={
              <>
                <Icon as={IoIosShareAlt} h={5} w={5} /> share page
              </>
            }
            size="md"
            padding="0.2rem 0rem"
            onClick={() => {
              setSharePageModal(true);
            }}
            opacity={"1"}
            border={"none"}
            backgroundColor="rgb(68, 165, 96)"
            textHoverColor={"#fff"}
            color={"#fff"}
            width="130px"
            height="50px"
            maxWidth="100%"
            type="submit"
          />
        </Show> */}
        <UserAvatar
          avatar={avatar}
          logOut={() => {
            logOut();
            // shutdown intercom on logout
            // shutdown();
          }}
          username={username}
        />
      </Flex>

      <SharePageModal
        isOpen={sharePageModal}
        onRequestClose={() => {
          setSharePageModal(false);
        }}
        eventTitle={`${userDetails?.fullname}'s Page`}
        maxWidth="400px"
        showCloseIcon={false}
      />
    </Flex>
  );
};

export default DashboardLayout;
