import { Box, Flex, Text } from "@chakra-ui/react";
import localforage from "localforage";
import DaysLeftCmp from "components/DaysLeftCmp/DaysLeftCmp";
import EmptyCardCmp from "components/EmptyCardCmp";
import ContributorWelcomeModal from "components/Modals/Contributor/ContributorWelcomeModal";
import WishCardCmp from "components/WishCardCmp/WishCardCmp";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { contributorActions, contributorSelectors } from "store/slices";
import { getNumberOfDays } from "utils/date";
import { dashboardData } from "data/dummy";
import NumberFormat from "react-number-format";
import { LogoLoader } from "components";
import FundCollection from "./FundCollection";

const eventVisited = localforage.createInstance({
  name: "Event Visited",
});

const Wishlist = () => {
  const { setShowBackArrow, setType } = useOutletContext<any>();
  const [showWelcome, setShowWelcome] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    setShowBackArrow(false);
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const getAWishlistPublicData = useAppSelector(
    contributorSelectors.getAWishlistPublic
  );


  const wishlistPublicData: any = getAWishlistPublicData?.payload?.wishlistCashfund
    ? getAWishlistPublicData?.payload?.wishlistCashfund
    : getAWishlistPublicData?.payload?.wishlist;

  const { getAWishlistPublic } = contributorActions;

  useEffect(() => {
    setPageLoading(true);
    (async function () {
      dispatch(
        getAWishlistPublic({
          username: params.username!,
          slug: params.slug!,
        })
      )
        .then(async (res: any) => {
          const response = res;
          if (response.payload.statusCode === 500 && response.payload.message) {
            return navigate("/not-found");
          }
          if (response.payload.payload.wishlistCashfund) {
            setType("collection");
          } else {
            setType("wishlist");
          }
          setPageLoading(false);

          /**
           * check if this event has been visited before (saved with localforage),
           * then prevent showing welcome message if it has been visited
           */

          const isEventVisited = await eventVisited.getItem(params.slug!);

          if (!isEventVisited) {
            const delay = setTimeout(() => {
              setShowWelcome(true);
            }, 1000);
            eventVisited.setItem(params.slug!, true);
            return () => clearTimeout(delay);
          }
          return;
        })
        .catch(() => {
          return;
        })
        .finally(() => setPageLoading(false));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getAWishlistPublic, navigate, params.slug, params.username]);
  return (
    <>
      {pageLoading && (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      )}
      {!pageLoading && (
        <>
          {getAWishlistPublicData?.payload?.wishlistCashfund ? (
            <FundCollection collectionPublicData={wishlistPublicData} />
          ) : (
            <Flex flexDir={"column"} gap="30px">
              <Box
                position="relative"
                bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%),url(${wishlistPublicData?.coverImage})`}
                backgroundSize={"cover"}
                backgroundPosition="center"
                backgroundRepeat={"no-repeat"}
                width={"100%"}
                height="227px"
                display={"flex"}
                justifyContent="center"
                borderRadius={"20px"}
              >
                <Flex
                  position={"absolute"}
                  bottom="20px"
                  justifyContent={"space-between"}
                  width="100%"
                  px="1rem"
                >
                  <Text
                    fontFamily={"Helvetica"}
                    fontSize={{ base: "1rem", md: "25px" }}
                    fontWeight={"700"}
                  >
                    {wishlistPublicData?.title}
                  </Text>
                  <DaysLeftCmp
                    num={getNumberOfDays(wishlistPublicData?.date)}
                    text="DAYS LEFT"
                  />
                </Flex>
              </Box>
              <Flex flexDir={"column"} pb="2rem">
                <Text
                  mx={"auto"}
                  mb={"17px"}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"18px"}
                  lineHeight={"24px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  pick an item below
                </Text>
                <Flex
                  flexDir={{ base: "row", md: "row" }}
                  justifyContent={{
                    md:
                      wishlistPublicData?.gifts.length > 6
                        ? "flex-start"
                        : "space-between",
                  }}
                  flexWrap={"wrap"}
                  gap={{ base: "5px", md: "3px" }}
                >
                  {wishlistPublicData?.gifts.map((v: any, i: number) => {
                    return (
                      <WishCardCmp
                        key={i}
                        minW="33%"
                        widthMd="33%"
                        flex="0 0 33%"
                        widthBase="48%"
                        minWidthBase="48%"
                        flexBase="0 0 48%"
                        percentage={Math.floor(
                          (v?.grossPaid?.Amount / v?.netCost?.Amount) * 100
                        )}
                        height="325px"
                        wishTitle={v.title}
                        bgColor="#22242B"
                        contributor
                        onWrapperClick={() => {
                          navigate(
                            `/@${params.username}/${params.slug}/${v._id}`
                          );
                        }}
                        wishPrice={
                          <NumberFormat
                            thousandSeparator
                            value={v.netCost.Amount}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        }
                        grossPaid={
                          <NumberFormat
                            thousandSeparator
                            value={v.grossPaid.Amount}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        }
                        coverImage={v.coverImage}
                        isFulfilled={v.isFulfilled}
                      />
                    );
                  })}
                  {dashboardData
                    .slice(wishlistPublicData?.gifts.length)
                    .map((_, index) => (
                      <EmptyCardCmp
                        minW="33%"
                        widthMd="33%"
                        flex="0 0 33%"
                        widthBase="48%"
                        minWidthBase="48%"
                        flexBase="0 0 48%"
                        bgColor="#22242B"
                        key={index}
                        height="325px"
                      />
                    ))}
                </Flex>
              </Flex>
              <ContributorWelcomeModal
                isOpen={showWelcome}
                onRequestClose={() => {
                  setShowWelcome(false);
                }}
                maxWidth="330px"
                showCloseIcon={false}
                welcomeNote={wishlistPublicData?.welcomeNote}
                wishlistImg={wishlistPublicData?.coverImage}
              />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default Wishlist;
