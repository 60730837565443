import { Box, Flex, Text } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";
import React from "react";

const ShowPendingInviteModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  exchangeTitle: string;
  wishlistImg: string;
  giftExchangeID: string;
  joinAction: () => any;
  inviteLoading: boolean;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  exchangeTitle,
  wishlistImg,
  joinAction,
  inviteLoading,
}) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{
          maxWidth: maxWidth || "350px",
          border: "none",
          backgroundColor: "#16181D",
        }}
        shouldFocusAfterRender={false}
        contentLabel="Welcome"
        id="welcome"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box textAlign={"center"} fontFamily={"Plus Jakarta Sans"}>
          <Box
            position="relative"
            bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 37.98%, #16181D 100%), linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${wishlistImg})`}
            backgroundSize={"cover"}
            backgroundPosition="center"
            backgroundRepeat={"no-repeat"}
            width={"100%"}
            height="150px"
            display={"flex"}
            justifyContent="center"
            borderRadius={"16px 16px 0px 0px"}
          ></Box>
          <Box fontWeight="500" color="#fff" textAlign={"left"} p="1rem">
            <Text fontSize={"16px"} pb="0.5rem">
              {exchangeTitle}
            </Text>
            <Text pb="0.5rem" fontSize={"12px"}>
              You’ve been invited to create your christmas wishlist to be
              fulfilled by a participant you’d be matched with.
            </Text>

            <Flex
              py="1rem"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Text
                fontWeight={"400"}
                textDecor="underline"
                cursor={"pointer"}
                textUnderlineOffset="3px"
                onClick={onRequestClose}
              >
                JOIN LATER
              </Text>
              <Box>
                <ButtonCmp
                  text={"JOIN NOW"}
                  size="md"
                  padding="1rem"
                  borderRadius="16px"
                  opacity={"1"}
                  border={"none"}
                  height="50px"
                  textHoverColor={"#fff"}
                  width="100px"
                  maxWidth="100%"
                  type="submit"
                  isLoading={inviteLoading}
                  onClick={() => {
                    joinAction();
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </ModalCmp>
    </>
  );
};

export default ShowPendingInviteModal;
