interface IProps {
  className?: string;
  fill?: string;
  offset?: string;
  id?: number;
}

const Heart: React.FC<IProps> = (props) => {
  const {fill, offset, id} = props;
  return (
    <svg className={'heart'} viewBox="0 0 40 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2473 0.999997C13.356 1.05442 15.6467 2.03401 17.6646 3.66666C18.3191 4.21088 18.919 4.7551 19.5735 5.29932C19.6281 5.35374 19.9008 5.29932 20.0098 5.19047C21.1552 4.31973 22.3005 3.34013 23.5004 2.52381C25.2456 1.43537 27.1545 0.891154 29.227 0.999997C31.5722 1.10884 33.6993 1.92517 35.4991 3.39456C37.7352 5.19047 38.826 7.58503 39.0441 10.415C39.2623 12.7551 38.7169 14.9864 37.8443 17.1633C36.5353 20.3197 34.3537 22.7687 31.8449 25C29.4997 27.068 26.9909 28.9728 24.373 30.7143C23.5004 31.2585 22.5732 31.8027 21.7006 32.3469C20.3371 33.2177 19.0281 33.0544 17.6646 32.2381C14.5559 30.4422 11.6653 28.3741 8.93831 26.034C7.79298 25.0544 6.59311 24.0204 5.55686 22.8775C3.04805 20.2653 1.24824 17.1633 0.648308 13.5714C0.157452 10.7959 0.484689 8.18367 1.95726 5.73469C3.2662 3.55782 5.22963 2.19728 7.68391 1.43537C8.61108 1.21768 9.64733 1.10884 10.2473 0.999997Z" fill={`url(#paint${id}_linear)`}/>
      <defs>
        <linearGradient id={`paint${id}_linear`} x1="19.7256" y1="32.7724" x2="19.7256" y2="0.791725" gradientUnits="userSpaceOnUse">
          <stop stopColor={fill}/>
          <stop offset="1" stopColor={offset}/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Heart;
