import { Box, Text } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";

const SuccessModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({ isOpen, onRequestClose, maxWidth, showCloseIcon }) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{
          maxWidth: maxWidth || "350px",
          border: "none",
        }}
        shouldFocusAfterRender={false}
        contentLabel="Success"
        id="success"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box fontWeight={"400"} color="#fff" textAlign={"left"} p="1rem">
          <Text pb="0.5rem" fontSize={"16px"}>
            Thank You 😊,
          </Text>
          <Text fontSize={"14px"}>
            I sincerely appreciate you for this gesture. You don't know how much
            it means to me, but I want you to know it means a lot. I can't thank
            you enough.
          </Text>
          <Box py="1rem" display="flex" gap="5px">
            <ButtonCmp
              text={"Finish"}
              size="md"
              padding="1rem"
              borderRadius="16px"
              opacity={"1"}
              border={"none"}
              height="50px"
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="100%"
              type="submit"
              onClick={onRequestClose}
            />
            <ButtonCmp
              text={"Create list"}
              size="md"
              padding="1rem"
              borderRadius="16px"
              opacity={"1"}
              border={"none"}
              height="50px"
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="100%"
              type="submit"
              onClick={() => {
                return window.open(
                  `${process.env.REACT_APP_DOMAIN_NAME}`,
                  "_blank"
                );
              }}
            />
          </Box>
        </Box>
      </ModalCmp>
    </>
  );
};

export default SuccessModal;
