import { Box, Flex, Text } from "@chakra-ui/react";
import DaysLeftCmp from "components/DaysLeftCmp/DaysLeftCmp";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getNumberOfDays } from "utils/date";
import CircularProgressCmp from "components/CircularProgressCmp/CircularProgressCmp";
import { FormikProvider, useFormik } from "formik";
import { CONTRIBUTORVALIDATIONS } from "validators/ContributorValidator";
import * as yup from "yup";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import FundModal from "components/Modals/Contributor/FundModal";
import NumberFormat from "react-number-format";

const FundCollection: React.FC<{
  collectionPublicData: any;
}> = ({ collectionPublicData }) => {
  const fundGiftValidationSchema = yup.object({
    fundAmount: CONTRIBUTORVALIDATIONS.fundAmount,
  });

  const { setShowBackArrow } = useOutletContext<any>();
  const [showFundModal, setShowFundModal] = useState(false);
  useEffect(() => {
    setShowBackArrow(true);
  });

  const formik = useFormik<{ fundAmount: any }>({
    enableReinitialize: true,
    initialValues: {
      fundAmount: "",
    },
    validationSchema: fundGiftValidationSchema,
    onSubmit: () => {
      setShowFundModal(true);
    },
  });
  return (
    <>
      <Flex flexDir={"column"} gap="30px">
        <Box
          position="relative"
          bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%),url(${collectionPublicData?.coverImage})`}
          backgroundSize={"cover"}
          backgroundPosition="center"
          backgroundRepeat={"no-repeat"}
          width={"100%"}
          height="227px"
          display={"flex"}
          justifyContent="center"
          borderRadius={"20px"}
        >
          <Flex
            position={"absolute"}
            bottom="20px"
            justifyContent={"space-between"}
            alignItems="flex-end"
            width="100%"
            px="1rem"
          >
            <Text
              fontFamily={"Helvetica"}
              fontSize={{ base: "1rem", md: "25px" }}
              fontWeight={"700"}
            >
              {collectionPublicData?.title}
            </Text>

            {collectionPublicData?.grossCost && (
              <Flex gap="5px" alignItems={"center"}>
                <Box>
                  <CircularProgressCmp
                    label={`${Math.floor(
                      (collectionPublicData?.grossPaid?.Amount /
                        collectionPublicData?.netCost?.Amount) *
                        100
                    )}%`}
                    value={
                      (collectionPublicData?.grossPaid?.Amount /
                        collectionPublicData?.netCost?.Amount) *
                      100
                    }
                  />
                </Box>
                <Flex flexDir={"column"}>
                  <Text color="#Fff" fontSize={"18px"} fontWeight="400">
                    <NumberFormat
                      prefix="₦"
                      thousandSeparator
                      value={collectionPublicData?.netCost?.Amount}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </Text>
                  <Text
                    color="rgba(255,255,255, 0.5)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    <NumberFormat
                      prefix="₦"
                      suffix=" raised"
                      thousandSeparator
                      value={collectionPublicData?.grossPaid?.Amount}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
          {collectionPublicData?.date && (
            <Flex
              alignItems={"center"}
              gap="15px"
              position="absolute"
              right="15px"
              top="10px"
            >
              <Box>
                <DaysLeftCmp
                  num={getNumberOfDays(collectionPublicData?.date) || 0}
                  text="DAYS LEFT"
                />
              </Box>
            </Flex>
          )}
        </Box>
        <Flex
          flexDir={"column"}
          justifyContent="center"
          alignItems={"center"}
          width="290px"
          margin="0 auto"
        >
          <Text
            mx={"auto"}
            mb={"17px"}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={"18px"}
            lineHeight={"24px"}
            display={"flex"}
            alignItems={"center"}
          >
            God bless the fruits of your labor
          </Text>
          <FormikProvider value={formik}>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: "100%" }}
            >
              <Flex flexDir={"column"} gap="15px">
                <Box>
                  <FormikFormInput
                    background="#22242C"
                    placeholder="amount to pay"
                    isNumericFormat
                    name="fundAmount"
                    isPrice
                    disabled={false}
                    showMessage={true}
                  />
                </Box>
                <ButtonCmp
                  text={"CONTRIBUTE"}
                  size="md"
                  // isLoading={loading}
                  isDisabled={formik.isValid ? false : true}
                  opacity={"1"}
                  border={"none"}
                  textHoverColor={"#fff"}
                  width="100%"
                  maxWidth="100%"
                  type="submit"
                />
              </Flex>
            </form>
          </FormikProvider>
        </Flex>
        <FundModal
          fundInfo={{
            wishlistCashfundID: collectionPublicData?._id,
            recipientID: collectionPublicData?.userID,
            fundAmount: formik.values.fundAmount,
            event: collectionPublicData?.title,
            type: "wishlistcashfund",
          }}
          isCollection={collectionPublicData.isCollection}
          isOpen={showFundModal}
          onRequestClose={() => {
            setShowFundModal(false);
          }}
          maxWidth="330px"
          showCloseIcon={true}
        />
      </Flex>
    </>
  );
};

export default FundCollection;
