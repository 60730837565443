import { Box, Text, Flex, Image, useToast } from "@chakra-ui/react";
import { CopyImg, FacebookImg, TwitterImg, WhatsappImg } from "assets/svgs";
import { useAppSelector } from "hooks";
import React from "react";
import { dashboardSelectors } from "store/slices";
import ModalCmp from "../ModalCmp/ModalCmp";
import { API_URLS } from "apis/urls";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { DownloadIcon } from "@chakra-ui/icons";
import QRCode from "qrcode.react";

const SharePageModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  eventTitle?: any;
  isCollection?: boolean;
  slug?: string;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  eventTitle,
  isCollection,
  slug,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const userDetails: any = useAppSelector(dashboardSelectors.getUser);
  const eventUrl = `${API_URLS.publicUrl.baseURL}/@${userDetails?.username}${
    slug ? `/${slug}` : ""
  }`;
  // const eventUrl = `${API_URLS.publicUrl.localURL}/@${username}/${wishlist?.slug}`; for development - DO NOT DELETE

  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById(
      "qrCode-unboxd"
    ) as HTMLCanvasElement;
    const convertToDataUrl = qrCodeURL
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = convertToDataUrl;
    aEl.download = `${userDetails?.username}-page.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    return toast({
      status: "success",
      description: "QR saved to device successfully",
    });
  };

  const shareToWhatsapp = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url + " \n\n" + title);
    const whatsappSharer = `whatsapp://send?text=${encodedUrl}`;

    window.open(whatsappSharer, "_blank");
  };

  const shareToFacebook = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url);
    const facebookSharer = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${title}`;

    window.open(facebookSharer, "_blank");
  };

  const shareToTwitter = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const twitterSharer = `https://www.twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;

    window.open(twitterSharer, "_blank");
  };

  const copyUrl = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast({
        status: "success",
        description: "Copied to clipboard",
      });
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Share"
        id="share"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={false}
      >
        <Box
          textAlign={"center"}
          padding="2rem 2rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Text
            color="#fff"
            fontWeight={"700"}
            fontSize="30px"
            paddingBottom={"10px"}
            textAlign={"left"}
          >
            {isCollection ? "Share collection" : "Share page"}
          </Text>
          <Text
            color="#c3c3c3"
            fontWeight={"400"}
            fontSize="13px"
            width="200px"
            paddingBottom={"1rem"}
            textAlign={"left"}
          >
            Invite people to view and contribute to your collections
          </Text>
          <Flex
            flexDir={"column"}
            gap={"10px"}
            justifyContent="center"
            alignItems={"center"}
            pt="1rem"
          >
            <Text
              color="#c3c3c3"
              fontWeight={"400"}
              fontSize="16px"
              paddingBottom={"1rem"}
              textAlign={"left"}
            >
              Choose sharing option
            </Text>

            <Flex width="100%" gap={{ base: "15px", md: "20px", lg: "30px" }}>
              <Swiper
                spaceBetween={15}
                slidesPerView={4.5}
                speed={500}
                loop={false}
                touchRatio={1.5}
                mousewheel={{ forceToAxis: true }}
                // style={{ width: "100%" }}
              >
                <SwiperSlide style={{ width: "fit-content" }}>
                  <Flex
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap="8px"
                    onClick={downloadQRCode}
                  >
                    <Box display="none">
                      <QRCode
                        id="qrCode-unboxd"
                        value={eventUrl}
                        size={290}
                        level={"H"}
                        includeMargin={true}
                      />
                    </Box>
                    <Flex
                      justifyContent={"center"}
                      alignItems="center"
                      borderRadius={"50%"}
                      bgColor="#44a560"
                      w="49px"
                      h="49px"
                      cursor="pointer"
                    >
                      <DownloadIcon w={6} h={6} />
                    </Flex>
                    <Text
                      color="#fff"
                      fontWeight={"400"}
                      fontSize="14px"
                      width={"max-content"}
                    >
                      QR Code
                    </Text>
                  </Flex>
                </SwiperSlide>

                <SwiperSlide style={{ width: "fit-content" }}>
                  <Flex
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap="8px"
                    onClick={() => copyUrl(eventUrl)}
                  >
                    <Image
                      cursor="pointer"
                      src={CopyImg}
                      alt="Copy"
                      w="49px"
                      h="49px"
                    />
                    <Text
                      color="#fff"
                      fontWeight={"400"}
                      fontSize="14px"
                      width={"max-content"}
                    >
                      Copy Link
                    </Text>
                  </Flex>
                </SwiperSlide>
                <SwiperSlide style={{ width: "fit-content" }}>
                  <Flex
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap="8px"
                    onClick={() => shareToWhatsapp(eventUrl, eventTitle)}
                  >
                    <Image
                      cursor="pointer"
                      src={WhatsappImg}
                      alt="Copy"
                      w="49px"
                      h="49px"
                    />
                    <Text color="#fff" fontWeight={"400"} fontSize="14px">
                      Whatsapp
                    </Text>
                  </Flex>
                </SwiperSlide>
                <SwiperSlide style={{ width: "fit-content" }}>
                  <Flex
                    flexDir="column"
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap="8px"
                    onClick={() => shareToTwitter(eventUrl, eventTitle)}
                  >
                    <Image
                      cursor="pointer"
                      src={TwitterImg}
                      alt="Copy"
                      w="49px"
                      h="49px"
                    />
                    <Text color="#fff" fontWeight={"400"} fontSize="14px">
                      Twitter
                    </Text>
                  </Flex>
                </SwiperSlide>
                <SwiperSlide style={{ width: "fit-content" }}>
                  <Flex
                    flexDir="column"
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap="8px"
                    onClick={() => shareToFacebook(eventUrl, eventTitle)}
                  >
                    <Image
                      cursor="pointer"
                      src={FacebookImg}
                      alt="Copy"
                      w="49px"
                      h="49px"
                    />
                    <Text color="#fff" fontWeight={"400"} fontSize="14px">
                      Facebook
                    </Text>
                  </Flex>
                </SwiperSlide>
              </Swiper>
            </Flex>
            <Text
              cursor="pointer"
              textDecor={"underline"}
              pt="15px"
              onClick={() => {
                window.open(eventUrl, "_blank");
              }}
            >
              {isCollection ? "preview collection" : "preview page"}
            </Text>
          </Flex>
        </Box>
      </ModalCmp>
    </>
  );
};

export default SharePageModal;
