import { DELETE, GET, POST, PUT, responseCallback } from "../baseAPI";

export type AsoEbi = {
  _id: string,
  name: string,
  isBasic: boolean,
  coverImage: string,
  yardsMeasure: {
    _id: string,
    numberOfYards: number,
    priceOfNumberOfYards: number,
  }[],
  price: number,
  quantityAvailable: number,
  totalNumberOfYardsAvailable: number,
  availabilityStatus: string,
  userID: string,
  weddingID: string,
};

export type AsoEbiPayer = {
  _id: string,
  asoEbis: {
    asoEbiID: string,
    yardsMeasureID: string,
    quantity: number,
    _id: string
  }[],
  address: string,
  fullName: string,
  email: string,
  phoneNumber: string,
  amount: number,
  fee: number,
  ownerID: string,
  weddingID: string,
  transactionReference: string,
  transactionStatus: string,
}

export type AsoEbiYardMeasure = {
  _id: string,
  asoEbiID: string,
  numberOfYards: number,
  priceOfNumberOfYards: number,
  userID: string,
  weddingID: string,
};

export type AsoEbiPayment = {
  _id: string,
  isBasic: boolean,
  asoEbiID: string,
  yardsMeasureID: string,
  price: number,
  quantity: number,
  sales: number,
  asoEbiPayerID: string,
  ownerID: string,
  weddingID: string,
};


export enum AsoEbiAvailabilityStatus {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock',
}

export type CreateAsoEbiAndYardsMeasureParams = Partial<{
  name: string;
  coverImage: string;
  totalNumberOfYardsAvailable: number;
  availabilityStatus: AsoEbiAvailabilityStatus;
  isBasic: boolean;
  price: number;
  quantityAvailable: number;
  userID: string;
  weddingID: string;
  yardsMeasure: {
    numberOfYards: number;
    priceOfNumberOfYards: number;
  }[];
}>;
export const createAsoEbiAndYardsMeasure = (CreateAsoEbiAndYardsMeasureParams: CreateAsoEbiAndYardsMeasureParams, responseCallback: responseCallback<AsoEbi[]>) => POST(
  {
    ext: 'aso-ebi/create-detailed',
    body: CreateAsoEbiAndYardsMeasureParams
  },
  responseCallback,
);

export type EditAsoEbiAndYardsMeasureParams = Partial<{
  asoEbiID: string;
  name: string;
  coverImage: string;
  totalNumberOfYardsAvailable: number;
  availabilityStatus: AsoEbiAvailabilityStatus;
  isBasic: boolean;
  price: number;
  quantityAvailable: number;
  userID: string;
  yardsMeasure: {
    asoEbiYardsMeasureID: string;
    numberOfYards: number;
    priceOfNumberOfYards: number;
  }[];
}>;
export const editAsoEbiAndYardsMeasure = (EditAsoEbiAndYardsMeasureParams: EditAsoEbiAndYardsMeasureParams, responseCallback: responseCallback<AsoEbi[]>) => PUT(
  {
    ext: 'aso-ebi/edit-detailed',
    body: EditAsoEbiAndYardsMeasureParams
  },
  responseCallback,
);


type DeleteAsoEbiParams = {
  asoEbiID: string;
};
export const deleteAsoEbi = ({asoEbiID}: DeleteAsoEbiParams, responseCallback: responseCallback<AsoEbi[]>) => DELETE(
  {
    ext: 'aso-ebi',
    params: asoEbiID,
  },
  responseCallback,
);


type GetAllAsoEbiParams = Partial<{
  weddingID: string;
}>;
export const getAllAsoEbi = ({weddingID}: GetAllAsoEbiParams, responseCallback: responseCallback<AsoEbi[]>) => GET(
  {
    ext: 'aso-ebi/all',
    params: weddingID,
  },
  responseCallback,
);


type GetAllAsoEbiPayersParams = Partial<{}>;
// eslint-disable-next-line
export const getAllAsoEbiPayers = ({}: GetAllAsoEbiPayersParams, responseCallback: responseCallback<GetAllAsoEbiPayersResponse>
) => GET(
  {
    ext: 'aso-ebi/pay/all-payers',
  },
  responseCallback,
);

export type GetAllAsoEbiPayersResponse = {
  asoEbiPayers: AsoEbiPayer[];
  asoEbis: (
    AsoEbi & 
    {
      count: number, 
      payers: AsoEbiPayer[]
    })[];
};

type GetAllAsoEbiPaymentsForWeddingParams = Partial<{}>;
// eslint-disable-next-line
export const getAllAsoEbiPayementsForWedding = ({}: GetAllAsoEbiPaymentsForWeddingParams, responseCallback: responseCallback<GetAllAsoEbiPaymentsForWeddingResponse>
) => GET(
  {
    ext: 'aso-ebi/pay/all-payments-wedding',
  },
  responseCallback,
);

export type GetAllAsoEbiPaymentsForWeddingResponse = {
  asoEbiID: string,
  name: string,
  asoEbiPayers: {
    asoEbiPayerID: string,
    asoEbiPaymentID: string,
    fullName: string,
    email: string,
    price: number,
    quantity: number,
    phoneNumber: string,
    address: string
  }[];
}[];
