import {
  Box,
  Text,
  Flex,
  Image,
  Textarea,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { CopyImg, FacebookImg, TwitterImg, WhatsappImg } from "assets/svgs";
import { useAppDispatch, useAppSelector } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { dashboardSelectors, wishlistActions } from "store/slices";
import ModalCmp from "../ModalCmp/ModalCmp";
import { API_URLS } from "apis/urls";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Controller } from "swiper";
import SwiperCore, { Keyboard, Mousewheel } from "swiper";
import { DownloadIcon } from "@chakra-ui/icons";
import QRCode from "qrcode.react";

const ShareModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  wishlist?: any;
  customFn?: any;
  showWelcomeNote?: boolean;
  shareTitle?: boolean;
  context: string;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  wishlist,
  customFn,
  showWelcomeNote,
  shareTitle,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const dispatch = useAppDispatch();
  const userDetails: any = useAppSelector(dashboardSelectors.getUser);
  const appType = localStorage.getItem("userType");
  const appRoute =
    appType === "Organisation"
      ? API_URLS.appRoute.ticket
      : API_URLS.appRoute.gift;
  const extRoute =
    appType === "Organisation"
      ? wishlist
        ? wishlist.ubxdid
        : ""
      : `@${userDetails?.username}/${wishlist ? wishlist?.slug : ""}`;

  const eventUrl = `${API_URLS.publicUrl.baseURL}/${appRoute}/${extRoute}`;

  const eventIDUrl = `<iframe src="https://unboxd.co/f/${wishlist._id}" width="550px" height="600px" >Loading…</iframe>`;
  // const eventIDUrl = `<iframe src="https://unboxd.co/f/${wishlist._id}/viewform?embedded=true" width="640" height="375" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>`;
  // const eventUrl2 = `${API_URLS.publicUrl.baseURL}/${
  //   API_URLS.appRoute[appType === "Organisation" ? "ticket" : "gift"]
  // }/@${userDetails?.username}/${wishlist?.slug}`;
  // const eventUrl = `${API_URLS.publicUrl.localURL}/@${username}/${wishlist?.slug}`; for development - DO NOT DELETE

  const [welcomeNoteData, setWelcomeNoteData] = useState(
    wishlist?.welcomeNote || ""
  );

  const downloadQRCode = () => {
    const qrCodeURL = document.getElementById(
      "qrCode-unboxd"
    ) as HTMLCanvasElement;
    const convertToDataUrl = qrCodeURL
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = convertToDataUrl;
    aEl.download = `${userDetails?.username}-Wishlist.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
    return toast({
      status: "success",
      description: "QR saved to device successfully",
    });
  };

  useEffect(() => {
    setWelcomeNoteData(wishlist?.welcomeNote);
  }, [wishlist?.welcomeNote]);

  const [showThis, setShowThis] = useState(false);
  const [writeNote, setWriteNote] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [, setLoading] = useState(false);

  const { saveWishNote } = wishlistActions;

  const writeNewRef = useRef<any>(null);
  const writeNoteRef = useRef<HTMLSpanElement>(null);
  const showThisRef = useRef<HTMLSpanElement>(null);
  const whatisRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | any) => {
    setWelcomeNoteData(e.target.value);
  };

  const showWhatIs = () => {
    setShowThis(!showThis);
    if (!showThis && whatisRef.current && showThisRef.current) {
      showThisRef.current.style.zIndex = "20";
      whatisRef.current.style.display = "flex";
    }
    if (showThis && whatisRef.current && showThisRef.current) {
      showThisRef.current.style.zIndex = "0";
      whatisRef.current.style.display = "none";
    }
  };

  const saveNote = async () => {
    const payload = new FormData();

    payload.append("welcomeNote", welcomeNoteData);
    payload.append("wishlistID", wishlist?._id);

    if (!welcomeNoteData) {
      return toast({
        status: "error",
        description: "Update welcome note.",
      });
    }

    setLoading(true);
    dispatch(
      saveWishNote({
        welcomeNote: welcomeNoteData || wishlist.welcomeNote!,
        wishlistID: wishlist?._id,
      })
    )
      .then((res: any) => {
        const response = res;

        if (
          response.payload.statusCode === 200 &&
          response.payload.message === "wishlist update succesful"
        ) {
          customFn();

          setDisabled(true);
          setWriteNote(false);
          setLoading(false);
          return toast({
            status: "success",
            description: "Note saved successfully",
          });
        }
      })
      .catch(() => {
        return toast({
          status: "error",
          description: "There is an error",
        });
      })
      .finally(() => {
        setDisabled(true);
        setWriteNote(false);
        setLoading(false);
      });
  };

  const writeNew = () => {
    setWriteNote(!writeNote);
    setDisabled(!writeNewRef.current?.disabled);
    if (!writeNote && writeNoteRef.current && showThisRef.current) {
      writeNoteRef.current.style.zIndex = "20";
      showThisRef.current.style.zIndex = "20";
    }

    if (writeNote && writeNoteRef.current && showThisRef.current) {
      writeNoteRef.current.style.zIndex = "0";
      showThisRef.current.style.zIndex = "0";
    }
  };

  const closeEditor = () => {
    setLoading(false);
    setDisabled(true);
    setShowThis(false);
    setWriteNote(false);

    if (whatisRef.current && showThisRef.current && writeNoteRef.current) {
      showThisRef.current.style.zIndex = "0";
      writeNoteRef.current.style.zIndex = "0";
      whatisRef.current.style.display = "none";
    }
  };

  const shareToWhatsapp = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url + " \n\n" + title);
    const whatsappSharer = `whatsapp://send?text=${encodedUrl}`;

    window.open(whatsappSharer, "_blank");
  };

  const shareToFacebook = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url);
    const facebookSharer = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${title}`;

    window.open(facebookSharer, "_blank");
  };

  const shareToTwitter = (url: string, title: string) => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const twitterSharer = `https://www.twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;

    window.open(twitterSharer, "_blank");
  };

  const copyUrl = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);
      toast({
        status: "success",
        description: "Copied to clipboard",
      });
    } catch (err) {
      return err;
    }
  };

  SwiperCore.use([Keyboard, Mousewheel]);

  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Share"
        id="share"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={false}
      >
        <Box
          textAlign={"center"}
          padding="2rem 2rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Text
            color="#fff"
            fontWeight={"700"}
            fontSize="30px"
            paddingBottom={"1rem"}
            textAlign={"left"}
          >
            {shareTitle ? "share event" : " share wishlist"}
          </Text>
          {showWelcomeNote && (
            <Box
              backgroundColor={"#2d3039"}
              padding="32px 16px"
              borderRadius={"10px"}
              position="relative"
            >
              <Textarea
                backgroundColor={"transparent"}
                border="none"
                focusBorderColor="#fff"
                resize={"none"}
                placeholder={
                  whatisRef.current?.style.display === "flex"
                    ? ""
                    : "Welcome note"
                }
                value={!showThis ? welcomeNoteData : ""}
                maxLength={230}
                disabled={disabled}
                size={"md"}
                rows={4}
                autoFocus
                ref={writeNewRef}
                // display={showThis ? "none" : "unset"}
                name="welcomeNote"
                onChange={handleChange}
                _disabled={{ opacity: "1" }}
              ></Textarea>
              <Text
                position="absolute"
                top="1rem"
                left="1rem"
                color={"rgba(255, 255, 255, 0.5)"}
                fontSize="0.75rem"
              >
                Welcome note
              </Text>
              <Text
                position="absolute"
                top="1rem"
                right="1.5rem"
                color={"rgba(255,255,255,0.5)"}
                fontSize="0.75rem"
              >
                {writeNote && `${welcomeNoteData.length}/230`}
              </Text>
              <Box
                display={"none"}
                marginBottom="1rem"
                position="absolute"
                top="1rem"
                width="90%"
                padding="10px"
                ref={whatisRef}
                marginTop="10px"
              >
                <p style={{ textAlign: "left" }}>
                  When you share your wishlist with loved ones, your welcome
                  note is what they first see before getting into your wishlist.
                </p>
              </Box>
              <Box
                display="flex"
                justifyContent={"space-between"}
                maxWidth="200px"
                width="100%"
                margin="0 auto"
                padding="10px 0 10px"
                position="relative"
              >
                <span
                  style={{
                    fontSize: "13px",
                    color: "#F7D38F",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  ref={writeNoteRef}
                  onClick={writeNote ? saveNote : writeNew}
                >
                  {writeNote ? "save note" : showThis ? " " : "edit note"}
                </span>
                <span
                  style={{
                    fontSize: "13px",
                    color: `${writeNote ? "#fff" : "#F7D38F"}`,
                    textDecoration: `${writeNote ? "none" : "underline"}`,
                    cursor: "pointer",
                  }}
                  ref={showThisRef}
                  onClick={writeNote ? closeEditor : showWhatIs}
                >
                  {writeNote
                    ? "cancel"
                    : showThis
                    ? "okay, close"
                    : "what's this?"}
                </span>
              </Box>
            </Box>
          )}

          {appType === "Organisation" ? (
            <Tabs>
              <TabList>
                <Tab>Whole Page</Tab>
                <Tab>
                  Embeded Form
                  <span
                    style={{
                      marginLeft: "10px",
                      color: "#000",
                      backgroundColor: "#A9F389",
                      padding: "6px 8px",
                      borderRadius: "36px",
                      fontSize: "8px",
                      lineHeight: "11px",
                    }}
                  >
                    NEW!
                  </span>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Flex
                    flexDir={"column"}
                    gap={"10px"}
                    justifyContent="center"
                    alignItems={"center"}
                    pt="1rem"
                  >
                    {/* <Text
              color="#c3c3c3"
              fontWeight={"400"}
              fontSize="16px"
              paddingBottom={"1rem"}
              textAlign={"left"}
            >
              Choose sharing option
            </Text> */}

                    <Box
                      mb="1rem"
                      borderRadius={"20px"}
                      background="#2d3039"
                      p="1rem 1rem"
                      width="100%"
                    >
                      <Text
                        pb="0"
                        color="#fff"
                        fontWeight={"400"}
                        fontSize="14px"
                        textAlign={"left"}
                      >
                        {eventUrl.length > 35
                          ? `${eventUrl.slice(0, 35)}...`
                          : eventUrl}
                      </Text>
                    </Box>

                    <Flex
                      width="100%"
                      gap={{ base: "15px", md: "20px", lg: "30px" }}
                    >
                      <Swiper
                        modules={[Controller]}
                        keyboard={true}
                        direction="horizontal"
                        mousewheel={true}
                        spaceBetween={15}
                        slidesPerView={4.5}
                        speed={500}
                        loop={false}
                        touchRatio={1.5}
                        // mousewheel={{ forceToAxis: true }}
                        scrollbar={{ draggable: true }}
                        // style={{ width: "100%" }}
                      >
                        <SwiperSlide style={{ width: "fit-content" }}>
                          <Flex
                            flexDir="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap="8px"
                            onClick={downloadQRCode}
                          >
                            <Box display="none">
                              <QRCode
                                id="qrCode-unboxd"
                                value={eventUrl}
                                size={290}
                                level={"H"}
                                includeMargin={true}
                              />
                            </Box>
                            <Flex
                              justifyContent={"center"}
                              alignItems="center"
                              borderRadius={"50%"}
                              bgColor="#44a560"
                              w="49px"
                              h="49px"
                              cursor="pointer"
                            >
                              <DownloadIcon w={6} h={6} />
                            </Flex>
                            <Text
                              color="#fff"
                              fontWeight={"400"}
                              fontSize="14px"
                              width={"max-content"}
                            >
                              QR Code
                            </Text>
                          </Flex>
                        </SwiperSlide>

                        <SwiperSlide style={{ width: "fit-content" }}>
                          <Flex
                            flexDir="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap="8px"
                            onClick={() => copyUrl(eventUrl)}
                          >
                            <Image
                              cursor="pointer"
                              src={CopyImg}
                              alt="Copy"
                              w="49px"
                              h="49px"
                            />
                            <Text
                              color="#fff"
                              fontWeight={"400"}
                              fontSize="14px"
                              width={"max-content"}
                            >
                              Copy Link
                            </Text>
                          </Flex>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: "fit-content" }}>
                          <Flex
                            flexDir="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap="8px"
                            onClick={() =>
                              shareToWhatsapp(eventUrl, wishlist.title)
                            }
                          >
                            <Image
                              cursor="pointer"
                              src={WhatsappImg}
                              alt="Copy"
                              w="49px"
                              h="49px"
                            />
                            <Text
                              color="#fff"
                              fontWeight={"400"}
                              fontSize="14px"
                            >
                              Whatsapp
                            </Text>
                          </Flex>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: "fit-content" }}>
                          <Flex
                            flexDir="column"
                            justifyContent={"center"}
                            alignItems={"center"}
                            gap="8px"
                            onClick={() =>
                              shareToTwitter(eventUrl, wishlist.title)
                            }
                          >
                            <Image
                              cursor="pointer"
                              src={TwitterImg}
                              alt="Copy"
                              w="49px"
                              h="49px"
                            />
                            <Text
                              color="#fff"
                              fontWeight={"400"}
                              fontSize="14px"
                            >
                              Twitter
                            </Text>
                          </Flex>
                        </SwiperSlide>
                        <SwiperSlide style={{ width: "fit-content" }}>
                          <Flex
                            flexDir="column"
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap="8px"
                            onClick={() =>
                              shareToFacebook(eventUrl, wishlist.title)
                            }
                          >
                            <Image
                              cursor="pointer"
                              src={FacebookImg}
                              alt="Copy"
                              w="49px"
                              h="49px"
                            />
                            <Text
                              color="#fff"
                              fontWeight={"400"}
                              fontSize="14px"
                            >
                              Facebook
                            </Text>
                          </Flex>
                        </SwiperSlide>
                      </Swiper>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex
                    flexDir={"column"}
                    gap={"10px"}
                    justifyContent="center"
                    alignItems={"center"}
                    pt="1rem"
                  >
                    {/* <Text
              color="#c3c3c3"
              fontWeight={"400"}
              fontSize="16px"
              paddingBottom={"1rem"}
              textAlign={"left"}
            >
              Choose sharing option
            </Text> */}

                    <Box
                      mb="1rem"
                      borderRadius={"20px"}
                      background="#2d3039"
                      p="1rem 1rem"
                      width="100%"
                    >
                      <Text
                        pb="0"
                        color="#fff"
                        fontWeight={"400"}
                        fontSize="14px"
                        textAlign={"left"}
                      >
                        {eventIDUrl}
                      </Text>
                    </Box>
                    <Flex
                      flexDir="column"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap="8px"
                      onClick={() => copyUrl(eventIDUrl)}
                    >
                      <Image
                        cursor="pointer"
                        src={CopyImg}
                        alt="Copy"
                        w="49px"
                        h="49px"
                      />
                      <Text
                        color="#fff"
                        fontWeight={"400"}
                        fontSize="14px"
                        width={"max-content"}
                      >
                        Copy Code
                      </Text>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          ) : (
            <Flex
              flexDir={"column"}
              gap={"10px"}
              justifyContent="center"
              alignItems={"center"}
              pt="1rem"
            >
              {/* <Text
              color="#c3c3c3"
              fontWeight={"400"}
              fontSize="16px"
              paddingBottom={"1rem"}
              textAlign={"left"}
            >
              Choose sharing option
            </Text> */}
              <Box
                mb="1rem"
                borderRadius={"20px"}
                background="#2d3039"
                p="1rem 1rem"
                width="100%"
              >
                <Text
                  pb="0"
                  color="#fff"
                  fontWeight={"400"}
                  fontSize="14px"
                  textAlign={"left"}
                >
                  {eventUrl.length > 35
                    ? `${eventUrl.slice(0, 35)}...`
                    : eventUrl}
                </Text>
              </Box>

              <Flex width="100%" gap={{ base: "15px", md: "20px", lg: "30px" }}>
                <Swiper
                  modules={[Controller]}
                  keyboard={true}
                  direction="horizontal"
                  mousewheel={true}
                  spaceBetween={15}
                  slidesPerView={4.5}
                  speed={500}
                  loop={false}
                  touchRatio={1.5}
                  // mousewheel={{ forceToAxis: true }}
                  scrollbar={{ draggable: true }}
                  // style={{ width: "100%" }}
                >
                  <SwiperSlide style={{ width: "fit-content" }}>
                    <Flex
                      flexDir="column"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap="8px"
                      onClick={downloadQRCode}
                    >
                      <Box display="none">
                        <QRCode
                          id="qrCode-unboxd"
                          value={eventUrl}
                          size={290}
                          level={"H"}
                          includeMargin={true}
                        />
                      </Box>
                      <Flex
                        justifyContent={"center"}
                        alignItems="center"
                        borderRadius={"50%"}
                        bgColor="#44a560"
                        w="49px"
                        h="49px"
                        cursor="pointer"
                      >
                        <DownloadIcon w={6} h={6} />
                      </Flex>
                      <Text
                        color="#fff"
                        fontWeight={"400"}
                        fontSize="14px"
                        width={"max-content"}
                      >
                        QR Code
                      </Text>
                    </Flex>
                  </SwiperSlide>

                  <SwiperSlide style={{ width: "fit-content" }}>
                    <Flex
                      flexDir="column"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap="8px"
                      onClick={() => copyUrl(eventUrl)}
                    >
                      <Image
                        cursor="pointer"
                        src={CopyImg}
                        alt="Copy"
                        w="49px"
                        h="49px"
                      />
                      <Text
                        color="#fff"
                        fontWeight={"400"}
                        fontSize="14px"
                        width={"max-content"}
                      >
                        Copy Link
                      </Text>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide style={{ width: "fit-content" }}>
                    <Flex
                      flexDir="column"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap="8px"
                      onClick={() => shareToWhatsapp(eventUrl, wishlist.title)}
                    >
                      <Image
                        cursor="pointer"
                        src={WhatsappImg}
                        alt="Copy"
                        w="49px"
                        h="49px"
                      />
                      <Text color="#fff" fontWeight={"400"} fontSize="14px">
                        Whatsapp
                      </Text>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide style={{ width: "fit-content" }}>
                    <Flex
                      flexDir="column"
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap="8px"
                      onClick={() => shareToTwitter(eventUrl, wishlist.title)}
                    >
                      <Image
                        cursor="pointer"
                        src={TwitterImg}
                        alt="Copy"
                        w="49px"
                        h="49px"
                      />
                      <Text color="#fff" fontWeight={"400"} fontSize="14px">
                        Twitter
                      </Text>
                    </Flex>
                  </SwiperSlide>
                  <SwiperSlide style={{ width: "fit-content" }}>
                    <Flex
                      flexDir="column"
                      alignItems={"center"}
                      justifyContent={"center"}
                      gap="8px"
                      onClick={() => shareToFacebook(eventUrl, wishlist.title)}
                    >
                      <Image
                        cursor="pointer"
                        src={FacebookImg}
                        alt="Copy"
                        w="49px"
                        h="49px"
                      />
                      <Text color="#fff" fontWeight={"400"} fontSize="14px">
                        Facebook
                      </Text>
                    </Flex>
                  </SwiperSlide>
                </Swiper>
              </Flex>
            </Flex>
          )}
        </Box>
      </ModalCmp>
    </>
  );
};

export default ShareModal;
