import { GetDashboardResponse } from "apis";
import { GET, responseCallback } from "../baseAPI";


export const getDashboard = (responseCallback: responseCallback<GetDashboardResponse["payload"]>) => GET(
  {
    ext: 'dashboard',
  },
  responseCallback,
);
