import { Box } from "@chakra-ui/react";
import React from "react";
import ModalCmp from "../../ModalCmp/ModalCmp";

const EditorModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  content: React.ReactNode;
  name: string;
}> = ({ isOpen, onRequestClose, maxWidth, showCloseIcon, content, name }) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel={name}
        id={name}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
        position={"right"}
        full
        fullHeight
        fullWidth
      >
        <Box
          flexDir={"column"}
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          p="2rem 1.5rem"
        >
          {content}
        </Box>
      </ModalCmp>
    </>
  );
};

export default EditorModal;
