import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateBankAccount,
  CreateBankAccountResponse,
  CreatePayout,
  CreatePayoutResponse,
  CreateTransactionPayout,
  CreateTransactionPayoutResponse,
  GetBankAccounts,
  GetBankAccountsResponse,
  GetBanks,
  GetBanksResponse,
  GetWalletTotalBalance,
  GetWalletTotalBalanceResponse,
  GetWishlistWalletResponse,
  GetWishlistWallets,
  MRequest,
  PostBankSearch,
  PostBankSearchResponse,
  VerifyBankAccount,
  VerifyBankAccountResponse,
  VerifyPayoutAmount,
  VerifyPayoutAmountResponse,
  VerifyPayoutRequest,
  VerifyPayoutRequestResponse,
} from "apis";
import { RootState } from "store/reducers";

const entity = "wishlist";

const getWishlistWallets = createAsyncThunk(
  "list/wishlistWallets",
  async (params?: { otherParam?: string }) => {
    const res = await MRequest.get<typeof GetWishlistWallets.RES>(
      `${GetWishlistWallets.ROUTE}${
        params?.otherParam ? params?.otherParam : ""
      }`
    );
    return res.data;
  }
);

const verifyPayoutAmount = createAsyncThunk(
  "list/verifyPayoutAmount",
  async (data: typeof VerifyPayoutAmount.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof VerifyPayoutAmount.RES>(
        VerifyPayoutAmount.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const verifyPayoutRequest = createAsyncThunk(
  "list/verifyPayoutRequest",
  async (data: typeof VerifyPayoutRequest.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof VerifyPayoutRequest.RES>(
        VerifyPayoutRequest.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getWalletTotalBalance = createAsyncThunk(
  "list/walletTotalBalance",
  async () => {
    const res = await MRequest.get<typeof GetWalletTotalBalance.RES>(
      GetWalletTotalBalance.ROUTE
    );
    return res.data;
  }
);

const getBankAccounts = createAsyncThunk("list/getBankAccounts", async () => {
  const res = await MRequest.get<typeof GetBankAccounts.RES>(
    GetBankAccounts.ROUTE
  );
  return res.data;
});

const postBankSearch = createAsyncThunk(
  "list/postBankSearch",
  async (data: typeof PostBankSearch.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostBankSearch.RES>(
        PostBankSearch.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const verifyBankAccount = createAsyncThunk(
  "list/verifyBankAccount",
  async (data: typeof VerifyBankAccount.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof VerifyBankAccount.RES>(
        VerifyBankAccount.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createBankAccount = createAsyncThunk(
  "list/createBankAccount",
  async (data: typeof CreateBankAccount.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreateBankAccount.RES>(
        CreateBankAccount.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createPayout = createAsyncThunk(
  "list/createPayout",
  async (data: typeof CreatePayout.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreatePayout.RES>(
        CreatePayout.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createTransactionPayout = createAsyncThunk(
  "list/createTransactionPayout",
  async (data: typeof CreateTransactionPayout.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreateTransactionPayout.RES>(
        CreateTransactionPayout.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getBanks = createAsyncThunk("list/getBanks", async () => {
  const res = await MRequest.get<typeof GetBanks.RES>(GetBanks.ROUTE);
  return res.data;
});

export type WalletInitialStateType = {
  getWishlistWalletsResponse: GetWishlistWalletResponse | null;
  verifyPayoutAmountResponse: VerifyPayoutAmountResponse | null;
  getWalletTotalBalanceResponse: GetWalletTotalBalanceResponse | null;
  getBankAccountsResponse: GetBankAccountsResponse | null;
  bankSearchResponse: PostBankSearchResponse | null;
  verifyBankAccountResponse: VerifyBankAccountResponse | null;
  createBankAccountResponse: CreateBankAccountResponse | null;
  verifyPayoutRequestResponse: VerifyPayoutRequestResponse | null;
  createPayoutResponse: CreatePayoutResponse | null;
  createTransactionPayoutResponse: CreateTransactionPayoutResponse | null;
  getBanksResponse: GetBanksResponse | null;
};

const initialState: WalletInitialStateType = {
  getWishlistWalletsResponse: null,
  verifyPayoutAmountResponse: null,
  getWalletTotalBalanceResponse: null,
  getBankAccountsResponse: null,
  bankSearchResponse: null,
  verifyBankAccountResponse: null,
  createBankAccountResponse: null,
  verifyPayoutRequestResponse: null,
  createPayoutResponse: null,
  createTransactionPayoutResponse: null,
  getBanksResponse: null,
};

const walletSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getWishlistWallets.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWishlistWalletsResponse = action.payload;
      }
    );
    builder.addCase(
      getWishlistWallets.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWishlistWalletsResponse = action.payload;
      }
    );
    builder.addCase(
      verifyPayoutAmount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.verifyPayoutAmountResponse = action.payload;
      }
    );
    builder.addCase(
      verifyPayoutAmount.rejected,
      (state, action: PayloadAction<any>) => {
        state.verifyPayoutAmountResponse = action.payload;
      }
    );
    builder.addCase(
      getWalletTotalBalance.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWalletTotalBalanceResponse = action.payload;
      }
    );
    builder.addCase(
      getWalletTotalBalance.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWalletTotalBalanceResponse = action.payload;
      }
    );
    builder.addCase(
      getBankAccounts.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getBankAccountsResponse = action.payload;
      }
    );
    builder.addCase(
      getBankAccounts.rejected,
      (state, action: PayloadAction<any>) => {
        state.getBankAccountsResponse = action.payload;
      }
    );
    builder.addCase(
      postBankSearch.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.bankSearchResponse = action.payload;
      }
    );
    builder.addCase(
      postBankSearch.rejected,
      (state, action: PayloadAction<any>) => {
        state.bankSearchResponse = action.payload;
      }
    );
    builder.addCase(
      verifyBankAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.verifyBankAccountResponse = action.payload;
      }
    );
    builder.addCase(
      verifyBankAccount.rejected,
      (state, action: PayloadAction<any>) => {
        state.verifyBankAccountResponse = action.payload;
      }
    );
    builder.addCase(
      createBankAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createBankAccountResponse = action.payload;
      }
    );
    builder.addCase(
      createBankAccount.rejected,
      (state, action: PayloadAction<any>) => {
        state.createBankAccountResponse = action.payload;
      }
    );
    builder.addCase(
      verifyPayoutRequest.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.verifyPayoutRequestResponse = action.payload;
      }
    );
    builder.addCase(
      verifyPayoutRequest.rejected,
      (state, action: PayloadAction<any>) => {
        state.verifyPayoutRequestResponse = action.payload;
      }
    );
    builder.addCase(
      createPayout.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createPayoutResponse = action.payload;
      }
    );
    builder.addCase(
      createPayout.rejected,
      (state, action: PayloadAction<any>) => {
        state.createPayoutResponse = action.payload;
      }
    );
    builder.addCase(getBanks.fulfilled, (state, action: PayloadAction<any>) => {
      state.getBanksResponse = action.payload;
    });
    builder.addCase(getBanks.rejected, (state, action: PayloadAction<any>) => {
      state.getBanksResponse = action.payload;
    });
  },
});

export const walletActions = {
  ...walletSlice.actions,
  getWishlistWallets,
  verifyPayoutAmount,
  getWalletTotalBalance,
  getBankAccounts,
  postBankSearch,
  verifyBankAccount,
  createBankAccount,
  verifyPayoutRequest,
  createPayout,
  createTransactionPayout,
  getBanks,
};

export const walletSelectors = {
  getWishlistWallets: (state: RootState) =>
    state.wallet.getWishlistWalletsResponse,
  verifyPayoutAmount: (state: RootState) =>
    state.wallet.verifyPayoutAmountResponse,
  getWalletTotalBalance: (state: RootState) =>
    state.wallet.getWalletTotalBalanceResponse,
  getBankAccounts: (state: RootState) => state.wallet.getBankAccountsResponse,
  postBankSearch: (state: RootState) => state.wallet.bankSearchResponse,
  verifyBankAccount: (state: RootState) =>
    state.wallet.verifyBankAccountResponse,
  createBankAccount: (state: RootState) =>
    state.wallet.createBankAccountResponse,
  verifyPayoutRequest: (state: RootState) =>
    state.wallet.verifyPayoutRequestResponse,
  createPayoutResponse: (state: RootState) => state.wallet.createPayoutResponse,
  createTransactionPayoutResponse: (state: RootState) => state.wallet.createTransactionPayoutResponse,
  getBanksResponse: (state: RootState) => state.wallet.getBanksResponse,
};

export const walletReducer = walletSlice.reducer;
