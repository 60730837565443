import { Flex } from "@chakra-ui/react";
import { LogoLoader } from "components";

const PageLoader = () => (
  <Flex
    justify={"center"}
    alignItems={"center"}
    pos={"fixed"}
    zIndex={3}
    width={"full"}
    height={"100vh"}
    overflow={"hidden"}
  >
    <LogoLoader size="md" />
  </Flex>
);

export default PageLoader;
