import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MRequest,
  CreateWedding,
  GetWedding,
  AWeddingResponse,
  CreateWeddingSchedule,
  AddWishlistToWedding,
  AddFaqToWedding,
  GetWeddingDashboard,
  AWeddingDashboardResponse,
  EditWeddingSchedule,
} from "apis";
import { RootState } from "store/reducers";

const entity = "wedding";

const createWedding = createAsyncThunk(
  "wedding/create",
  async (data: typeof CreateWedding.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreateWedding.RES>(
        CreateWedding.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAWedding = createAsyncThunk(
  "wedding/get",
  async (params: { userId: string }) => {
    const res = await MRequest.get<typeof GetWedding.RES>(
      `${GetWedding.ROUTE}?userID=${params.userId}`
    );
    return res.data;
  }
);

const getAWeddingDashboard = createAsyncThunk(
  "wedding/dashboard/get",
  async (params: { userId: string }) => {
    const res = await MRequest.get<typeof GetWeddingDashboard.RES>(
      `${GetWeddingDashboard.ROUTE}?userID=${params.userId}`
    );
    return res.data;
  }
);

const createWeddingSchedule = createAsyncThunk(
  "wedding/createSchedule",
  async (data: typeof CreateWeddingSchedule.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreateWeddingSchedule.RES>(
        CreateWeddingSchedule.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const editWeddingSchedule = createAsyncThunk(
  "wedding/editSchedule",
  async (data: typeof EditWeddingSchedule.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof EditWeddingSchedule.RES>(
        EditWeddingSchedule.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addWishlistToWedding = createAsyncThunk(
  "wedding/addWishlistToWedding",
  async (data: typeof AddWishlistToWedding.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof AddWishlistToWedding.RES>(
        AddWishlistToWedding.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addFaqToWedding = createAsyncThunk(
  "wedding/addFaqToWedding",
  async (data: typeof AddFaqToWedding.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof AddFaqToWedding.RES>(
        AddFaqToWedding.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export type WeddingInitialStateType = {
  createWeddingResponse: AWeddingResponse | null;
  getWeddingResponse: AWeddingResponse | null;
  getWeddingDashboardResponse: AWeddingDashboardResponse | null;
  createWeddingScheduleResponse: AWeddingResponse | null;
  editWeddingScheduleResponse: AWeddingResponse | null;
  addWishlistToWeddingResponse: AWeddingResponse | null;
  addFaqToWeddingResponse: AWeddingResponse | null;
};

const initialState: WeddingInitialStateType = {
  createWeddingResponse: null,
  getWeddingResponse: null,
  getWeddingDashboardResponse: null,
  createWeddingScheduleResponse: null,
  editWeddingScheduleResponse: null,
  addWishlistToWeddingResponse: null,
  addFaqToWeddingResponse: null,
};

const weddingSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      createWedding.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      createWedding.rejected,
      (state, action: PayloadAction<any>) => {
        state.createWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      getAWedding.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      getAWeddingDashboard.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWeddingDashboardResponse = action.payload;
      }
    );
    builder.addCase(
      createWeddingSchedule.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createWeddingScheduleResponse = action.payload;
      }
    );
    builder.addCase(
      createWeddingSchedule.rejected,
      (state, action: PayloadAction<any>) => {
        state.createWeddingScheduleResponse = action.payload;
      }
    );
    builder.addCase(
      editWeddingSchedule.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.editWeddingScheduleResponse = action.payload;
      }
    );
    builder.addCase(
      editWeddingSchedule.rejected,
      (state, action: PayloadAction<any>) => {
        state.editWeddingScheduleResponse = action.payload;
      }
    );    
    builder.addCase(
      addWishlistToWedding.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addWishlistToWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      addWishlistToWedding.rejected,
      (state, action: PayloadAction<any>) => {
        state.addWishlistToWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      addFaqToWedding.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addFaqToWeddingResponse = action.payload;
      }
    );
    builder.addCase(
      addFaqToWedding.rejected,
      (state, action: PayloadAction<any>) => {
        state.addFaqToWeddingResponse = action.payload;
      }
    );
  },
});

export const weddingActions = {
  ...weddingSlice.actions,
  createWedding,
  getAWedding,
  getAWeddingDashboard,
  createWeddingSchedule,
  editWeddingSchedule,
  addWishlistToWedding,
  addFaqToWedding,
};

export const weddingSelectors = {
  createWedding: (state: RootState) => state.wedding.createWeddingResponse,
  getAWishlist: (state: RootState) => state.wedding.getWeddingResponse,
  createWeddingSchedule: (state: RootState) =>
    state.wedding.createWeddingScheduleResponse,
  editWeddingSchedule: (state: RootState) =>
    state.wedding.editWeddingScheduleResponse,    
  addWishlistToWedding: (state: RootState) =>
    state.wedding.addWishlistToWeddingResponse,
};

export const weddingReducer = weddingSlice.reducer;
