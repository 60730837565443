import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AcceptInviteGiftExchange,
  AcceptInviteGiftExchangeResponse,
  AddWishItemToGiftExchange,
  AddWishItemToGiftResponse,
  DeleteGiftFromExchange,
  GetAllUsersGiftExchanges,
  GetAllUsersGiftExchangesResponse,
  GetGiftExchangeRecipientWishlist,
  GetGiftInGiftExchange,
  GetSingleUserExchange,
  GetSingleUserExchangeRespone,
  MRequest,
} from "apis";
import { RootState } from "store/reducers";

const entity = "secretSanta";

const getAllUserGiftExchanges = createAsyncThunk(
  "secretSanta/exchanges",
  async () => {
    const res = await MRequest.get<typeof GetAllUsersGiftExchanges.RES>(
      GetAllUsersGiftExchanges.ROUTE
    );
    return res.data;
  }
);

const addWishItemToGiftExchange = createAsyncThunk(
  `secretSanta/add-wish-item-to-gift`,
  async (data: typeof AddWishItemToGiftExchange.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof AddWishItemToGiftExchange.RES>(
        AddWishItemToGiftExchange.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getSingleUserExchange = createAsyncThunk(
  "list/getSingleUserExchange",
  async (params: { id: any }) => {
    const res = await MRequest.get<typeof GetSingleUserExchange.RES>(
      `${GetSingleUserExchange.ROUTE}/${params.id}`
    );
    return res.data;
  }
);

const acceptInviteGiftExchange = createAsyncThunk(
  `secretSanta/accept-invite-gift-exchange`,
  async (data: typeof AcceptInviteGiftExchange.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof AcceptInviteGiftExchange.RES>(
        AcceptInviteGiftExchange.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deleteGiftFromExchange = createAsyncThunk(
  "secretSanta/deleteGiftFromExchange",
  async (params: { giftID: string }) => {
    const res = await MRequest.delete<typeof DeleteGiftFromExchange.RES>(
      `${DeleteGiftFromExchange.ROUTE}/${params.giftID}`
    );
    return res.data;
  }
);

const editGiftItemInExchange = createAsyncThunk(
  `secretSanta/edit-gift-item`,
  async (data: typeof AddWishItemToGiftExchange.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.put<typeof AddWishItemToGiftExchange.RES>(
        AddWishItemToGiftExchange.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getGiftInGiftExchange = createAsyncThunk(
  "list/getGiftInGiftExchange",
  async (params: { id: any }) => {
    const res = await MRequest.get<typeof GetGiftInGiftExchange.RES>(
      `${GetGiftInGiftExchange.ROUTE}/${params.id}`
    );
    return res.data;
  }
);

const getGiftExchangeRecipientWishlist = createAsyncThunk(
  "list/getGiftExchangeRecipientWishlist",
  async (params: { giftExchangeID: string; recipientID: string }) => {
    const res = await MRequest.get<typeof GetGiftExchangeRecipientWishlist.RES>(
      `${GetGiftExchangeRecipientWishlist.ROUTE}?giftExchangeID=${params.giftExchangeID}&recipientID=${params.recipientID}`
    );
    return res.data;
  }
);

export type SecretSantaInitialStateType = {
  getAllUsersGiftExchangesResponse: GetAllUsersGiftExchangesResponse | null;
  addWishItemToGiftExchangeResponse: AddWishItemToGiftResponse | null;
  getSingleUserExchangeResponse: GetSingleUserExchangeRespone | null;
  acceptInviteGiftExchangeResponse: AcceptInviteGiftExchangeResponse | null;
  deleteGiftFromExchangeResponse: null;
  editGiftItemInExchangeResponse: AddWishItemToGiftResponse | null;
  getGiftInGiftExchangeResponse: AddWishItemToGiftResponse | null;
  getGiftExchangeRecipientWishlistResponse: any | null;
};

const initialState: SecretSantaInitialStateType = {
  getAllUsersGiftExchangesResponse: null,
  addWishItemToGiftExchangeResponse: null,
  getSingleUserExchangeResponse: null,
  acceptInviteGiftExchangeResponse: null,
  deleteGiftFromExchangeResponse: null,
  editGiftItemInExchangeResponse: null,
  getGiftInGiftExchangeResponse: null,
  getGiftExchangeRecipientWishlistResponse: null,
};

const secretSantaSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAllUserGiftExchanges.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAllUsersGiftExchangesResponse = action.payload;
      }
    );
    builder.addCase(
      getAllUserGiftExchanges.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAllUsersGiftExchangesResponse = action.payload;
      }
    );
    builder.addCase(
      addWishItemToGiftExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addWishItemToGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      addWishItemToGiftExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.addWishItemToGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      getSingleUserExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getSingleUserExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      getSingleUserExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.getSingleUserExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      acceptInviteGiftExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.acceptInviteGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      acceptInviteGiftExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.acceptInviteGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      deleteGiftFromExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteGiftFromExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      deleteGiftFromExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.deleteGiftFromExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      editGiftItemInExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.editGiftItemInExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      editGiftItemInExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.editGiftItemInExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      getGiftInGiftExchange.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getGiftInGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      getGiftInGiftExchange.rejected,
      (state, action: PayloadAction<any>) => {
        state.getGiftInGiftExchangeResponse = action.payload;
      }
    );
    builder.addCase(
      getGiftExchangeRecipientWishlist.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getGiftExchangeRecipientWishlistResponse = action.payload;
      }
    );
    builder.addCase(
      getGiftExchangeRecipientWishlist.rejected,
      (state, action: PayloadAction<any>) => {
        state.getGiftExchangeRecipientWishlistResponse = action.payload;
      }
    );
  },
});

export const secretSantaActions = {
  ...secretSantaSlice.actions,
  getAllUserGiftExchanges,
  addWishItemToGiftExchange,
  getSingleUserExchange,
  acceptInviteGiftExchange,
  deleteGiftFromExchange,
  editGiftItemInExchange,
  getGiftInGiftExchange,
  getGiftExchangeRecipientWishlist,
};

export const secretSantaSelectors = {
  getAllUserGiftExchanges: (state: RootState) =>
    state.secretSanta.getAllUsersGiftExchangesResponse,
  addWishItemToGiftExchange: (state: RootState) =>
    state.secretSanta.addWishItemToGiftExchangeResponse,
  getSingleUserExchange: (state: RootState) =>
    state.secretSanta.getSingleUserExchangeResponse,
  acceptInviteGiftExchange: (state: RootState) =>
    state.secretSanta.acceptInviteGiftExchangeResponse,
  deleteGiftFromExchange: (state: RootState) =>
    state.secretSanta.deleteGiftFromExchangeResponse,
  editGiftItemInExchange: (state: RootState) =>
    state.secretSanta.editGiftItemInExchangeResponse,
  getGiftInGiftExchange: (state: RootState) =>
    state.secretSanta.getGiftInGiftExchangeResponse,
  getGiftExchangeRecipientWishlist: (state: RootState) =>
    state.secretSanta.getGiftExchangeRecipientWishlistResponse,
};

export const secretSantaReducer = secretSantaSlice.reducer;
