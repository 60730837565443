import { useState } from "react";
import { useEffect } from "react";
import { Flex, Box, Text, useToast } from "@chakra-ui/react";

import { useAppSelector } from "hooks";
import { WeddingPageContext } from "apis";
import { authSelectors } from "store/slices";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree_";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";
import Wedding2 from "../my-wedding-2";
import { getWedding } from "utils/workbench/APIs/routes/wedding";
import {
  UpdateWeddingCollection,
  WeddingCollection,
} from "utils/workbench/STOREs/collections/wedding.collection";
import { BasicStepper } from "./components/Stepper";

const WeddingForm = () => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  // const { nextStep, activeStep, prevStep } = useSteps({
  //   initialStep: 0,
  // });

  type StepType = 1 | 2 | 3 | 4 | 5 | 6;
  const [ activeStep, setActiveStep ] = useState<StepType>(1);
  
  const nextStep = (_nextStep: StepType) => {
    setActiveStep(_nextStep);
  };

  const [loading, setLoading] = useState(false);
  const { weddingExists } = WeddingCollection();

  const { weddingPageContext } = WeddingCollection();

  const userDetails = useAppSelector(authSelectors.getUserDetails);

  useEffect(() => {
    setLoading(true);
    getWedding({ userID: userDetails._id }, (response) => {
      if (response.statusCode === 200) {
        UpdateWeddingCollection((weddingCollection) => {
          weddingCollection.weddingID = response.payload._id;
          weddingCollection.weddingDataObj = response.payload;
          weddingCollection.weddingExists = true;
          weddingCollection.weddingPageContext = WeddingPageContext.DASHBOARD;
        });
      } else
        UpdateWeddingCollection((weddingCollection) => {
          weddingCollection.weddingExists = false;
        });

      if (response.errors)
        toast({ status: "error", description: "There is an error" });
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contents = {
    1: <StepOne
      nextBtnAction={() => {
        setActiveStep(2);
      }}
    />,
    2: <StepTwo
      nextBtnAction={() => {
        setActiveStep(3);
      }}
      prevBtnAction={() => {
        setActiveStep(1);
      }}
    />,
    3: <StepThree
      nextBtnAction={() => {
        setActiveStep(4);
      }}
      prevBtnAction={() => {
        setActiveStep(2);
      }}
    />,
    4: <StepFour
      nextBtnAction={() => {
        setActiveStep(5);
      }}
      prevBtnAction={() => {
        setActiveStep(3);
      }}
    />,
    5: <StepFive
      nextBtnAction={() => {
        setActiveStep(6);
      }}
      prevBtnAction={() => {
        setActiveStep(4);
      }}
    />,
    6: <StepSix
      nextBtnAction={() => {
        setActiveStep(6); // don't move forward
      }}
      prevBtnAction={() => {
        setActiveStep(5);
      }}
    />,
  };

  const steps = {
    1: { label: "Step 1", description: "Wedding details", content: contents[1] },
    2: { label: "Step 2", description: "Event schedule", content: contents[2] },
    3: { label: "Step 3", description: "RSVP", content: contents[3] },
    4: { label: "Step 4", description: "Aso Ebi", content: contents[4] },
    5: { label: "Step 5", description: "Wedding FAQs", content: contents[5] },
    6: { label: "Step 6", description: "Add wishlist", content: contents[6] },
  };
  return (
    <>
      {weddingPageContext === WeddingPageContext.DASHBOARD ? (
        <Wedding2 />
      ) : (
        <Box
          position="relative"
          bgColor={"#16181D33"}
          borderRadius="16px"
          minH="90vh"
          p={{ base: "1rem", md: "2rem" }}
        >
          {weddingExists && (
            <Flex justifyContent={"end"}>
              <Text
                cursor={"pointer"}
                borderBottom={"1px solid"}
                onClick={() => {
                  UpdateWeddingCollection((weddingCollection) => {
                    weddingCollection.weddingPageContext =
                      WeddingPageContext.DASHBOARD;
                  });
                }}
              >
                back to wedding dashboard
              </Text>
            </Flex>
          )}
          <Box maxW={"345px"} w="100%" m="0 auto">
            <Flex flexDir="column" width="100%">
              <Box display={{ base: "none", sm: "unset" }}>
                {loading ? (
                  "Loading"
                ) : (
                  <>
                    <Flex
                      h={'5px'}
                    ></Flex>
                    <BasicStepper 
                      steps={[1, 2, 3, 4, 5, 6]}
                      activeStep={activeStep}
                      onStepSelect={(_step: StepType) => {
                        if (weddingExists) setActiveStep(_step);
                      }}
                    />
                    <Flex
                      h={'10px'}
                    ></Flex>
                    {contents[activeStep]}
                  </>
                )}
              </Box>
              <Box display={{ base: "unset", sm: "none" }}>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Flex flexDir={"column"} gap="3px" pt="10px" pb="4px">
                    <Text
                      color="rgba(255, 255, 255, 0.5)"
                      fontSize={"12px"}
                      fontWeight="400"
                    >
                      {steps[activeStep].label}
                    </Text>
                    {
                      (
                        steps[activeStep].description === "Wedding FAQs"  ||
                        steps[activeStep].description === "Aso Ebi" 
                      ) ? (
                      <Flex flexDir="column">
                        <Box>
                          <Text
                            fontSize={"20px"}
                            color="#fff"
                            fontWeight={"400"}
                          >
                            {steps[activeStep].description}
                          </Text>{" "}
                        </Box>
                        <Box
                          borderBottom="1px solid"
                          color="#CEFABB"
                          width="35px"
                        >
                          <Text onClick={() => nextStep(activeStep)}>Skip</Text>
                        </Box>
                      </Flex>
                    ) : (
                      <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
                        {steps[activeStep].description}
                      </Text>
                    )}
                  </Flex>
                  <Flex gap="8px">
                    {Object.keys(steps).map((_, i) => {
                      return (
                        <Box
                          key={i}
                          width="5px"
                          height="5px"
                          borderRadius={"50%"}
                          backgroundColor={
                            activeStep <= i
                              ? "rgba(255,255,255,0.5)"
                              : "#0F441E"
                          }
                        ></Box>
                      );
                    })}
                  </Flex>
                </Flex>
                {contents[activeStep]}
              </Box>
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WeddingForm;
