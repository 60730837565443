import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Image,
} from "@chakra-ui/react";
import { FulfilledSvg } from "assets/svgs";
import CircularProgressCmp from "components/CircularProgressCmp/CircularProgressCmp";
import { MenuItemCmp } from "Layout/Dashboard";
// import millify from "millify";
// import { TVImg } from "assets/svgs";
import React from "react";
import { FiMoreHorizontal } from "react-icons/fi";

const WishCardCmp: React.FC<{
  widthMd?: string;
  minW?: string;
  flex?: string;
  height?: string;
  widthBase?: string;
  minWidthBase?: string;
  flexBase?: string;
  wishTitle?: string;
  wishPrice?: string | JSX.Element;
  grossPaid?: string | JSX.Element;
  percentage?: number;
  coverImage?: string;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onWrapperClick?: () => void;
  bgColor?: string;
  contributor?: boolean;
  bgHeight?: string;
  hideProgress?: boolean;
  isFulfilled?: boolean;
  showPrice?: boolean;
}> = ({
  widthMd,
  minW,
  flex,
  height,
  widthBase,
  minWidthBase,
  flexBase,
  wishTitle,
  wishPrice,
  grossPaid,
  coverImage,
  onDeleteClick,
  onEditClick,
  onWrapperClick,
  bgColor,
  contributor,
  percentage,
  bgHeight,
  hideProgress,
  isFulfilled,
  showPrice,
}) => {
  // const [readableAmount, setReadableAmount] = useState("");
  // useEffect(() => {
  //   if (grossPaid && typeof grossPaid === "string") {
  //     let grossPaidNumber = parseInt(grossPaid!);
  //     setReadableAmount(millify(grossPaidNumber));
  //   }
  // }, []);

  return (
    <>
      <Box
        pointerEvents={isFulfilled ? "none" : "unset"}
        w={{
          base: `${widthBase || "100%"}`,
          md: `${widthMd || "48%"}`,
        }}
        minW={{
          base: `${minWidthBase || "100%"}`,
          md: `${minW || "48%"}`,
        }}
        flex={{
          base: `${flexBase || "0 0 48%"}`,
          md: `${flex || "0 0 48%"}`,
        }}
        h={{ base: "240px", sm: height || "227px" }}
        bgColor={bgColor || "#16181D"}
        borderRadius="18px"
        pos={"inherit"}
        my={"12px"}
        cursor="pointer"
        onClick={onWrapperClick}
      >
        <Box
          position={"relative"}
          backgroundSize="cover"
          width="100%"
          height={{ base: bgHeight || "120px", sm: bgHeight || "200px" }}
          borderRadius={"18px 18px 0px 0px"}
          bgImage={coverImage}
        >
          {!contributor && (
            <Box
              width="30px"
              height="30px"
              borderRadius={"50%"}
              backdropFilter="blur(30px)"
              bgColor="rgba(0, 0, 0, 0.3)"
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              position="absolute"
              right="15px"
              top="10px"
              pointerEvents={"auto"}
            >
              <HStack spacing={{ base: "0", md: "6" }}>
                <Flex alignItems={"center"}>
                  <Menu offset={[-80, 2]}>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: "none" }}
                    >
                      <HStack>
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius={"50%"}
                          bgColor="rgba(0, 0, 0, 0.3)"
                          display={"flex"}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Box color={"#fff"}>
                            <Icon
                              as={FiMoreHorizontal}
                              width={"12px"}
                              height={"12px"}
                            />
                          </Box>
                        </Box>
                      </HStack>
                    </MenuButton>
                    <MenuList
                      minWidth={"7rem"}
                      bg={"#16181D"}
                      borderColor={"#22242c"}
                      zIndex="500"
                    >
                      <MenuItemCmp onClick={onEditClick} name={"Edit"} />
                      <MenuItemCmp onClick={onDeleteClick} name={"Delete"} />
                    </MenuList>
                  </Menu>
                </Flex>
              </HStack>
            </Box>
          )}
          {isFulfilled && (
            <Flex
              justifyContent={"center"}
              alignItems="center"
              borderRadius={"18px 18px 0px 0px"}
              w="100%"
              height={{ base: bgHeight || "120px", sm: bgHeight || "200px" }}
              zIndex="10"
              bgColor={"rgba(0, 0, 0, 0.5)"}
            >
              <Flex
                justifyContent={"center"}
                alignItems="center"
                w="70px"
                h="23px"
                borderRadius={"16px"}
                backgroundColor="rgba(0, 0, 0, 0.7)"
                border="1px solid #A9F389"
              >
                <Flex gap="8px" alignItems="center">
                  <CheckIcon color="#A9F389" w={2} h={2} />
                  <Text color="#fff" fontWeight={"400"} fontSize="8px">
                    Fulfilled
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Box>
        <Box
          pt={"1rem"}
          fontFamily={"Plus Jakarta Sans"}
          textAlign={`${showPrice ? "center" : "left"}`}
          px="1rem"
          pb={hideProgress ? "12px" : ""}
        >
          <Text color="#Fff" fontSize={"15px"} fontWeight="400" noOfLines={1}>
            {wishTitle}
          </Text>
          {showPrice && (
            <Text color="#Fff" fontSize={"18px"} fontWeight="400">
              ₦{wishPrice}
            </Text>
          )}
        </Box>
        {!hideProgress && (
          <Flex
            justifyContent={"space-between"}
            alignItems="flex-end"
            p="5px 16px 20px"
          >
            <Box>
              <Text color="#Fff" fontSize={"18px"} fontWeight="400">
                ₦{wishPrice}
              </Text>
              <Text
                color={"rgba(255,255,255, 0.5)"}
                fontSize={"12px"}
                fontWeight="400"
              >
                ₦{grossPaid} raised
              </Text>
            </Box>
            {isFulfilled ? (
              <Image src={FulfilledSvg} alt="Fulfilled" />
            ) : (
              <CircularProgressCmp
                label={`${percentage}%`}
                color={`${contributor ? "#44A560" : "#F7D38F"}`}
                value={percentage!}
                isContributor={contributor ? true : false}
              />
            )}
          </Flex>
        )}
      </Box>
    </>
  );
};

export default WishCardCmp;
