import React from "react";
import { Flex } from "@chakra-ui/react";


type StepType = 1 | 2 | 3 | 4 | 5 | 6;

const StepDivider: React.FC<{}> = () => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Flex 
        width={"31px"}
        height={"2px"}
        background={"rgba(255, 255, 255, 0.50)"}
      ></Flex>
    </Flex>
  );
};

const Step: React.FC<
{
  isChecked: boolean, 
  value: number,
  onClick: () => void,
}> = ({ isChecked, value, onClick }) => {
  return (
    <Flex
      w={'31px'}
      h={'31px'}
      borderRadius={'50px'}
      backgroundColor={isChecked ? '#39A069' : '#13431e'}
      justifyContent={'center'}
      alignItems={'center'}
      cursor={'pointer'}
      onClick={onClick}
    >
      {
        isChecked ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
            <path d="M1 4.10345L5.39655 8.5L12.8966 1" stroke="white" stroke-width="2"/>
          </svg>
        ) : 
        (
          <>
            {value}
          </>
        )
      }
    </Flex>
  )
};


export const BasicStepper: React.FC<{
  steps: number[];
  activeStep: number;
  onStepSelect: (_step: StepType) => void;
}> = ({ steps, activeStep, onStepSelect }) => {
  const  _steps = steps.map((step) => ({step, isChecked: step < activeStep}));
  return (
    <Flex> 
      {
        _steps.map(
        (step, index) => {
          return (
            <Flex
              key={index}
            >
              <Step 
                isChecked={step.isChecked} 
                value={step.step}
                onClick={() => onStepSelect(step.step as StepType)}
                />
              {index < _steps.length-1 ? <StepDivider /> : <></>}
            </Flex>
          );
        })
      }
    </Flex>
  );
};
