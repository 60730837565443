const auth = {
  login: "/auth/signin-user",
  register: "/auth",
  verify: "/auth/verify-otp-user",
  sendOtp: "/auth/send-otp-user",
  resetPassword: "/auth/reset-password-user",
  orgCategory: "/organisation/categories",
};

const user = {
  users: "/users",
  export: "/users/export",
};

const wishlist = {
  getWishlistCategories: "/list/categories",
  getAWishlist: "/list",
  createWishlist: "/list",
  addWish: "/gift",
  deleteWish: "/gift",
  saveWishNote: "/list/note",
  externalProduct: "/external-product-info/user",
  suggestedUrl: "/external-product-info/not-supported-url/user",
};

const collection = {
  createCollection: "/collection/wishlist-cash-fund",
  getCollectionCategories: "/list/categories/organisation",
  deleteCollection: "/cash-fund/wishlist",
  updateACollection: "/cash-fund/wishlist",
  getACollection: "/cash-fund/wishlist/single",
};

const event = {
  getAnEvent: "/event/general/dashboard",
  getUser: "/user",
  getSearchResult: "/event/general/tickets/sold",
};

const wedding = {
  aWedding: "/wedding",
  weddingDashboard: "/wedding/dashboard",
  weddingSchedule: "/wedding/schedule",
  wishlistToWedding: "/wedding/wishlist",
  faqToWedding: "/wedding/faq",
};

const dashboard = {
  getDashboard: "/dashboard",
  getUser: "/user",
};

const generalEvents = {
  getDrawer: "/event/general/tickets/sold",
  searchDrawer: "/event/general/tickets/sold/search",
  createAdmitted:"/event/general/ticket/admission"
};

const settings = {
  checkUsername: "user/check/username",
  checkEmail: "user/check/email",
  updateUserProfile: "user/profile",
  updateUsername: "user/username",
  updateEmail: "user/email",
  verifyPasswordUser: "auth/verify-password-user",
  changePassword: "auth/change-password-user",
  deactivateAccount: "auth/deactivate-account-user",
};

const wallets = {
  getWishlistWallet: "/wallets/all/types",
  verifyPayoutAmount: "/payout/verifyAmount",
  verifyPayoutRequest: "/payout/verifyRequest",
  getWalletTotalBalance: "/wallets/total/balance",
  getBankAccounts: "/bankAccounts",
  postBankSearch: "/banks/search",
  verifyBankAccount: "/bankAccounts/verify",
  createBankAccount: "/bankAccounts/create",
  createPayout: "/payout/request",
  createTransactionPayout: "/transaction/request/payout/flutterwave",
  getBanks: "/banks",
};

const contributor = {
  getWishlistPublic: "/public/inventory",
  getAGiftPublic: "/gift/public/contributions",
  createGiven: "/giving",
};

const analytics = {
  getAnalyticsContributionChart: "/organisation/analytics/chart/all",
  getAnalyticsContributionOverview: "/organisation/analytics/overview/all",
  getAnalyticsTopContributors: "/organisation/analytics/top-contributors/all",
  getAnalyticsSingleContributionChart: "/organisation/analytics/chart/single",
  getAnalyticsSingleContributionOverview:
    "/organisation/analytics/overview/single",
};

const groups = {
  giftExchange: "/gift-exchange",
  getAllUsersGiftExchange: "/gift-exchange-gift",
  getGiftExchangeRecipientWishlist: "gift-exchange-gift/recipient/wishlist",
  getSingleUserExchange: "/gift-exchange/user/exchange",
  acceptInviteGiftExchange: "/gift-exchange/accept-invite",
};

const publicUrl = {
  baseURL: process.env.REACT_APP_PUBLIC_URL,
  localURL: window.location.origin,
};

const appRoute = {
  ticket: process.env.REACT_APP_TICKET_ROUTE,
  gift: process.env.REACT_APP_GIFT_ROUTE,
};

export const API_URLS = {
  auth,
  user,
  wishlist,
  wedding,
  dashboard,
  generalEvents,
  settings,
  publicUrl,
  appRoute,
  wallets,
  contributor,
  groups,
  collection,
  analytics,
  event,
};
