import {
  Box,
  Container,
  Flex,
  Icon,
  Image,
  Text,
  Link,
} from "@chakra-ui/react";
import { Outlet, useNavigate } from "react-router-dom";
import UnboxdLogoWhite from "assets/unboxd-logo-white.svg";
import PhonePicture from "assets/phone-pic.png";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useState } from "react";

const ContributorLayout = () => {
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [itemType, setType] = useState("collection");
  const navigate = useNavigate();
  return (
    <Container minW={"full"} w={"full"} bg={"#16181D"} p={0}>
      {itemType !== "collection" && (
        <Flex
          bg="rgb(237, 255, 229)"
          justifyContent="center"
          alignItems="center"
          position={{ base: "relative" }}
          top="0"
          fontFamily={"Plus Jakarta Sans"}
          overflow={{ base: "hidden" }}
        >
          <Box ml="-190px">
            <Image src={PhonePicture} alt="phone-picture" />
          </Box>
          <Box>
            <Text color="#000" fontSize={{ base: "12px", lg: "16px" }}>
              Like this wishlist? `{" "}
              <Link
                href="/add-wishlist"
                color="rgb(48, 147, 91)"
                fontWeight="700"
                textDecoration="underline"
              >
                Set up your own - It's free
              </Link>
            </Text>
          </Box>
        </Flex>
      )}
      <Box minH="100vh" maxW={"670px"} m={"0 auto"} h={"full"}>
        <Box
          bg={"transparent"}
          px={{ base: "1rem", md: "0" }}
          className="no-scrollbar"
        >
          <Flex
            justifyContent="center"
            py={{ base: "1rem", md: "2rem" }}
            position={"relative"}
            // top="0"
            bg={"#16181D"}
          >
            <Image
              src={UnboxdLogoWhite}
              alt="unboxd"
              width={"141px"}
              height="27px"
            />
            {showBackArrow && (
              <Icon
                cursor="pointer"
                as={ArrowBackIcon}
                color="#fff"
                position={"absolute"}
                left="0"
                top="45%"
                onClick={() => {
                  navigate(-1);
                }}
              />
            )}
          </Flex>
          <Outlet context={{ setShowBackArrow, setType }} />
        </Box>
      </Box>
    </Container>
  );
};

export default ContributorLayout;
