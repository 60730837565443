import { combineReducers } from "redux";
import {
  authReducer,
  AuthInitialStateType,
  WishlistInitialStateType,
  WeddingInitialStateType,
  wishlistReducer,
  weddingReducer,
  dashboardReducer,
  DashboardInitialStateType,
  DrawerInitialStateType,
  drawerReducer,
  settingsReducer,
  SettingsInitialStateType,
  WalletInitialStateType,
  walletReducer,
  contributorReducer,
  ContributorInitialStateType,
  SecretSantaInitialStateType,
  secretSantaReducer,
  eventReducer,
  EventInitialStateType,

} from "./slices";
import {
  AnalyticsInitialStateType,
  analyticsReducer,
} from "./slices/analyticsSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  wishlist: wishlistReducer,
  wedding: weddingReducer,
  dashboard: dashboardReducer,
  settings: settingsReducer,
  wallet: walletReducer,
  Drawer: drawerReducer,
  contributor: contributorReducer,
  secretSanta: secretSantaReducer,
  analytics: analyticsReducer,
  event: eventReducer,
});

export type RootState = {
  auth: AuthInitialStateType;
  wishlist: WishlistInitialStateType;
  wedding: WeddingInitialStateType;
  dashboard: DashboardInitialStateType;
  settings: SettingsInitialStateType;
  wallet: WalletInitialStateType;
  drawer: DrawerInitialStateType;
  contributor: ContributorInitialStateType;
  secretSanta: SecretSantaInitialStateType;
  analytics: AnalyticsInitialStateType;
  event: EventInitialStateType;
};

export default rootReducer;
