import { StoreApi, UseBoundStore, create } from 'zustand';
import { produce } from 'immer';

export const BaseStore = <T>(initialState: T) => create(() => initialState);
export const UpdateStore = <T>(
  store: UseBoundStore<StoreApi<T>>, 
  updateCallback: (baseState: T) => void) => store.setState(
    (baseState) => produce(
      baseState, (draft) => updateCallback(draft as T)), true);
export const UpdateCollectionStore = <T>(
  store: UseBoundStore<StoreApi<T>>
) => 
  (
    updateCallback: (baseState: T) => void
  ) => UpdateStore(store, updateCallback);
