import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import WalletHistoryCmp from "components/WalletHistoryCmp";
import WishlistCardCmp from "components/WishlistCardCmp/WishlistCardCmp";
import WithdrawalHistoryCmp from "components/WithdrawalHistoryCmp";
import React from "react";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { getNumberOfDays } from "utils/date";

const GiftWalletDrawer: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  bgImage?: string;
  data?: any;
}> = ({ onClose, isOpen, bgImage, data }) => {
  const EmptyWithdrawalCmp: React.FC<{ desc: string; title: string }> = ({
    desc,
    title,
  }) => {
    return (
      <>
        <Flex h="300px" justifyContent={"center"} alignItems="center">
          <Flex
            flexDirection={"column"}
            gap="10px"
            fontFamily={"Plus Jakarta Sans"}
            alignItems="center"
          >
            <Image
              src={"https://media.giphy.com/media/26hkhPJ5hmdD87HYA/giphy.gif"}
              alt="Nothing yet"
              width="100px"
              height="100px"
              borderRadius={"50%"}
            />
            <Flex flexDirection={"column"} gap="10px" alignItems="center">
              <Text fontSize={"20px"} fontWeight="400">
                {title}
              </Text>
              <Text
                width="178px"
                margin="0 auto"
                textAlign="center"
                fontSize={"12px"}
                fontWeight="400"
                opacity="0.6"
              >
                {desc}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  };
  const navigate = useNavigate();
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent bgColor={"#22242C"}>
          <DrawerCloseButton outline={"none"} boxShadow="none !important" />
          <DrawerBody pt="3rem">
            <Flex flexDir="column">
              <WishlistCardCmp
                style={{
                  border: "2px solid #fff",
                }}
                widthLg="100%"
                minWLg="100%"
                flexLg="0 0 100%"
                flexBase="0 0 100%"
                flex="0 0 100%"
                minW="100%"
                widthMd="100%"
                wishlistTitle={data?.singleUserExchangeData?.title}
                amount={2}
                amountText={2 <= 1 ? "ITEM" : "ITEMS"}
                bgImage={bgImage!}
                walletBalance={data?.walletsDataPayload?.walletBalance?.Naira}
                removeMenu={true}
                showWalletBal
                payoutBtnText={`${getNumberOfDays(
                  data?.singleUserExchangeData?.dateOfExchange
                )} DAYS TO PAYOUT`}
                isCompleted={false}
                toggleDisabled={
                  getNumberOfDays(
                    data?.singleUserExchangeData?.dateOfExchange
                  ) === 0
                    ? false
                    : true
                }
                // onClick={() => {
                //   setCurrentWishlistData(v);
                //   setCurrentWishlistIndex(i);
                // }}
                payoutBtnAction={() => {
                  navigate(
                    `/request-payout/${data?.singleUserExchangeData?.wishlist[0]?.giftExchangeWishlistID}`,
                    {
                      state: {
                        walletBalance:
                          data?.walletsDataPayload?.walletBalance?.Naira,
                        type: "giftExchange",
                      },
                    }
                  );
                }}
              />
            </Flex>
            <Box
              fontFamily={"Plus Jakarta Sans"}
              overflowY="scroll"
              css={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Tabs isFitted isManual borderColor={"rgba(255, 255, 255, 0.7)"}>
                <TabList>
                  <Tab
                    color={"rgba(255, 255, 255, 0.7)"}
                    _selected={{
                      color: "white",
                      fontWeight: "400",
                      borderColor: "white",
                    }}
                  >
                    {1 > 0 && (
                      <span
                        style={{
                          color: "#45AA61",
                          fontSize: "11px",
                          fontWeight: "400",
                        }}
                      >
                        {1} new &nbsp;
                      </span>
                    )}
                    Cash gifts
                  </Tab>
                  <Tab
                    color={"rgba(255, 255, 255, 0.7)"}
                    _selected={{
                      color: "white",
                      fontWeight: "400",
                      borderColor: "white",
                    }}
                  >
                    Withdrawals
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <Flex flexDir={"column"} gap="5px">
                      {data?.walletsDataPayload?.contributions?.length ? (
                        data?.walletsDataPayload?.contributions?.map(
                          (v: any, i: number) => {
                            return (
                              <>
                                <WalletHistoryCmp
                                  key={i}
                                  name={v.giver}
                                  item={v.forItem}
                                  date={v.date}
                                  amount={
                                    <>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix="₦"
                                        value={v.paid}
                                        decimalScale={2}
                                        defaultValue={"0.00"}
                                        allowNegative={false}
                                        placeholder="0.00"
                                        displayType="text"
                                        disabled
                                      />
                                    </>
                                  }
                                  charge={
                                    <>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix="₦"
                                        value={v.deduction}
                                        decimalScale={2}
                                        defaultValue={"0.00"}
                                        allowNegative={false}
                                        placeholder="0.00"
                                        displayType="text"
                                        disabled
                                      />
                                    </>
                                  }
                                  rate={"5"}
                                  total={
                                    <>
                                      <NumberFormat
                                        thousandSeparator
                                        prefix="₦"
                                        value={v.amount}
                                        decimalScale={2}
                                        defaultValue={"0.00"}
                                        allowNegative={false}
                                        placeholder="0.00"
                                        displayType="text"
                                        disabled
                                      />
                                    </>
                                  }
                                />
                                {i !==
                                  data?.walletsDataPayload?.contributions
                                    ?.length && <Divider opacity="0.5" />}
                              </>
                            );
                          }
                        )
                      ) : (
                        <EmptyWithdrawalCmp
                          title="Nothing yet!"
                          desc="There are no contributions to your gift yet"
                        />
                      )}
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <Flex flexDir={"column"} gap="5px">
                      {data?.walletsDataPayload?.withdrawals?.length ? (
                        data?.walletsDataPayload?.withdrawals?.map(
                          (v: any, i: number) => {
                            return (
                              <>
                                <WithdrawalHistoryCmp
                                  key={i}
                                  accountNo={v.accountNumber}
                                  bankName={v.bankName}
                                  date={new Date(v.date).toLocaleDateString()}
                                  amount={
                                    <>
                                      <NumberFormat
                                        thousandSeparator
                                        // prefix="₦"
                                        value={v.amount.Amount}
                                        decimalScale={2}
                                        defaultValue={"0.00"}
                                        allowNegative={false}
                                        placeholder="0.00"
                                        displayType="text"
                                        disabled
                                      />
                                    </>
                                  }
                                  status={v.status}
                                />
                                {i !==
                                  data?.walletsDataPayload?.withdrawals
                                    ?.length -
                                    1 && <Divider opacity="0.5" />}
                              </>
                            );
                          }
                        )
                      ) : (
                        <EmptyWithdrawalCmp
                          title="Nothing yet!"
                          desc="All withdrawals from your wallet to a bank account will be logged here"
                        />
                      )}
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default GiftWalletDrawer;
