/* eslint-disable */
// TODO - handle errors here
import { Middleware } from "@reduxjs/toolkit";
import { MRequest } from "apis";
import axios, { AxiosRequestConfig } from "axios";
import { logout } from "utils/logout";

export const requestMiddleWare: Middleware =
  ({ dispatch, getState }: { dispatch?: any; getState: () => any }) =>
  (next) =>
  (action) => {
    // pass the action down the middleware pipeline
    next(action);

    const tokenKey = process.env.REACT_APP_STORAGE_KEY as string;

    const requestConfiguration = (config: AxiosRequestConfig) => {
      /**
       * if user chooses to be remembered pull from local storage else
       * pull from session storage for consequent request
       */
      // const sToken = sessionStorage.getItem(tokenKey);
      // const lToken = localStorage.getItem("accessToken");
      const token = localStorage.getItem("accessToken");

      // don't append token for this routes
      if (token) {
        return {
          ...config,
          headers: {
            "x-access-token": token,
            Authorization: `Bearer ${token}`,
            ...config.headers,
          },
        };
      }
      return config;
    };

    const responseErrorHandler = (error: any) => {
      if (!error.response) {
        error.response = {
          data: { errors: ["Network Error, Please check your connection."] },
          message: "Network Error, Please check your connection.",
          status: 0,
        };
      }

      switch (error.code) {
        case "ECONNABORTED":
          error.response = {
            data: { errors: ["Server connection timed out."] },
            message: "Server connection timed out.",
            status: 408,
          };
          break;
        default:
          break;
      }

      if ([401, 403].includes(error?.response?.status)) {
        const sToken = sessionStorage.getItem(tokenKey);
        const lToken = localStorage.getItem(tokenKey);
        const refreshToken = JSON.parse(
          sToken || lToken || JSON.stringify({ refresh: "" })
        ).refresh;
         dispatch(logout());

        // if (
        //   error?.response?.statusText === "Unauthorized" &&
        //   !excludeRoutes.includes(error?.config?.url as string)
        // ) {
        //   // dispatch(logOut({ _return: true }));
        // }

        if (error?.response?.statusText === "InvalidToken") {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BASE_URL}/`,
            data: { refreshToken },
          })
            .then((response) => {
              const { auth } = getState();

              if (auth.rememberSession) {
                localStorage.setItem(
                  tokenKey,
                  JSON.stringify({
                    access: response.data.data.token,
                    refresh: "",
                  })
                );
              } else {
                sessionStorage.setItem(
                  tokenKey,
                  JSON.stringify({
                    access: response.data.data.token,
                    refresh: "",
                  })
                );
              }

              const newConfig = {
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: `${response.data.token}`,
                },
              };

              axios.request(newConfig).then((response) => response);
            })
            .catch(() => {
              // dispatch(logOut({ _return: true }));
            });
        }
      }

      // handleErrors(error, "", { toastId: "global-error" });
      return Promise.reject(error);
    };

    MRequest.interceptors.request.use(requestConfiguration, (error) => {
      // Do something with request error
      return Promise.reject(error);
    });

    MRequest.interceptors.response.use((response) => {
      return response;
    }, responseErrorHandler);
  };
