import {
  Box,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch } from "hooks";
import { WishlistBg } from "assets/svgs";
import { useNavigate } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useCallback, useEffect, useState } from "react";

import { wishlistActions } from "store/slices";
import UploadImage from "components/UploadImage";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ActionModal from "components/Modals/ActionModal";
import { BasicSelect } from "components/InputsCmp/CustomSelect";
import { BasicInput } from "components/InputsCmp/CustomInputCmp";
import ImageCropperModal from "components/Modals/ImageCropperModal";
import {
  generateYears,
  getDateFromDayAndTime,
  getDaysInMonth,
  months,
} from "utils/date";

import { getWedding } from "utils/workbench/APIs/routes/wedding";
import { getDashboard } from "utils/workbench/APIs/routes/dashboard";
import {
  UpdateWeddingCollection,
  WeddingCollection,
} from "utils/workbench/STOREs/collections/wedding.collection";
import AWishlist from "./AWishlist";
import AddGift from "./AddGift";
import { Wishlist } from "apis";

type DashboardWishlist = {
  _id: string;
  coverImage: string;
  title: string;
  noOfGifts: number;
  listCategoryEmoji: string;
};

export enum WeddingWishlistActiveScreen {
  ADD_WISHLIST = "wishlist",
  ADD_GIFT = "add_gift",
  A_WISHLIST = "a_wishlist",
}

const AddWishlist: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  setSelectedWishlistID: (selectedWishlistID: string) => void;
  setDashboardWishlists: (dashboardWishlists: DashboardWishlist[]) => void;
  logoLoading: boolean;
  setLogoLoading: (logoloading: boolean) => void;
}> = ({
  onClose,
  isOpen,
  setDashboardWishlists,
  setSelectedWishlistID,
  setLogoLoading,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const { weddingDataObj } = WeddingCollection();

  const dispatch: any = useAppDispatch();
  const { createWishlist } = wishlistActions;

  const [date, setDate] = useState({
    day: new Date().getDay(),
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });
  const [showImageModal, setShowImageModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const categoryId = "5f9b70f0407e270021f85785";

  const [image, setImage] = useState(weddingDataObj?.coverImage || "");

  const [wishlist_title, setWishlistTitle] = useState(weddingDataObj?.hashtag);
  const [day, setDay] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  const [showImageCropper, setShowImageCropper] = useState(false);
  const [receiveCashGifts, setReceiveCashGifts] = useState(false);

  const [, setFile] = useState<File | string>("");

  const [isExit, setIsExit] = useState(false);

  const [activeScreen, setActiveScreen] = useState(
    WeddingWishlistActiveScreen.ADD_WISHLIST
  );
  const [wishlistSlug, setWishlistSlug] = useState("");
  const [wishlistId, setWishlistId] = useState("");
  const [newWishlist, setNewWishlist] = useState({});

  const navigate = useNavigate();

  useEffect(
    () => {
      let maxDate = null as Date | null;
      weddingDataObj.schedules.forEach((schedule) => {
        maxDate = getDateFromDayAndTime(schedule.date, schedule.time);
      });
      if (maxDate) {
        setDay(maxDate.getUTCDate());
        setMonth(maxDate.getUTCMonth() + 1);
        setYear(maxDate.getFullYear());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSubmit = () => {
    if (!image) {
      toast({
        status: "error",
        description: "Pick an image",
      });
      return;
    }
    if (!wishlist_title || !day || !month || !year) {
      toast({
        status: "error",
        description: "complete the form",
      });
      return;
    }
    const reqData = {
      title: wishlist_title,
      coverImage: image,
      date: `${year}-${month}-${day}`,
    };

    const payload = new FormData();

    payload.append("title", wishlist_title);
    payload.append("listcategoryID", categoryId);
    payload.append("coverImage", reqData.coverImage);
    payload.append("date", reqData.date);
    setLoading(true);

    setLogoLoading(true);
    dispatch(createWishlist(payload))
      .then((res: any) => {
        const response = res;
        if (response.payload.statusCode === 500 && response.payload.message) {
          if (response.payload.message.includes("date")) {
            return toast({
              status: "error",
              description: "Please pick a future date",
            });
          }
          return toast({
            status: "error",
            description: response.payload.message,
          });
        }
        setLoading(false);
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 201
        ) {
          // reset form
          setSelectedWishlistID(response.payload.payload._id);
          setWishlistSlug(response?.payload?.payload?.slug);
          setWishlistId(response?.payload?.payload?._id);
          setNewWishlist(response.payload.payload);

          const userID = localStorage.getItem("userID")!;
          const accessToken = localStorage.getItem("accessToken");
          setActiveScreen(WeddingWishlistActiveScreen.A_WISHLIST);

          getDashboard((response) => {
            if (response.statusCode === 200) {
              setDashboardWishlists(
                response.payload.wishlists.filter(
                  (list) => list.listCategoryEmoji === "👰"
                )
              );

              setLoading(false);
              // onClose();
              getWedding({ userID }, (response) => {
                UpdateWeddingCollection((weddingCollection) => {
                  weddingCollection.weddingDataObj = response.payload;
                });
              });
            }
          });

          if (receiveCashGifts) {
            (async () => {
              const body = JSON.stringify({
                wishlistID: response.payload.payload._id,
                coverImage: response.payload.payload.coverImage,
                title: wishlist_title,
              });
              await fetch(`${process.env.REACT_APP_BASE_URL}/cash-fund`, {
                method: "post",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-Type": "application/json",
                },
                body,
              });
              // TODO: handle failure cases
            })();
          }
          // setImage("");
          setWishlistTitle("");
          // setDay(0);
          // setMonth(0);
          // setYear(0);
          setReceiveCashGifts(false);
        }
      })
      .catch(() => {
        toast({
          status: "error",
          description: "There is an error",
        });
      })
      .finally(() => {
        setLoading(false);
        setLogoLoading(false);
        // onClose();
      });
  };

  const fromUnsplash = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setShowImageCropper(true);
    setFile(imageUrl);
    setShowImageModal(false);
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    setImage(URL.createObjectURL(file));
    setShowImageCropper(true);
    setShowImageModal(false);
  }, []);
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent bgColor={"#22242C"}>
          <DrawerBody pt="3rem">
            {activeScreen === WeddingWishlistActiveScreen.ADD_WISHLIST ? (
              <>
                <Box
                  py="1rem"
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  position="relative"
                >
                  <ArrowBackIcon
                    position="absolute"
                    left="0"
                    cursor="pointer"
                    color="#fff"
                    h={8}
                    w={8}
                    onClick={() => {
                      onClose();
                    }}
                  />
                  <Text color="#fff" fontWeight={"400"} fontSize="24px">
                    create wishlist
                  </Text>
                </Box>
                <Flex justifyContent={"center"} py="1rem">
                  <Flex
                    flexDirection={"column"}
                    gap="30px"
                    width="100%"
                    maxWidth="345px"
                  >
                    <UploadImage
                      fromGallery={fromGallery}
                      fromUnsplash={fromUnsplash}
                      image={image}
                      instruction={"upload cover image"}
                      placeholder={WishlistBg}
                      showImageModal={showImageModal}
                      setShowImageModal={setShowImageModal}
                      setShowImageCropper={function (_: boolean): void {
                        throw new Error("Function not implemented.");
                      }}
                    />
                    <Box display={"flex"} flexDirection="column" gap="1px">
                      <Box margin={{ base: "0", sm: "0" }} width="100%">
                        <BasicInput
                          placeholder="wishlist title"
                          name="wishlist_title"
                          disabled={false}
                          showMessage={true}
                          value={wishlist_title}
                          onChange={(e) => {
                            setWishlistTitle(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display={"flex"} flexDir="column" gap="8px">
                      <Text textAlign={"center"}>set due date</Text>
                      <Flex gap="5px">
                        <Box width="102px">
                          <BasicSelect
                            placeholder="Year"
                            name="year"
                            value={year}
                            disabled={false}
                            showMessage={true}
                            items={generateYears(new Date(), 10)}
                            handleChange={(e) => {
                              setYear(Number(e.target.value));
                              setDate((prev: any) => ({
                                ...prev,
                                year: Number(e.target.value),
                              }));
                            }}
                            required
                          />
                        </Box>
                        <Box width="116px">
                          <BasicSelect
                            placeholder="Month"
                            name="month"
                            value={month}
                            disabled={false}
                            showMessage={true}
                            items={months}
                            handleChange={(e) => {
                              setMonth(Number(e.target.value));
                              setDate((prev: any) => ({
                                ...prev,
                                month: Number(e.target.value),
                              }));
                            }}
                            required
                          />
                        </Box>
                        <Box width="102px">
                          <BasicSelect
                            placeholder="Day"
                            name="day"
                            value={day}
                            disabled={false}
                            showMessage={true}
                            items={getDaysInMonth(
                              date.month - 1,
                              new Date().getFullYear()
                            )}
                            handleChange={(e) => {
                              setDay(Number(e.target.value));
                              setDate((prev: any) => ({
                                ...prev,
                                day: Number(e.target.value),
                              }));
                            }}
                            required
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      cursor="pointer"
                      fontWeight={"400"}
                      fontSize="24px"
                      color="#CEFABB"
                      textAlign={"center"}
                      py="1rem"
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"center"}
                      gap="4px"
                    >
                      <Checkbox
                        isChecked={receiveCashGifts}
                        onChange={(e) => setReceiveCashGifts(e.target.checked)}
                      >
                        Do you prefer cash gifts?
                      </Checkbox>
                    </Box>
                    <Box width={{ base: "100%" }} textAlign="center">
                      <ButtonCmp
                        text={"PROCEED"}
                        size="md"
                        padding="1.5rem 3rem"
                        isLoading={loading}
                        isDisabled={
                          !image || !wishlist_title || !day || !month || !year
                        }
                        opacity={"1"}
                        border={"none"}
                        textHoverColor={"#fff"}
                        width="100%"
                        maxWidth="345px"
                        type="submit"
                        onClick={handleSubmit}
                      />
                    </Box>
                  </Flex>
                  <ActionModal
                    isOpen={isExit}
                    onRequestClose={() => {
                      setIsExit(false);
                    }}
                    maxWidth="400px"
                    showCloseIcon={false}
                    actionTitle="are you sure?"
                    actionDesc="You will lose progress on this item if you don't save it before you go to another page"
                    yesText="YES, EXIT"
                    noText="NO, CANCEL"
                    yesAction={() => {
                      navigate(-1);
                    }}
                    noAction={() => {
                      setIsExit(false);
                    }}
                  />
                  <ImageCropperModal
                    isOpen={showImageCropper}
                    imageToCrop={image}
                    onRequestClose={() => {
                      setShowImageCropper(false);
                    }}
                    onCropComplete={(url: any) => {
                      setImage(url);
                    }}
                    onBackButtonClick={() => {
                      setShowImageCropper(false);
                      setShowImageModal(true);
                    }}
                    maxWidth="500px"
                    showCloseIcon={false}
                  />
                </Flex>
              </>
            ) : activeScreen === WeddingWishlistActiveScreen.A_WISHLIST ? (
              <>
                <AWishlist
                  onClose={onClose}
                  wishlistSlug={wishlistSlug}
                  wishlist={newWishlist as unknown as Wishlist}
                  setActiveScreen={setActiveScreen}
                />
              </>
            ) : (
              <>
                <AddGift
                  wishlistId={wishlistId}
                  setActiveScreen={setActiveScreen}
                />
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddWishlist;
