import { Box, Flex, Text } from "@chakra-ui/react";
import DaysLeftCmp from "components/DaysLeftCmp/DaysLeftCmp";
import EmptyCardCmp from "components/EmptyCardCmp";
import WishCardCmp from "components/WishCardCmp/WishCardCmp";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  contributorActions,
  contributorSelectors,
  wishlistSelectors,
} from "store/slices";
import { getNumberOfDays } from "utils/date";
import { dashboardData } from "data/dummy";
import CircularProgressCmp from "components/CircularProgressCmp/CircularProgressCmp";
import { FormikProvider, useFormik } from "formik";
import { CONTRIBUTORVALIDATIONS } from "validators/ContributorValidator";
import * as yup from "yup";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import FundModal from "components/Modals/Contributor/FundModal";
import NumberFormat from "react-number-format";
import { LogoLoader } from "components";

const FundGift = () => {
  const fundGiftValidationSchema = yup.object({
    fundAmount: CONTRIBUTORVALIDATIONS.fundAmount,
  });

  const { setShowBackArrow } = useOutletContext<any>();
  const [showFundModal, setShowFundModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [defaultFundIndex, setDefaultFundIndex] = useState<number>();
  const [defaultFundAmount, setDefaultFundAmount] = useState("");
  useEffect(() => {
    setShowBackArrow(true);
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const getAGiftPublicData = useAppSelector(
    contributorSelectors.getAGiftPublic
  );

  const giftPublicData: any = getAGiftPublicData?.payload;

  const getAWishlistData = useAppSelector(wishlistSelectors.getAWishlist);

  const wishlistData: any = getAWishlistData?.payload;

  const { getAGiftPublic, getAWishlistPublic } = contributorActions;


  useEffect(() => {
    if (!wishlistData) {
      dispatch(
        getAWishlistPublic({
          username: params.username!,
          slug: params.slug!,
        })
      );
    }
    setPageLoading(true);
    (async function () {
      dispatch(
        getAGiftPublic({
          username: params.username!,
          slug: params.slug!,
          giftId: params.giftId!,
        })
      )
        .then(async (res: any) => {
          const response = res;
          if (response.payload.statusCode === 500 && response.payload.message) {
            return navigate("/not-found");
          }
          setPageLoading(false);

          return;
        })
        .catch(() => {
          return;
        })
        .finally(() => setPageLoading(false));
    })();
  }, [
    dispatch,
    navigate,
    params.slug,
    params.username,
    getAGiftPublic,
    params.giftId,
    wishlistData,
    getAWishlistPublic,
  ]);


  const shouldNotDisplaySuggestedItems = giftPublicData?.userID === "655f7295a607d56baa4792ae";
  useEffect(() => {
    if (shouldNotDisplaySuggestedItems) {
      setDefaultFundIndex(3);
      formik.setFieldValue("fundAmount", "0");
      setDefaultFundAmount("0");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldNotDisplaySuggestedItems]);

  const formik = useFormik<{ fundAmount: any }>({
    enableReinitialize: true,
    initialValues: {
      fundAmount: defaultFundAmount,
    },
    validationSchema: fundGiftValidationSchema,
    onSubmit: (_values, ) => {
      setShowFundModal(true);
    },
  });
  return (
    <>
      {pageLoading && (
        <Flex justifyContent={"center"} py="1rem">
          {/* <SpinnerCmp
            enabled={true}
            color={"#4DB168"}
            size={50}
            secondaryColor={"#fff"}
          /> */}
          <LogoLoader />
        </Flex>
      )}
      {!pageLoading && (
        <Flex flexDir={"column"} gap="30px">
          <Box
            position="relative"
            bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%),url(${giftPublicData?.coverImage})`}
            backgroundSize={"cover"}
            backgroundPosition="center"
            backgroundRepeat={"no-repeat"}
            width={"100%"}
            height="227px"
            display={"flex"}
            justifyContent="center"
            borderRadius={"20px"}
          >
            <Flex
              position={"absolute"}
              bottom="20px"
              justifyContent={"space-between"}
              alignItems="flex-end"
              width="100%"
              px="1rem"
            >
              <Text
                fontFamily={"Helvetica"}
                fontSize={{ base: "1rem", md: "25px" }}
                fontWeight={"700"}
              >
                {giftPublicData?.title}
              </Text>
              <Flex gap="5px" alignItems={"center"}>
                <Box>
                  <CircularProgressCmp
                    label={`${Math.floor(
                      (giftPublicData?.grossPaid?.Amount /
                        giftPublicData?.netCost?.Amount) *
                        100
                    )}%`}
                    value={
                      (giftPublicData?.grossPaid?.Amount /
                        giftPublicData?.netCost?.Amount) *
                      100
                    }
                  />
                </Box>
                <Flex flexDir={"column"}>
                  <Text color="#Fff" fontSize={"18px"} fontWeight="400">
                    <NumberFormat
                      prefix="₦"
                      thousandSeparator
                      value={giftPublicData?.netCost?.Amount}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </Text>
                  <Text
                    color="rgba(255,255,255, 0.5)"
                    fontSize="12px"
                    fontWeight="400"
                  >
                    <NumberFormat
                      prefix="₦"
                      suffix=" raised"
                      thousandSeparator
                      value={giftPublicData?.grossPaid?.Amount}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              alignItems={"center"}
              gap="15px"
              position="absolute"
              right="15px"
              top="10px"
            >
              <Box>
                <DaysLeftCmp
                  num={getNumberOfDays(wishlistData?.wishlist?.date) || 0}
                  text="DAYS LEFT"
                />
              </Box>
            </Flex>
          </Box>
          <Flex
            flexDir={"column"}
            justifyContent="center"
            alignItems={"center"}
            width="290px"
            margin="0 auto"
          >
            <Text
              mx={"auto"}
              mb={"17px"}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"18px"}
              lineHeight={"24px"}
              display={"flex"}
              alignItems={"center"}
            >
              {!shouldNotDisplaySuggestedItems ? "choose an ": ""} amount to contribute
            </Text>
            <FormikProvider value={formik}>
              <form
                noValidate
                onSubmit={formik.handleSubmit}
                style={{ width: "100%" }}
              >
                <Flex flexDir={"column"} gap="15px">
                  {
                    !shouldNotDisplaySuggestedItems ?
                    <Flex justifyContent={"space-evenly"}>
                      {giftPublicData?.suggestedConstributionAmounts.map(
                        (v: number, i: number) => {
                          return (
                            <Box
                              key={i}
                              bgColor={"#22242C"}
                              borderRadius="16px"
                              display={"flex"}
                              justifyContent="center"
                              alignItems={"center"}
                              cursor="pointer"
                              p="12px"
                              border={
                                defaultFundIndex === i
                                  ? "1px solid white"
                                  : "1px solid transparent"
                              }
                              onClick={() => {
                                setDefaultFundIndex(i);
                                formik.setFieldValue("fundAmount", v);
                                setDefaultFundAmount(`${v}`);
                              }}
                            >
                              <NumberFormat
                                prefix="₦"
                                thousandSeparator
                                value={v}
                                decimalScale={2}
                                defaultValue="0.00"
                                allowNegative={false}
                                placeholder="0.00"
                                displayType="text"
                                disabled
                              />
                            </Box>
                          );
                        }
                      )}
                      <Box
                        bgColor={"#22242C"}
                        borderRadius="16px"
                        display={"flex"}
                        justifyContent="center"
                        alignItems={"center"}
                        cursor="pointer"
                        p="12px"
                        border={
                          defaultFundIndex === 3
                            ? "1px solid white"
                            : "1px solid transparent"
                        }
                        onClick={() => {
                          setDefaultFundIndex(3);
                          formik.setFieldValue("fundAmount", "0");
                          setDefaultFundAmount("0");
                        }}
                      >
                        others
                      </Box>
                    </Flex> : <></>
                  }
                  {defaultFundAmount && (
                    <Box>
                      <FormikFormInput
                        background="#22242C"
                        placeholder="amount to pay"
                        isNumericFormat
                        name="fundAmount"
                        isPrice
                        disabled={false}
                        showMessage={true}
                      />
                    </Box>
                  )}
                  <ButtonCmp
                    text={"CONTRIBUTE"}
                    size="md"
                    // isLoading={loading}
                    isDisabled={
                      formik.isValid &&
                      giftPublicData?.grossPaid?.Amount !==
                        giftPublicData?.netCost?.Amount
                        ? false
                        : true
                    }
                    opacity={"1"}
                    border={"none"}
                    textHoverColor={"#fff"}
                    width="100%"
                    maxWidth="100%"
                    type="submit"
                  />
                </Flex>
              </form>
            </FormikProvider>
          </Flex>
          {giftPublicData?.otherGifts.length <= 1 ? null : (
            <Flex flexDir={"column"}>
              <Text
                mx={"auto"}
                mb={"17px"}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"18px"}
                lineHeight={"24px"}
                display={"flex"}
                alignItems={"center"}
              >
                pick an item below
              </Text>
              <Flex
                flexDir={{ base: "row", md: "row" }}
                justifyContent={{ md: "space-between" }}
                flexWrap={"wrap"}
                gap={{ base: "5px", md: "unset" }}
              >
                {giftPublicData?.otherGifts.map((v: any, i: number) => {
                  return (
                    <WishCardCmp
                      key={i}
                      minW="33%"
                      widthMd="33%"
                      flex="0 0 33%"
                      widthBase="48%"
                      minWidthBase="48%"
                      flexBase="0 0 48%"
                      height="325px"
                      wishTitle={v.title}
                      bgColor="#22242B"
                      contributor
                      percentage={Math.floor(
                        (v?.grossPaid?.Amount / v?.netCost?.Amount) * 100
                      )}
                      onWrapperClick={() => {
                        navigate(
                          `/@${params.username}/${params.slug}/${v._id}`
                        );
                      }}
                      wishPrice={
                        <NumberFormat
                          thousandSeparator
                          value={v.netCost.Amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      }
                      grossPaid={
                        <NumberFormat
                          thousandSeparator
                          value={v.grossPaid.Amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      }
                      coverImage={v.coverImage}
                      isFulfilled={v?.grossPaid?.Amount === v?.netCost?.Amount}
                    />
                  );
                })}
                {dashboardData
                  .slice(giftPublicData?.otherGifts.length)
                  .map((_, index) => (
                    <EmptyCardCmp
                      minW="33%"
                      widthMd="33%"
                      flex="0 0 33%"
                      widthBase="48%"
                      minWidthBase="48%"
                      flexBase="0 0 48%"
                      bgColor="#22242B"
                      key={index}
                      height="325px"
                    />
                  ))}
              </Flex>
            </Flex>
          )}
          <FundModal
            fundInfo={{
              giftID: giftPublicData?._id,
              wishlistID: giftPublicData?.wishlistID,
              recipientID: giftPublicData?.userID,
              fundAmount: formik.values.fundAmount,
              event: giftPublicData?.title,
            }}
            isOpen={showFundModal}
            onRequestClose={() => {
              setShowFundModal(false);
            }}
            maxWidth="330px"
            showCloseIcon={true}
          />
        </Flex>
      )}
    </>
  );
};

export default FundGift;
