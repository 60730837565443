import daysLeft from "utils/daysLeft";
import { useAppDispatch } from "hooks";
import trimMoney from "utils/trimMoney";
import { LogoLoader } from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { weddingActions } from "store/slices/weddingSlice";
import {
  Box,
  Flex,
  Hide,
  Show,
  Text,
  // useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import ShareWeddingModal from "components/Modals/ShareWeddingModal";
import { GuestList, WeddingData, WeddingDataPayload } from "./Wedding/types";
import WeddingGuestListModal from "components/Modals/ShowWeddingGuestListModal";
import { AiOutlineEdit } from "react-icons/ai";
import { WeddingPageContext } from "apis";
import { UpdateWeddingCollection } from "utils/workbench/STOREs/collections/wedding.collection";
import AsoEbiPayersListModal from "components/Modals/ShowAsoEbiListModal";

const Wedding2: React.FC<{}> = () => {
  const [showAsoEbiPayersList, setShowAsoEbiPayersList] = useState(false);
  const [showWeddingGuestList, setShowWeddingGuestList] = useState(false);
  const [showShareWeddingModal, setShowShareWeddingModal] = useState(false);

  // const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [guestList] = useState<GuestList>([
    {
      _id: "",
      name: "",
      email: "",
      relationship: "",
      comingFor: "",
      accessCardAddress: "",
      phoneNumber: "",
    },
  ]);
  const [weddingData, setWeddingData] = useState<WeddingData>({
    _id: "",
    coverImage: "",
    date: new Date(),
    hashtag: "",
    ubxdid: "",
    allGuests: 0,
    brideGuests: 0,
    groomGuests: 0,
    guestList: guestList,
    totalGiftsBalance: 0,
    totalWalletBalance: 0,
    asoEbiExists: false,
    asoEbi: {
      salesCount: 0,
      amountPurchased: 0,
      asoEbiWalletBalance: 0,
    },
    shouldIncludeAccessCardAddress: false,
    shouldIncludePhoneNumber: false,
    rsvpRelationshipValues: [],
  });

  const { getAWeddingDashboard } = weddingActions;

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAWeddingDashboard({ userId: localStorage.getItem("userID")! }))
      .then((res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          const weddingPayload = response.payload.payload as WeddingDataPayload;
          const _weddingData = {
            _id: weddingPayload._id,
            coverImage: weddingPayload.coverImage,
            date: new Date(weddingPayload.date),
            hashtag: weddingPayload.hashtag,
            ubxdid: weddingPayload.ubxdid,
            allGuests: weddingPayload.allGuests,
            brideGuests: weddingPayload.brideGuests,
            groomGuests: weddingPayload.groomGuests,
            guestList: weddingPayload.guestList,
            totalGiftsBalance: weddingPayload.totalGiftsBalance,
            totalWalletBalance: weddingPayload.totalWalletBalance,
            asoEbiExists: weddingPayload.asoEbiExists,
            asoEbi: weddingPayload.asoEbi,
            shouldIncludeAccessCardAddress:
              weddingPayload.shouldIncludeAccessCardAddress,
            shouldIncludePhoneNumber: weddingPayload.shouldIncludePhoneNumber,
            rsvpRelationshipValues: weddingPayload.rsvpRelationshipValues,
          };
          setWeddingData(_weddingData);
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast({
          status: "error",
          description: "No wedding",
        });
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // don't update deps

  return (
    <>
      {isLoading ? (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      ) : (
        <>
          <Box
            // border={"1px solid red"}
            // bgColor={{base: "#16181D33", md: "none", lg: "none"}}
            borderRadius="16px"
            h="90vh"
            w={"100%"}
            p={{ base: "1rem", md: "2rem" }}
          >
            <Flex
              flexDir={"column"}
              flexWrap={"wrap"}
              // justifyContent={"space-between"}
              // maxHeight={{ base: "800px", lg: "677px" }}
              height={"100%"}
              w={"100%"}
              // border={"1px solid green"}
              // border={"1px solid"}
              // borderColor={"red"}
            >
              {/* TOP SECTION */}
              <Box height={{ base: "50%", md: "57.5%" }} w={"full"}>
                <Box
                  bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${weddingData.coverImage})`}
                  bgSize={"cover"}
                  pos={"relative"}
                  // border={"1px solid"}
                  // borderColor={"blue"}
                  width={"100%"}
                  height={"95%"}
                  // border={"1px solid white"}
                  // height={{ base: "300px", lg: "50%" }}
                  borderTopRightRadius={"16px"}
                  borderTopLeftRadius={"16px"}
                  marginBottom={"2.5%"}
                >
                  <Flex position={"absolute"} top={5} right={5}>
                    <Flex
                      cursor={"pointer"}
                      // mr={{ lg: "30px" }}
                      // mb={{ lg: "280px" }}
                      background={"#44A560"}
                      fontSize={"18px"}
                      alignItems={"center"}
                      gap={"5px"}
                      fontWeight={700}
                      px={"8px"}
                      borderRadius={"18px"}
                      onClick={() => {
                        UpdateWeddingCollection((weddingCollection) => {
                          weddingCollection.weddingPageContext =
                            WeddingPageContext.EDIT;
                        });
                      }}
                    >
                      <AiOutlineEdit color="white" />
                      <Text>edit</Text>
                    </Flex>
                  </Flex>

                  <Flex 
                    flexDir={"column"} 
                    justifyContent={"space-evenly"}                      position={"absolute"}
                    left={3}
                    bottom={3}
                    maxW={"50%"}
                  >
                    <Text fontSize={{ base: "14px", lg: "16px" }}>
                      {" "}
                      {`${daysLeft(weddingData.date) || 0} ${
                        daysLeft(weddingData.date) > 1 ? "DAYS" : "DAY"
                      } LEFT 💍`}
                    </Text>
                    <Text
                      fontSize={{ base: "18px", md: "32px", lg: "40px" }}
                      noOfLines={1}
                    >{`#${weddingData.hashtag}`}</Text>
                  </Flex>

                  <Flex
                    cursor={"pointer"}
                    position={"absolute"}
                    right={3}
                    bottom={5}
                    backgroundColor={"#44A560"}
                    width={{ base: "90px", md: "170px", lg: "205px" }}
                    height={{ base: "30px", lg: "47px" }}
                    borderRadius={"100px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    _hover={{
                      boxShadow: "none",
                      fontWeight: "bold",
                      backgroundColor: "#4cba6c",
                    }}
                    // marginBottom={{ lg: "15px" }}
                    onClick={() => setShowShareWeddingModal(true)}
                  >
                    <Text
                      fontSize={{ base: "10px", md: "16px", lg: "16px" }}
                    >
                      SHARE WEBSITE
                    </Text>
                  </Flex>

                  <Text
                    position={"absolute"}
                    bottom={{base: 65, md: 85}}
                    right={5}
                    cursor={"pointer"}
                    fontSize={{ base: "12px", md: "16px", lg: "16px" }}
                    borderBottom={"1px solid"}
                    // paddingTop={{ base: "20px", lg: "40px" }}
                    // marginBottom={{ base: "10px", lg: "20px" }}
                    _hover={
                      {
                        // fontWeight: { lg: "bold" },
                        // paddingTop: { lg: "69px" },
                        // marginBottom: { lg: "none" },
                        // borderBottom: { lg: "2px solid" },
                      }
                    }
                    onClick={() => {
                      window.open(
                        `https://www.unboxd.co/w/${weddingData.ubxdid}`
                      );
                    }}
                  >
                    PREVIEW
                  </Text>
                </Box>
              </Box>

              {/* LOWER SECTION */}
              <Show above="md">
                <Flex
                  width={"100%"}
                  height={"35%"}
                  // border={"1px solid blue"}
                  flexDir={"row"}
                  flexWrap={"wrap"}
                  // overflowY={"auto"}
                  // flexBasis={"10px"}
                  // border={"1px solid"}
                  // borderColor={"green"}
                  // justifyContent={"stretch"}
                >
                  {/* OPEN GUEST LIST COMPONENT */}
                  <Flex
                    backgroundColor={"#2D242E"}
                    borderRadius={"24px"}
                    flexDir={"column"}
                    marginBottom={{ base: "20px", lg: "0px" }}
                    w={"60%"}
                    mr={"5%"}
                    h={"100%"}
                  >
                    <Flex
                      height={{ lg: "27%" }}
                      justifyContent={"space-between"}
                      padding={"0px 27.5px"}
                      paddingTop={{ base: "30px", lg: "0px" }}
                    >
                      <Text
                        alignSelf={"flex-end"}
                        color={"rgba(255, 255, 255, 0.5)"}
                      >
                        Guests
                      </Text>
                      <Text
                        onClick={() => setShowWeddingGuestList(true)}
                        cursor={"pointer"}
                        alignSelf={"flex-end"}
                        margin={"0px 17px 0px 0px"}
                        fontSize={"12px"}
                        color={"#EC40FF"}
                        borderBottom={"1px solid "}
                        paddingBottom={"2px"}
                        _hover={{
                          fontWeight: "bold",
                          paddingBottom: "4px",
                          borderBottom: "2px solid",
                        }}
                      >
                        OPEN GUEST LIST
                      </Text>
                    </Flex>
                    <Flex
                      marginTop={{ base: "40px", lg: "0px" }}
                      height={{ lg: "73%" }}
                      flexDir={"column"}
                      justifyContent={"center"}
                    >
                      <Flex
                        height={{ lg: "57px" }}
                        flexDir={"row"}
                        padding={"0px 27.5px"}
                      >
                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "50px", lg: "10px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            height={{ base: "100%" }}
                            justifyContent={"space-between"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              All
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.allGuests}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "70px", lg: "10px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              For Bride
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.brideGuests}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              For Groom
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.groomGuests}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  {/* OPEN WALLET COMPONENT */}
                  <Flex
                    backgroundColor={"#2E2D24"}
                    w={"35%"}
                    h={"100%"}
                    borderRadius={"24px"}
                    flexDir={"column"}
                  >
                    <Flex
                      height={{ lg: "27%" }}
                      padding={"0px 22px"}
                      justifyContent={"space-between"}
                      paddingTop={{ base: "30px", lg: "0px" }}
                    >
                      <Text
                        alignSelf={"end"}
                        color={"rgba(255, 255, 255, 0.5)"}
                      >
                        Gifts
                      </Text>
                      <Text
                        cursor={"pointer"}
                        alignSelf={"end"}
                        color={"#FFEB35"}
                        fontSize={"12px"}
                        _hover={{
                          fontWeight: "bold",
                          paddingBottom: "4px",
                          borderBottom: "2px solid",
                        }}
                        paddingBottom={"2px"}
                        borderBottom={"1px solid"}
                        onClick={() => {
                          navigate(`/wallet`);
                        }}
                      >
                        OPEN WALLET
                      </Text>
                    </Flex>
                    <Flex
                      marginTop={{ base: "40px", lg: "0px" }}
                      height={{ lg: "73%" }}
                      flexDir={"column"}
                      justifyContent={"center"}
                    >
                      <Flex height={{ lg: "57px" }} padding={"0px 22px"}>
                        <Box
                          // border={"1px solid"}
                          // borderColor={"gray"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "130px", lg: "60px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              color={"rgba(255, 255, 255, 0.5)"}
                              fontSize={{ base: "16px", lg: "12px" }}
                            >
                              Total
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              ₦{trimMoney(weddingData.totalGiftsBalance)}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"gray"}
                          width={{ base: "100px", lg: "400px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              color={"rgba(255, 255, 255, 0.5)"}
                              fontSize={{ base: "16px", lg: "12px" }}
                            >
                              Balance
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              ₦{trimMoney(weddingData.totalWalletBalance)}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  {/* OPEN ASO EBI COMPONENT */}
                  {weddingData.asoEbiExists ? (
                    <Flex
                      backgroundColor={"#242E27"}
                      borderRadius={"24px"}
                      flexDir={"column"}
                      marginBottom={{ base: "20px", lg: "0px" }}
                      w={"60%"}
                      mr={"5%"}
                      mt={"22px"}
                      h={"80%"}
                    >
                      <Flex
                        height={{ lg: "27%" }}
                        justifyContent={"space-between"}
                        padding={"0px 27.5px"}
                        paddingTop={{ base: "30px", lg: "0px" }}
                      >
                        <Text
                          alignSelf={"flex-end"}
                          color={"rgba(255, 255, 255, 0.5)"}
                        >
                          Aso ebi
                        </Text>
                        <Text
                          onClick={() => setShowAsoEbiPayersList(true)}
                          cursor={"pointer"}
                          alignSelf={"flex-end"}
                          margin={"0px 17px 0px 0px"}
                          color={"#15ED56"}
                          fontSize={"12px"}
                          borderBottom={"1px solid "}
                          paddingBottom={"2px"}
                          _hover={{
                            fontWeight: "bold",
                            paddingBottom: "4px",
                            borderBottom: "2px solid",
                          }}
                        >
                          OPEN &nbsp; ASO EBI
                        </Text>
                        <Text
                          onClick={() => navigate("/wallet")}
                          cursor={"pointer"}
                          alignSelf={"flex-end"}
                          margin={"0px 17px 0px 0px"}
                          color={"#15ED56"}
                          fontSize={"12px"}
                          borderBottom={"1px solid "}
                          paddingBottom={"2px"}
                          _hover={{
                            fontWeight: "bold",
                            paddingBottom: "4px",
                            borderBottom: "2px solid",
                          }}
                        >
                          OPEN &nbsp; ASO EBI WALLET
                        </Text>
                        {/* <Text
                            onClick={() => setShowWeddingGuestList(true)}
                            cursor={"pointer"}
                            alignSelf={"flex-end"}
                            margin={"0px 17px 0px 0px"}
                            color={"#15ED56"}
                            borderBottom={"1px solid "}
                            paddingBottom={"2px"}
                            fontSize={'12px'}
                            _hover={{
                              fontWeight: "bold",
                              paddingBottom: "4px",
                              borderBottom: "2px solid",
                            }}
                          >
                            OPEN &nbsp; ASO EBI &nbsp; WALLET
                          </Text> */}
                      </Flex>
                      <Flex
                        marginTop={{ base: "40px", lg: "0px" }}
                        height={{ lg: "73%" }}
                        flexDir={"column"}
                        justifyContent={"center"}
                      >
                        <Flex
                          height={{ lg: "57px" }}
                          flexDir={"row"}
                          padding={"0px 27.5px"}
                        >
                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                            marginRight={{ base: "70px", lg: "10px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              height={{ base: "100%" }}
                              justifyContent={"space-between"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Sales
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                {weddingData?.asoEbi?.salesCount}
                              </Text>
                            </Flex>
                          </Box>

                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                            marginRight={{ base: "70px", lg: "10px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                              justifyContent={"space-evenly"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Sales Amount
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                ₦{trimMoney(weddingData.asoEbi.amountPurchased)}
                              </Text>
                            </Flex>
                          </Box>

                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                              justifyContent={"space-evenly"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Wallet Balance
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                ₦
                                {trimMoney(
                                  weddingData.asoEbi.asoEbiWalletBalance
                                )}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Show>
              <Hide above="md">
                <Flex
                  width={"100%"}
                  height={"47.5%"}
                  // border={"1px solid blue"}
                  // flexDir={"row"}
                  flexDirection="column"
                  overflowY={"auto"}
                  // flexWrap={"wrap"}
                  // border={"1px solid"}
                  // borderColor={"green"}
                  // justifyContent={"stretch"}
                >
                  {/* OPEN GUEST LIST COMPONENT */}
                  <Flex
                    backgroundColor={"#2D242E"}
                    borderRadius={"24px"}
                    flexDir={"column"}
                    marginBottom={{ base: "20px", lg: "0px" }}
                    w={"100%"}
                    // h={"40%"}
                  >
                    <Flex
                      height={{ lg: "27%" }}
                      justifyContent={"space-between"}
                      padding={"0px 27.5px"}
                      paddingTop={{ base: "30px", lg: "0px" }}
                    >
                      <Text
                        alignSelf={"flex-end"}
                        color={"rgba(255, 255, 255, 0.5)"}
                      >
                        Guests
                      </Text>
                      <Text
                        onClick={() => setShowWeddingGuestList(true)}
                        cursor={"pointer"}
                        alignSelf={"flex-end"}
                        margin={"0px 17px 0px 0px"}
                        color={"#EC40FF"}
                        borderBottom={"1px solid "}
                        fontSize={"10px"}
                        paddingBottom={"2px"}
                        _hover={{
                          fontWeight: "bold",
                          paddingBottom: "4px",
                          borderBottom: "2px solid",
                        }}
                      >
                        OPEN GUEST LIST
                      </Text>
                    </Flex>
                    <Flex
                      marginTop={{ base: "40px", lg: "0px" }}
                      height={{ lg: "73%" }}
                      flexDir={"column"}
                      justifyContent={"center"}
                    >
                      <Flex
                        height={{ lg: "57px" }}
                        flexDir={"row"}
                        padding={"0px 27.5px"}
                      >
                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "70px", lg: "10px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            height={{ base: "100%" }}
                            justifyContent={"space-between"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              All
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.allGuests}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "70px", lg: "10px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              For Bride
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.brideGuests}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"green"}
                          width={{ base: "100px", lg: "400px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            // border={"1px solid"}
                            // borderColor={"red"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              fontSize={{ base: "16px", lg: "12px" }}
                              color={"rgba(255, 255, 255, 0.5)"}
                            >
                              For Groom
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              {weddingData.groomGuests}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  {/* OPEN WALLET COMPONENT */}
                  <Flex
                    backgroundColor={"#2E2D24"}
                    w={"100%"}
                    // h={"40%"}
                    borderRadius={"24px"}
                    flexDir={"column"}
                  >
                    <Flex
                      height={{ lg: "27%" }}
                      padding={"0px 22px"}
                      justifyContent={"space-between"}
                      paddingTop={{ base: "30px", lg: "0px" }}
                    >
                      <Text
                        alignSelf={"end"}
                        color={"rgba(255, 255, 255, 0.5)"}
                      >
                        Gifts
                      </Text>
                      <Text
                        cursor={"pointer"}
                        alignSelf={"end"}
                        color={"#FFEB35"}
                        _hover={{
                          fontWeight: "bold",
                          paddingBottom: "4px",
                          borderBottom: "2px solid",
                        }}
                        fontSize={"10px"}
                        paddingBottom={"2px"}
                        borderBottom={"1px solid"}
                        onClick={() => {
                          navigate(`/wallet`);
                        }}
                      >
                        OPEN WALLET
                      </Text>
                    </Flex>
                    <Flex
                      marginTop={{ base: "40px", lg: "0px" }}
                      height={{ lg: "73%" }}
                      flexDir={"column"}
                      justifyContent={"center"}
                    >
                      <Flex height={{ lg: "57px" }} padding={"0px 22px"}>
                        <Box
                          // border={"1px solid"}
                          // borderColor={"gray"}
                          width={{ base: "100px", lg: "400px" }}
                          marginRight={{ base: "130px", lg: "60px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              color={"rgba(255, 255, 255, 0.5)"}
                              fontSize={{ base: "16px", lg: "12px" }}
                            >
                              Total
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              ₦{trimMoney(weddingData.totalGiftsBalance)}
                            </Text>
                          </Flex>
                        </Box>

                        <Box
                          // border={"1px solid"}
                          // borderColor={"gray"}
                          width={{ base: "100px", lg: "400px" }}
                        >
                          <Flex
                            flexDir={"column"}
                            justifyContent={"space-evenly"}
                          >
                            <Text
                              color={"rgba(255, 255, 255, 0.5)"}
                              fontSize={{ base: "16px", lg: "12px" }}
                            >
                              Balance
                            </Text>
                            <Text fontSize={{ base: "26px", lg: "28px" }}>
                              ₦{trimMoney(weddingData.totalWalletBalance)}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Flex>
                  </Flex>
                  {/* OPEN ASO EBI COMPONENT */}
                  {weddingData.asoEbiExists ? (
                    <Flex
                      backgroundColor={"#242E27"}
                      borderRadius={"24px"}
                      flexDir={"column"}
                      marginTop={{ base: "20px", lg: "0px" }}
                      w={"100%"}
                      // h={"450px"}
                    >
                      <Flex
                        height={{ lg: "27%" }}
                        padding={"0px 20px"}
                        justifyContent={"space-between"}
                        paddingTop={{ base: "30px", lg: "0px" }}
                      >
                        <Text
                          // alignSelf={"flex-end"}
                          color={"rgba(255, 255, 255, 0.5)"}
                        >
                          Aso ebi
                        </Text>
                        <Text
                          onClick={() => setShowAsoEbiPayersList(true)}
                          cursor={"pointer"}
                          alignSelf={"flex-end"}
                          marginLeft={"15px"}
                          // margin={"0px 17px 0px 10px"}
                          color={"#15ED56"}
                          fontSize={"10px"}
                          borderBottom={"1px solid "}
                          paddingBottom={"2px"}
                          _hover={{
                            fontWeight: "bold",
                            paddingBottom: "4px",
                            borderBottom: "2px solid",
                          }}
                        >
                          OPEN ASO EBI
                        </Text>
                        <Text
                          onClick={() => navigate("/wallet")}
                          cursor={"pointer"}
                          alignSelf={"flex-end"}
                          marginLeft={"15px"}
                          // margin={"0px 17px 0px 10px"}
                          color={"#15ED56"}
                          fontSize={"10px"}
                          borderBottom={"1px solid "}
                          paddingBottom={"2px"}
                          _hover={{
                            fontWeight: "bold",
                            paddingBottom: "4px",
                            borderBottom: "2px solid",
                          }}
                        >
                          OPEN ASO EBI WALLET
                        </Text>
                        {/* <Text
                            onClick={() => setShowWeddingGuestList(true)}
                            cursor={"pointer"}
                            alignSelf={"flex-end"}
                            marginLeft={"15px"}
                            // margin={"0px 17px 0px 10px"}
                            color={"#15ED56"}
                            fontSize={"10px"}
                            borderBottom={"1px solid "}
                            paddingBottom={"2px"}
                            _hover={{
                              fontWeight: "bold",
                              paddingBottom: "4px",
                              borderBottom: "2px solid",
                            }}
                          >
                            OPEN ASO EBI WALLET
                          </Text> */}
                      </Flex>
                      <Flex
                        marginTop={{ base: "40px", lg: "0px" }}
                        height={{ lg: "73%" }}
                        flexDir={"column"}
                        justifyContent={"center"}
                      >
                        <Flex
                          height={{ lg: "57px" }}
                          flexDir={"row"}
                          padding={"0px 27.5px"}
                        >
                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                            marginRight={{ base: "40px", lg: "10px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              height={{ base: "100%" }}
                              justifyContent={"space-between"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Sales
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                {weddingData?.asoEbi?.salesCount}
                              </Text>
                            </Flex>
                          </Box>

                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                            marginRight={{ base: "40px", lg: "10px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                              justifyContent={"space-evenly"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Sales Amount
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                ₦{trimMoney(weddingData.asoEbi.amountPurchased)}
                              </Text>
                            </Flex>
                          </Box>

                          <Box
                            // border={"1px solid"}
                            // borderColor={"green"}
                            width={{ base: "100px", lg: "400px" }}
                          >
                            <Flex
                              flexDir={"column"}
                              // border={"1px solid"}
                              // borderColor={"red"}
                              justifyContent={"space-evenly"}
                            >
                              <Text
                                fontSize={{ base: "16px", lg: "12px" }}
                                color={"rgba(255, 255, 255, 0.5)"}
                              >
                                Wallet Balance
                              </Text>
                              <Text fontSize={{ base: "26px", lg: "28px" }}>
                                ₦
                                {trimMoney(
                                  weddingData.asoEbi.asoEbiWalletBalance
                                )}
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Hide>
            </Flex>

            <ShareWeddingModal
              ubxdid={weddingData.ubxdid}
              hashtag={weddingData.hashtag}
              isOpen={showShareWeddingModal}
              onRequestClose={() => {
                setShowShareWeddingModal(false);
              }}
              maxWidth="400px"
            />

            <WeddingGuestListModal
              weddingID={weddingData._id}
              guestList={weddingData.guestList}
              isOpen={showWeddingGuestList}
              onRequestClose={() => {
                setShowWeddingGuestList(false);
              }}
              showCloseIcon={false}
              setShowWeddingGuestList={setShowWeddingGuestList}
              showWeddingGuestList={showWeddingGuestList}
              wedding={weddingData}
            />
            <AsoEbiPayersListModal
              isOpen={showAsoEbiPayersList}
              onRequestClose={() => {
                setShowAsoEbiPayersList(false);
              }}
              showCloseIcon={false}
              showAsoEbiPayersList={showAsoEbiPayersList}
              setShowAsoEbiPayersList={setShowAsoEbiPayersList}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default Wedding2;
