import { useAppSelector } from "hooks";
import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";

import { logout as Logout } from "./logout";
import { authSelectors } from "store/slices";
import { useDispatch } from "react-redux";

// import SessionTimeoutDialog from "./SessionTimeoutDialog";

interface ISessionTimeout {
	ref?: any | undefined;
	logout?: () => void;
}

const SessionTimeout: React.FC<ISessionTimeout> = () => {
	let countdownInterval: any;
	let timeout: any;
	const idleTimer = useRef<any>(null);
	const dispatch: any = useDispatch();

	const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
	const [, setTimeoutCountdown] = useState(0);

	const clearSessionTimeout = () => {
		clearTimeout(timeout);
	};

	const clearSessionInterval = () => {
		clearInterval(countdownInterval);
	};

	const handleLogout = async (_ = false) => {
		try {
			setTimeoutModalOpen(false);
			clearSessionInterval();
			clearSessionTimeout();
			dispatch(Logout());
		} catch (err) {
			console.error(err);
		}
	};

	// const handleContinue = () => {
	//   setTimeoutModalOpen(false);
	//   clearSessionInterval();
	//   clearSessionTimeout();
	// };

	const onActive = () => {
		if (!timeoutModalOpen) {
			clearSessionInterval();
			clearSessionTimeout();
		}
	};

	const accessToken = useAppSelector(authSelectors.getAccessToken);

	const onIdle = () => {
		const delay = 1000 * 1;
		if (localStorage.getItem("accessToken") || accessToken) {
			timeout = setTimeout(() => {
				let countDown = 10;
				// setTimeoutModalOpen(true);
				setTimeoutCountdown(countDown);
				countdownInterval = setInterval(() => {
					if (countDown > 0) {
						setTimeoutCountdown(--countDown);
					} else {
						handleLogout(true);
					}
				}, 1000);
			}, delay);
		}
	};

	return (
		<>
			<IdleTimer
				ref={(_: any) => idleTimer}
				onActive={onActive}
				onIdle={onIdle}
				debounce={250}
				timeout={100000000000}
			/>
		</>
	);
};
export default SessionTimeout;
