import { Flex } from "@chakra-ui/react";
import EmptyDataCmp from "components/EmptyDataCmp";

const GiftExchange = () => {
  return (
    <>
      <Flex flexDir={"column"} gap="5px">
        <EmptyDataCmp
          title="Ooops you are not part of any secret santa yet"
          desc="Create one with your friends or colleagues"
          link="/secret-santa/create"
          btnText="Yes! Create"
        />
      </Flex>
    </>
  );
};

export { default as SingleGiftExchange } from "./singleGiftExchange";
export { default as CreateGiftExchange } from "./create";
export default GiftExchange;
