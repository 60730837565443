import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetAnalyticsContributionChart,
  GetAnalyticsContributionOverview,
  GetAnalyticsSingleContributionChart,
  GetAnalyticsSingleContributionOverview,
  GetAnalyticsTopContributors,
  MRequest,
} from "apis";
import { RootState } from "store/reducers";

const entity = "contributor";

const getAnalyticsContributionChart = createAsyncThunk(
  "analytics/getAnalyticsContributionChart",
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<typeof GetAnalyticsContributionChart.RES>(
        `${GetAnalyticsContributionChart.ROUTE}?${params}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAnalyticsContributionOverview = createAsyncThunk(
  "analytics/getAnalyticsContributionOverview",
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<
        typeof GetAnalyticsContributionOverview.RES
      >(`${GetAnalyticsContributionOverview.ROUTE}?${params}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAnalyticsTopContributors = createAsyncThunk(
  "analytics/getAnalyticsTopContributors",
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<typeof GetAnalyticsTopContributors.RES>(
        `${GetAnalyticsTopContributors.ROUTE}?${params}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAnalyticsSingleContributionChart = createAsyncThunk(
  "analytics/getAnalyticsSingleContributionChart",
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<
        typeof GetAnalyticsSingleContributionChart.RES
      >(`${GetAnalyticsSingleContributionChart.ROUTE}?${params}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAnalyticsSingleContributionOverview = createAsyncThunk(
  "analytics/getAnalyticsSingleContributionOverview",
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<
        typeof GetAnalyticsSingleContributionOverview.RES
      >(`${GetAnalyticsSingleContributionOverview.ROUTE}?${params}`);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export type AnalyticsInitialStateType = {
  getAnalyticsContributionChartResponse:
    | GetAnalyticsContributionChart
    | null
    | any;
  getAnalyticsContributionOverviewResponse:
    | GetAnalyticsContributionOverview
    | null
    | any;
  getAnalyticsTopContributorsResponse: GetAnalyticsTopContributors | null | any;
  getAnalyticsSingleContributionChartResponse:
    | GetAnalyticsSingleContributionChart
    | null
    | any;
  getAnalyticsSingleContributionOverviewResponse:
    | GetAnalyticsSingleContributionOverview
    | null
    | any;
};

const initialState: AnalyticsInitialStateType = {
  getAnalyticsContributionChartResponse: null,
  getAnalyticsContributionOverviewResponse: null,
  getAnalyticsTopContributorsResponse: null,
  getAnalyticsSingleContributionChartResponse: null,
  getAnalyticsSingleContributionOverviewResponse: null,
};

const analyticsSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAnalyticsContributionChart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsContributionChartResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsContributionChart.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsContributionChartResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsContributionOverview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsContributionOverviewResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsContributionOverview.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsContributionOverviewResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsTopContributors.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsTopContributorsResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsTopContributors.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsTopContributorsResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsSingleContributionChart.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsSingleContributionChartResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsSingleContributionChart.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsSingleContributionChartResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsSingleContributionOverview.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsSingleContributionOverviewResponse = action.payload;
      }
    );
    builder.addCase(
      getAnalyticsSingleContributionOverview.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAnalyticsSingleContributionOverviewResponse = action.payload;
      }
    );
  },
});

export const analyticsActions = {
  ...analyticsSlice.actions,
  getAnalyticsContributionChart,
  getAnalyticsContributionOverview,
  getAnalyticsTopContributors,
  getAnalyticsSingleContributionChart,
  getAnalyticsSingleContributionOverview,
};

export const analyticsSelectors = {
  getAnalyticsContributionChart: (state: RootState) =>
    state.analytics.getAnalyticsContributionChartResponse,
  getAnalyticsContributionOverview: (state: RootState) =>
    state.analytics.getAnalyticsContributionOverviewResponse,
  getAnalyticsTopContributors: (state: RootState) =>
    state.analytics.getAnalyticsTopContributorsResponse,
  getAnalyticsSingleContributionChart: (state: RootState) =>
    state.analytics.getAnalyticsSingleContributionChartResponse,
  getAnalyticsSingleContributionOverview: (state: RootState) =>
    state.analytics.getAnalyticsSingleContributionOverviewResponse,
};

export const analyticsReducer = analyticsSlice.reducer;
