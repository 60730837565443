import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

const TableCmp: React.FC<{
  header: string[];
  data: any[];
  tableHeight?: string;
  tHeadBgColor?: string;
  tHColor?: string;
  tDColor?: string;
}> = ({ header, data, tableHeight, tHeadBgColor, tHColor, tDColor }) => {
  return (
    <>
      <TableContainer
        height={tableHeight || "100%"}
        overflowY={"scroll"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Table variant="simple">
          <Thead
            position={"sticky"}
            top={0}
            zIndex="docked"
            bgColor={tHeadBgColor || "#fff"}
          >
            <Tr>
              {header.map((v, i) => {
                return (
                  <Th
                    fontWeight={"500"}
                    fontSize="12px"
                    color={tHColor || "#000"}
                    textTransform={"unset"}
                    key={i}
                  >
                    {v}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.map((v, _) => {
                return (
                  <Tr>
                    {Object.values(v).map((value: any, _: number) => {
                      return (
                        <Td
                          fontWeight={"400"}
                          fontSize={"16px"}
                          color={tDColor || "#000"}
                        >
                          {value}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableCmp;
