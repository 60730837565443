import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { OpenEyeImg, ShowImg } from "assets/svgs";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IBalance {
  amount: any;
  widthMd?: string;
  text?: string;
  showToggle?: boolean;
  h?: string;
  baseH?: string;
  baseMt?: string;
  bg?: string;
  padding?: string;
  showPayoutBtn?: boolean;
  showNotification?: boolean;
  payoutBtnAction?: () => void;
  cardClick?: boolean;
  payoutBtnText?: string;
  isCompleted?: boolean;
  toggleDisabled?: boolean;
  isOrganization?: boolean;
}

const BalanceCmp = ({
  amount,
  widthMd,
  text,
  showToggle,
  bg,
  h,
  baseH,
  baseMt,
  padding,
  showPayoutBtn,
  showNotification,
  payoutBtnAction,
  cardClick,
  payoutBtnText,
  isCompleted,
  toggleDisabled,
  isOrganization,
}: IBalance) => {
  const [hideBal, setHideBal] = useState(false);
  const navigate = useNavigate();
  return (
    <Box
      zIndex="90"
      w={{ base: "100%", md: widthMd || "220px" }}
      h={{ base: baseH || "100px", sm: h || "100px" }}
      bg={bg || "#16181D"}
      p={padding || "14px 20px"}
      borderRadius={"18px"}
      display={"flex"}
      flexDir={"column"}
      alignItems={{ base: "flex-start", md: "flex-start" }}
      mt={{ base: baseMt || "0px", md: "0" }}
      position="relative"
      onClick={cardClick ? () => navigate("/wallet") : () => {}}
    >
      <Flex
        justifyContent={
          showNotification
            ? { base: "center", lg: "flex-start" }
            : "space-between"
        }
        width="100%"
        zIndex="1000"
      >
        <Box>
          <Text
            color={"#FDD384"}
            fontSize={"15px"}
            lineHeight={"21px"}
            fontWeight={400}
            fontFamily={"Plus Jakarta Sans"}
            textAlign={showNotification ? "center" : "unset"}
            noOfLines={1}
          >
            {text || "Wallet Balance"}
          </Text>
          <Text
            color={"#FFFFFF"}
            fontSize={"21px"}
            lineHeight={"36px"}
            fontWeight={400}
            fontFamily={"Plus Jakarta Sans"}
          >
            NGN{hideBal ? "***" : amount}
          </Text>
        </Box>
        {showToggle && (
          <Image
            src={hideBal ? OpenEyeImg : ShowImg}
            alt="Toggle"
            cursor={"pointer"}
            onClick={() => {
              setHideBal(!hideBal);
            }}
          />
        )}
      </Flex>
      {showNotification && (
        <>
          {/* <Flex
            display={{ base: "flex", sm: "none" }}
            justifyContent="space-between"
            borderRadius={"0px 0px 18px 18px"}
            bgColor="#007CBE"
            width="100%"
            fontFamily={"Plus Jakarta Sans"}
            position="absolute"
            bottom="0"
            left="0"
            px="1.1rem"
            py="0.7rem"
          >
            <Text fontSize={"15px"} fontWeight="400">
              2 new contributions
            </Text>
            <Text
              fontSize={"15px"}
              fontWeight="400"
              textDecoration={"underline"}
            >
              Open wallet
            </Text>
          </Flex> */}
        </>
      )}
      {showPayoutBtn && (
        <Box pt="1rem">
          <ButtonCmp
            isDisabled={!toggleDisabled ? false : isCompleted ? false : true}
            text={
              <>
                {payoutBtnText || isOrganization
                  ? "WITHDRAW FUNDS"
                  : "REQUEST PAYOUT"}{" "}
                &nbsp;&nbsp; <ArrowForwardIcon h={6} w={6} />
              </>
            }
            size="md"
            padding="1.5rem 1rem"
            onClick={payoutBtnAction}
            // isLoading={loggingIn}
            opacity={"1"}
            height="40px"
            border={"none"}
            textHoverColor={"#fff"}
            width="100%"
            maxWidth="100%"
            borderRadius="20px"
            type="submit"
          />
        </Box>
      )}
    </Box>
  );
};

export default BalanceCmp;
