import ModalCmp from "components/ModalCmp/ModalCmp";
import React from "react";


const SuggestGiftModal: React.FC<
{
  isOpen: boolean;
  // maxWidth: string;
  onRequestClose: () => void;
  children: React.ReactNode;
}> = ({
  isOpen,
  // maxWidth,
  onRequestClose,
  children,
}) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        // contentStyles={{
        //   // maxWidth: maxWidth || "350px",
        // }}
        shouldFocusAfterRender={false}
        contentLabel="Terms"
        id="terms"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={true}
        // fullHeight={true}
        position="bottom"
      >
        {children}
      </ModalCmp>    
    </>
  );
};

export default SuggestGiftModal;
