export const getDaysInMonth = (month: number, year: number) => {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push({
      text: new Date(date).getDate(),
      value: new Date(date).getDate(),
    });
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const months = [
  { text: "January", value: 1 },
  { text: "February", value: 2 },
  { text: "March", value: 3 },
  { text: "April", value: 4 },
  { text: "May", value: 5 },
  { text: "June", value: 6 },
  { text: "July", value: 7 },
  { text: "August", value: 8 },
  { text: "September", value: 9 },
  { text: "October", value: 10 },
  { text: "November", value: 11 },
  { text: "December", value: 12 },
];

export const generateYears = (startDate: Date, range: number) => {
  let years: Array<{ text: number; value: number }> = [];
  const startYear = startDate.getFullYear();
  for (let i = startYear; i < startYear + range; i++) {
    years.push({ text: i, value: i });
  }

  return years;
};

export const splitDate = (date: Date) => {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

export const getNumberOfDays = (futureDate: string) => {
  const date1 = new Date(Date.now());
  const date2 = new Date(futureDate);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays < 0 ? 0 : diffInDays;
};

export type DestructuredDate = {
  day: number;
  month: number;
  year: number;
};


export const createTimeFromTimeString = (timeString: string) => {
  const timeHr = timeString.slice(0,2);
  const timeMinute = timeString.slice(3,5);
  let timeFormat = "am";
  if (Number(timeHr) >= 12) {
    timeFormat = "pm"
  }
  return {timeHr, timeMinute, timeFormat};
};

export const getDateFromTrimmedDate = (trimmedDate: string) => {
  if (!trimmedDate) throw new Error(`please input date`);
  if (trimmedDate.length > 15 || trimmedDate.length < 15) throw new Error(`invalid date: length should be 15`);
  const month = getMonth[trimmedDate.slice(4,7)];
  const day = Number(trimmedDate.slice(8,10));
  const year = Number(trimmedDate.slice(11,15));
  return new Date(year, month, day, 1);
};


export const getMonth: {
  [key: string]: number
} = {
  'Jan': 0,
  'Feb': 1,
  'Mar': 2,
  'Apr': 3,
  'May': 4,
  'Jun': 5,
  'Jul': 6,
  'Aug': 7,
  'Sep': 8,
  'Oct': 9,
  'Nov': 10,
  'Dec': 11,
};

export type DestructuredTime = {
  timeHr: string;
  timeMinute: string;
  timeFormat: string;
};

export const destructureDate = (dateStr: string | Date) => {
  const newDate = new Date(dateStr);
  const day = newDate.getUTCDate();
  const month = newDate.getUTCMonth();
  const year = newDate.getFullYear();
  return {day, month: month + 1, year};
};


export const combineDateAndTime = (destructuredDate: DestructuredDate, destructuredTime: DestructuredTime) => {
  const {day, month, year} = destructuredDate;
  const {timeHr, timeMinute, timeFormat} = destructuredTime;
  return new Date(year, month - 1, day, timeFormat === 'am' ? Number(timeHr) : toTwentyFourHrTime(Number(timeHr)), Number(timeMinute));
}

const toTwentyFourHrTime = (timeHr: number) => {
  if (timeHr < 12) return timeHr + 12;
  return 12;
};

export const getDateFromDayAndTime = (day: string, time: string) => {
  const destructuredTime = createTimeFromTimeString(time);
  // console.log({destructuredTime, time});
  const dateFromTrimmedDate = getDateFromTrimmedDate(day);
  const destructuredDate = destructureDate(dateFromTrimmedDate);
  const theDate = combineDateAndTime(destructuredDate, destructuredTime);
  return theDate;
};

export const getDayAndTimeFromDate = (date: Date) => {
  const { destructuredDate, destructuredTime } = destructureCombinedDateAndTime(date);
  const day = createDateFromDestructuredDate(destructuredDate);
  const time = createTimeFromDestructuredTime(destructuredTime);
  return {day, time};
};

const toTwelveHrTime = (timeHr: number) => (timeHr % 12);

export const destructureCombinedDateAndTime = (combinedDateAndTime: string | Date) => {
  const date = new Date(combinedDateAndTime);
  const day = date.getDate();
  const month = date.getUTCMonth();
  const year = date.getFullYear();
  const timeFormat = date.getHours() >= 12 ? 'pm' : 'am';
  const timeHr = toTwelveHrTime(date.getHours());
  const timeMinute = date.getMinutes();
  return {
    destructuredDate: {day, month: month + 1, year},
    destructuredTime: {timeHr: timeHr === 0 ? `12` : `${timeHr < 10 ? `0${timeHr}` : timeHr }`, timeMinute: `${timeMinute < 10 ? `0${timeMinute}` : timeMinute}`, timeFormat: timeFormat}
  };
};

export const createDateFromDestructuredDate = (destructuredDate: DestructuredDate) => {
  const {day, month, year} = destructuredDate;
  return new Date(year, month - 1, day);
}

export const createTimeFromDestructuredTime = (destructuredTime: DestructuredTime) => {
  const {timeHr, timeMinute, timeFormat} = destructuredTime;
  return `${timeHr}:${timeMinute}${timeFormat}`;
};



const date = {
  getDaysInMonth,
  months,
  generateYears,
  splitDate,
  getNumberOfDays,
};


export default date;
