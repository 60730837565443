import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Image,
  Show,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import millify from "millify";
import { LogoLoader } from "components";
import WishlistCardCmp from "components/WishlistCardCmp/WishlistCardCmp";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { WalletSvg, WishlistSvg } from "assets/svgs";
import NumberFormat from "react-number-format";
import WishCardCmp from "components/WishCardCmp/WishCardCmp";
import {
  secretSantaActions,
  secretSantaSelectors,
  walletActions,
  walletSelectors,
} from "store/slices";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import ActionModal from "components/Modals/ActionModal";
import OpenWishlistDrawer from "./openWishlistDrawer";
import GiftWalletDrawer from "./giftWalletDrawer";
import { getNumberOfDays } from "utils/date";
import { getNameInitials } from "utils/getNameInitials";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

const SingleGiftExchange = () => {
  const { width, height } = useWindowSize();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [showWalletDrawer, setShowWalletDrawer] = useState(false);
  const dispatch = useAppDispatch();
  const [showDeleteGift, setShowDeleteGift] = useState(false);
  const [giftId, setGiftId] = useState("");

  const singleUserExchangeDetails = useAppSelector(
    secretSantaSelectors.getSingleUserExchange
  );

  const giftExchangeRecipientWishlistDetails = useAppSelector(
    secretSantaSelectors.getGiftExchangeRecipientWishlist
  );

  const {
    getSingleUserExchange,
    deleteGiftFromExchange,
    getGiftExchangeRecipientWishlist,
  } = secretSantaActions;

  const getWalletsData: any = useAppSelector(
    walletSelectors.getWishlistWallets
  );

  const { getWishlistWallets } = walletActions;

  const walletsDataPayload: any = getWalletsData?.payload;

  const singleUserExchangeData: any = singleUserExchangeDetails?.payload;
  const giftExchangeRecipientWishlistData: any =
    giftExchangeRecipientWishlistDetails?.payload;

  const [hideConfetti, setHideConfetti] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(getSingleUserExchange({ id }))
      .then((res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          dispatch(
            getGiftExchangeRecipientWishlist({
              giftExchangeID: id!,
              recipientID:
                response?.payload?.payload?.match?.recipient?.recipientID,
            })
          );
          dispatch(
            getWishlistWallets({
              otherParam: `/giftexchange/${singleUserExchangeData?.wishlist[0]?.giftExchangeWishlistID}`,
            })
          );
          setHideConfetti(response?.payload?.payload?.match?.hasBeenViewed);
          return setLoading(false);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getSingleUserExchange,
    getWishlistWallets,
    id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    singleUserExchangeData?.wishlist[0]?.giftExchangeWishlistID,
  ]);
  const StatCard: React.FC<{
    title: string;
    count: string;
    total?: string;
    bgColor: string;
  }> = ({ title, count, total, bgColor }) => {
    return (
      <>
        {loading ? (
          <Flex justifyContent={"center"} py="1rem">
            <LogoLoader />
          </Flex>
        ) : (
          <Box
            w="115px"
            h="110px"
            borderRadius={"12px"}
            bgColor={bgColor || "#242D2E"}
            p="1rem"
          >
            <Flex
              flexDir={"column"}
              h="100%"
              justifyContent={"space-between"}
              color="#fff"
            >
              <Text fontSize={"10px"} w="50px" opacity="0.5">
                {title}
              </Text>
              <Text fontSize={"23px"} fontWeight="400">
                {count}{" "}
                {total && (
                  <span style={{ fontSize: "16px", opacity: "0.5" }}>
                    /{total}
                  </span>
                )}
              </Text>
            </Flex>
          </Box>
        )}
      </>
    );
  };

  const InitialsCard: React.FC<{
    initials: string;
    right?: string;
    absolutePosition?: boolean;
  }> = ({ initials, right, absolutePosition }) => {
    return (
      <Flex
        justifyContent={"center"}
        alignItems="center"
        width="40px"
        height="40px"
        borderRadius={"50%"}
        bgColor="#E7D7BB"
        color="#6D4C1D"
        border="1px solid #22242c"
        right={right}
        position={`${absolutePosition ? "absolute" : "unset"}`}
      >
        {initials}
      </Flex>
    );
  };

  return (
    <>
      {loading ? (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      ) : (
        <Box maxW={"800px"} w="100%">
          <Flex flexDir={"column"} gap="15px">
            <Flex flexDir={"column"} gap="4px">
              <Box
                display={{ base: "flex" }}
                gap="20px"
                //   marginRight={{ base: "-20px", lg: "unset" }}
              >
                <Box w={{ base: "100%", sm: "75%", md: "70%" }}>
                  <Text
                    textAlign={"center"}
                    fontWeight="400"
                    fontSize={"16px"}
                    pb="0.5rem"
                  >
                    Hosted by {singleUserExchangeData?.companyName}
                  </Text>
                  <WishlistCardCmp
                    widthMd="100%"
                    minW="100%"
                    widthLg="100%"
                    flex="0 0 100%"
                    wishlistTitle={singleUserExchangeData?.title}
                    wishCardWidth="max-content"
                    amount={2}
                    pillBgColor="#CEFABB"
                    pillTextColor="#338149"
                    amountText={
                      singleUserExchangeData?.participants <= 1
                        ? "Participant"
                        : "Participants"
                    }
                    hideCard={true}
                    bgImage={singleUserExchangeData?.coverImage}
                    removeMenu={true}
                    weddingType
                  />
                  <Show above="sm">
                    <>
                      <Flex
                        flexDir={"column"}
                        gap="8px"
                        maxW={"700px"}
                        w="100%"
                      >
                        <Flex
                          alignItems={"center"}
                          gap="5px"
                          fontWeight={"400"}
                        >
                          <Text color={"#fff"} fontSize="16px">
                            Your wishlist
                          </Text>
                          <Text
                            color={"rgba(255, 255, 255, 0.5)"}
                            fontSize="10px"
                          >
                            2 of 4 items added
                          </Text>
                        </Flex>

                        {!singleUserExchangeData?.wishlist.length ? (
                          <Box
                            borderRadius={"12px"}
                            h="160px"
                            background="linear-gradient(250.39deg, #16181D 16.7%, #2C2F38 76.72%)"
                            p="1rem"
                            display="flex"
                            alignItems={"center"}
                            gap="20px"
                          >
                            <Box>
                              <Image
                                src={WishlistSvg}
                                alt="Wishlist"
                                opacity="0.2"
                              />
                            </Box>
                            <Flex
                              flexDir={{ base: "column", sm: "row" }}
                              justifyContent="space-between"
                              alignItems={{
                                base: "flex-start",
                                sm: "center",
                              }}
                              w="100%"
                            >
                              <Box>
                                <Text fontSize="20px" color="#fff">
                                  Nothing here
                                </Text>
                                <Text
                                  fontSize={"14px"}
                                  color="rgba(255, 255, 255, 0.7)"
                                  maxWidth={"200px"}
                                  lineHeight="18px"
                                  py="0.5rem"
                                >
                                  add up to 2 items you’d like to receive as
                                  Christmas gifts
                                </Text>
                              </Box>
                              <Box pt="4px">
                                <ButtonCmp
                                  text={"ADD ITEMS NOW"}
                                  size="md"
                                  padding="1rem"
                                  opacity={"1"}
                                  border={"none"}
                                  textHoverColor={"#fff"}
                                  backgroundColor="#44A560"
                                  backgroundHoverColor="#44A560"
                                  width="100%"
                                  maxWidth="125px"
                                  height="42px"
                                  type="submit"
                                  fontSize="12px"
                                  borderRadius="10px"
                                  onClick={() => {
                                    navigate(`/secret-santa/create-gift/${id}`);
                                  }}
                                />
                              </Box>
                            </Flex>
                          </Box>
                        ) : (
                          <>
                            <Flex flexDir={"row"} gap="5px">
                              <Swiper
                                spaceBetween={10}
                                // slidesPerView={2.5}
                                speed={500}
                                loop={false}
                                touchRatio={1.5}
                                style={{ width: "100%" }}
                              >
                                {singleUserExchangeData?.wishlist.map(
                                  (v: any, i: number) => {
                                    return (
                                      <SwiperSlide key={i}>
                                        <WishCardCmp
                                          minW="33%"
                                          widthMd="33%"
                                          flex="0 0 33%"
                                          widthBase="48%"
                                          minWidthBase="48%"
                                          flexBase="0 0 48%"
                                          height="230px"
                                          bgHeight="150px"
                                          wishTitle={v.title}
                                          bgColor="#2C2F38"
                                          onDeleteClick={() => {
                                            setGiftId(v._id);
                                            setShowDeleteGift(true);
                                          }}
                                          onEditClick={() => {
                                            navigate(
                                              `/secret-santa/edit-gift/${v._id}`,
                                              { state: { exchangeId: id } }
                                            );
                                          }}
                                          wishPrice={
                                            <NumberFormat
                                              thousandSeparator
                                              value={v?.netCost.Amount}
                                              decimalScale={2}
                                              defaultValue="0.00"
                                              allowNegative={false}
                                              placeholder="0.00"
                                              displayType="text"
                                              disabled
                                            />
                                          }
                                          coverImage={v.coverImage}
                                          hideProgress
                                          showPrice
                                        />
                                      </SwiperSlide>
                                    );
                                  }
                                )}
                              </Swiper>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </>
                  </Show>
                </Box>
                <Show above="sm">
                  <Box
                    display={{ base: "flex" }}
                    flexDir="column"
                    w="fit-content"
                    gap="20px"
                    mt="12px"
                  >
                    {[
                      {
                        title: "gift added",
                        count: `${singleUserExchangeData?.wishlist.length}`,
                        bgColor: "#242d2e",
                      },
                      {
                        title: "gift price limit",
                        count: `₦${millify(
                          singleUserExchangeData?.priceCap?.Amount
                        )}`,
                        bgColor: "#2d242e",
                      },
                      {
                        title: "wishlist fulfilled",
                        count: "0",
                        bgColor: "#2E2D24",
                        total: "0",
                      },
                    ].map((v: any, i: number) => {
                      return (
                        <>
                          <StatCard
                            key={i}
                            title={v.title}
                            count={v.count}
                            total={v.total}
                            bgColor={v.bgColor}
                          />
                        </>
                      );
                    })}
                  </Box>
                </Show>
              </Box>
            </Flex>
            <Show below="sm">
              <Flex flexDir={"column"} gap="8px" maxW={"700px"} w="100%">
                <Flex alignItems={"center"} justifyContent="space-between">
                  <Flex alignItems={"center"} gap="5px" fontWeight={"400"}>
                    <Text color={"#fff"} fontSize="16px">
                      Your wishlist
                    </Text>
                    <Text color={"rgba(255, 255, 255, 0.5)"} fontSize="10px">
                      2 of 4 items added
                    </Text>
                  </Flex>
                  <Image
                    src={WalletSvg}
                    alt="Wallet"
                    onClick={() => {
                      setShowWalletDrawer(true);
                    }}
                  />
                </Flex>
                {!singleUserExchangeData?.wishlist.length ? (
                  <Box
                    borderRadius={"12px"}
                    h="160px"
                    background="linear-gradient(250.39deg, #16181D 16.7%, #2C2F38 76.72%)"
                    p="1rem"
                    display="flex"
                    alignItems={"center"}
                    gap="20px"
                  >
                    <Box>
                      <Image src={WishlistSvg} alt="Wishlist" opacity="0.2" />
                    </Box>
                    <Flex
                      flexDir={{ base: "column", sm: "row" }}
                      justifyContent="space-between"
                      alignItems={{
                        base: "flex-start",
                        sm: "center",
                      }}
                      w="100%"
                    >
                      <Box>
                        <Text fontSize="20px" color="#fff">
                          Nothing here
                        </Text>
                        <Text
                          fontSize={"14px"}
                          color="rgba(255, 255, 255, 0.7)"
                          maxWidth={"200px"}
                          lineHeight="18px"
                          py="0.5rem"
                        >
                          add up to 2 items you’d like to receive as Christmas
                          gifts
                        </Text>
                      </Box>
                      <Box pt="4px">
                        <ButtonCmp
                          text={"ADD ITEMS NOW"}
                          size="md"
                          padding="1rem"
                          opacity={"1"}
                          border={"none"}
                          textHoverColor={"#fff"}
                          backgroundColor="#44A560"
                          backgroundHoverColor="#44A560"
                          width="100%"
                          maxWidth="125px"
                          height="42px"
                          type="submit"
                          fontSize="12px"
                          borderRadius="10px"
                          onClick={() => {
                            navigate(`/secret-santa/create-gift/${id}`);
                          }}
                        />
                      </Box>
                    </Flex>
                  </Box>
                ) : (
                  <>
                    <Flex flexDir={"row"} gap="5px">
                      <Swiper
                        spaceBetween={10}
                        // slidesPerView={2.5}
                        speed={500}
                        loop={false}
                        touchRatio={1.5}
                        style={{ width: "100%" }}
                      >
                        {singleUserExchangeData?.wishlist.map(
                          (v: any, i: number) => {
                            return (
                              <SwiperSlide key={i}>
                                <WishCardCmp
                                  minW="33%"
                                  widthMd="33%"
                                  flex="0 0 33%"
                                  widthBase="48%"
                                  minWidthBase="48%"
                                  flexBase="0 0 48%"
                                  height="230px"
                                  bgHeight="150px"
                                  wishTitle={v.title}
                                  bgColor="#2C2F38"
                                  onDeleteClick={() => {
                                    setGiftId(v._id);
                                    setShowDeleteGift(true);
                                  }}
                                  onEditClick={() => {
                                    navigate(
                                      `/secret-santa/edit-gift/${v._id}`,
                                      {
                                        state: { exchangeId: id },
                                      }
                                    );
                                  }}
                                  wishPrice={
                                    <NumberFormat
                                      thousandSeparator
                                      value={v?.netCost.Amount}
                                      decimalScale={2}
                                      defaultValue="0.00"
                                      allowNegative={false}
                                      placeholder="0.00"
                                      displayType="text"
                                      disabled
                                    />
                                  }
                                  coverImage={v.coverImage}
                                  hideProgress
                                  showPrice
                                />
                              </SwiperSlide>
                            );
                          }
                        )}
                      </Swiper>
                    </Flex>
                  </>
                )}
              </Flex>
            </Show>
            <Show below="sm">
              <Flex flexDir={"column"} gap="4px">
                <Box display={{ base: "flex", lg: "none" }}>
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={2.5}
                    speed={500}
                    loop={false}
                    touchRatio={1.5}
                    style={{ width: "100%" }}
                  >
                    {[
                      {
                        title: "gift added",
                        count: `${singleUserExchangeData?.wishlist.length}`,
                        bgColor: "#242d2e",
                      },
                      {
                        title: "gift price limit",
                        count: `₦${millify(
                          singleUserExchangeData?.priceCap?.Amount
                        )}`,
                        bgColor: "#2d242e",
                      },
                      {
                        title: "wishlist fulfilled",
                        count: "0",
                        bgColor: "#2E2D24",
                        total: "0",
                      },
                    ].map((v: any, i: number) => {
                      return (
                        <>
                          <SwiperSlide key={i}>
                            <StatCard
                              title={v.title}
                              count={v.count}
                              total={v.total}
                              bgColor={v.bgColor}
                            />
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                </Box>
              </Flex>
            </Show>

            <Flex flexDir={"column"} gap="8px" maxW={"700px"} w="100%">
              <Text color={"#fff"} fontSize="16px" fontWeight={"500"}>
                Your match
              </Text>
              <Box
                borderRadius={"12px"}
                h="180px"
                // h="160px" for when it is empty
                background="linear-gradient(250.39deg, #16181D 16.7%, #2C2F38 76.72%)"
                p="1rem"
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                //conditionally render justifyContent
                gap="20px"
              >
                {singleUserExchangeData?.match ? (
                  <Flex
                    flexDir={"column"}
                    justifyContent="center"
                    alignItems={"center"}
                    gap="10px"
                  >
                    <Flex alignItems={"center"} ml="-30px">
                      <Box display="flex" position="relative">
                        <InitialsCard
                          initials={getNameInitials(
                            `${singleUserExchangeData?.match.giver.firstname} ${singleUserExchangeData?.match.giver.lastname}`
                          )}
                        />
                        <InitialsCard
                          initials={getNameInitials(
                            `${singleUserExchangeData?.match.recipient.firstname} ${singleUserExchangeData?.match.recipient.lastname}`
                          )}
                          right="-30px"
                          absolutePosition
                        />
                      </Box>
                    </Flex>
                    <Text
                      color={"rgba(255, 255, 255, 0.7)"}
                      fontWeight="400"
                      fontSize={"12px"}
                      width="163px"
                      m="0 auto"
                      textAlign={"center"}
                    >
                      You were matched with{" "}
                      <span
                        style={{ textTransform: "capitalize" }}
                      >{`${singleUserExchangeData?.match.recipient.firstname} ${singleUserExchangeData?.match.recipient.lastname}`}</span>
                    </Text>
                    <ButtonCmp
                      text={`OPEN ${(singleUserExchangeData?.match.recipient.firstname).toUpperCase()}’S WISHLIST`}
                      size="md"
                      onClick={() => {
                        setOpenWishlist(true);
                      }}
                      opacity={"1"}
                      height="50px"
                      border={"none"}
                      textHoverColor={"#fff"}
                      width="fit-content"
                      maxWidth="100%"
                      type="submit"
                      fontWeight="400"
                      fontSize="12px"
                    />
                  </Flex>
                ) : (
                  <>
                    <Box>
                      <CircularProgress
                        trackColor="rgba(255, 255, 255, 0.32)"
                        value={80}
                        color="#F7D38F"
                        size="80px"
                        thickness={"5px"}
                      >
                        <CircularProgressLabel
                          fontSize={"12px"}
                          fontWeight="400"
                          w="50px"
                        >
                          {getNumberOfDays(singleUserExchangeData?.deadline)}{" "}
                          days left
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Box>
                    <Flex
                      flexDir={{ base: "column", sm: "row" }}
                      justifyContent="space-between"
                      alignItems={{ base: "flex-start", sm: "center" }}
                      w="100%"
                    >
                      <Box>
                        <Text fontSize="20px" color="#fff">
                          Nothing here
                        </Text>
                        <Text
                          fontSize={"14px"}
                          color="rgba(255, 255, 255, 0.7)"
                          maxWidth={"200px"}
                          lineHeight="18px"
                          py="0.5rem"
                        >
                          Participants will be matched on the{" "}
                          {new Date(
                            singleUserExchangeData?.deadline
                          ).toDateString()}
                        </Text>
                      </Box>
                      <Box pt="4px">
                        <ButtonCmp
                          text={"coming soon"}
                          size="md"
                          padding="1rem"
                          opacity={"1"}
                          border={"none"}
                          textHoverColor={"#fff"}
                          backgroundColor="rgba(255, 255, 255, 0.2)"
                          backgroundHoverColor="rgba(255, 255, 255, 0.2)"
                          width="100%"
                          maxWidth="120px"
                          height="31px"
                          type="submit"
                        />
                      </Box>
                    </Flex>
                  </>
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
      <ActionModal
        isOpen={showDeleteGift}
        onRequestClose={() => {
          setShowDeleteGift(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        actionTitle="delete gift?"
        actionDesc="You will not be able to recover the gift after you confirm this action"
        yesText="YES, DELETE"
        noText="NO, CANCEL"
        yesAction={() => {
          dispatch(deleteGiftFromExchange({ giftID: giftId }))
            .then((res: any) => {
              const response = res;
              if (
                response &&
                response.payload &&
                response.payload.statusCode === 200
              ) {
                dispatch(getSingleUserExchange({ id }));
                toast({
                  status: "success",
                  description: "Gift deleted successfully",
                });
                return navigate(`/secret-santa/${id}`);
              }
            })
            .catch(() => {
              toast({
                status: "error",
                description: "There is an error",
              });
            });
          setShowDeleteGift(false);
        }}
        noAction={() => {
          setShowDeleteGift(false);
        }}
      />
      <OpenWishlistDrawer
        isOpen={openWishlist}
        onClose={() => {
          setOpenWishlist(false);
        }}
        giftExchangeRecipientWishlistData={giftExchangeRecipientWishlistData}
        userExchangeData={singleUserExchangeData}
      />
      <GiftWalletDrawer
        isOpen={showWalletDrawer}
        onClose={() => {
          setShowWalletDrawer(false);
        }}
        bgImage={singleUserExchangeData?.coverImage}
        data={{
          singleUserExchangeData: singleUserExchangeData,
          walletsDataPayload: walletsDataPayload,
        }}
      />
      {!hideConfetti && (
        <Confetti
          width={width}
          height={height}
          recycle={false}
          numberOfPieces={750}
          onConfettiComplete={(confettiInstance: any) => {
            confettiInstance.reset();
          }}
        />
      )}
    </>
  );
};

export default SingleGiftExchange;
