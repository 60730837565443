import { Text, Flex, Stack, Box, Heading, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import Colors from "constants/Colors";
import pixel2rem from "utils/pixel2rem";
import { ReactComponent as ChevronRight } from "assets/svgs/chevron_right.svg";
import Header from "Layout/Dashboard/Header";
import { useAppSelector } from "hooks";
import { dashboardSelectors } from "store/slices";
import React from "react";

const AccountInformation = React.lazy(
  () => import("./SettingsCmp/accountInformation")
);
const ChangePassword = React.lazy(() => import("./SettingsCmp/changePassword"));
const DeactivateAccount = React.lazy(
  () => import("./SettingsCmp/deactivateAccount")
);
const EditProfile = React.lazy(() => import("./SettingsCmp/EditProfile"));

const Settings = () => {
  const [activeTab, setActiveTab] = useState("editProfile");
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const [changed, setChanged] = useState(false);

  const userDetails = useAppSelector(dashboardSelectors.getUser);

  const settingsComp: any = (activeTab: string) => {
    if (activeTab === "editProfile") {
      return (
        <EditProfile
          changed={changed}
          setDataChange={setChanged}
          userDetails={userDetails}
        />
      );
    } else if (activeTab === "accountInfo") {
      return (
        <AccountInformation
          changed={changed}
          setDataChange={setChanged}
          userDetails={userDetails}
        />
      );
    } else if (activeTab === "changePassword") {
      return <ChangePassword />;
    } else {
      return <DeactivateAccount />;
    }
  };

  return (
    <>
      <Flex
        w="100%"
        flex={{ lg: "1" }}
        alignItems={{ lg: "space-between" }}
        pos="relative"
        fontFamily={"Plus Jakarta Sans"}
      >
        <Stack width={"100%"} spacing={8} maxW={{ md: "250px", lg: "unset" }}>
          <Box
            cursor="pointer"
            onClick={() => {
              setActiveTab("editProfile");
              setMobileDisplay(!mobileDisplay);
            }}
            opacity={activeTab === "editProfile" ? "1" : "0.5"}
          >
            <Flex justifyContent="space-between" alignItems="center" w={"100%"}>
              <Heading
                as="h4"
                fontSize={pixel2rem(20)}
                fontWeight={"390"}
                lineHeight={"150%"}
                // w={{ base: pixel2rem(264), lg: pixel2rem(316) }}
                fontFamily={"Plus Jakarta Sans"}
              >
                Edit Profile
              </Heading>
              <IconButton
                aria-label={"navigation button"}
                icon={<ChevronRight />}
                bg="transparent"
                _active={{ background: "transparent" }}
                _focus={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
              />
            </Flex>
            <Text
              fontSize={pixel2rem(16)}
              fontWeight={"390"}
              lineHeight={"150%"}
              w={{ base: pixel2rem(184), lg: pixel2rem(337) }}
              color={Colors.uGrey}
            >
              Change your profile picture and name
            </Text>
          </Box>
          <Box
            cursor="pointer"
            onClick={() => {
              setActiveTab("accountInfo");
              setMobileDisplay(!mobileDisplay);
            }}
            opacity={activeTab === "accountInfo" ? "1" : "0.5"}
          >
            <Flex justifyContent="space-between" alignItems="center" w={"100%"}>
              <Heading
                as="h4"
                fontSize={pixel2rem(20)}
                fontWeight={"390"}
                lineHeight={"150%"}
                w={{ base: pixel2rem(264), lg: pixel2rem(316) }}
                fontFamily={"Plus Jakarta Sans"}
              >
                Account information
              </Heading>
              <IconButton
                aria-label={"navigation button"}
                icon={<ChevronRight />}
                bg="transparent"
                _active={{ background: "transparent" }}
                _focus={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
              />
            </Flex>
            <Text
              fontSize={pixel2rem(16)}
              fontWeight={"390"}
              lineHeight={"150%"}
              w={{ base: pixel2rem(184), lg: pixel2rem(337) }}
              color={Colors.uGrey}
            >
              See your account info like your email and username
            </Text>
          </Box>
          <Box
            cursor="pointer"
            onClick={() => {
              setActiveTab("changePassword");
              setMobileDisplay(!mobileDisplay);
            }}
            opacity={activeTab === "changePassword" ? "1" : "0.5"}
          >
            <Flex justifyContent="space-between" alignItems="center" w={"100%"}>
              <Heading
                as="h4"
                fontSize={pixel2rem(20)}
                fontWeight={"390"}
                lineHeight={"150%"}
                w={{ base: pixel2rem(264), lg: pixel2rem(316) }}
                fontFamily={"Plus Jakarta Sans"}
              >
                Change password
              </Heading>
              <IconButton
                aria-label={"navigation button"}
                icon={<ChevronRight />}
                bg="transparent"
                _active={{ background: "transparent" }}
                _focus={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
              />
            </Flex>
            <Text
              fontSize={pixel2rem(16)}
              fontWeight={"390"}
              lineHeight={"150%"}
              w={{ base: pixel2rem(184), lg: pixel2rem(337) }}
              color={Colors.uGrey}
            >
              Reset the password of your account
            </Text>
          </Box>
          <Box
            cursor="pointer"
            onClick={() => {
              setActiveTab("deactivateAccount");
              setMobileDisplay(!mobileDisplay);
            }}
            opacity={activeTab === "deactivateAccount" ? "1" : "0.5"}
          >
            <Flex justifyContent="space-between" alignItems="center" w={"100%"}>
              <Heading
                as="h4"
                fontSize={pixel2rem(20)}
                fontWeight={"390"}
                lineHeight={"150%"}
                w={{ base: pixel2rem(264), lg: pixel2rem(316) }}
                fontFamily={"Plus Jakarta Sans"}
              >
                Deactivate account
              </Heading>
              <IconButton
                aria-label={"navigation button"}
                icon={<ChevronRight />}
                bg="transparent"
                _active={{ background: "transparent" }}
                _focus={{ background: "transparent" }}
                _hover={{ background: "transparent" }}
              />
            </Flex>
            <Text
              fontSize={pixel2rem(16)}
              fontWeight={"390"}
              lineHeight={"150%"}
              w={{ base: pixel2rem(184), lg: pixel2rem(337) }}
              color={Colors.uGrey}
            >
              Find out how you can deactivate your account
            </Text>
          </Box>
        </Stack>
        <Box
          display={{ base: "none", md: "block" }}
          marginLeft={{ base: "none", md: "1rem", lg: "3rem" }}
          maxWidth="350px"
          width="100%"
        >
          {settingsComp(activeTab)}
        </Box>
        {mobileDisplay && (
          <>
            <Box
              pos="fixed"
              top="0"
              left="0"
              alignSelf={"start"}
              justifySelf={"start"}
              display={{ base: "block", md: "none" }}
              bg={Colors.uBackground}
              h="100vh"
              w="100%"
              p={4}
              pt={"16px"}
            >
              <Header
                pageTitle={activeTab}
                hasInnerPage
                setMobileDisplay={() => setMobileDisplay(false)}
              />

              {settingsComp(activeTab)}
            </Box>
          </>
        )}
      </Flex>
    </>
  );
};

export default Settings;
