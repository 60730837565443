import { Box, Flex, Image } from "@chakra-ui/react";

type SupportSiteCmpParams = {
  productImage: string;
  productUrl: string;
  productCategory1: string;
  productCategory2: string;
};

const SupportSiteCmp = ({
  productImage,
  productUrl,
  productCategory1,
  productCategory2,
}: SupportSiteCmpParams) => {
  return (
    <>
      <Box>
        <Flex flexDirection={"row"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            marginRight={"20px"}
          >
            <Image
              src={productImage}
              alt="Nothing yet"
              width="49px"
              height="49px"
              borderRadius={"50%"}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"start"}
            alignItems={"start"}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "start",
                width: "230px",
              }}
            >
              {productUrl}
            </span>
            <Flex flexDirection={"row"}>
              <span
                style={{
                  color: "#000",
                  backgroundColor: "#A9F389",
                  padding: "4px 8px",
                  borderRadius: "36px",
                  marginRight: "10px",
                  fontSize: "10px",
                  height: "23px",
                }}
              >
                {productCategory1}
              </span>
              <span
                style={{
                  color: "#000",
                  backgroundColor: "#A9F389",
                  padding: "4px 8px",
                  borderRadius: "36px",
                  fontSize: "10px",
                  height: "23px",
                }}
              >
                {productCategory2}
              </span>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default SupportSiteCmp;
