import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { useNavigate } from "react-router-dom";

const EmptyDataCmp: React.FC<{
  desc: string;
  title: string;
  link?: string;
  btnText?: string;
}> = ({ desc, title, link, btnText }) => {
  const navigate = useNavigate();

  return (
    <>
      <Flex h="300px" justifyContent={"center"} alignItems="center">
        <Flex
          flexDirection={"column"}
          gap="10px"
          fontFamily={"Plus Jakarta Sans"}
          alignItems="center"
        >
          <Image
            src={"https://media.giphy.com/media/26hkhPJ5hmdD87HYA/giphy.gif"}
            alt="Nothing yet"
            width="100px"
            height="100px"
            borderRadius={"50%"}
          />
          <Flex flexDirection={"column"} gap="10px" alignItems="center">
            <Text fontSize={"20px"} fontWeight="400">
              {title}
            </Text>
            <Text
              width="178px"
              margin="0 auto"
              textAlign="center"
              fontSize={"12px"}
              fontWeight="400"
              opacity="0.6"
            >
              {desc}
            </Text>
          </Flex>
          {link && (
            <Box width={{ base: "100%" }}>
              <ButtonCmp
                text={btnText}
                size="md"
                opacity={"1"}
                border={"none"}
                textHoverColor={"#fff"}
                onClick={() => {
                  navigate(link);
                }}
                height="50px"
                width="100%"
                maxWidth="100%"
                type="submit"
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default EmptyDataCmp;
