import { useEffect, useRef } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useAppDispatch } from "hooks";
import { contributorActions } from "store/slices";

type PropsTypes = {
  reference: string;
  amount: number;
  email: string;
  event: string;
  success: () => void;
};

const PayWithFlutterwave = ({
  reference,
  amount,
  email,
  event,
  success,
}: PropsTypes) => {
  const successReference = useRef("");
  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_CLIENT || "",
    tx_ref: reference,
    amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email,
      name: "",
      phone_number: "",
    },
    customizations: {
      title: "Unboxd",
      description: `Payment for ${event}`,
      logo: "",
    },
  };

  const handlePayment = useFlutterwave(config);

  const dispatch = useAppDispatch();

  const { updateGiven } = contributorActions;

  useEffect(() => {
    if (
      reference &&
      (!successReference.current || successReference.current !== reference)
    ) {
      handlePayment({
        callback: async (response) => {
          const { status, transaction_id, tx_ref } = response;
          if (status === "successful") {
            dispatch(
              updateGiven({
                transactionID: `${transaction_id}`,
                reference: tx_ref,
              })
            ).then((res: any) => {
              const response = res;
              if (
                response.payload.statusCode === 200 &&
                response.payload.message === "contribution successful"
              ) {
                closePaymentModal();
                success();
              }
            });
          }
        },
        onClose: () => {},
      });
    }
  }, [handlePayment, reference, dispatch, updateGiven, success]);

  return null;
};

export default PayWithFlutterwave;
