import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";

import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { authActions, walletActions, walletSelectors } from "store/slices";
import { useAppDispatch, useAppSelector } from "hooks";
import { WALLETVALIDATIONS } from "validators/WalletValidator";
import ActionModal from "components/Modals/ActionModal";
import NumberFormat from "react-number-format";
import { LogoLoader } from "components";
import { isAsoEbiID } from "utils/createPageName";

const otpValidationSchema = yup.object({
  otp: WALLETVALIDATIONS.otp,
});

const VerifyRequest = () => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [isExit, setIsExit] = useState(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const data = useLocation().state as any;

  const { sendOTP } = authActions;
  const { createPayout, createTransactionPayout } = walletActions;

  const [pageLoading, setPageLoading] = useState(false);

  const getVerifyRequestResponse: any = useAppSelector(
    walletSelectors.verifyPayoutRequest
  )?.payload;

  useEffect(() => {
    setPageLoading(true);
    if (getVerifyRequestResponse) {
      setPageLoading(false);
    }
  }, [getVerifyRequestResponse]);

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      let payload = {};
      if (data?.type === "giftExchange") {
        payload = { giftExchangeWishlistID: data.wishlistID };
      } else if (localStorage.getItem("userType") === "Organisation") {
        payload = { walletContextID: data.wishlistID, walletContext : "generalevent" };
      } else {
        payload = { wishlistID: data.wishlistID };
      }
      
      const { isAsoEbi, weddingID } = isAsoEbiID(data.wishlistID);
      
      dispatch(
        isAsoEbi ?
        createTransactionPayout({
          bankAccountID: data._id,
          ...payload,
          walletContext: 'aso_ebi',
          walletContextID: weddingID,
          amount: getVerifyRequestResponse?.amount?.Amount,
          otp: `${values.otp}`,
          email: getVerifyRequestResponse?.email,
        })
        :        
        localStorage.getItem("userType") === "Organisation" ?
        createTransactionPayout({
          bankAccountID: data._id,
          ...payload,
          amount: getVerifyRequestResponse?.amount?.Amount,
          otp: `${values.otp}`,
          email: getVerifyRequestResponse?.email,
        })
        :
        createPayout({
          bankAccountID: data._id,
          ...payload,
          amount: getVerifyRequestResponse?.amount?.Amount,
          otp: `${values.otp}`,
          email: getVerifyRequestResponse?.email,
        })
      )
        .then((res: any) => {
          const response = res;

          setLoading(false);
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            data?.type === "giftExchange"
              ? navigate("/secret-santa")
              : navigate("/wallet");
            return toast({
              status: "success",
              description: "Withdrawal request successsful",
            });
          } else {
            return toast({
              status: "error",
              description: "Cannot process payout at this time. Try again later!",
              // description: response.payload.message,
            });
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "There is an error",
          });
        })
        .finally(() => setLoading(false));
    },
  });
  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            color="#fff"
            h={8}
            w={8}
            cursor="pointer"
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <Box maxWidth="360px" width="100%" margin="0 auto" pt="1rem">
          <Box display={"flex"} flexDirection="column" gap="1px">
            <Box textAlign={"center"} fontFamily="Plus Jakarta Sans">
              <Text
                color={"#fff"}
                fontWeight="700"
                fontSize={"30px"}
                pb="0.5rem"
              >
                verify request
              </Text>
            </Box>
            {pageLoading ? (
              <Flex justifyContent={"center"} py="1rem">
                <LogoLoader />
              </Flex>
            ) : (
              <Box
                maxWidth="320px"
                width="100%"
                margin={"0 auto"}
                display="flex"
                flexDir={"column"}
                alignItems="center"
                pt="2rem"
              >
                <Box fontFamily={"Plus Jakarta Sans"} width="100%">
                  <Text color={"#E7D7BB"} fontWeight="400" fontSize={"12px"}>
                    payout details
                  </Text>
                  <Flex flexDir={"column"} gap="5px">
                    <Flex justifyContent={"space-between"}>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        amount
                      </Text>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={getVerifyRequestResponse?.amount?.Amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      </Text>
                    </Flex>
                    <Flex justifyContent={"space-between"}>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        withdrawal fee
                      </Text>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={getVerifyRequestResponse?.fee?.Amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box fontFamily={"Plus Jakarta Sans"} width="100%" pt="1.5rem">
                  <Text color={"#E7D7BB"} fontWeight="400" fontSize={"12px"}>
                    payout details
                  </Text>
                  <Flex flexDir={"column"} gap="5px">
                    <Flex justifyContent={"space-between"}>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        bank
                      </Text>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        {getVerifyRequestResponse?.bankName}
                      </Text>
                    </Flex>
                    <Flex justifyContent={"space-between"}>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        acct. no
                      </Text>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        {getVerifyRequestResponse?.accountNumber}
                      </Text>
                    </Flex>
                    <Flex justifyContent={"space-between"}>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        acct. name
                      </Text>
                      <Text color="#fff" fontWeight={"400"} fontSize="16px">
                        {getVerifyRequestResponse?.accountName}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <FormikProvider value={formik}>
                  <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    style={{ width: "100%" }}
                  >
                    <Box pt="1.5rem">
                      <Text
                        color="rgba(255, 255, 255, 0.8)"
                        fontSize={"14px"}
                        pb="1rem"
                        maxWidth="240px"
                        margin="0 auto"
                        textAlign={"center"}
                      >
                        We sent a code to {getVerifyRequestResponse?.email}
                      </Text>
                      <FormikFormInput
                        placeholder="enter code"
                        name="otp"
                        type={"number"}
                        disabled={false}
                        showMessage={true}
                      />
                      <Flex justifyContent={"flex-end"}>
                        <Text
                          cursor={"pointer"}
                          color="rgba(255, 255, 255, 0.8)"
                          fontSize={"14px"}
                          pb="1rem"
                          textAlign={"center"}
                          textDecoration="underline"
                          onClick={() => {
                            dispatch(
                              sendOTP({
                                email: getVerifyRequestResponse?.email,
                              })
                            )
                              .then((res: any) => {
                                const response = res.payload;

                                if (
                                  response.statusCode === 200 &&
                                  response.message ===
                                    "otp has been sent to your email"
                                ) {
                                  toast({
                                    status: "success",
                                    description: "OTP sent successfully",
                                  });
                                }
                              })
                              .catch(() => {
                                toast({
                                  status: "error",
                                  description: "Sending OTP failed",
                                });
                              });
                          }}
                        >
                          resend
                        </Text>
                      </Flex>
                    </Box>
                    <Box width={{ base: "100%" }} pt="1rem">
                      <ButtonCmp
                        text={
                          <>
                            WITHDRAW&nbsp;
                            <NumberFormat
                              thousandSeparator
                              prefix="₦"
                              value={
                                getVerifyRequestResponse?.amount?.Amount -
                                getVerifyRequestResponse?.fee?.Amount
                              }
                              decimalScale={2}
                              defaultValue="0.00"
                              allowNegative={false}
                              placeholder="0.00"
                              displayType="text"
                              disabled
                            />
                          </>
                        }
                        size="md"
                        padding="1.5rem 3rem"
                        isLoading={loading}
                        isDisabled={formik.isValid ? false : true}
                        opacity={"1"}
                        border={"none"}
                        textHoverColor={"#fff"}
                        width="100%"
                        maxWidth="100%"
                        type="submit"
                      />
                    </Box>
                  </form>
                </FormikProvider>
                <ActionModal
                  isOpen={isExit}
                  onRequestClose={() => {
                    setIsExit(false);
                  }}
                  maxWidth="400px"
                  showCloseIcon={false}
                  actionTitle="are you sure?"
                  actionDesc="You will lose progress on this item if you don’t save it before you
          go to another page"
                  yesText="YES, EXIT"
                  noText="NO, CANCEL"
                  yesAction={() => {
                    navigate(-1);
                  }}
                  noAction={() => {
                    setIsExit(false);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyRequest;
