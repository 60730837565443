import React, { useState } from "react";
import { Box, Flex, Input, Text, useToast } from "@chakra-ui/react";

import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { updateWedding } from "utils/workbench/APIs/routes/wedding";
import { UpdateWeddingCollection, WeddingCollection } from "utils/workbench/STOREs/collections/wedding.collection";


const StepThree: React.FC<{
  nextBtnAction: () => void;
  prevBtnAction: () => void;
}> = ({
  nextBtnAction,
  prevBtnAction,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const defaultRelationships = [
    'parent', 
    'sibling', 
    'relative', 
    'friend',
    'colleague',
    'family', 
    'other'
  ];

  const { weddingDataObj } = WeddingCollection();

  const [rsvpHasChanged, setRsvpHasChanged] = useState(false);
  const [config, setConfig] = useState(
    { 
      shouldIncludePhoneNumber: weddingDataObj.shouldIncludePhoneNumber,
      shouldIncludeAccessCardAddress: weddingDataObj.shouldIncludeAccessCardAddress,
      rsvpRelationshipValues: weddingDataObj.rsvpRelationshipValues
    }
  );
  const [shouldAddOtherOptions, setShouldAddOtherOptions] = useState(false);
  const [additionalOptional, setAdditionalOption] = useState('');
  const [addedRelationships, setAddedRelationships] = useState(
    config.rsvpRelationshipValues
      .filter(v => !defaultRelationships.includes(v))
      .map(v => ({text: v, checked: true}))
    );

  const handleSubmit = () => {
    console.log({rsvpHasChanged});
    if (rsvpHasChanged) {
      let rsvpRelationshipValues = config.rsvpRelationshipValues;
      let hasOther = false;
      let other = "";
      for (const rel of rsvpRelationshipValues) {
        if (rel.toLowerCase().trim() === "other" || rel.toLowerCase().trim() === "others") {
          hasOther = true;
          other = rel;
        }
      }

      if (hasOther) {
        rsvpRelationshipValues = [...rsvpRelationshipValues.filter(e => e.toLowerCase().trim() !== "other" && e.toLowerCase().trim() !== "others"), other]
      }

      updateWedding(
        {
          shouldIncludePhoneNumber: config.shouldIncludePhoneNumber,
          shouldIncludeAccessCardAddress: config.shouldIncludeAccessCardAddress,
          rsvpRelationshipValues
        },
        (response) => {
          if (response.statusCode === 200) {
            UpdateWeddingCollection(
              (weddingCollection) => {
                weddingCollection.weddingDataObj = response.payload;
              }
            );
          }
          if (response.statusCode === 400 || response.errors) toast(
            { status: "error", description: response.message });
        }
      );
    }
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap="3px"
        pt="10px"
        pb="4px"
        display={{ base: "none", sm: "unset" }}
      >
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize={"12px"}
          fontWeight="400"
        >
          Step 3
        </Text>
        <Flex flexDir="row" width="100%" justifyContent="space-between">
          <Box>
            <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
              RSVP
            </Text>
          </Box>
          <Box borderBottom="1px solid" color="#CEFABB" cursor="pointer">
            <Text
              onClick={() => {
                nextBtnAction();
              }}
            >
              Skip
            </Text>
          </Box>
        </Flex>

        <Text color="rgba(255, 255, 255, 0.5)" mt="8px">
          Choose the data you wish to gather from your guests.
        </Text>
      </Flex>
      <Box mt="15px" display="flex" flexDir="column" >
        <Flex
          background={'#0F441D'}
          height={'30px'}
          alignItems={'center'}
          pl={'16px'}
          mt={'15px'}
          mb={'15px'}
          borderRadius={'6px'}
        >
          <Text
            fontSize={'14px'}
            fontWeight={'600'}
            lineHeight={'20px'}
          >Personal Information</Text>
        </Flex>

        <Flex
          flexDirection={'column'}
          gap={'12px'}
          mb={'40px'}
        >
          {
            ['First name', 'Last name', 'Email address'].map(
              (info) => (
                <DefaultPersonalInformation 
                  key={info}
                  shouldIncludeDeleteButton={false}
                  info={info}
                  isActive={false}
                  checked={true}
                  onCheck={() => {}}
                  />
              )
            )
          }
          <DefaultPersonalInformation 
            info={'Phone number'}
            shouldIncludeDeleteButton={false}
            isActive={true}
            checked={config.shouldIncludePhoneNumber}
            onCheck={() => {
              setRsvpHasChanged(true);
              setConfig({...config, shouldIncludePhoneNumber: !config.shouldIncludePhoneNumber});
            }}
            />    
          <DefaultPersonalInformation 
            info={'Access card delivery address'} 
            shouldIncludeDeleteButton={false}
            isActive={true}
            checked={config.shouldIncludeAccessCardAddress}
            onCheck={() => {
              setRsvpHasChanged(true);
              setConfig({...config, shouldIncludeAccessCardAddress: !config.shouldIncludeAccessCardAddress});
            }}
            />
        </Flex>

        <Flex
          background={'#0F441D'}
          height={'30px'}
          alignItems={'center'}
          pl={'16px'}
          mb={'16px'}
          borderRadius={'6px'}
        >
          <Text
            fontSize={'14px'}
            fontWeight={'600'}
            lineHeight={'20px'}
          >Guest's connection to the couple</Text>
        </Flex>
        <Text
          color={'#6B6B6B'}
          fontSize={'12px'}
          fontWeight={'400'}
        >Selecting an option below implies guests should indicate their connection to the bride or groom.</Text>
        
        {/* show default options */}
        <Flex
          mt={'16px'}
          mb={'18px'}
          flexDirection={'column'}
          gap={'16px'}
        >
          {
            defaultRelationships.map(
              (info, index) => (
                <DefaultPersonalInformation 
                  key={`${info}-${index}`}
                  info={info.charAt(0).toUpperCase() + info.slice(1)} 
                  isActive={true}
                  checked={config.rsvpRelationshipValues.includes(info)}
                  shouldIncludeDeleteButton={false}
                  onCheck={() => {
                    setRsvpHasChanged(true);
                    const isRemoveOperation = config.rsvpRelationshipValues.includes(info);
                    if (isRemoveOperation) {
                      const rsvpRelationshipValues = config.rsvpRelationshipValues.filter(v => v !== info);
                      setConfig({...config, rsvpRelationshipValues});
                    }else {
                      setConfig({...config, rsvpRelationshipValues: [...config.rsvpRelationshipValues, info]});
                    }
                  }}
                />
              )
            )
          }
        </Flex>

        {/* then show other options */}
        <Flex
          mb={'18px'}
          flexDirection={'column'}
          gap={'16px'}
        >
          {
            addedRelationships.map(
              (info, index) => (
                <DefaultPersonalInformation 
                  key={`${info.text}-${index}`}
                  info={info.text.charAt(0).toUpperCase() + info.text.slice(1)} 
                  isActive={true}
                  checked={info.checked}
                  shouldIncludeDeleteButton={true}
                  onDelete={() => {
                    setRsvpHasChanged(true);
                    setAddedRelationships(addedRelationships.filter((v) => v !== info));
                    const rsvpRelationshipValues = config.rsvpRelationshipValues.filter(v => v !== info.text);
                    setConfig({...config, rsvpRelationshipValues});
                  }}
                  onCheck={() => {
                    setRsvpHasChanged(true);
                    let isRemoveOperation = false;
                    for (const relationship of addedRelationships) {
                      if (relationship.text === info.text) {
                        isRemoveOperation = relationship.checked;
                        break;
                      }
                    }
                    if (isRemoveOperation) {
                      const rsvpRelationshipValues = config.rsvpRelationshipValues.filter(v => v !== info.text);
                      setConfig({...config, rsvpRelationshipValues});
                      setAddedRelationships(addedRelationships.map((v) => {
                        if (v.text === info.text) {
                          return { ...v, checked: false };
                        }
                        return v;
                      }));
                    }else {
                      setAddedRelationships(addedRelationships.map((v) => {
                        if (v.text === info.text) {
                          return { ...v, checked: true };
                        }
                        return v;
                      }));
                      setConfig({...config, rsvpRelationshipValues: [...config.rsvpRelationshipValues, info.text]});
                    }
                  }}
                />
              )
            )
          }
        </Flex>

        {
          shouldAddOtherOptions ? 
          (
            <Flex
              flexDirection={'column'}
              mb={'8px'}
            >
              <Text
                fontSize={'13px'}
                mb={'6px'}
              >Another option</Text>
              <Input 
                variant='outline' 
                placeholder='Ex:Friend of friends'
                borderColor="#77AA84"
                focusBorderColor="#77AA84"
                _placeholder={{
                  fontSize: '13px'
                }}
                value={additionalOptional}
                fontSize={'13px'}
                onChange={(e) => {
                  if (e.target.value.length < 22) {
                    setAdditionalOption(e.target.value);
                  }
                }}
                />
            </Flex>
          )
          : 
          (<></>)
        }
        
        <Flex
          mb={'20px'}
        >
          <Text
            cursor={'pointer'}
            onClick={() => {
              if (config.rsvpRelationshipValues.length >= 11 ) return;
              if (!shouldAddOtherOptions) setShouldAddOtherOptions(!shouldAddOtherOptions);
              if (shouldAddOtherOptions) {
                // if no errors
                if (additionalOptional) {
                  const lcOption = additionalOptional.toLowerCase();
                  if (defaultRelationships.includes(lcOption)) return;
                  let err = false;
                  for (const relationship of addedRelationships){
                    if (relationship.text === lcOption || relationship.text === additionalOptional) {
                      err = true;
                      break;
                    }
                  }
                  if (err) return;
                  setConfig({...config, rsvpRelationshipValues: [...config.rsvpRelationshipValues, additionalOptional]});
                  setAddedRelationships([...addedRelationships, {text: additionalOptional, checked: true}]);
                  setShouldAddOtherOptions(!shouldAddOtherOptions);
                  setAdditionalOption("");
                }
              }
            }}
            fontSize={'13px'}
            fontWeight={'500'}
            lineHeight={'20px'}
            borderBottom={`1px solid ${shouldAddOtherOptions ? '#96B687' : '#FFF'}`}
            color={shouldAddOtherOptions ? '#96B687' : '#FFF'}
          >
            {
              shouldAddOtherOptions ? 
              'Save option' :
              '+ Add another option'
            }
          </Text>
        </Flex>

        <Box
          position="sticky"
          bgColor={"#1F2229"}
          zIndex="3"
          textAlign="center"
          display="flex"
          gap="10px"
          bottom={{ base: "-20px", sm: "-80px" }}
          justifyContent={"center"}
        >
          <ButtonCmp
            text={"Previous"}
            size="lg"
            color="#44A560"
            backgroundColor="transparent"
            opacity={"1"}
            border={"1px solid #44A560"}
            textHoverColor={"#fff"}
            width="100%"
            height="55px"
            maxWidth="100%"
            type="submit"
            onClick={() => {
              handleSubmit();
              prevBtnAction();
            }}
          />
          <ButtonCmp
            text={"Next"}
            size="lg"
            opacity={"1"}
            border={"none"}
            textHoverColor={"#fff"}
            width="100%"
            height="55px"
            maxWidth="100%"
            type="submit"
            onClick={() => {
              handleSubmit();
              nextBtnAction();
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const DefaultPersonalInformation: React.FC<
{
  info: string;
  isActive: boolean;
  checked: boolean;
  onCheck: () => void;
  shouldIncludeDeleteButton: boolean;
  onDelete?: () => void;
}> = ({info, isActive, checked, onCheck, shouldIncludeDeleteButton, onDelete}) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      >
      <Flex
        gap={'8px'}
      >
        <Flex
          border={isActive && !checked ? '1px solid' : 'none'}
          bgColor={isActive ? checked ? '#96b587' : 'none' : '#6b6b6b'}
          w={'16px'}
          h={'16px'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={onCheck}
          >
          {
            checked ? 
            (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M13.9999 4.66656L5.99992 12.6666L2.33325 8.9999L3.27325 8.0599L5.99992 10.7799L13.0599 3.72656L13.9999 4.66656Z" fill="white"/>
              </svg>
            ) : 
            (
              <></>
            )
          }
        </Flex>
        <Text
          color={isActive ? '#FFF' : '#6B6B6B'}
          fontSize={'13px'}
          fontWeight={'400px'}
        >{info}</Text>
      </Flex>
      {
        shouldIncludeDeleteButton ?
        (
          <Text
            cursor={'pointer'}
            color={'#c14a4c'}
            fontSize={'12px'}
            borderBottom={'1px solid #c14a4c'}
            onClick={onDelete}
          >Delete</Text>
        ) :
        (
          <></>
        )
      }
    </Flex>
  );
};

export default StepThree;
