import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateGiven,
  CreateGivenResponse,
  GetAGiftPublic,
  GetAWishlistPublic,
  GetAWishlistResponse,
  MRequest,
  UpdateGiven,
} from "apis";
import { RootState } from "store/reducers";

const entity = "contributor";

const getAWishlistPublic = createAsyncThunk(
  "list/getAWishlist",
  async (params: { username: string; slug: string }, { rejectWithValue }) => {
    try {
      const res = await MRequest.get<typeof GetAWishlistPublic.RES>(
        `${GetAWishlistPublic.ROUTE}?username=${params.username}&slug=${params.slug}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getAGiftPublic = createAsyncThunk(
  "list/getAGift",
  async (
    params: { username: string; slug: string; giftId: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await MRequest.get<typeof GetAGiftPublic.RES>(
        `${GetAGiftPublic.ROUTE}?username=${params.username}&wishlistSlug=${params.slug}&giftID=${params.giftId}`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createGiven = createAsyncThunk(
  "list/createGiven",
  async (params: { data: any; type: string }, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof CreateGiven.RES>(
        `${CreateGiven.ROUTE}?type=${params.type}`,
        params.data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const updateGiven = createAsyncThunk(
  "list/updateGiven",
  async (data: typeof UpdateGiven.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.put<typeof UpdateGiven.RES>(
        UpdateGiven.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export type ContributorInitialStateType = {
  getAWishlistPublicResponse: GetAWishlistResponse | null | any;
  getAGiftPublicResponse: GetAWishlistResponse | null;
  createGivenResponse: CreateGivenResponse | null;
  updateGivenResponse: CreateGivenResponse | null;
};

const initialState: ContributorInitialStateType = {
  getAWishlistPublicResponse: null,
  getAGiftPublicResponse: null,
  createGivenResponse: null,
  updateGivenResponse: null,
};

const contributorSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAWishlistPublic.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAWishlistPublicResponse = action.payload;
      }
    );
    builder.addCase(
      getAWishlistPublic.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAWishlistPublicResponse = action.payload;
      }
    );
    builder.addCase(
      getAGiftPublic.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAGiftPublicResponse = action.payload;
      }
    );
    builder.addCase(
      getAGiftPublic.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAGiftPublicResponse = action.payload;
      }
    );
    builder.addCase(
      createGiven.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createGivenResponse = action.payload;
      }
    );
    builder.addCase(
      createGiven.rejected,
      (state, action: PayloadAction<any>) => {
        state.createGivenResponse = action.payload;
      }
    );
    builder.addCase(
      updateGiven.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateGivenResponse = action.payload;
      }
    );
    builder.addCase(
      updateGiven.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateGivenResponse = action.payload;
      }
    );
  },
});

export const contributorActions = {
  ...contributorSlice.actions,
  getAWishlistPublic,
  getAGiftPublic,
  createGiven,
  updateGiven,
};

export const contributorSelectors = {
  getAWishlistPublic: (state: RootState) =>
    state.contributor.getAWishlistPublicResponse,
  getAGiftPublic: (state: RootState) =>
    state.contributor.getAGiftPublicResponse,
  createGiven: (state: RootState) => state.contributor.createGivenResponse,
  updateGiven: (state: RootState) => state.contributor.updateGivenResponse,
};

export const contributorReducer = contributorSlice.reducer;
