import { ISVGS } from "./types";

const HelpIcon = (props: ISVGS) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6125 0C3.40825 0 0 3.42628 0 7.65278C0 17.1528 12.6 19 12.6 19V15.3056H13.3875C17.5918 15.3056 21 11.8793 21 7.65278C21 3.42628 17.5918 0 13.3875 0H7.6125ZM10.5 9.23611C11.2249 9.23611 11.8125 8.64537 11.8125 7.91667C11.8125 7.18796 11.2249 6.59722 10.5 6.59722C9.77513 6.59722 9.1875 7.18796 9.1875 7.91667C9.1875 8.64537 9.77513 9.23611 10.5 9.23611ZM16.0125 7.91667C16.0125 8.64537 15.4249 9.23611 14.7 9.23611C13.9751 9.23611 13.3875 8.64537 13.3875 7.91667C13.3875 7.18796 13.9751 6.59722 14.7 6.59722C15.4249 6.59722 16.0125 7.18796 16.0125 7.91667ZM6.3 9.23611C7.02487 9.23611 7.6125 8.64537 7.6125 7.91667C7.6125 7.18796 7.02487 6.59722 6.3 6.59722C5.57513 6.59722 4.9875 7.18796 4.9875 7.91667C4.9875 8.64537 5.57513 9.23611 6.3 9.23611Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_dashboard"
        x1="9.23077"
        y1="0"
        x2="9.23077"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.fill || "#8DB782"} />
        <stop offset="1" stopColor={props.fill || "#8DB782"} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default HelpIcon;
