import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import RouteCmp from "routes";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "store";
import { Global } from "@emotion/react";
import { GlobalStyles } from "theme/GlobalStyles";
import SessionTimeout from "utils/idleTimer";
import { StepsStyleConfig } from "chakra-ui-steps";
// import { IntercomProvider } from "react-use-intercom";
import { ErrorBoundary } from "react-error-boundary";
import { LogoLoader } from "components";

const CustomSteps = {
  ...StepsStyleConfig,
  baseStyle: (props: any) => {
    return {
      ...StepsStyleConfig.baseStyle(props),
      steps: {
        ...StepsStyleConfig.baseStyle(props).steps,
        maxWidth: "213px",
      },
      step: {
        ...StepsStyleConfig.baseStyle(props).step,
        maxWidth: "71px",
        _isCompletedStep: {
          background: "#0F441E",
          borderColor: "#0F441E",
        },
      },
      stepContainer: {
        ...StepsStyleConfig.baseStyle(props).stepContainer,
        alignItems: "flex-start",
        width: "71px",
        marginBottom: "17px",
      },
      connector: {
        ...StepsStyleConfig.baseStyle(props).connector,
        borderColor: "rgba(255, 255, 255, 0.5)",
        width: "31px",
        left: "calc((40px - (40px - 31px)))",
      },
      stepIconContainer: {
        ...StepsStyleConfig.baseStyle(props).stepIconContainer,
        c: "blue",
        background: "transparent",
        width: "40px",
        height: "40px",
        _activeStep: {
          background: "#0F441E",
          borderColor: "#0F441E",
        },
        _isCompletedStep: {
          background: "#0F441E",
          borderColor: "#0F441E",
        },
      },
      iconLabel: {
        ...StepsStyleConfig.baseStyle(props).iconLabel,
        color: "rgba(255, 255, 255, 0.5)",
      },
    };
  },
};

const theme = extendTheme({
  components: {
    Steps: CustomSteps,
    Tabs: {
      variants: {
        line: {
          tab: {
            borderBottom: "none",
          },
          tablist: {
            borderBottom: "0.5px solid",
            borderColor: "#868686",
          },
        },
      },
    },
    Table: {
      variants: {
        simple: {
          td: {
            borderBottom: "0.5px solid",
            borderColor: "#868686",
          },
        },
      },
    },
  },
});

export const App = () => {
  const ErrorFallback = () => {
    return <LogoLoader />; // There is an error
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ChakraProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            {/* <IntercomProvider
              appId={`${process.env.REACT_APP_INTERCOM_APP_ID}`}
            > */}
            <Router>
              <RouteCmp />
            </Router>
            <SessionTimeout />
            {/* </IntercomProvider> */}
          </PersistGate>
        </Provider>
      </ChakraProvider>
    </ErrorBoundary>
  );
};
