import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Flex,
  Box,
  Text,
  Textarea,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import { FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { useCallback, useEffect, useState } from "react";
import { FormikFormSelect } from "components/InputsCmp/CustomSelect";
import { WISHLISTVALIDATIONS } from "validators/WishlistValidator";
import { useAppDispatch, useAppSelector } from "hooks";
import { wishlistActions, wishlistSelectors } from "store/slices/wishlistSlice";
import ActionModal from "components/Modals/ActionModal";
import { useNavigate, useParams } from "react-router-dom";
import ImageCropperModal from "components/Modals/ImageCropperModal";
import UploadImage from "components/UploadImage";
import { WishlistBg } from "assets/svgs";
import CustomDateCmp from "components/InputsCmp/CustomDateCmp";
import NumberFormat from "react-number-format";
import { authSelectors } from "store/slices";

const addWishlistValidationSchema = yup.object({
  category: WISHLISTVALIDATIONS.category,
  collection_title: WISHLISTVALIDATIONS.collection_title,
  grossCost: WISHLISTVALIDATIONS.grossCost,
  goalChecked: WISHLISTVALIDATIONS.goalChecked,
  dateChecked: WISHLISTVALIDATIONS.dateChecked,
  welcomeNote: WISHLISTVALIDATIONS.welcomeNote,
  date: WISHLISTVALIDATIONS.date,
});

const EditCollection = () => {
  const { slug } = useParams<{ slug: string }>();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const dispatch: any = useAppDispatch();
  // const navigate = useNavigate();
  const { getCollectionCategories, getACollection, updateACollection } =
    wishlistActions;
  const userDetails = useAppSelector(authSelectors.getUserDetails);
  const getACollectionData = useAppSelector(
    wishlistSelectors.getACollection
  )?.payload;
  const [categoryArr, setCategoryArr] = useState([]);
  const [category, setCategory] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    collection_title: "",
    date: null,
    dateChecked: false,
    grossCost: "",
    goalChecked: false,
    welcomeNote: "",
    listcategoryID: "",
  });

  useEffect(() => {
    if (getACollectionData) {
      setImage(getACollectionData?.coverImage);
      setFile(getACollectionData?.coverImage);
      setCategory({
        name: `${getACollectionData?.listcategoryID?.name}`,
        emoji: `${getACollectionData?.listcategoryID?.emoji}`,
        _id: getACollectionData?.listcategoryID?._id,
      });
      setData({
        collection_title: getACollectionData?.title!,
        date: getACollectionData?.date!,
        dateChecked: getACollectionData?.date ? false : true,
        grossCost: getACollectionData?.grossCost?.Amount,
        goalChecked: getACollectionData?.grossCost ? false : true,
        welcomeNote: getACollectionData?.welcomeNote,
        listcategoryID: getACollectionData?.listcategoryID,
      });
    } else {
      dispatch(getACollection({ slug: slug!, userID: userDetails._id }));
    }
  }, [dispatch, getACollection, getACollectionData, slug, userDetails._id]);

  useEffect(() => {
    dispatch(getCollectionCategories())
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setCategoryArr(response.payload.payload);
          return;
        }
      })
      .finally(() => {
        return;
      });
  }, [dispatch, getCollectionCategories]);

  const [showImageModal, setShowImageModal] = useState(false);

  const [image, setImage] = useState("");

  const [showImageCropper, setShowImageCropper] = useState(false);

  const [, setFile] = useState<File | string>("");

  const [isExit, setIsExit] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik<{
    collection_title: string;
    grossCost: any;
    date?: Date;
    dateChecked: boolean;
    category: string;
    goalChecked: boolean;
    welcomeNote: string;
  }>({
    initialValues: {
      collection_title: data?.collection_title!,
      date: data?.date!,
      dateChecked: data?.dateChecked,
      category: category ? `${category._id}` : "",
      grossCost: data?.grossCost,
      goalChecked: data?.goalChecked,
      welcomeNote: data?.welcomeNote,
    },
    enableReinitialize: true,
    validationSchema: addWishlistValidationSchema,
    validateOnMount: false,
    onSubmit: (values, { resetForm }) => {
      if (!image) {
        toast({
          status: "error",
          description: "Pick an image",
        });
      }
      let reqData: any = {
        listcategoryID: category._id,
        title: values.collection_title,
        coverImage: image,
        wishlistCashfundID: getACollectionData?._id!,
        welcomeNote: values.welcomeNote,
      };
      if (!values.dateChecked) {
        reqData = { ...reqData, date: values.date };
      }
      if (!values.goalChecked) {
        reqData = {
          ...reqData,
          grossCost: values.grossCost,
          netCost: `${
            (Number(values.grossCost) * 5) / 100 + Number(values.grossCost)
          }`,
        };
      }
      //   const payload = new FormData();
      //   payload.append("title", values.wishlist_title);
      //   payload.append("listcategoryID", category._id);
      //   payload.append("coverImage", reqData.coverImage);
      //   payload.append("date", reqData.date);
      setLoading(true);
      dispatch(updateACollection(reqData))
        .then((res: any) => {
          const response = res;
          if (response.payload.statusCode === 500 && response.payload.message) {
            if (response.payload.message.includes("date")) {
              return toast({
                status: "error",
                description: "Please pick a future date",
              });
            }
            return toast({
              status: "error",
              description: response.payload.message,
            });
          }
          setLoading(false);
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            resetForm();
            window.location.assign(`/`);
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "There is an error",
          });
        })
        .finally(() => setLoading(false));
    },
  });

  const fromUnsplash = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setShowImageCropper(true);
    setFile(imageUrl);
    setShowImageModal(false);
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    setImage(URL.createObjectURL(file));
    setShowImageCropper(true);
    setShowImageModal(false);
  }, []);
  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            cursor="pointer"
            color="#fff"
            h={8}
            w={8}
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            style={{ width: "100%" }}
          >
            <Flex justifyContent={"center"} py="1rem">
              <FormikProvider value={formik}>
                <Flex
                  flexDirection={"column"}
                  gap="30px"
                  width="100%"
                  maxWidth="345px"
                >
                  <UploadImage
                    fromGallery={fromGallery}
                    fromUnsplash={fromUnsplash}
                    image={image}
                    instruction={"upload cover image"}
                    placeholder={WishlistBg}
                    showImageModal={showImageModal}
                    setShowImageModal={setShowImageModal} setShowImageCropper={function (_: boolean): void {
                      throw new Error("Function not implemented.");
                    } }                  />
                  <Box display={"flex"} flexDirection="column" gap="1px">
                    <Box margin={{ base: "0", sm: "0" }} width="100%">
                      <FormikFormInput
                        placeholder="collection title"
                        name="collection_title"
                        disabled={false}
                        showMessage={true}
                      />
                    </Box>
                    <Box width={{ base: "100%" }}>
                      <FormikFormSelect
                        placeholder="type"
                        name="category"
                        showMessage={true}
                        value={formik.values.category}
                        customHandleChange={(e) => {
                          setCategory(
                            categoryArr.find(
                              (item: any) => item._id === e.target.value
                            )
                          );
                        }}
                        items={categoryArr.map((v: any, _: number) => {
                          return {
                            text: `${v.name}`,
                            value: `${v._id}`,
                          };
                        })}
                      />
                    </Box>
                    <Box w="100%">
                      <CustomDateCmp
                        placeholder="Date Picker"
                        name="date"
                        disabled={false}
                        value={formik.values.date}
                        onChangeAction={(value: any) =>
                          formik.setFieldValue("date", value)
                        }
                        errorMessage={formik.errors["date"]}
                        showMessage={true}
                      />
                      <Flex w="100%" justifyContent={"flex-start"} pb="1rem">
                        <Checkbox
                          colorScheme="yellow"
                          iconColor="#B66D12"
                          isChecked={formik.values.dateChecked}
                          onChange={formik.handleChange}
                          name="dateChecked"
                        >
                          <Text
                            fontSize={"12px"}
                            color="rgba(255, 255, 255, 0.5)"
                            fontWeight={"400"}
                          >
                            {" "}
                            there’s no due date
                          </Text>
                        </Checkbox>
                      </Flex>
                    </Box>
                    <Box>
                      <FormikFormInput
                        type="text"
                        placeholder="amount"
                        name="grossCost"
                        disabled={false}
                        showMessage={true}
                        isNumericFormat
                      />
                      <Flex
                        color={"rgba(255, 255, 255, 0.5)"}
                        fontWeight="400"
                        fontSize="15px"
                        fontFamily={"Plus Jakarta Sans"}
                        justifyContent={"space-between"}
                        pb="2rem"
                      >
                        <Text>+ Unboxd fee @5%</Text>
                        <Text>
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={formik?.values?.grossCost * 1.05}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </Text>
                      </Flex>
                      <Flex w="100%" justifyContent={"flex-start"} pb="1rem">
                        <Checkbox
                          colorScheme="yellow"
                          iconColor="#B66D12"
                          isChecked={formik.values.goalChecked}
                          onChange={formik.handleChange}
                          name="goalChecked"
                        >
                          <Text
                            fontSize={"12px"}
                            color="rgba(255, 255, 255, 0.5)"
                            fontWeight={"400"}
                          >
                            {" "}
                            there’s no goal
                          </Text>
                        </Checkbox>
                      </Flex>
                    </Box>
                    <Box backgroundColor={"#16181D"} borderRadius="18px">
                      <Textarea
                        backgroundColor={"transparent"}
                        border="none"
                        focusBorderColor="#fff"
                        resize={"none"}
                        placeholder={
                          "notes for guests e.g God bless the fruits of your labor"
                        }
                        value={formik.values.welcomeNote}
                        maxLength={230}
                        size={"md"}
                        rows={4}
                        autoFocus
                        name="welcomeNote"
                        onChange={formik.handleChange}
                        fontSize={"13px"}
                        color={"#ffffffd7"}
                        fontWeight="400"
                      ></Textarea>
                    </Box>
                  </Box>
                  <Box width={{ base: "100%" }} textAlign="center">
                    <ButtonCmp
                      text={"PROCEED"}
                      size="md"
                      padding="1.5rem 3rem"
                      isLoading={loading}
                      isDisabled={formik.isValid ? false : true}
                      opacity={"1"}
                      border={"none"}
                      textHoverColor={"#fff"}
                      width="100%"
                      maxWidth="345px"
                      type="submit"
                    />
                  </Box>
                </Flex>
              </FormikProvider>
              <ActionModal
                isOpen={isExit}
                onRequestClose={() => {
                  setIsExit(false);
                }}
                maxWidth="400px"
                showCloseIcon={false}
                actionTitle="are you sure?"
                actionDesc="You will lose progress on this item if you don’t save it before you
          go to another page"
                yesText="YES, EXIT"
                noText="NO, CANCEL"
                yesAction={() => {
                  navigate(-1);
                }}
                noAction={() => {
                  setIsExit(false);
                }}
              />
              <ImageCropperModal
                isOpen={showImageCropper}
                imageToCrop={image}
                onRequestClose={() => {
                  setShowImageCropper(false);
                }}
                onCropComplete={(url: any) => {
                  setImage(url);
                }}
                onBackButtonClick={() => {
                  setShowImageCropper(false);
                  setShowImageModal(true);
                }}
                maxWidth="500px"
                showCloseIcon={false}
              />
            </Flex>
          </form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default EditCollection;
