

export const to12HoursTime = (time: string) => {
  try {
    const hrs = time.substring(0, 2);
    const mins = time.substring(3, 5);
    const num = Number(hrs);
    if (isNaN(Number(mins)) || isNaN(num)) {
      // console.log(
      //   {
      //     hrs,
      //     mins,
      //     num,
      //   }
      // );
      throw new Error("Error converting to 12 hour time");
    }
    const format = num >= 12 ? "pm" : "am";
    return `${hrs}:${mins}${format}`;
  } catch (error) {
    console.log(error);
    return time; 
  }
};
