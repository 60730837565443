import { Box, Container, Image, Flex } from "@chakra-ui/react";
import UnboxdLogoWhite from "assets/unboxd-logo-white.svg";

interface LayoutProps {
  content: React.ReactNode;
}

const OthersLayout = ({ content }: LayoutProps) => {
  return (
    <Container
      maxW={"100%"}
      backgroundColor="#22242C"
      minH={"100vh"}
      p={0}
      display={"flex"}
      flexDir={{ base: "column" }}
    >
      <Box
        w={{ base: "full", lg: "60%" }}
        maxW={"500px"}
        m={{ base: "0 auto" }}
        padding={{ base: "50px 18px 0" }}
        display={{ lg: "flex" }}
        flexDir={{ lg: "column" }}
        justifyContent={{ lg: "center" }}
      >
        <Box textAlign={"center"} pb="44px">
          <Image src={UnboxdLogoWhite} alt="Logo" margin="0 auto" />
        </Box>
        <Flex
          flexDirection={{ base: "column" }}
          alignItems={{ base: "center" }}
        >
          {content}
        </Flex>
      </Box>
    </Container>
  );
};

export default OthersLayout;
