import { Box, Text, Image } from "@chakra-ui/react";
import OthersLayout from "Layout/Others";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <OthersLayout
      content={
        <>
          <Box mb={"20px"}>
            <Image
              src="https://media.giphy.com/media/iGpkO05xWTl17Vhq6Y/giphy.gif"
              alt="not found"
              margin="0 auto"
            />
          </Box>
          <Text
            color="#fff"
            fontFamily={"Helvetica"}
            fontWeight={{ base: "700" }}
            fontSize={{ base: "35px" }}
            lineHeight={"40.25px"}
            mb={"-15px"}
          >
            Nothing here!
          </Text>
          <Box
            width={{ base: "240px" }}
            paddingTop={{ base: "13px" }}
            paddingBottom={"40px"}
          >
            <Text
              color="#fff"
              textAlign={{ base: "center" }}
              opacity="0.5"
              fontSize={{ base: "15px" }}
              fontWeight={{ base: "400" }}
              fontFamily="Plus Jakarta Sans"
            >
              Error 404: The page you tried to access is not available.{" "}
              <span style={{ textDecoration: "underline" }}>
                <span onClick={goBack}>Go back to the previous page</span>
              </span>{" "}
              or{" "}
              <span style={{ textDecoration: "underline" }}>
                <Link to={"/"}>Go home</Link>
              </span>
            </Text>
          </Box>
        </>
      }
    />
  );
};

export default NotFound;
