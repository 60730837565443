import { Box, Flex, Show, Text } from "@chakra-ui/react";
import millify from "millify";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "./index.css";
import StatCardCmp from "components/Card/StatCardCmp";
import DateRangeModal from "components/Modals/DateRangeModal";
import ContributionLogDrawer from "./ContributionLogDrawer";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  analyticsActions,
  analyticsSelectors,
} from "store/slices/analyticsSlice";
import { LogoLoader } from "components";
import CustomSelectCmp from "components/InputsCmp/CustomSelect";
import { wishlistActions } from "store/slices";

const SingleAnalytics = () => {
  const StatCard: React.FC<{
    title: string;
    count: string;
    bgColor: string;
  }> = ({ title, count, bgColor }) => {
    return (
      <>
        <Box
          w="200px"
          h="110px"
          borderRadius={"12px"}
          bgColor={bgColor || "#242D2E"}
          p="1rem"
        >
          <Flex
            flexDir={"column"}
            h="100%"
            justifyContent={"space-between"}
            color="#fff"
          >
            <Text fontSize={"10px"} w="80px" opacity="0.5">
              {title}
            </Text>
            <Text fontSize={"23px"} fontWeight="400">
              {count}{" "}
            </Text>
          </Flex>
        </Box>
      </>
    );
  };

  const getAnalyticsSingleContributionChartData = useAppSelector(
    analyticsSelectors.getAnalyticsSingleContributionChart
  )?.payload;
  const getAnalyticsSingleContributionOverviewData = useAppSelector(
    analyticsSelectors.getAnalyticsSingleContributionOverview
  )?.payload;
  const [activeState, setActiveState] = useState("All time");
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showContributionLogDrawer, setShowContributionLogDrawer] =
    useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [dateRange, setDateRange] = useState("");

  const dispatch = useAppDispatch();

  const {
    getAnalyticsSingleContributionChart,
    getAnalyticsSingleContributionOverview,
  } = analyticsActions;
  const { getCollectionCategories } = wishlistActions;
  const [categoryArr, setCategoryArr] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCollectionCategories())
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setCategoryArr(response.payload.payload);
          return;
        }
      })
      .finally(() => {
        return;
      });
  }, [dispatch, getCollectionCategories]);

  useEffect(() => {
    dispatch(getCollectionCategories())
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setCategoryArr(response.payload.payload);
          return;
        }
      })
      .finally(() => {
        return;
      });
  }, [dispatch, getCollectionCategories]);

  useEffect(() => {
    setChartLoading(true);
    dispatch(
      getAnalyticsSingleContributionChart(
        `listcategoryID=${params.listcategoryID}${
          activeState === "Last 30 days"
            ? "&last30Days=true"
            : activeState === "Last 60 days"
            ? "&last60Days=true"
            : activeState === "Custom" && dateRange
            ? dateRange
            : ""
        }`
      )
    )
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setChartLoading(false);
        }
        // else {
        //   navigate("/analytics");
        // }
      })
      .finally(() => setChartLoading(false));
  }, [
    activeState,
    dateRange,
    dispatch,
    getAnalyticsSingleContributionChart,
    navigate,
    params.listcategoryID,
  ]);

  useEffect(() => {
    setOverviewLoading(true);
    dispatch(
      getAnalyticsSingleContributionOverview(
        `listcategoryID=${params.listcategoryID}${
          activeState === "Last 30 days"
            ? "&last30Days=true"
            : activeState === "Last 60 days"
            ? "&last60Days=true"
            : activeState === "Custom" && dateRange
            ? dateRange
            : ""
        }`
      )
    )
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setOverviewLoading(false);
        }
        // else {
        //   navigate("/analytics");
        // }
      })
      .finally(() => setOverviewLoading(false));
  }, [
    activeState,
    dateRange,
    dispatch,
    getAnalyticsSingleContributionOverview,
    navigate,
    params.listcategoryID,
  ]);

  return (
    <Box maxW={"800px"} w="100%">
      <Flex flexDir={"column"} gap="15px">
        <Flex
          gap="10px"
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
        >
          <Box maxW={"350px"} w="100%">
            <CustomSelectCmp
              placeholder="type"
              name="category"
              showMessage={true}
              items={categoryArr.map((v: any, _: number) => {
                return {
                  text: `${v.name}`,
                  value: `${v._id}`,
                };
              })}
              handleChange={(e) => {
                if (e.target.value) {
                  navigate(`/analytics/single/${e.target.value}`);
                }
              }}
            />
          </Box>
          <Swiper
            spaceBetween={10}
            slidesPerView={3.3}
            speed={500}
            loop={false}
            touchRatio={1.5}
            style={{ width: "100%" }}
            breakpoints={{
              // when window width is >= 768px
              680: { slidesPerView: 3.6 },
            }}
          >
            {["All time", "Last 30 days", "Last 60 days", "Custom"].map(
              (v: string, _: number) => {
                return (
                  <SwiperSlide style={{ width: "fit-content" }}>
                    <StatCardCmp
                      onClickCard={() => {
                        if (v === "Custom") {
                          setActiveState(v);
                          setShowDateRangeModal(true);
                        } else {
                          setActiveState(v);
                        }
                      }}
                      active={activeState === v ? true : false}
                      title={v}
                    />
                  </SwiperSlide>
                );
              }
            )}
          </Swiper>
        </Flex>
        <Flex flexDir={"column"} gap="4px">
          <Box
            display={{ base: "flex" }}
            gap="20px"
            //   marginRight={{ base: "-20px", lg: "unset" }}
          >
            <Box
              w={{ base: "100%", sm: "100%", md: "100%" }}
              bgColor="#000"
              borderRadius={"12px"}
              opacity="1"
              padding="1rem"
            >
              <Text fontSize={"13px"} color="#fff">
                Total contributions
              </Text>
              {chartLoading ? (
                <Flex justifyContent={"center"} py="1rem">
                  <LogoLoader />
                </Flex>
              ) : (
                <>
                  {" "}
                  <Text fontSize={"30px"} color="#fff">
                    <NumberFormat
                      thousandSeparator
                      prefix="₦"
                      value={
                        getAnalyticsSingleContributionChartData?.totalContribution
                      }
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                    />
                  </Text>
                  <Box display={"flex"} justifyContent="center" pt="1rem">
                    <Pie
                      className="analytics-piechart"
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                      data={{
                        labels: [
                          getAnalyticsSingleContributionChartData
                            ?.categoryContributions[0].category?.name,
                        ],
                        datasets: [
                          {
                            label: `${getAnalyticsSingleContributionChartData?.categoryContributions[0].category?.name}`,
                            data: [
                              getAnalyticsSingleContributionChartData?.totalContribution,
                            ],
                            backgroundColor: [
                              "rgb(255, 99, 132)",
                              "rgb(54, 162, 235)",
                              "rgb(255, 205, 86)",
                              "rgb(253, 53, 86)",
                              "rgb(55, 205, 86)",
                              "rgb(255, 105, 86)",
                            ],
                            hoverOffset: 4,
                          },
                        ],
                      }}
                    />
                  </Box>
                  <Text
                    textAlign={"center"}
                    color="#ff"
                    fontSize={"12px"}
                    pt="1rem"
                    textDecor={"underline"}
                    onClick={() => {
                      setShowContributionLogDrawer(true);
                    }}
                  >
                    view contribution log
                  </Text>
                </>
              )}
            </Box>
            <Show above="md">
              <Box
                display={{ base: "flex" }}
                flexDir="column"
                w="fit-content"
                gap="20px"
                mt="12px"
              >
                {overviewLoading ? (
                  <Flex justifyContent={"center"} py="1rem">
                    <LogoLoader />
                  </Flex>
                ) : (
                  <>
                    {getAnalyticsSingleContributionOverviewData &&
                      [
                        {
                          title: "collections created",
                          count:
                            getAnalyticsSingleContributionOverviewData?.totalCollections,
                          bgColor: "#242d2e",
                        },
                        {
                          title: "Total contributors",
                          count:
                            getAnalyticsSingleContributionOverviewData?.totalContributors,
                          bgColor: "#2E2D24",
                        },
                        {
                          title: "Total payouts",
                          count: `₦${millify(
                            getAnalyticsSingleContributionOverviewData?.totalPayouts
                          )}`,
                          bgColor: "#2d242e",
                        },
                      ].map((v: any, i: number) => {
                        return (
                          <>
                            <StatCard
                              key={i}
                              title={v.title}
                              count={v.count}
                              bgColor={v.bgColor}
                            />
                          </>
                        );
                      })}
                  </>
                )}
              </Box>
            </Show>
          </Box>
        </Flex>
        <Show below="md">
          <Flex flexDir={"column"} gap="4px">
            <Box display={{ base: "flex", md: "none" }}>
              <Swiper
                breakpoints={{
                  // when window width is >= 320px
                  320: { slidesPerView: 1.4 },
                  400: { slidesPerView: 1.8 },
                  480: { slidesPerView: 2.1 },
                  540: { slidesPerView: 2.4 },
                  600: { slidesPerView: 2.8 },
                  680: { slidesPerView: 3 },
                }}
                spaceBetween={10}
                slidesPerView={2.5}
                speed={500}
                loop={false}
                touchRatio={1.5}
                style={{ width: "100%" }}
              >
                {getAnalyticsSingleContributionOverviewData &&
                  [
                    {
                      title: "collections created",
                      count:
                        getAnalyticsSingleContributionOverviewData?.totalCollections,
                      bgColor: "#242d2e",
                    },
                    {
                      title: "Total contributors",
                      count:
                        getAnalyticsSingleContributionOverviewData?.totalContributors,
                      bgColor: "#2E2D24",
                    },
                    {
                      title: "Total payouts",
                      count: `₦${millify(
                        getAnalyticsSingleContributionOverviewData?.totalPayouts
                      )}`,
                      bgColor: "#2d242e",
                    },
                  ].map((v: any, i: number) => {
                    return (
                      <>
                        <SwiperSlide key={i}>
                          <StatCard
                            key={i}
                            title={v.title}
                            count={v.count}
                            bgColor={v.bgColor}
                          />
                        </SwiperSlide>
                      </>
                    );
                  })}
              </Swiper>
            </Box>
          </Flex>
        </Show>
      </Flex>
      <DateRangeModal
        isOpen={showDateRangeModal}
        onRequestClose={() => {
          setShowDateRangeModal(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        saveFn={(range: any) => {
          setDateRange(
            `&startDate=${range[0].startDate}&endDate=${range[0].endDate}`
          );
        }}
      />
      <ContributionLogDrawer
        isOpen={showContributionLogDrawer}
        onClose={() => {
          setShowContributionLogDrawer(false);
        }}
        data={getAnalyticsSingleContributionChartData?.categoryContributions}
      />
    </Box>
  );
};

export default SingleAnalytics;
