import { Box, Text } from "@chakra-ui/react";
import CircularProgressCmp from "components/CircularProgressCmp/CircularProgressCmp";

const DaysLeftCmp: React.FC<{
  num: number;
  text: string;
  width?: string;
  showProgress?: boolean;
  size?: string;
  bgColor?: string;
  color?: string;
}> = ({ num, text, width, showProgress, size, bgColor, color }) => {
  return (
    <>
      {showProgress ? (
        <CircularProgressCmp
          label={`${num || 0} ${text}`}
          value={num || 0}
          size={size || "50px"}
          fontColorLabel="#fff"
          fontSizeLabel="10px"
          fontWeightLabel="400"
          widthLabel="40px"
        />
      ) : (
        <>
          {!isNaN(num) && (
            <Box
              bgColor={bgColor || "#44A560"}
              borderRadius="10px"
              padding={"0rem 0.5rem"}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={width}
              height="40px"
            >
              <Text
                color={color || "#fff"}
                fontWeight={"400"}
                fontFamily="Plus Jakarta Sans"
                fontSize={"12px"}
              >
                {num} {text}
              </Text>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default DaysLeftCmp;
