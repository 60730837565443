import { Box, Select, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import "./CustomInput.css";
import { ISelect } from "./types";
import { useFormikContext } from "formik";

const CustomSelectCmp: React.FC<ISelect> = ({
  placeholder,
  items,
  handleChange,
  background,
  required,
  name,
  disabled,
  value,
  errorMessage,
  showMessage,
  color,
  borderRadius,
  icon
}) => {
  return (
    <>
      <Box
        position={"relative"}
        fontFamily={"Plus Jakarta Sans"}
        display="flex"
        flexDirection={"column"}
        id="custom-select-wrapper"
        _focusWithin={{
          border: "0.5px solid #9a9b9e",
        }}
        outline={"none"}
        borderRadius={borderRadius || "18px"}
        background={background || "#16181D"}
        overflow={"hidden"}
        border={errorMessage ? "1px solid #FF8585 !important" : `none`}
      >
        <Select
          paddingInlineStart={"0 !Important"}
          paddingInlineEnd={"0 !Important"}
          padding={"1rem 1rem"}
          background={"transparent"}
          fontFamily={"Plus Jakarta Sans"}
          id="input-select"
          {...(icon ? {icon} : {})}
          placeholder={placeholder}
          onChange={handleChange}
          required={required}
          name={name}
          disabled={disabled || false}
          border={0}
          borderRadius={"0"}
          _hover={{
            border: 0,
          }}
          _focus={{
            boxShadow: "none",
            borderRadius: borderRadius || "18px",
          }}
          _disabled={{ opacity: 0.9 }}
          fontSize={"15px"}
          color={color || "#ffffffd7"}
          fontWeight="400"
        >
          {Array.isArray(items) &&
            items?.map((item) => (
              <option
                key={item.value}
                value={item.value}
                style={{ color: "#000" }}
                selected={value === item.value}
              >
                {item.text}
              </option>
            ))}
        </Select>
      </Box>
      <Box textAlign={"left"} padding="0.3rem 0">
        <Text color="#FF8585" fontSize={"12px"} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ""}
        </Text>
      </Box>
    </>
  );
};

export const FormikFormSelect: FC<
  Omit<ISelect, "handleChange"> & {
    name: string;
    showMessage: boolean;
    items: Array<{ text: string | number; value: any }>;
    customHandleChange?: (e: any) => void | undefined;
  }
> = ({ name, showMessage, items, customHandleChange, ...rest }) => {
  const { handleChange, values, errors } = useFormikContext<any>();
  const hasError = errors[name] || "";

  return (
    <CustomSelectCmp
      {...rest}
      name={name}
      items={items}
      handleChange={(e) => {
        handleChange(e);
        customHandleChange?.(e);
      }}
      value={(values as any)[name as any]}
      showMessage={showMessage}
      errorMessage={hasError}
    />
  );
};

export const BasicSelect: FC<
ISelect
> = (
  { name, showMessage, handleChange, items, value, ...rest }
) => {
  return (
    <CustomSelectCmp
      {...rest}
      name={name}
      items={items}
      handleChange={handleChange}
      value={value}
      showMessage={showMessage}
    />
  );
};

export default CustomSelectCmp;
