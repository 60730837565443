import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { LogoLoader } from "components";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ActionModal from "components/Modals/ActionModal";
import ShowSearchBankModal from "components/Modals/ShowSearchBankModal";
import { FormikProvider, FormikValues, useFormik } from "formik";
import { useAppDispatch } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { walletActions } from "store/slices";
import { WALLETVALIDATIONS } from "validators/WalletValidator";
import * as yup from "yup";

const addBankAccountValidatorSchema = yup.object({
  bankName: WALLETVALIDATIONS.bankName,
  bankCode: WALLETVALIDATIONS.bankCode,
  accountNumber: WALLETVALIDATIONS.accountNumber,
});

const AddBankAccount = () => {
  const [isExit, setIsExit] = useState(false);
  const { wishlistID } = useParams<{ wishlistID: string }>();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useLocation().state as any;
  const { verifyBankAccount, createBankAccount } = walletActions;

  // const verifyBankAccountData: any = useAppSelector(
  //   walletSelectors.verifyBankAccount
  // );

  const [showSearchBank, setShowSearchBank] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState<any>();
  const [accountName, setAccountName] = useState<any>();

  const setBankFn = useCallback((data: any) => {
    setBankData(data);
    setShowSearchBank(false);
  }, []);

  const formik = useFormik<FormikValues>({
    enableReinitialize: true,
    initialValues: {
      accountNumber: "",
      accountName: "",
      bankName: bankData ? `${bankData.name}` : "",
      bankCode: bankData ? `${bankData.code}` : "",
    },
    validationSchema: addBankAccountValidatorSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      dispatch(
        createBankAccount({
          bankCode: values.bankCode,
          accountName: values.accountName,
          accountNumber: values.accountNumber,
        })
      )
        .then((res: any) => {
          const response = res;
          setLoading(false);
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            resetForm();
            toast({
              status: "success",
              description: "Bank details added successfully",
            });
            navigate(`/choose-account/${wishlistID}`, {
              state: { amount: data.amount },
            });
          } else {
            toast({
              status: "error",
              description: response.payload.message,
            });
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "There is an error",
          });
        })
        .finally(() => setLoading(false));
    },
  });

  useEffect(() => {
    if (
      formik.values.accountNumber &&
      formik.values.accountNumber.length === 10 &&
      formik.values.bankCode
    ) {
      setAccountName(<LogoLoader />);
      dispatch(
        verifyBankAccount({
          accountNumber: formik.values.accountNumber,
          bankCode: formik.values.bankCode,
        })
      ).then((res: any) => {
        const response = res;
        if (response.payload.statusCode === 200) {
          setAccountName(response?.payload?.payload?.account_name);
          formik.setFieldValue(
            "accountName",
            response?.payload?.payload?.account_name
          );
        } else {
          setAccountName("");
          toast({
            status: "error",
            description: response.payload.message,
          });
        }
      });
    } else {
      formik.setFieldValue("accountName", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.accountNumber]);
  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            color="#fff"
            h={8}
            w={8}
            cursor="pointer"
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            style={{ width: "100%" }}
          >
            <Box maxWidth="360px" width="100%" margin="0 auto" pt="1rem">
              <Box display={"flex"} flexDirection="column" gap="20px">
                <Box textAlign={"center"} fontFamily="Plus Jakarta Sans">
                  <Text
                    color={"#fff"}
                    fontWeight="700"
                    fontSize={"30px"}
                    pb="0.5rem"
                  >
                    add new account
                  </Text>
                </Box>
                <Flex flexDir={"column"} gap="2px">
                  <Box
                    onClick={() => {
                      setShowSearchBank(true);
                    }}
                  >
                    <FormikFormInput
                      hideCaret
                      placeholder="choose bank"
                      name="bankName"
                      type={"text"}
                      disabled={false}
                      showMessage={true}
                      value={bankData ? `${bankData.name}` : ""}
                    />
                  </Box>
                  <Box>
                    <FormikFormInput
                      placeholder="account number"
                      name="accountNumber"
                      type={"text"}
                      disabled={false}
                      showMessage={true}
                    />
                  </Box>
                  <Box display="none">
                    <FormikFormInput
                      placeholder="bank code"
                      name="bankCode"
                      type={"text"}
                      disabled={false}
                      showMessage={true}
                      value={bankData ? `${bankData.code}` : ""}
                    />
                  </Box>
                  <Box className="accountName">
                    {/* <FetchAccountName /> */}
                    <Text
                      fontFamily={"Plus Jakarta Sans"}
                      fontSize={"13px"}
                      fontWeight="400"
                    >
                      {accountName}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box width={{ base: "100%" }} pt="2rem">
                <ButtonCmp
                  text={"SAVE"}
                  size="md"
                  padding="1.5rem 3rem"
                  isLoading={loading}
                  isDisabled={
                    formik.isValid && formik.values.accountName ? false : true
                  }
                  opacity={"1"}
                  border={"none"}
                  textHoverColor={"#fff"}
                  width="100%"
                  maxWidth="100%"
                  type="submit"
                />
              </Box>
              <Box display="flex" justifyContent={"center"} pt="1.5rem">
                <Text
                  width="210px"
                  color="rgba(255, 255, 255, 0.7)"
                  fontWeight={"400"}
                  fontSize="13px"
                  textAlign={"center"}
                >
                  having issues?{" "}
                  <span style={{ color: "#fff" }}>Contact us</span>
                </Text>
              </Box>
            </Box>
          </form>
        </FormikProvider>
        <ShowSearchBankModal
          isOpen={showSearchBank}
          onRequestClose={() => {
            setShowSearchBank(false);
          }}
          setBank={setBankFn}
          maxWidth="400px"
          showCloseIcon={false}
        />
        <ActionModal
          isOpen={isExit}
          onRequestClose={() => {
            setIsExit(false);
          }}
          maxWidth="400px"
          showCloseIcon={false}
          actionTitle="are you sure?"
          actionDesc="You will lose progress on this item if you don’t save it before you
          go to another page"
          yesText="YES, EXIT"
          noText="NO, CANCEL"
          yesAction={() => {
            navigate(-1);
          }}
          noAction={() => {
            setIsExit(false);
          }}
        />
      </Box>
    </>
  );
};

export default AddBankAccount;
