/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const WALLETVALIDATIONS = {
  amount: yup.number().required("Amount is required!"),
  bankName: yup.string().required("Bank name is required"),
  accountName: yup.string().required("Account name is required"),
  bankCode: yup.string().required("Bank code is required"),
  accountNumber: yup
    .string()
    .required("Provide account number")
    .matches(/^\d+$/, "Only digit is allowed")
    .test("length", "Provide 10 digits account number", (val) => {
      if (val && val.length === 10) {
        return true;
      }
      return false;
    }),
  otp: yup.string().required("OTP is required"),
};
