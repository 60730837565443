import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddWish,
  AddWishResponse,
  CreateWishlistResponse,
  DeleteACollection,
  DeleteWish,
  GetExternalWishResponse,
  GetACollection,
  GetAWishlist,
  GetAWishlistResponse,
  GetExternalWish,
  GetListCategoriesResponse,
  GetListOfCategories,
  GetListOfCategoriesCollection,
  GetWish,
  GetWishResponse,
  MRequest,
  PostCreateCollection,
  PostCreateWishlist,
  SaveWishNote,
  UpdateACollection,
  UpdateAWishlist,
  UpdateWish,
  PostCreateSuggestedUrl,
  CreateSuggestedUrlResponse,
} from "apis";
import { RootState } from "store/reducers";

const entity = "wishlist";

const getWishlistCategory = createAsyncThunk("list/categories", async () => {
  const res = await MRequest.get<typeof GetListOfCategories.RES>(
    GetListOfCategories.ROUTE
  );
  return res.data;
});

const getCollectionCategories = createAsyncThunk(
  "list/categories/collection",
  async () => {
    const res = await MRequest.get<typeof GetListOfCategoriesCollection.RES>(
      GetListOfCategoriesCollection.ROUTE
    );
    return res.data;
  }
);

const createWishlist = createAsyncThunk(
  "list/createWishlist",
  async (data: typeof PostCreateWishlist.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostCreateWishlist.RES>(
        PostCreateWishlist.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


const createSuggestedUrl = createAsyncThunk(
  "list/createSuggestedUrl",
  async (data: typeof PostCreateSuggestedUrl.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostCreateSuggestedUrl.RES>(
        PostCreateSuggestedUrl.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createCollection = createAsyncThunk(
  "list/createCollection",
  async (data: typeof PostCreateCollection.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostCreateCollection.RES>(
        PostCreateCollection.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deleteACollection = createAsyncThunk(
  "list/deleteACollection",
  async (params: { collectionID: string }) => {
    const res = await MRequest.delete<typeof GetAWishlist.RES>(
      `${DeleteACollection.ROUTE}/${params.collectionID}`
    );
    return res.data;
  }
);

const updateACollection = createAsyncThunk(
  "list/updateACollection",
  async (data: typeof UpdateACollection.BODY) => {
    const res = await MRequest.put<typeof UpdateACollection.RES>(
      `${UpdateACollection.ROUTE}`,
      data
    );
    return res.data;
  }
);

const getACollection = createAsyncThunk(
  "list/getACollection",
  async (params: { userID: any; slug: string }) => {
    const res = await MRequest.get<typeof GetACollection.RES>(
      `${GetACollection.ROUTE}?userID=${params.userID}&slug=${params.slug}`
    );
    return res.data;
  }
);

const getAWishlist = createAsyncThunk(
  "list/getAWishlist",
  async (params: { userID: any; slug: string }) => {
    const res = await MRequest.get<typeof GetAWishlist.RES>(
      `${GetAWishlist.ROUTE}?userID=${params.userID}&slug=${params.slug}`
    );
    return res.data;
  }
);

const deleteAWishlist = createAsyncThunk(
  "list/deleteAWishlist",
  async (params: { wishlistID: string }) => {
    const res = await MRequest.delete<typeof GetAWishlist.RES>(
      `${GetAWishlist.ROUTE}/${params.wishlistID}`
    );
    return res.data;
  }
);

const updateAWishlist = createAsyncThunk(
  "list/updateWishlist",
  async (data: typeof UpdateAWishlist.BODY) => {
    const res = await MRequest.put<typeof UpdateAWishlist.RES>(
      `${UpdateAWishlist.ROUTE}`,
      data
    );
    return res.data;
  }
);

const addWish = createAsyncThunk(
  "list/addWish",
  async (data: typeof AddWish.BODY) => {
    const res = await MRequest.post<typeof AddWish.RES>(AddWish.ROUTE, data);
    return res.data;
  }
);

const deleteWish = createAsyncThunk(
  "list/deleteWish",
  async (params: { wishID: string }) => {
    const res = await MRequest.delete<typeof DeleteWish.RES>(
      `${DeleteWish.ROUTE}/${params.wishID}`
    );
    return res.data;
  }
);

const getWish = createAsyncThunk(
  "list/getWish",
  async (params: { wishId: string }) => {
    const res = await MRequest.get<typeof GetWish.RES>(
      `${GetWish.ROUTE}/${params.wishId}`
    );
    return res.data;
  }
);

const getExternalWish = createAsyncThunk(
  "list/getExternalWish",
  async (params: { url: string, wishlistID : string }) => {
    const res = await MRequest.get<typeof GetExternalWish.RES>(
      `${GetExternalWish.ROUTE}?url=${params.url}&wishlistID=${params.wishlistID}`
    );
    return res.data;
  }
);


const updateWish = createAsyncThunk(
  "list/updateWish",
  async (data: typeof UpdateWish.BODY) => {
    const res = await MRequest.put<typeof UpdateWish.RES>(
      `${UpdateWish.ROUTE}`,
      data
    );
    return res.data;
  }
);

const saveWishNote = createAsyncThunk(
  "list/saveWishNote",
  async (data: typeof SaveWishNote.BODY) => {
    const res = await MRequest.put<typeof SaveWishNote.RES>(
      SaveWishNote.ROUTE,
      data
    );
    return res.data;
  }
);

export type WishlistInitialStateType = {
  getWishlistCategoriesResponse: GetListCategoriesResponse | null;
  getCollectionCategoriesResponse: GetListCategoriesResponse | null;
  createWishlistResponse: CreateWishlistResponse | null;
  createSuggestedUrlResponse: CreateSuggestedUrlResponse | null;
  createCollectionResponse: CreateWishlistResponse | null;
  getAwishlistResponse: GetAWishlistResponse | null;
  getACollectionResponse: GetAWishlistResponse | null | any;
  getEventResponse: GetAWishlistResponse | null | any;
  deleteAwishlistResponse: null;
  deleteACollectionResponse: null;
  getWishResponse: GetWishResponse | null;
  getExternalWishResponse: GetExternalWishResponse | null;
  addWishResponse: AddWishResponse | null;
  deleteWishResponse: null;
};

const initialState: WishlistInitialStateType = {
  getWishlistCategoriesResponse: null,
  createWishlistResponse: null,
  createSuggestedUrlResponse: null,
  getAwishlistResponse: null,
  getACollectionResponse: null,
  getEventResponse: null,
  getWishResponse: null,
  getExternalWishResponse: null,
  addWishResponse: null,
  deleteAwishlistResponse: null,
  deleteWishResponse: null,
  deleteACollectionResponse: null,
  createCollectionResponse: null,
  getCollectionCategoriesResponse: null,
};

const wishlistSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getWishlistCategory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWishlistCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      getWishlistCategory.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWishlistCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      getCollectionCategories.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getCollectionCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      getCollectionCategories.rejected,
      (state, action: PayloadAction<any>) => {
        state.getCollectionCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      createSuggestedUrl.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createSuggestedUrlResponse = action.payload;
      }
    );
    builder.addCase(
      createWishlist.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWishlistCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      createWishlist.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWishlistCategoriesResponse = action.payload;
      }
    );
    builder.addCase(
      createCollection.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createCollectionResponse = action.payload;
      }
    );
    builder.addCase(
      createCollection.rejected,
      (state, action: PayloadAction<any>) => {
        state.createCollectionResponse = action.payload;
      }
    );
    builder.addCase(
      getACollection.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getACollectionResponse = action.payload;
      }
    );
    builder.addCase(
      getACollection.rejected,
      (state, action: PayloadAction<any>) => {
        state.getACollectionResponse = action.payload;
      }
    );
   
    builder.addCase(
      getAWishlist.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAwishlistResponse = action.payload;
      }
    );
    builder.addCase(
      getAWishlist.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAwishlistResponse = action.payload;
      }
    );
    builder.addCase(
      deleteAWishlist.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteAwishlistResponse = action.payload;
      }
    );
    builder.addCase(
      deleteAWishlist.rejected,
      (state, action: PayloadAction<any>) => {
        state.deleteAwishlistResponse = action.payload;
      }
    );
    builder.addCase(
      deleteACollection.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteACollectionResponse = action.payload;
      }
    );
    builder.addCase(
      deleteACollection.rejected,
      (state, action: PayloadAction<any>) => {
        state.deleteACollectionResponse = action.payload;
      }
    );
    builder.addCase(
      updateAWishlist.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getAwishlistResponse = action.payload;
      }
    );
    builder.addCase(
      updateACollection.rejected,
      (state, action: PayloadAction<any>) => {
        state.getACollectionResponse = action.payload;
      }
    );
    builder.addCase(
      updateACollection.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getACollectionResponse = action.payload;
      }
    );
    builder.addCase(
      updateAWishlist.rejected,
      (state, action: PayloadAction<any>) => {
        state.getAwishlistResponse = action.payload;
      }
    );
    builder.addCase(addWish.fulfilled, (state, action: PayloadAction<any>) => {
      state.addWishResponse = action.payload;
    });
    builder.addCase(addWish.rejected, (state, action: PayloadAction<any>) => {
      state.addWishResponse = action.payload;
    });
    builder.addCase(
      deleteWish.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deleteWishResponse = action.payload;
      }
    );
    builder.addCase(
      deleteWish.rejected,
      (state, action: PayloadAction<any>) => {
        state.deleteWishResponse = action.payload;
      }
    );
    builder.addCase(getWish.fulfilled, (state, action: PayloadAction<any>) => {
      state.getWishResponse = action.payload;
    });
    builder.addCase(getWish.rejected, (state, action: PayloadAction<any>) => {
      state.getWishResponse = action.payload;
    });
    builder.addCase(
      getExternalWish.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getExternalWishResponse = action.payload;
      }
    );
    builder.addCase(
      getExternalWish.rejected,
      (state, action: PayloadAction<any>) => {
        state.getExternalWishResponse = action.payload;
      }
    );
    builder.addCase(
      updateWish.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getWishResponse = action.payload;
      }
    );
    builder.addCase(
      updateWish.rejected,
      (state, action: PayloadAction<any>) => {
        state.getWishResponse = action.payload;
      }
    );
    builder.addCase(
      saveWishNote.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createWishlistResponse = action.payload;
      }
    );
    builder.addCase(
      saveWishNote.rejected,
      (state, action: PayloadAction<any>) => {
        state.createWishlistResponse = action.payload;
      }
    );
  },
});

export const wishlistActions = {
  ...wishlistSlice.actions,
  getWishlistCategory,
  createWishlist,
  deleteAWishlist,
  getAWishlist,
  updateAWishlist,
  getWish,
  getExternalWish,
  updateWish,
  addWish,
  deleteWish,
  saveWishNote,
  createCollection,
  deleteACollection,
  getCollectionCategories,
  getACollection,
  updateACollection,
  createSuggestedUrl,
};

export const wishlistSelectors = {
  getWishlistCategories: (state: RootState) =>
    state.wishlist.getWishlistCategoriesResponse,
  getCollectionCategories: (state: RootState) =>
    state.wishlist.getCollectionCategoriesResponse,
  createWishlist: (state: RootState) => state.wishlist.createWishlistResponse,
  createSuggestedUrl: (state: RootState) => state.wishlist.createSuggestedUrlResponse,
  createCollection: (state: RootState) =>
    state.wishlist.createCollectionResponse,
  deleteACollection: (state: RootState) =>
    state.wishlist.deleteACollectionResponse,
  getAWishlist: (state: RootState) => state.wishlist.getAwishlistResponse,
  getEvent: (state: RootState) => state.wishlist.getEventResponse,
  getACollection: (state: RootState) => state.wishlist.getACollectionResponse,
  updateAWishlist: (state: RootState) => state.wishlist.getAwishlistResponse,
  updateACollection: (state: RootState) =>
    state.wishlist.getACollectionResponse,
  deleteAWishlist: (state: RootState) => state.wishlist.deleteAwishlistResponse,
  getWish: (state: RootState) => state.wishlist.getWishResponse,
  getExternalWish: (state: RootState) => state.wishlist.getExternalWishResponse,
  updateWish: (state: RootState) => state.wishlist.getWishResponse,
  addWish: (state: RootState) => state.wishlist.addWishResponse,
  deleteWish: (state: RootState) => state.wishlist.deleteWishResponse,
};

export const wishlistReducer = wishlistSlice.reducer;
