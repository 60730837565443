import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const StatCardCmp: React.FC<{
  active?: boolean;
  title: string;
  onClickCard: () => void;
}> = ({ active, title, onClickCard }) => {
  return (
    <Box
      onClick={onClickCard}
      cursor="pointer"
      p="0.3rem 0.5rem"
      borderRadius={"3px"}
      w="fit-content"
      // h="120px"
      // minW={{ base: "113px", sm: "140px", md: "200px", lg: "240px" }}
      // maxW={{ md: "200px", lg: "240px" }}
      bgColor={active ? "#000" : "#e4e4e4"}
    >
      <Flex flexDir="column" gap="3px">
        <Text
          fontSize={"13px"}
          fontWeight="500"
          color={active ? "#fff" : "#000"}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

export default StatCardCmp;
