import {
  Box,
  Button,
  Input,
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Checkbox,
  useToast
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import ModalCmp from "../ModalCmp/ModalCmp";
import trimMoney from "utils/trimMoney";
import { Document as FlexDocument } from "flexsearch";
import { ExportToCsv } from "../../utils/ExportToCSV";
import { AsoEbiPayer, GetAllAsoEbiPaymentsForWeddingResponse, getAllAsoEbiPayementsForWedding, getAllAsoEbiPayers } from "utils/workbench/APIs/routes/asoebi";

const changeSingleBooleanByIndexInArray = (index: number, bool: boolean, arr: boolean[]) => arr.map(
  (_bool, _index) => _index === index ? bool : _bool);

const fetchCheckedUsers = (booleanArray: boolean[], asoEbiListPlayerList: Partial<AsoEbiPayer>[]) => {
  if (
    booleanArray.length !== asoEbiListPlayerList.length ||
    asoEbiListPlayerList.length === 0 ||
    booleanArray.length === 0
  ) return [];

  const checkedUsers: Partial<AsoEbiPayer>[] = [];
  booleanArray.forEach(
    (bool, index) => {
      if (bool) checkedUsers.push(asoEbiListPlayerList[index]);
    }
  );
  return checkedUsers;
};


const CustomTab: React.FC<
{
  name: string; 
  count: number;
  active: boolean;
  onClick: () => void;
}> = ({name, count, active, onClick}) => {
  return (
    <Box
      borderBottom={active ? "1px solid white" : ""}
      display={"flex"}
      flexDirection={"row"}
      cursor={'pointer'}
      onClick={onClick}
      pb={'3px'}
      width={'min-content'}
    >
      <Text
        color={"#FFFFFF"}
        fontSize="16px"
        width={'max-content'}
      >
        {name}
      </Text>
      <Flex
        height={"22px"}
        width={"22px"}
        borderRadius={"100%"}
        background={"#0F441E"}
        justifyContent={"center"}
        alignItems={"center"}
        marginLeft={"0.4rem"}
        >
        <Text
          // width={{lg: "14px"}}
          height={{lg: "14px"}}
          // border={"1px solid"}
          // borderColor={"pink"}
          width={'min-content'}
          color="#80BA7C"
          fontSize="10px"
          fontWeight="500"
        >
          {count}
        </Text>
      </Flex>
    </Box>
  );
};

const CustomTabList: React.FC<
{
  tabProps: {
    name: string;
    count: number;
  }[];
  currentTab: number, 
  setCurrentTab: (tabIndex: number) => void;
}> = ({ tabProps, currentTab, setCurrentTab }) => {
  return (
    <Flex
      borderBottom={"1px solid rgba(255, 255, 255, 0.5)"}
      gap={"15px"}
      overflowX={'auto'}
      w={'100%'}
      pl={"20px"}
      // position={"sticky"}
    >
      {
        tabProps.map(
          ({name, count}, index) => (
            <CustomTab 
              key={`str${index}`}
              name={name}
              count={count}
              active={index === currentTab}
              onClick={() => {setCurrentTab(index)}}
            />
          )
        )
      }
    </Flex>
  );
};



const AsoEbiPayersListModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  showCloseIcon: boolean;
  showAsoEbiPayersList: boolean;
  setShowAsoEbiPayersList: (arg: boolean) => void;
}> = ({
  isOpen,
  onRequestClose,
  showCloseIcon,
  showAsoEbiPayersList,
  setShowAsoEbiPayersList,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [ currentTab, setCurrentTab ] = useState(0);

  const [searchString, setSearchString] = useState("");
  const [allTickets, setAllTickets] = useState<AsoEbiPayer[]>([]);
  const [asoEbiTickets, setAsoEbiTickets] = useState<GetAllAsoEbiPaymentsForWeddingResponse>([]);
  const [checkedItems, setCheckedItems] = useState(allTickets.map(() => false));
  const allChecked = checkedItems.every(Boolean);

  const [currentAsoEbiList, setCurrentAsoEbiList] = useState<Partial<AsoEbiPayer>[]>(allTickets);

  const handleTabSelect = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    const _currentAsoEbiList = tabIndex === 0 ? allTickets : asoEbiTickets[tabIndex - 1].asoEbiPayers;
    setCurrentAsoEbiList(_currentAsoEbiList);
  };

  useEffect(() => {
    getAllAsoEbiPayers({}, 
      (response) => {
        if (response.statusCode === 200) {
          setAllTickets(response.payload.asoEbiPayers);
          setCurrentAsoEbiList(response.payload.asoEbiPayers);
        }
        if (response.statusCode === 400 || response.errors) toast(
          { status: "error", description: response.message });
    });

    getAllAsoEbiPayementsForWedding({},
      (response) => {
        if (response.statusCode === 200) {
          setAsoEbiTickets(response.payload);
        }
        if (response.statusCode === 400 || response.errors) toast(
          { status: "error", description: response.message });        
    });
    // eslint-disable-next-line
  }, []);

  const SearchEngine = new FlexDocument<AsoEbiPayer>(
    {
      document: {
        id: "_id",
        index: [ 
          "fullName", "email", "phoneNumber"
        ]
      }
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ticketsObject: {[id: string]: AsoEbiPayer} = {};
    setSearchString(event.target.value);
    currentAsoEbiList.forEach(ticket => {
      SearchEngine.add(ticket as AsoEbiPayer)
    });
    currentAsoEbiList.forEach(
      (ticket) => {
        ticketsObject[(ticket as AsoEbiPayer)!._id] = ticket as AsoEbiPayer;
      }
    );
    const res = SearchEngine.search(event.target.value);
    console.log(res);
    if (res.length) {
      setCurrentAsoEbiList(res[0].result.map(id => ticketsObject[id]));
    }
    if (event.target.value === '') {
      handleTabSelect(currentTab);
    }
  };

  const downloadCSV = (tickets: Partial<AsoEbiPayer>[]) => {
    if (tickets.length) {
      const data = currentAsoEbiList.map((_ticket) => {
        const {
          fullName,
          email,
          address,
          phoneNumber,
          amount,
          price,
          quantity,
        } = _ticket as AsoEbiPayer & {price: number, quantity: number};
        return {
          fullName,
          email,
          address,
          phoneNumber,
          amount: amount || price,
          ...(quantity ? {quantity} : {})
        };
      });
  
      const options = {
        filename: `unboxd_wedding_aso_ebi_list${currentTab !== 0 ? `_${asoEbiTickets[currentTab - 1].name}_` : ""}`,
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "ASO EBI PAYERS LIST",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };
  
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
    }else {
      toast({
        status: "error",
        description: "no user selected",
      });
    }
  };

  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{
          maxWidth: "980px",
          borderTopLeftRadius: "16px"
        }}
        shouldFocusAfterRender={false}
        contentLabel="Terms"
        id="terms"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
        fullHeight={true}
        position="right"
      >
        <Flex
          flexDirection={'column'}
          height={'100vh'}
        > 
          <Flex
            // position={"absolute"}
            mt={{base: "40px", md: "0px"}}
            backgroundColor={"#1F2228"}
            // border={"1px solid red"}
            // borderColor={"#868686"}
          >
            <Flex
              marginRight={"0px"}
              py={5}
              px={5}
              width={{ base: "420px", md: "977px" }}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Box
                // mr={{base: "140px", md: "540px", lg: "650px"}}
              >
                <div
                  style={{
                    fontSize: "25px",
                  }}
                >
                  Aso Ebi List
                </div>
              </Box>
              <Box
                paddingRight={"15px"}
                alignSelf={"center"}
                cursor= "pointer"
                // position={"sticky"}
                onClick={() => setShowAsoEbiPayersList(!showAsoEbiPayersList)}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.8459 19.1016C19.8947 19.1504 19.9335 19.2085 19.96 19.2723C19.9864 19.3362 20 19.4046 20 19.4737C20 19.5429 19.9864 19.6113 19.9599 19.6751C19.9335 19.739 19.8947 19.797 19.8458 19.8459C19.7969 19.8947 19.7389 19.9335 19.6751 19.96C19.6112 19.9864 19.5428 20 19.4736 20C19.4045 20 19.3361 19.9864 19.2722 19.9599C19.2084 19.9335 19.1504 19.8947 19.1015 19.8458L10 10.7444L0.898491 19.8458C0.799798 19.9445 0.665935 20 0.526351 20C0.386766 20 0.252893 19.9446 0.154183 19.8459C0.0554734 19.7472 1.20932e-05 19.6133 1.97741e-09 19.4737C-1.20892e-05 19.3342 0.0554262 19.2003 0.154119 19.1016L9.25566 10L0.154119 0.898427C0.0554262 0.799717 -1.20892e-05 0.665844 1.97741e-09 0.526259C5.98892e-06 0.457144 0.0136251 0.388706 0.04008 0.324854C0.0665348 0.261003 0.105307 0.202987 0.154183 0.154119C0.203059 0.105251 0.261082 0.0664891 0.324939 0.0400453C0.388795 0.0136016 0.457235 -5.98497e-06 0.526351 1.9747e-09C0.665935 1.20931e-05 0.799798 0.0554734 0.898491 0.154183L10 9.25563L19.1015 0.154183C19.1504 0.105307 19.2084 0.0665348 19.2722 0.04008C19.3361 0.0136251 19.4045 5.98892e-06 19.4736 1.9747e-09C19.5428 -5.98497e-06 19.6112 0.0136016 19.6751 0.0400453C19.7389 0.0664891 19.7969 0.105251 19.8458 0.154119C19.8947 0.202987 19.9335 0.261003 19.9599 0.324854C19.9864 0.388706 20 0.457144 20 0.526259C20 0.595375 19.9864 0.663814 19.96 0.727671C19.9335 0.791527 19.8947 0.84955 19.8459 0.898427L10.7443 10L19.8459 19.1016Z" fill="white"/>
                </svg>
              </Box>
            </Flex>
          </Flex>

          <Flex
            fontFamily={"Plus Jakarta Sans"}
            marginTop={{base: "0px", md: "0px"}}
            flexDirection={"column"}
          >
            <Box
              fontFamily={`Plus Jakarta Sans`}
              width={{ base: "420px", md: "977px" }}
              // position={"absolute"}
              backgroundColor={"#1F2228"}
            >
              <CustomTabList 
                tabProps={
                  [
                    {
                      name: "All",
                      count: allTickets.length,
                    },
                    ...asoEbiTickets.map(
                      (asoEbiTicket) => {
                        return {
                          name: asoEbiTicket.name,
                          count: asoEbiTicket.asoEbiPayers.length,
                        };
                      }
                    )
                  ]
                }
                currentTab={currentTab}
                setCurrentTab={handleTabSelect}
              />
                
              <Flex
                pt={10}
                pb={5}
                // position={"sticky"}
              >
                <Box
                  width={{base: "174px", lg: "351px"}}
                  marginLeft={{base: "28px", lg: "20px"}}
                  marginRight={{base: "25px", lg: "93px"}}
                >
                    <Input
                      height={{base: "48px", lg: "56px"}}
                      className={"input-reg-field"}
                      fontFamily={"Plus Jakarta Sans"}
                      padding={"1.9rem 1rem"}
                      background={"#16181D"}
                      fontWeight="400"
                      fontSize={"12px"}
                      _hover={{
                        border: "0.5px solid #9a9b9e",
                      }}
                      _focus={{
                        boxShadow: "none",
                        border: "0.5px solid #9a9b9e",
                        borderRadius: "18px",
                      }}
                      border={"0"}
                      borderRadius={"18px"}
                      boxShadow={"none"}
                      value={searchString}
                      onChange={handleChange}
                      placeholder="Search Guests"
                      _placeholder={{color: "#868686"}}
                    />
                </Box>
                
                <Button
                  alignSelf={"center"}
                  background={`#44A560`}
                  width={{base: "108px", lg: "174px"}}
                  height={{base: "48px", lg: "56px"}}
                  fontSize={"12px"}
                  borderRadius={"100px"}
                  onClick={() => {
                    const checkedUsers = fetchCheckedUsers(checkedItems, currentAsoEbiList);
                    downloadCSV(checkedUsers);
                  }}
                >
                  Download list
                </Button>
              </Flex>
            </Box>
            <Box>

            <TableContainer
              borderTop={"0.5px solid"}
              borderColor={"#868686"}
              h={{base: "400px", md: "550px"}}
              overflowY={"auto"}
            >
              <Table size="md">
                <Thead>
                  <Tr>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      <Checkbox 
                        isChecked={allChecked}
                        backgroundColor={"#D9D9D9"} 
                        onChange={(e) => setCheckedItems(currentAsoEbiList.map(() => e.target.checked))}
                      />
                    </Th>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Name
                    </Th>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Email
                    </Th>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Tel&nbsp;&nbsp;no.
                    </Th>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Amount
                    </Th>
                    <Th
                      borderColor={"#868686"}
                      color={"white"}
                      fontWeight={400}
                      fontSize={14}
                      textTransform={"none"}
                      fontFamily={"Plus Jakarta Sans"}
                    >
                      Delivery Address
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    currentAsoEbiList.map((ticket, index) => (
                      <Tr key={`sds${index}`}>
                        <Td>
                          <Checkbox 
                            backgroundColor={"#D9D9D9"}
                            isChecked={checkedItems[index]}
                            onChange={(e) => {
                              setCheckedItems(
                                changeSingleBooleanByIndexInArray(index, e.target.checked, checkedItems)
                              );
                            }}
                          />
                        </Td>
                        <Td fontSize={14} py={4}>
                          {ticket.fullName}
                        </Td>
                        <Td fontSize={14}>{ticket.email}</Td>
                        <Td fontSize={14}>{ticket.phoneNumber}</Td>
                        <Td fontSize={14}>₦{trimMoney((ticket as any)?.amount || (ticket as any)?.price)}</Td>
                        <Td fontSize={14}>{ticket!.address!.length > 40 ? ticket!.address!.substring(0, 40) + "..." : ticket.address}</Td>  
                      </Tr>
                    ))
                  }
                </Tbody>
                <Tfoot></Tfoot>
              </Table>
            </TableContainer>
            </Box>
          </Flex>
        </Flex>
      </ModalCmp>
    </>
  );
};

export default AsoEbiPayersListModal;
