import { Box, Checkbox, Flex, Image, Text, useToast } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";
import { FormikProvider, useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import PayWithFlutterwave from "pages/Contributor/Payment";
import React, { useState } from "react";
import { contributorActions, contributorSelectors } from "store/slices";
import { AUTHVALIDATIONS } from "validators/AuthValidations";
import { CONTRIBUTORVALIDATIONS } from "validators/ContributorValidator";
import * as yup from "yup";
import SuccessModal from "./SuccessModal";

const FundModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  fundInfo: any;
  isCollection?: boolean;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  fundInfo,
  isCollection,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const fundModalValidationSchema = yup.object({
    fullName: AUTHVALIDATIONS.fullName,
    email: AUTHVALIDATIONS.email,
    phone: CONTRIBUTORVALIDATIONS.phone,
  });

  const dispatch = useAppDispatch();

  const createGivenData = useAppSelector(contributorSelectors.createGiven);

  const givenData: any = createGivenData?.payload;
  const shouldNotDisplayGif = fundInfo?.recipientID === "655f7295a607d56baa4792ae";

  const { createGiven } = contributorActions;

  const formik = useFormik<{
    fullName: string;
    email: string;
    phone: "";
    anonymous: boolean;
  }>({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      anonymous: false,
    },
    validationSchema: fundModalValidationSchema,
    onSubmit: (values) => {
      setPageLoading(true);
      const accessToken = localStorage.getItem("accessToken");
      let typeOfGiver;
      if (accessToken) {
        typeOfGiver = "user";
      } else {
        typeOfGiver = "guest";
      }
      if (values.anonymous) {
        typeOfGiver = "anonymous";
      }

      let payload: any = {
        recipientID: fundInfo.recipientID,
        giver: {
          email: values.email,
          name: values.fullName,
          phone: values.phone,
        },
        typeOfGiver,
        amount: fundInfo.fundAmount,
      };

      if (localStorage.getItem("userType") === "Organisation") {
        payload = {
          ...payload,
          wishlistCashfundID: fundInfo?.wishlistCashfundID,
        };
      } else {
        payload = {
          ...payload,
          wishlistID: fundInfo?.wishlistID,
          giftID: fundInfo.giftID,
        };
      }

      dispatch(createGiven({ data: payload, type: fundInfo?.type || "gift" }))
        .then((res: any) => {
          const response = res;
          if (
            response.payload.statusCode === 200 &&
            response.payload.message === "proceed to payment gateway"
          ) {
            setShowPaymentModal(true);
          } else {
            toast({
              status: "error",
              description: response?.payload?.message,
            });
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "Please fill out the form, don't worry, you'll remain anonymous",
          });
          // return;
        })
        .finally(() => setPageLoading(false));
    },
  });
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={() => {
          onRequestClose();
          setShowPaymentModal(false);
        }}
        contentStyles={{
          maxWidth: maxWidth || "350px",
          border: "none",
          maxHeight: "100vh",
        }}
        shouldFocusAfterRender={false}
        contentLabel="Fund"
        id="fund"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box textAlign={"center"} fontFamily={"Plus Jakarta Sans"} p="1rem">
          <Flex flexDir="column" alignItems={"center"} gap="7px">
            {!isCollection && !shouldNotDisplayGif && (
              <Image
                src={
                  "https://media.giphy.com/media/JlpjgShzDsrMIyFu5U/giphy.gif"
                }
                alt="Thanks"
                width="100px"
                height="100px"
                borderRadius={"50%"}
              />
            )}
            <Text
              fontSize={"16px"}
              fontWeight="400"
              color="rgba(255, 255, 255, 0.5)"
            >
              fill your details now to continue
            </Text>
          </Flex>
          <FormikProvider value={formik}>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
              style={{ width: "100%" }}
            >
              <Flex flexDir="column" alignItems={"center"} py="1rem">
                <Box w="100%">
                  <FormikFormInput
                    background="#22242C"
                    placeholder="full name"
                    name="fullName"
                    disabled={false}
                    showMessage={true}
                  />
                </Box>
                <Box w="100%">
                  <FormikFormInput
                    background="#22242C"
                    placeholder="email address"
                    name="email"
                    disabled={false}
                    showMessage={true}
                  />
                </Box>
                <Box w="100%">
                  <FormikFormInput
                    background="#22242C"
                    placeholder="phone number"
                    name="phone"
                    disabled={false}
                    showMessage={true}
                  />
                </Box>
                <Flex w="100%" justifyContent={"flex-start"} pb="1rem">
                  <Checkbox
                    colorScheme="yellow"
                    iconColor="#B66D12"
                    isChecked={formik.values.anonymous || pageLoading ? false : true}
                    onChange={formik.handleChange}
                    name="anonymous"
                  >
                    send as anonymous
                  </Checkbox>
                  {}
                </Flex>
                <ButtonCmp
                  text={"GO TO PAYMENT PAGE"}
                  size="md"
                  isLoading={pageLoading}
                  isDisabled={formik.isValid || pageLoading ? false : true}
                  opacity={"1"}
                  border={"none"}
                  textHoverColor={"#fff"}
                  width="100%"
                  maxWidth="100%"
                  type="submit"
                />
              </Flex>
            </form>
          </FormikProvider>
          {showPaymentModal && (
            <PayWithFlutterwave
              email={givenData?.giverID?.email}
              amount={givenData?.amount?.Amount}
              reference={givenData?.reference}
              event={fundInfo.event}
              success={() => {
                onRequestClose();
                setShowPaymentModal(false);
                setShowSuccess(true);
                // setPageLoading(true);
              }}
            />
          )}
        </Box>
      </ModalCmp>
      <SuccessModal
        isOpen={showSuccess}
        onRequestClose={() => {
          window.location.reload();
          setShowSuccess(false);
        }}
        maxWidth="330px"
        showCloseIcon={false}
      />
    </>
  );
};

export default FundModal;
