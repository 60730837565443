import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { HistoryImg } from "assets/svgs";
import React from "react";
import NumberFormat from "react-number-format";

const ContributionLogDrawer: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  bgImage?: string;
  data?: any;
}> = ({ onClose, isOpen, data }) => {
  const ContributionItem: React.FC<{
    name: string;
    date: string;
    total: string | JSX.Element;
  }> = ({ name, date, total }) => {
    return (
      <>
        <Flex
          padding="0.6rem 0"
          fontFamily={"Plus Jakarta Sans"}
          justifyContent={"space-between"}
        >
          <Flex gap="10px" flex="0 0 50%">
            <Image
              width={"29px"}
              height="29px"
              src={HistoryImg}
              alt="History"
            />
            <Flex flexDir={"column"} gap="2px">
              <Text fontSize={"14px"} fontWeight="400" color={"#fff"}>
                {name}
              </Text>
              <Text
                fontSize={"12px"}
                fontWeight="400"
                color={"#FFF"}
                opacity="0.5"
              >
                {date}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDir={"column"} gap="2px" textAlign="right">
            <Text fontSize={"15px"} fontWeight="400" color={"#fff"}>
              {total}
            </Text>
          </Flex>
        </Flex>
      </>
    );
  };
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
        <DrawerOverlay />
        <DrawerContent bgColor={"#22242C"}>
          <DrawerCloseButton outline={"none"} boxShadow="none !important" />
          <DrawerBody pt="3rem">
            <Flex flexDir="column">
              {data &&
                data.map((v: any, _: number) => {
                  return (
                    <ContributionItem
                      name={v.contributor}
                      date={v.date}
                      total={
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={v.amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                        />
                      }
                    />
                  );
                })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ContributionLogDrawer;
