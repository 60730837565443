import {
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { dashboardData } from "data/dummy";
import { useAppDispatch, useAppSelector } from "hooks";
import { MenuItemCmp } from "Layout/Dashboard";
import React from "react";
import { useEffect, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import NumberFormat from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { dashboardActions, dashboardSelectors } from "store/slices";
import { wishlistActions, wishlistSelectors } from "store/slices/wishlistSlice";
import { useMeasure } from "react-use";
import { WeddingWishlistActiveScreen } from "./AddWishlist";
import { Wishlist } from "apis";

const LogoLoader = React.lazy(() => import("components/LogoLoader"));
const ButtonCmp = React.lazy(() => import("components/ButtonCmp/ButtonCmp"));
const EmptyCardCmp = React.lazy(() => import("components/EmptyCardCmp"));
const ActionModal = React.lazy(() => import("components/Modals/ActionModal"));
const ShareModal = React.lazy(() => import("components/Modals/ShareModal"));
const WishCardCmp = React.lazy(
  () => import("components/WishCardCmp/WishCardCmp")
);

const AWishlist: React.FC<{
  wishlistSlug: string;
  onClose: () => void;
  setActiveScreen: (activeScreen: WeddingWishlistActiveScreen) => void;
  wishlist: Wishlist;
}> = ({ wishlistSlug, onClose, wishlist, setActiveScreen }) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [addItemButtonRef, { width: buttonWidth }] = useMeasure<any>();
  const [singleEventContainerRef, { width: containerWidth }] =
    useMeasure<any>();

  const dispatch = useAppDispatch();

  const params = useParams();
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  const getAWishlistData = useAppSelector(wishlistSelectors.getAWishlist);

  const wishlistData = getAWishlistData?.payload || wishlist;

  const { getDashboard } = dashboardActions;
  const { getAWishlist, deleteWish, deleteAWishlist } = wishlistActions;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getAWishlist({ userID: userDetails?._id, slug: wishlistSlug! }))
      .then((res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          return setLoading(false);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug, userDetails?._id, dispatch, getAWishlist]);

  const navigate = useNavigate();

  const [shareModal, setShareModal] = useState(false);

  const currentDate: Date | any = new Date().getTime();

  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteWish, setShowDeleteWish] = useState(false);
  const [wishId, setWishId] = useState("");

  const cwMarginLeft = `calc(${containerWidth / 2}px - ${buttonWidth / 2}px)`;

  return (
    <>
      {loading ? (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      ) : (
        <>
          <Flex
            flexDir={"column"}
            gap="20px"
            ref={singleEventContainerRef}
            mb={"30px"}
            overflow={"hidden"}
          >
            <Flex justifyContent={"start"}>
              <Text
                textDecoration={"underline"}
                cursor={"pointer"}
                onClick={() => {
                  setActiveScreen(WeddingWishlistActiveScreen.ADD_WISHLIST);
                  onClose();
                }}
              >
                close and save
              </Text>
            </Flex>
            <Box
              position="relative"
              bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%),url(${wishlistData?.coverImage})`}
              backgroundSize={"cover"}
              backgroundPosition="center"
              backgroundRepeat={"no-repeat"}
              width={"100%"}
              height={{ base: "227px", md: "270px" }}
              display={"flex"}
              justifyContent="center"
              borderRadius={"20px"}
            >
              <Flex
                position={"absolute"}
                bottom="20px"
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                width="100%"
                px="1rem"
              >
                <Text
                  fontFamily={"Helvetica"}
                  fontSize={{ base: "1.25rem", md: "25px" }}
                  lineHeight={"1"}
                  fontWeight={"700"}
                  w={"70%"}
                >
                  {wishlistData?.title}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                gap="15px"
                position="absolute"
                right="15px"
                top="10px"
              >
                <HStack spacing={{ base: "0", md: "6" }}>
                  <Flex alignItems={"center"}>
                    <Menu>
                      <MenuButton
                        py={2}
                        transition="all 0.3s"
                        _focus={{ boxShadow: "none" }}
                      >
                        <HStack>
                          <Box
                            width="30px"
                            height="30px"
                            borderRadius={"50%"}
                            bgColor="rgba(0, 0, 0, 0.3)"
                            display={"flex"}
                            justifyContent="center"
                            alignItems={"center"}
                          >
                            <Box color={"#fff"}>
                              <Icon
                                as={FiMoreHorizontal}
                                width={"12px"}
                                height={"12px"}
                              />
                            </Box>
                          </Box>
                        </HStack>
                      </MenuButton>
                      <MenuList bg={"#16181D"} borderColor={"#22242c"}>
                        <MenuItemCmp
                          onClick={() => {
                            navigate(`/wishlist/${wishlistData?.slug}/edit`);
                          }}
                          name={"Edit"}
                        />
                        <MenuItemCmp
                          onClick={() => {
                            setShowDelete(true);
                          }}
                          name={"Delete"}
                        />
                      </MenuList>
                    </Menu>
                  </Flex>
                </HStack>
              </Flex>
            </Box>
            <Flex flexDir={"column"}>
              <Text
                mx={"auto"}
                mb={"17px"}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"18px"}
                lineHeight={"24px"}
                display={"flex"}
                alignItems={"center"}
              >
                your wishes{" "}
                <Badge
                  color={"#80BA7C"}
                  bg={"#0F441E"}
                  ml={"2"}
                  borderRadius={"50%"}
                  fontSize={"12px"}
                  fontWeight={500}
                  w={"22px"}
                  h={"22px"}
                  textAlign={"center"}
                >
                  {wishlistData?.gifts.length}
                </Badge>
              </Text>
              {!wishlistData?.gifts.length && (
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  justifyContent={{ md: "space-between" }}
                  flexWrap={"wrap"}
                >
                  {dashboardData.map((item) => (
                    <EmptyCardCmp
                      minW="33%"
                      widthMd="33%"
                      flex="0 0 33%"
                      key={item.id}
                    />
                  ))}
                </Flex>
              )}
              {(wishlistData?.gifts?.length ?? 0) > 0 && (
                <Flex
                  flexDir={{ base: "row", md: "row" }}
                  justifyContent={{
                    md: "flex-start",
                  }}
                  flexWrap={"wrap"}
                  gap={{ base: "5px", md: "2px" }}
                >
                  {wishlistData?.gifts.map((v: any, i: number) => {
                    return (
                      <WishCardCmp
                        key={i}
                        minW="33%"
                        widthMd="33%"
                        flex="0 0 33%"
                        widthBase="48%"
                        minWidthBase="48%"
                        flexBase="0 0 48%"
                        height="325px"
                        wishTitle={v.title}
                        percentage={Math.floor(
                          (v?.grossPaid?.Amount / v?.netCost?.Amount) * 100
                        )}
                        wishPrice={
                          <NumberFormat
                            thousandSeparator
                            value={v.netCost.Amount}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        }
                        grossPaid={
                          <NumberFormat
                            thousandSeparator
                            value={v.grossPaid.Amount}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        }
                        coverImage={v.coverImage}
                        isFulfilled={v.isFulfilled}
                        onDeleteClick={() => {
                          setWishId(v._id);
                          setShowDeleteWish(true);
                        }}
                        onEditClick={() => {
                          navigate(
                            `/wishlist/${wishlistData.slug}/edit-gift/${v._id}`
                          );
                        }}
                      />
                    );
                  })}
                  {dashboardData
                    .slice(wishlistData?.gifts.length)
                    .map((_: any, index: number) => (
                      <EmptyCardCmp
                        key={index}
                        minW="33%"
                        widthMd="33%"
                        flex="0 0 33%"
                        widthBase="48%"
                        minWidthBase="48%"
                        flexBase="0 0 48%"
                        height="325px"
                      />
                    ))}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Box
            ref={addItemButtonRef}
            fontFamily={"Plus Jakarta Sans"}
            textAlign={{ base: "center" }}
            display="flex"
            alignItems={{ base: "center" }}
            flexDir="column"
            gap="10px"
            position="fixed"
            bottom="10px"
            marginLeft={cwMarginLeft}
          >
            {!wishlistData?.gifts.length && (
              <>
                {Math.abs(
                  (currentDate -
                    new Date((wishlistData as any).createdAt).getTime()) /
                    1000
                ) < 5 && (
                  <Text
                    fontSize={"18px"}
                    fontWeight="400"
                    fontFamily={"Plus Jakarta Sans"}
                    color="#fff"
                  >
                    Wishlist created 🎉
                  </Text>
                )}
                <Text
                  fontFamily={"Plus Jakarta Sans"}
                  color="rgba(255, 255, 255, 0.8)"
                  fontSize={"13px"}
                  width="180px"
                >
                  Click the button below to add items to your wishlist or skip
                  for now
                </Text>
              </>
            )}
            <Box>
              <ButtonCmp
                text={"Add Item"}
                size="md"
                padding="0rem 0rem"
                // isLoading={loggingIn}
                opacity={"1"}
                border={"none"}
                backgroundColor="#EDFFE5"
                textHoverColor={"#000"}
                color={"#000"}
                width="130px"
                height="50px"
                maxWidth="100%"
                type="submit"
                borderRadius="29px"
                onClick={() => {
                  setActiveScreen(WeddingWishlistActiveScreen.ADD_GIFT);
                }}
              />
            </Box>
          </Box>
          {Object.keys(userDetails).length && (
            <ShareModal
              context="addwishlist"
              isOpen={shareModal}
              onRequestClose={() => {
                setShareModal(false);
              }}
              customFn={() => {
                // console.log("a wishlist", userDetails);
                if (userDetails) {
                  dispatch(
                    getAWishlist({
                      userID: userDetails?._id!,
                      slug: params.slug!,
                    })
                  );
                }
              }}
              wishlist={wishlistData}
              maxWidth="400px"
              showCloseIcon={false}
            />
          )}
          <ActionModal
            isOpen={showDelete}
            onRequestClose={() => {
              setShowDelete(false);
            }}
            maxWidth="400px"
            showCloseIcon={false}
            actionTitle="delete wishlist?"
            actionDesc="You will not be able to recover the wishlist after you confirm this action"
            yesText="YES, DELETE"
            noText="NO, CANCEL"
            yesAction={() => {
              dispatch(deleteAWishlist({ wishlistID: wishlistData?._id! }))
                .then((res: any) => {
                  const response = res;
                  if (
                    response &&
                    response.payload &&
                    response.payload.statusCode === 200
                  ) {
                    dispatch(getDashboard());
                    toast({
                      status: "success",
                      description: "Wishlist deleted successfully",
                    });
                    return navigate("/");
                  }
                })
                .catch(() => {
                  toast({
                    status: "error",
                    description: "There is an error",
                  });
                });
              setShowDelete(false);
            }}
            noAction={() => {
              setShowDelete(false);
            }}
          />
          <ActionModal
            isOpen={showDeleteWish}
            onRequestClose={() => {
              setShowDeleteWish(false);
            }}
            maxWidth="400px"
            showCloseIcon={false}
            actionTitle="delete wish?"
            actionDesc="You will not be able to recover the wish after you confirm this action"
            yesText="YES, DELETE"
            noText="NO, CANCEL"
            yesAction={() => {
              dispatch(deleteWish({ wishID: wishId }))
                .then((res: any) => {
                  const response = res;
                  if (
                    response &&
                    response.payload &&
                    response.payload.statusCode === 200
                  ) {
                    dispatch(getDashboard());
                    toast({
                      status: "success",
                      description: "Wish deleted successfully",
                    });
                    return navigate("/");
                  }
                })
                .catch(() => {
                  toast({
                    status: "error",
                    description: "There is an error",
                  });
                });
              setShowDeleteWish(false);
            }}
            noAction={() => {
              setShowDeleteWish(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default AWishlist;
