import { WeddingEventType } from "apis";

export const makeEventTypeReadable = 
(
  eventType: WeddingEventType | string
) => {
  if (eventType === WeddingEventType.WHITE_WEDDING) return "White Wedding";
  if (eventType === WeddingEventType.TRADITIONAL_WEDDING) return "Traditional Wedding";
  if (eventType === WeddingEventType.NIKAI) return "Nikai";
  if (eventType === WeddingEventType.AFTER_PARTY) return "After Party";
  if (eventType === WeddingEventType.ENGAGEMENT) return "Engagement";
  if (eventType === WeddingEventType.RECEPTION) return "Reception";
  if (eventType === WeddingEventType.OTHERS) return "Others";
  return eventType;
};
