import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetOrgCategory,
  LoginResponse,
  MRequest,
  OTPResponse,
  PostLogin,
  PostOtp,
  PostResetPassword,
  PostSignUp,
  PostVerify,
  ResetPasswordResponse,
  SignUpResponse,
  VerifyResponse,
} from "apis";
import { RootState } from "store/reducers";

const entity = "auth";

const loginUser = createAsyncThunk(
  "auth/login",
  async (data: typeof PostLogin.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostLogin.RES>(
        PostLogin.ROUTE,
        // "https://unboxd-web-services.onrender.com/api/v2/auth/signin-user",
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// const checkUsernameExists = createAsyncThunk(
//   "auth/userExists",
//   async (data: typeof GetUsernameExists.BODY) => {
//     const res = await MRequest.get<typeof GetUsernameExists.RES>(
//       `${GetUsernameExists.ROUTE}?slug=${data}`
//     );
//     return res.data;
//   }
// );

const getOrganisationCategory = createAsyncThunk(
  "auth/getOrgCategory",
  async () => {
    const res = await MRequest.get<typeof GetOrgCategory.RES>(
      GetOrgCategory.ROUTE
    );
    return res.data;
  }
);

const signupUser = createAsyncThunk(
  "auth/signUp",
  async (data: typeof PostSignUp.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostSignUp.RES>(
        `${PostSignUp.ROUTE}/signup-user`,
        // `https://unboxd-web-services.onrender.com/api/v2/auth/signin-user`,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const signupUserOrganization = createAsyncThunk(
  "auth/signUpOrg",
  async (
    data: Omit<typeof PostSignUp.BODY, "context">,
    { rejectWithValue }
  ) => {
    try {
      const res = await MRequest.post<typeof PostSignUp.RES>(
        `${PostSignUp.ROUTE}/signup-organisation-user`,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const verifyUser = createAsyncThunk(
  "auth/verify",
  async (data: typeof PostVerify.BODY) => {
    const res = await MRequest.post<typeof PostVerify.RES>(
      PostVerify.ROUTE,
      data
    );
    return res.data;
  }
);

const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (data: typeof PostOtp.BODY) => {
    const res = await MRequest.post<typeof PostOtp.RES>(PostOtp.ROUTE, data);
    return res.data;
  }
);

const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: typeof PostResetPassword.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostResetPassword.RES>(
        PostResetPassword.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export type AuthInitialStateType = {
  loginResponse: LoginResponse | null;
  usernameExistsResponse: string | null;
  signupResponse: SignUpResponse | null;
  signupOrgResponse: any;
  verifyRespomse: VerifyResponse | null;
  otpResponse: OTPResponse | null;
  resetPasswordResponse: ResetPasswordResponse | null;
  token: Pick<LoginResponse, "token"> | null;
  getOrganisationCategoryResponse: any | null;
};

const initialState: AuthInitialStateType = {
  loginResponse: null,
  usernameExistsResponse: null,
  signupResponse: null,
  signupOrgResponse: null,
  verifyRespomse: null,
  otpResponse: null,
  resetPasswordResponse: null,
  token: null,
  getOrganisationCategoryResponse: null,
};

const authSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    storeToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loginUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loginResponse = action.payload;
      }
    );

    // builder.addCase(
    //   checkUsernameExists.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.usernameExistsResponse = action.payload;
    //   }
    // );

    builder.addCase(
      signupUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.signupResponse = action.payload;
      }
    );

    builder.addCase(
      verifyUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.verifyRespomse = action.payload;
      }
    );

    builder.addCase(sendOTP.fulfilled, (state, action: PayloadAction<any>) => {
      state.otpResponse = action.payload;
    });

    builder.addCase(
      resetPassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.resetPasswordResponse = action.payload;
      }
    );

    builder.addCase(
      signupUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.signupResponse = action.payload;
      }
    );
    builder.addCase(
      signupUserOrganization.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.signupOrgResponse = action.payload;
      }
    );
    builder.addCase(
      signupUserOrganization.rejected,
      (state, action: PayloadAction<any>) => {
        state.signupOrgResponse = action.payload;
      }
    );
    builder.addCase(
      getOrganisationCategory.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getOrganisationCategoryResponse = action.payload;
      }
    );
    builder.addCase(
      getOrganisationCategory.rejected,
      (state, action: PayloadAction<any>) => {
        state.getOrganisationCategoryResponse = action.payload;
      }
    );
    builder.addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
      state.loginResponse = action.payload;
    });
  },
});

export const authActions = {
  ...authSlice.actions,
  loginUser,
  signupUser,
  signupUserOrganization,
  verifyUser,
  sendOTP,
  resetPassword,
  getOrganisationCategory,
  // checkUsernameExists,
};

export const authSelectors = {
  getAccessToken: (state: RootState) => state.auth.token,
  getUserDetails: (state: RootState) => state.auth.loginResponse?.payload!,
  getSignupResponse: (state: RootState) => state.auth.signupResponse,
  getSignupOrgResponse: (state: RootState) => state.auth.signupOrgResponse,
  getVerifyResponse: (state: RootState) => state.auth.verifyRespomse,
  getOTPResponse: (state: RootState) => state.auth.otpResponse,
  getResetPasswordResponse: (state: RootState) =>
    state.auth.resetPasswordResponse,
  getOrganisationCategoryResponse: (state: RootState) =>
    state.auth.getOrganisationCategoryResponse,
};

export const authReducer = authSlice.reducer;
