import { Box, Flex, Img, Text } from "@chakra-ui/react";
import { CameraIcon, CropIcon } from "assets/svgs";
import ImageModal from "components/Modals/ImageModal";
import { useMeasure } from "react-use";

interface IUploadImage {
  showImageModal: boolean;
  image: string;
  instruction: string;
  setShowImageModal: (data: boolean) => void;
  setShowImageCropper: (data: boolean) => void;
  fromUnsplash: (data: any) => void;
  fromGallery: (data: any) => void;
  placeholder: any;
}

const UploadImage: React.FC<IUploadImage> = ({
  fromGallery,
  fromUnsplash,
  image,
  instruction,
  placeholder,
  showImageModal,
  setShowImageModal,
  setShowImageCropper,
}) => {
  // console.log(["image", image]);
  const [imgContainerRef, { width: containerWidth }] = useMeasure<any>();
  return (
    <Flex w={"full"} maxW={{ md: "345px" }}>
      <Box
        ref={imgContainerRef}
        position="relative"
        // bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${
        //   `https://ng.jumia.is/unsafe/fit-in/500x500/filters:fill(white)/product/19/042576/1.jpg?0343`
        // })`}
        bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${
          image ? image : placeholder
        })`}
        backgroundSize="cover"
        backgroundRepeat={"no-repeat"}
        w={"full"}
        maxW="345px"
        minW={"216px"}
        maxHeight={"225px"}
        borderRadius={"20px"}
        border={"1px solid #4a4a4a"}
        height={`calc(${containerWidth}px * 0.6522)`}
      >
        <Flex
          justifyContent={"start"}
          alignItems="center"
          px="1rem"
          position="absolute"
          width="100%"
          bottom="18px"
        >
          <Flex
            borderRadius={"50%"}
            h="45px"
            w="45px"
            mr="1rem"
            bgColor={"rgba(0, 0, 0, 0.3)"}
            backdropFilter="blur(15.1324px)"
            // bgColor={"rgba(217, 217, 217, 0.5)"}
            // backdropFilter="blur(30px)"
            justifyContent={"center"}
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setShowImageModal(true);
            }}
          >
            <Img src={CameraIcon} alt="Upload" width="20px" height="20px" />
          </Flex>
          <Text>{instruction}</Text>
          {image && (
            <Flex
              borderRadius={"50%"}
              h="45px"
              w="45px"
              ml="5.2rem"
              bgColor={"rgba(0, 0, 0, 0.3)"}
              backdropFilter="blur(15.1324px)"
              justifyContent={"center"}
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                setShowImageCropper(true);
              }}
            >
              <Img src={CropIcon} alt="Upload" width="25px" height="25px" />
            </Flex>
          )}
        </Flex>
        <ImageModal
          isOpen={showImageModal}
          fromUnsplash={fromUnsplash}
          fromGallery={fromGallery}
          onRequestClose={() => {
            setShowImageModal(false);
          }}
          maxWidth="400px"
          showCloseIcon={false}
        />
      </Box>
    </Flex>
  );
};

export default UploadImage;
