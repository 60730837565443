import { Box, Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";
import SupportSiteCmp from "components/SupportSiteCmp/SupportSiteCmp";
import { FormikProvider, useFormik } from "formik";
import { useAppDispatch } from "hooks";
import React, { useState } from "react";
import Jumia from "../../../src/assets/supportedSites/JMIA-356fb835.png";
import Konga from "../../../src/assets/supportedSites/konga_logo.png";
import RovingHeights from "../../../src/assets/supportedSites/rovingheights.png";
import Amazon from "../../../src/assets/supportedSites/amazon_logo.jpg";
import SlotNG from "../../../src/assets/supportedSites/slot_logo.png";
import Hingees from "../../../src/assets/supportedSites/hingees-logo.png";
import Jiji from "../../../src/assets/supportedSites/Jiji_Logo.png";
import ThegiftfairyNG from "../../../src/assets/supportedSites/Thegiftfairy.ng_logo.jpg";
import Shopbcode from "../../../src/assets/supportedSites/shopbcode1_logo.png";
import Taeillio from "../../../src/assets/supportedSites/taeillio1_logo.png";
import Danglifestyle from "../../../src/assets/supportedSites/danglifestyle_logo.png";
import Notjustpulp from "../../../src/assets/supportedSites/njp_logo.png";
import SuccessModal from "./Contributor/SuccessModal";
import { SpinnerCircular } from "spinners-react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { wishlistActions } from "store/slices";

const supportedSites = [
  {
    image: Jumia,
    url: "www.jumia.com",
    category1: "electronics",
    category2: "fashion",
  },
  {
    image: Konga,
    url: "www.konga.com",
    category1: "electronics",
    category2: "household",
  },
  {
    image: RovingHeights,
    url: "www.rovingheights.com",
    category1: "books",
    category2: "literature",
  },
  {
    image: Amazon,
    url: "www.amazon.com",
    category1: "electronics",
    category2: "fashion",
  },
  {
    image: SlotNG,
    url: "www.slot.ng",
    category1: "electronics",
    category2: "phones",
  },
  {
    image: Notjustpulp,
    url: "www.notjustpulp.com",
    category1: "cards",
    category2: "household",
  },
  {
    image: Jiji,
    url: "www.jiji.com.ng",
    category1: "household",
    category2: "electronics",
  },
  {
    image: Hingees,
    url: "www.hinges.com",
    category1: "stationary",
    category2: "fashion",
  },
  {
    image: ThegiftfairyNG,
    url: "www.thegiftfairyng.com",
    category1: "gift-card",
    category2: "perfume",
  },
  {
    image: Danglifestyle,
    url: "www.danglifestyle.co",
    category1: "cosmetics",
    category2: "household",
  },
  {
    image: Shopbcode,
    url: "www.shopbcode.com",
    category1: "household",
    category2: "fashion",
  },
  {
    image: Taeillio,
    url: "www.taeillo.com",
    category1: "furniture",
    category2: "household",
  },
];

const SupportModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({ isOpen, onRequestClose, showCloseIcon }) => {
  const [circleLoading, setCircleLoading] = useState(false);
  const [urlValue] = useState<string>("");
  const { createSuggestedUrl } = wishlistActions;

  const [showSuccess, setShowSuccess] = useState(false);
  const [, setResponse] = useState<any>({});

  const dispatch = useAppDispatch();

  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const formik = useFormik<{
    url: string;
  }>({
    initialValues: {
      url: "",
    },

    onSubmit: (values, { resetForm }) => {
      setCircleLoading(true);
      dispatch(createSuggestedUrl(values))
        .then((res: any) => {
          const response = res.payload;
          setCircleLoading(false);
          if ([400, 500].includes(response.statusCode) && response.message) {
            resetForm();
            toast({
              status: "error",
              description: "Invalid website link.",
            });
          } else if (
            response &&
            response.payload &&
            (response.statusCode === 200 || response.statusCode === 201)
          ) {
            resetForm();
            setResponse(res);
            toast({
              status: "success",
              description: "Website submitted successfully.",
            });
          }
        })

        .catch(() => {
          toast({
            status: "error",
            description: "Invalid website link.",
          });
        })
        .finally(() => setCircleLoading(false));
    },
  });
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={() => {
          onRequestClose();
        }}
        contentStyles={{
          border: "1px solid #000",
          maxHeight: "100vh",
          width: "339px",
        }}
        shouldFocusAfterRender={false}
        contentLabel="Fund"
        id="fund"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Flex marginLeft={"20px"} flexDirection={"column"}>
          <Text fontSize={"24px"} fontWeight={"600"} marginBottom={"15px"}>
            Supported Websites
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"} width={"300px"}>
            We have integrated 13 websites for top wishlist items. We are
            rapidly extending to more websites.
          </Text>
        </Flex>

        <Box
          flexDir={"column"}
          position={"relative"}
          display="flex"
          gap="20px"
          textAlign={"center"}
          padding="2rem 1rem"
          fontFamily={"Plus Jakarta Sans"}
          maxHeight="53vh"
          overflowY="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {supportedSites.map((product, index) => (
            <SupportSiteCmp
              key={`${index}-xyz`}
              productImage={product.image}
              productUrl={product.url}
              productCategory1={product.category1}
              productCategory2={product.category2}
            />
          ))}
        </Box>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            style={{ width: "100%" }}
          >
            <Flex
              flexDirection={{ base: "column", lg: "column" }}
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                bottom={{ base: "16%", md: "18%", lg: "16%" }}
                position={"fixed"}
                width={"305px"}
                height={"175px"}
                zIndex={"1"}
                background={
                  "linear-gradient(180deg, #1E2229 0%, rgba(30, 34, 41, 0) 100%)"
                }
                transform={"rotate(-180deg)"}
              ></Box>
              <Text fontSize={"12px"} zIndex={"2"}>
                Suggest a site for support
              </Text>
              <Box
                width={{ base: "307px" }}
                style={{
                  position: "relative",
                  margin: "10px",
                }}
              >
                <FormikFormInput
                  type="text"
                  placeholder="Website Link"
                  name="url"
                  value={urlValue}
                  disabled={false}
                  showMessage={true}
                />
                <IconButton
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#16181d",
                    position: "absolute",
                    height: "40px",
                    width: "40px",
                    top: "0",
                    right: "0",
                    marginTop: "15px",
                    marginRight: "15px",
                    zIndex: "2",
                  }}
                  aria-label={""}
                  type={"submit"}
                  icon={
                    <ArrowRightIcon
                      color="#fff"
                      h={4}
                      w={4}
                      cursor="pointer"
                      style={{
                        color: "#CEFABB",
                      }}
                    />
                  }
                />

                {circleLoading && (
                  <SpinnerCircular
                    style={{
                      backgroundColor: "#16181d",
                      color: "#CEFABB",
                      position: "absolute",
                      height: "80px",
                      width: "90px",
                      top: "0",
                      right: "0",
                      marginRight: "-30px",
                      marginTop: "-10px",
                      zIndex: "2",
                      transform: "scale(0.5, 0.5) translateX(-50%)",
                    }}
                  />
                )}
              </Box>
              {/* <Text>
                {urlError !== "" ? (
                  <p style={{ color: "#FF8585" }}>{urlError}</p>
                ) : (
                  <p style={{ color: "#A9F389" }}>{urlSuccessMsg}</p>
                )}
              </Text> */}
            </Flex>
          </form>
        </FormikProvider>
      </ModalCmp>
      <SuccessModal
        isOpen={showSuccess}
        onRequestClose={() => {
          window.location.reload();
          setShowSuccess(false);
        }}
        maxWidth="330px"
        showCloseIcon={false}
      />
    </>
  );
};

export default SupportModal;
