import * as yup from "yup";
import NumberFormat from "react-number-format";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WALLETVALIDATIONS } from "validators/WalletValidator";

const ButtonCmp = React.lazy(() => import("components/ButtonCmp/ButtonCmp"));
const ActionModal = React.lazy(() => import("components/Modals/ActionModal"));
const FormikFormInput = React.lazy(
  () => import("components/InputsCmp/CustomInputCmp")
);

const requestPayoutValidationSchema = yup.object({
  amount: WALLETVALIDATIONS.amount,
});

const RequestPayout = () => {
  const [isExit, setIsExit] = useState(false);
  const [validateError, setValidateError] = useState("");
  const [validAmount, setValidAmount] = useState(false);
  const { wishlistID } = useParams<{ wishlistID: string }>();
  const navigate = useNavigate();

  const location: any = useLocation();

  useEffect(() => {
    if (!location.state) {
      navigate("/wallet");
    }
  }, [location.state, navigate]);

  const formik = useFormik<{ amount: string; balance: string }>({
    initialValues: {
      amount: "",
      balance: location?.state.walletBalance,
    },
    enableReinitialize: true,
    validationSchema: requestPayoutValidationSchema,
    validate: (values) => {
      if (values.amount > values.balance) {
        setValidAmount(false);
        setValidateError("entered amount is more than balance");
      } else if (values.amount < "120") {
        setValidAmount(false);
        setValidateError("entered amount is less than 120");
      } else {
        setValidAmount(true);
        setValidateError("");
      }
    },
    onSubmit: (values) => {
      navigate(`/choose-account/${wishlistID}`, {
        state: { amount: values.amount, type: location?.state?.type },
      });
    },
  });
  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            color="#fff"
            h={8}
            w={8}
            cursor="pointer"
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box maxWidth="360px" width="100%" margin="0 auto" pt="1rem">
              <Box display={"flex"} flexDirection="column" gap="1px">
                <Box textAlign={"center"} fontFamily="Plus Jakarta Sans">
                  <Text
                    color={"#fff"}
                    fontWeight="700"
                    fontSize={"30px"}
                    pb="0.5rem"
                  >
                    request payout
                  </Text>
                  <Text
                    color="rgba(255, 255, 255, 0.8)"
                    fontSize={"16px"}
                    pb="1rem"
                  >
                    How much do you want to withdraw?
                  </Text>
                </Box>
                <Box>
                  <FormikFormInput
                    placeholder="enter amount"
                    name="amount"
                    type={"number"}
                    disabled={false}
                    showMessage={true}
                    onChange={formik.handleChange}
                  />
                </Box>
              </Box>
              <Flex
                color={"rgba(255, 255, 255, 0.5)"}
                fontWeight="400"
                fontSize="12px"
                fontFamily={"Plus Jakarta Sans"}
                justifyContent={"space-between"}
                pb="2rem"
              >
                <Text color={"#FF8585"}>{validateError}</Text>
                <Text>
                  balance{" "}
                  <NumberFormat
                    thousandSeparator
                    prefix="₦"
                    value={location.state.walletBalance}
                    decimalScale={2}
                    defaultValue="Loading"
                    allowNegative={false}
                    placeholder="Loading"
                    displayType="text"
                    disabled
                  />
                </Text>
              </Flex>
              <Box width={{ base: "100%" }}>
                <ButtonCmp
                  text={"PROCEED"}
                  size="md"
                  padding="1.5rem 3rem"
                  isDisabled={formik.isValid && validAmount ? false : true}
                  opacity={"1"}
                  border={"none"}
                  textHoverColor={"#fff"}
                  width="100%"
                  maxWidth="100%"
                  type="submit"
                />
              </Box>
              <Box display="flex" justifyContent={"center"} pt="1.5rem">
                <Text
                  width="210px"
                  color="rgba(255, 255, 255, 0.7)"
                  fontWeight={"400"}
                  fontSize="13px"
                  textAlign={"center"}
                >
                  having issues?{" "}
                  <span style={{ color: "#fff" }}>Contact us</span>
                </Text>
              </Box>
            </Box>
          </form>
        </FormikProvider>
        <ActionModal
          isOpen={isExit}
          onRequestClose={() => {
            setIsExit(false);
          }}
          maxWidth="400px"
          showCloseIcon={false}
          actionTitle="are you sure?"
          actionDesc="You will lose progress on this item if you don’t save it before you
          go to another page"
          yesText="YES, EXIT"
          noText="NO, CANCEL"
          yesAction={() => {
            navigate(-1);
          }}
          noAction={() => {
            setIsExit(false);
          }}
        />
      </Box>
    </>
  );
};

export default RequestPayout;
