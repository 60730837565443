import { Box, Text } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";
import React from "react";

const ContributorWelcomeModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  welcomeNote: string;
  wishlistImg: string;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  welcomeNote,
  wishlistImg,
}) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px", border: "none" }}
        shouldFocusAfterRender={false}
        contentLabel="Welcome"
        id="welcome"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box textAlign={"center"} fontFamily={"Plus Jakarta Sans"}>
          <Box
            position="relative"
            bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 37.98%, #16181D 100%), linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${wishlistImg})`}
            backgroundSize={"cover"}
            backgroundPosition="center"
            backgroundRepeat={"no-repeat"}
            width={"100%"}
            height="150px"
            display={"flex"}
            justifyContent="center"
            borderRadius={"20px"}
          ></Box>
          <Box fontWeight={"400"} color="#fff" textAlign={"left"} p="1rem">
            <Text pb="0.5rem" fontSize={"20px"}>
              Welcome 🎉
            </Text>
            <Text fontSize={"16px"}>{welcomeNote}</Text>
            <Box py="1rem">
              <ButtonCmp
                text={
                  localStorage.getItem("userType") === "Organisation"
                    ? "OPEN COLLECTION"
                    : "OPEN WISHLIST"
                }
                size="md"
                padding="1rem"
                borderRadius="16px"
                opacity={"1"}
                border={"none"}
                height="50px"
                textHoverColor={"#fff"}
                width="100%"
                maxWidth="100%"
                type="submit"
                onClick={onRequestClose}
              />
            </Box>
          </Box>
        </Box>
      </ModalCmp>
    </>
  );
};

export default ContributorWelcomeModal;
