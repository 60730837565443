import { Flex, Image, Text } from "@chakra-ui/react";
import { WithdrawalImg } from "assets/svgs";
import StatusCardCmp from "components/StatusCardCmp";

const WithdrawalHistoryCmp: React.FC<{
  accountNo: string;
  bankName: string;
  date: string;
  amount: any;
  status: string;
}> = ({ accountNo, bankName, date, amount, status }) => {
  return (
    <>
      <Flex
        padding="0.6rem 0"
        fontFamily={"Plus Jakarta Sans"}
        justifyContent={"space-between"}
      >
        <Flex gap="10px" flex="0 0 50%">
          <Image
            width={"29px"}
            height="29px"
            src={WithdrawalImg}
            alt="History"
          />
          <Flex flexDir={"column"} gap="2px">
            <Text fontSize={"16px"} fontWeight="400" color={"#fff"}>
              to {accountNo}
            </Text>
            <Text
              fontSize={"12px"}
              fontWeight="400"
              color={"#fff"}
              opacity="0.5"
            >
              {bankName}
            </Text>
            <Text fontSize={"12px"} fontWeight="400" color={"#FFF"}>
              {date}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir={"column"} gap="2px" textAlign="right">
          <Text fontSize={"16px"} fontWeight="400" color={"#fff"}>
            ₦{amount}
          </Text>
          <StatusCardCmp
            bgcolor={
              status === "unsuccessful"
                ? "#EE9C9C"
                : status === ("pending" || "processing")
                ? "#F4DF82"
                : "#285433"
            }
            text={status}
            textColor={
              status === ("unsuccessful" || "processing" || "pending")
                ? "#000"
                : "#fff"
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

export default WithdrawalHistoryCmp;
