import { Button } from "@chakra-ui/react";
import SpinnerCmp from "components/SpinnerCmp/SpinnerCmp";
import React from "react";

const ButtonCmp: React.FC<{
  margin?: string;
  padding?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  fontSize?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  color?: string;
  borderRadius?: string;
  fontWeight?: string;
  opacity?: string;
  size?: "lg" | "md" | "sm" | "xs";
  border?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  textHoverColor?: string;
  backgroundHoverColor?: string;
  borderHoverState?: string;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: (e?: any) => void;
  text: any;
}> = ({
  margin,
  padding,
  size,
  color,
  backgroundColor,
  isDisabled,
  isLoading,
  fontSize,
  borderRadius,
  fontWeight,
  opacity,
  border,
  width,
  maxWidth,
  height,
  textHoverColor,
  backgroundHoverColor,
  borderHoverState,
  type,
  onClick,
  text,
}) => {
  return (
    <Button
      fontFamily={"Plus Jakarta Sans"}
      _focus={{ outline: "none", boxShadow: "none" }}
      _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
      type={type || "submit"}
      margin={margin || "0"}
      padding={padding || "1rem 2rem"}
      size={size || "md"}
      outline="none"
      color={color || "#fff"}
      backgroundColor={backgroundColor || "#4DB168"}
      isDisabled={isDisabled}
      isLoading={isLoading}
      fontSize={fontSize || "15px"}
      borderRadius={borderRadius || "18px"}
      fontWeight={fontWeight || 400}
      opacity={opacity || "0"}
      border={border || "none"}
      width={width || "fit-content"}
      height={height || "64px"}
      maxWidth={maxWidth || "fit-content"}
      _hover={{
        color: `${textHoverColor || "#fff"}`,
        backgroundColor: `${backgroundHoverColor || "#4DB168d7"}`,
        border: `${borderHoverState || "none"}`,
      }}
      _active={{
        color: `${textHoverColor}`,
        backgroundColor: `${backgroundHoverColor || "#4DB168d7"}`,
        border: `${borderHoverState || "none"}`,
      }}
      onClick={onClick}
    >
      {isLoading ? (
        <>
          <SpinnerCmp
            enabled={true}
            color={"#000"}
            size={20}
            secondaryColor={"#fff"}
          />
        </>
      ) : (
        text
      )}
    </Button>
  );
};

export default ButtonCmp;
