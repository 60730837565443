import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "./CustomCalendar.css";
import { getDayAndTimeFromDate } from "utils/date";

const CustomDateCmp: React.FC<{
  errorMessage?: string;
  showMessage?: boolean;
  borderRadius?: string;
  background?: string;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  value?: Date | null;
  onChangeAction: (value: Date) => void;
}> = ({
  errorMessage,
  showMessage,
  borderRadius,
  background,
  placeholder,
  name,
  disabled,
  onChangeAction,
  value,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <>
      <Box
        onClick={() => {
          setShowCalendar(!showCalendar);
        }}
        cursor="pointer"
        pointerEvents={disabled ? "none" : "unset"}
      >
        <Box
          padding={"0.8rem 1rem"}
          className={name}
          pointerEvents={disabled ? "none" : "unset"}
          // _disabled={{ pointerEvents: disabled ? "none" : "" }}
          h="64px"
          outline={"none"}
          borderRadius={borderRadius || "18px"}
          background={background || "#16181D"}
          border={errorMessage ? "1px solid #FF8585 !important" : `none`}
          display={"flex"}
          alignItems={"center"}
        >
          <Text
            color={"#fff"}
            fontSize="14px"
            opacity={"50%"}
            fontWeight="400"
            fontFamily={"Plus Jakarta Sans"}
          >
            {
              value ?
              getDayAndTimeFromDate(value).day.toDateString().substring(0, 15)
            : placeholder
            }
          </Text>
        </Box>
        <Box textAlign={"left"} padding="0.3rem 0">
          <Text color="#FF8585" fontSize={"12px"} fontWeight="400" pl="1rem">
            {showMessage ? errorMessage : ""}
          </Text>
        </Box>
      </Box>
      <Box position={"relative"}>
        <Box
          w={'350px'}
          zIndex={"30"}
          position={"absolute"}
          display={showCalendar ? "unset" : "none"}
        >
          <Calendar
            onChange={(val: any) => {
              onChangeAction(val);
              setShowCalendar(false);
            }}
            minDate={new Date()}
            value={value}
            className={"unboxd-calendar"}
          />
        </Box>
      </Box>
    </>
  );
};

export default CustomDateCmp;
