import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetDrawer, GetDrawerResponse, MRequest, SearchDrawer, PostCreateAdmitted, CreateAdmittedResponse } from "apis";
import { RootState } from "store/reducers";

const entity = "drawer";

// const getDrawer = createAsyncThunk("drawer/get", async () => {
//   const res = await MRequest.get<typeof GetDrawer.RES>(GetDrawer.ROUTE);
//   console.log("res", res);
//   return res.data;
// });

const getDrawer = createAsyncThunk("drawer/get", async (id: string) => {
  const res = await MRequest.get<typeof GetDrawer.RES>(
    `${GetDrawer.ROUTE}?id=${id}`
  );
  return res.data;
});

type searchDrawerParams = {
  id: string;
  search: string;
  searchType: "all" | "celebrant" | "guest";
};
const searchDrawer = createAsyncThunk(
  "drawer/get",
  async ({ id, search, searchType }: searchDrawerParams) => {
    let searchParams = "";
    id && (searchParams += `id=${id}`);
    search && (searchParams += `&search=${search}`);
    searchType && (searchParams += `&searchType=${searchType}`);

    const res = await MRequest.get<typeof SearchDrawer.RES>(
      `${SearchDrawer.ROUTE}?${searchParams}`
    );
    return res.data;
  }
);

const createAdmitted = createAsyncThunk(
  "drawer/post",
  async (data: typeof PostCreateAdmitted.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof PostCreateAdmitted.RES>(
        PostCreateAdmitted.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: DrawerInitialStateType = {
  getDrawerResponse: null,
  createAdmittedResponse: null,
};

const drawerSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDrawer.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getDrawerResponse = action.payload;
      }
    );
    builder.addCase(getDrawer.rejected, (state, action: PayloadAction<any>) => {
      state.getDrawerResponse = action.payload;
    });
    builder.addCase(
      createAdmitted.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.createAdmittedResponse = action.payload;
      }
    );
    builder.addCase(createAdmitted.rejected,
       (state, action: PayloadAction<any>) => {
      state.createAdmittedResponse = action.payload;
    });
  },
});



export type DrawerInitialStateType = {
  getDrawerResponse: GetDrawerResponse | null;
createAdmittedResponse: CreateAdmittedResponse | null;

};

export const drawerActions = {
  ...drawerSlice.actions,
  getDrawer,
  searchDrawer,
  createAdmitted,

};

export const drawerSelectors = {
  getDrawer: (state: RootState) => state.dashboard.getDashboardResponse,
  createAdmitted: (state: RootState) => state.dashboard.getDashboardResponse,
};

export const drawerReducer = drawerSlice.reducer;
