import { ArrowBackIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { GiftType } from "apis";
import { ItemPlaceholder } from "assets/svgs";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ActionModal from "components/Modals/ActionModal";
import AddItemPricingModal from "components/Modals/AddItemPricingModal";
import ImageCropperModal from "components/Modals/ImageCropperModal";
import UploadImage from "components/UploadImage";
import { FormikProvider, useFormik } from "formik";
import { useAppDispatch } from "hooks";
import { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { dashboardActions, wishlistActions } from "store/slices";
import trimWords from "utils/trimWord";
import { WISHLISTVALIDATIONS } from "validators/WishlistValidator";
import * as yup from "yup";
import { WeddingWishlistActiveScreen } from "./AddWishlist";
import { SpinnerCircular } from "spinners-react";
import SupportModal from "components/Modals/SupportModal";
import SuggestGiftModal from "components/Modals/SuggestGiftModal";

const AddGift: React.FC<{
  wishlistId?: string;
  setActiveScreen: (activeScreen: WeddingWishlistActiveScreen) => void;
}> = ({ wishlistId, setActiveScreen }) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  const addWishValidationSchema = yup.object({
    title: WISHLISTVALIDATIONS.title,
    grossCost: WISHLISTVALIDATIONS.grossCost,
    goalChecked: WISHLISTVALIDATIONS.goalChecked,
  });

  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [file, setFile] = useState<File | string>("");
  const [suggestedGifts, setSuggestedGifts] = useState<GiftType[]>([]);
  const [showSuggestGiftModal, setShowSuggestGiftModal] = useState(false);

  const { state }: any = useLocation();

  useEffect(() => {
    (async () => {
      const listcategoryID = "5f9b70f0407e270021f85785";
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/gift/suggest/${listcategoryID}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = (await response.json()) as {
        statusCode: number;
        message: string;
        payload: GiftType[];
      };
      if (data.statusCode === 200) {
        setSuggestedGifts(data.payload);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const { getDashboard } = dashboardActions;

  const dispatch = useAppDispatch();

  const { addWish, getExternalWish } = wishlistActions;

  const [isExit, setIsExit] = useState(false);

  const [showPricingModal, setShowPricingModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [urlError, setUrlError] = useState("");

  const [, setResponse] = useState<any>({});

  const [circleLoading, setCircleLoading] = useState(false);
  const [urlValue] = useState<string>("");
  const [wishValues, setWishValues] = useState<{
    title: string;
    grossCost: string;
    goalChecked: false;
  }>({
    title: "",
    grossCost: "",
    goalChecked: false,
  });

  const fromUnsplash = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setShowImageCropper(true);
    setFile(imageUrl);
    setShowImageModal(false);
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    setImage(URL.createObjectURL(file));
    setShowImageCropper(true);
    setShowImageModal(false);
  }, []);

  const getExternalFormik = useFormik<{ url: string }>({
    initialValues: { url: "" },
    onSubmit: (values) => {
      setUrlError("");
      setCircleLoading(true);
      dispatch(getExternalWish({ ...values, wishlistID: state._id }))
        .then((res: any) => {
          const response = res.payload;
          setCircleLoading(false);
          if (response.payload.statusCode === 400 && response.payload.message) {
            return setUrlError("Website not supported yet.");
          } else if (
            response &&
            response.payload &&
            (response.statusCode === 200 || response.statusCode === 201)
          ) {
            setResponse(res);
            setImage(response.payload?.img);
            setShowImageCropper(true);
            setWishValues({
              ...wishValues,
              title: trimWords(response.payload?.title),
              grossCost: response.payload?.discount?.amount,
            });
          }
        })
        .catch(() => {
          return setUrlError("Website not supported yet.");
        })
        .finally(() => setCircleLoading(false));
    },
  });

  const formik = useFormik<{
    title: string;
    grossCost: any;
    goalChecked: false;
  }>({
    enableReinitialize: true,
    initialValues: wishValues,
    validationSchema: addWishValidationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!image) {
        toast({
          status: "error",
          description: "Pick an image",
        });
        return;
      }

      if (!values.title) {
        toast({
          status: "error",
          description: "You need to give your gift a name",
        });
        return;
      }

      const payload = new FormData();

      payload.append("title", values.title);
      payload.append("wishlistID", wishlistId!);
      payload.append("grossCost", values.grossCost);
      payload.append(
        "netCost",
        `${(Number(values.grossCost) * 5) / 100 + Number(values.grossCost)}`
      );
      payload.append("coverImage", file);

      setLoading(true);
      dispatch(addWish(payload))
        .then((res: any) => {
          const response = res;
          setLoading(false);
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            resetForm();
            // onClose()!;
            setActiveScreen(WeddingWishlistActiveScreen.A_WISHLIST);
            dispatch(getDashboard());
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "There is an error",
          });
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <>
      <Box
        py="1rem"
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        position="relative"
        pb="2rem"
      >
        <ArrowBackIcon
          position="absolute"
          left="0"
          cursor="pointer"
          color="#fff"
          h={8}
          w={8}
          onClick={() => {
            setIsExit(true);
          }}
        />
      </Box>
      <FormikProvider value={getExternalFormik}>
        <form
          noValidate
          onSubmit={getExternalFormik.handleSubmit}
          style={{ width: "100%" }}
        >
          <Flex
            flexDirection={{ base: "column", lg: "column" }}
            width="100%"
            gap="10px"
            justifyContent="center"
            alignItems="center"
          >
            <Text
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              fontSize="12px"
              gap="8px"
              marginBottom="8px"
            >
              Fetch item details from link (optional)
            </Text>
            <Box
              width={{ base: "330px", md: "350px", lg: "350px" }}
              style={{
                position: "relative",
              }}
            >
              <FormikFormInput
                type="text"
                placeholder="Paste web link e.g Amazon"
                name="url"
                value={urlValue}
                disabled={false}
                showMessage={true}
              />
              <IconButton
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#16181d",
                  position: "absolute",
                  height: "40px",
                  width: "40px",
                  top: "0",
                  right: "0",
                  marginTop: "15px",
                  marginRight: "15px",
                  zIndex: "2",
                }}
                aria-label={""}
                type={"submit"}
                icon={
                  <ArrowRightIcon
                    color="#fff"
                    h={4}
                    w={4}
                    cursor="pointer"
                    style={{
                      color: "#CEFABB",
                    }}
                  />
                }
              />

              {circleLoading && (
                <SpinnerCircular
                  style={{
                    backgroundColor: "#16181d",
                    color: "#CEFABB",
                    position: "absolute",
                    height: "80px",
                    width: "90px",
                    top: "0",
                    right: "0",
                    marginRight: "-30px",
                    marginTop: "-10px",
                    zIndex: "2",
                    transform: "scale(0.5, 0.5) translateX(-50%)",
                  }}
                />
              )}
              <Flex
                fontWeight="400"
                fontSize="12px"
                fontFamily={"Plus Jakarta Sans"}
                justifyContent={"space-between"}
                pb={{ base: "0px", md: "2rem" }}
                px="1rem"
              >
                <Text color={"#FF8585"}>
                  {urlError !== "" ? <p>{urlError}</p> : null}
                </Text>
                <Text
                  cursor={"pointer"}
                  onClick={() => setShowSupportModal(true)}
                >
                  See supported sites
                </Text>
              </Flex>
            </Box>
          </Flex>
        </form>
      </FormikProvider>

      <Flex justifyContent={{ base: "start", md: "center" }}>
        <Flex w={{ base: "330px", md: "350px", lg: "350px" }} h={"50px"}>
          <Text
            // mb={"10px"}
            // mt={"10px"}
            fontSize={{ base: "16px", md: "18px", lg: "18px" }}
            textDecoration={"underline"}
            color={"#A9F389"}
            cursor={"pointer"}
            onClick={() => {
              // console.log("suggestions clicked")
              setShowSuggestGiftModal(true);
            }}
          >
            See suggestions
          </Text>
          <Flex
            gap={"4.885px"}
            fontSize={"8px"}
            fontWeight={700}
            color={"#16181D"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            backgroundColor={"#A9F389"}
            padding={"2.442px 4.885px"}
            borderRadius={"36px"}
            marginRight={"10px"}
            w={"38px"}
            h={"13px"}
          >
            New!
          </Flex>
        </Flex>
      </Flex>

      <FormikProvider value={formik}>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          style={{ width: "100%" }}
        >
          <Flex
            flexDirection={{ base: "column" }}
            gap="30px"
            alignItems={{ base: "center" }}
            justifyContent="center"
          >
            <UploadImage
              fromGallery={fromGallery}
              fromUnsplash={fromUnsplash}
              image={image}
              instruction={"upload image"}
              placeholder={ItemPlaceholder}
              showImageModal={showImageModal}
              setShowImageModal={setShowImageModal}
              setShowImageCropper={setShowImageCropper}
            />
            <Box width="343px">
              <Box display={"flex"} flexDirection="column" gap="1px">
                <Box>
                  <FormikFormInput
                    placeholder="item name"
                    type="text"
                    name="title"
                    disabled={false}
                    showMessage={true}
                    value={wishValues.title}
                  />
                </Box>
                <Box>
                  <FormikFormInput
                    type="text"
                    placeholder="price"
                    name="grossCost"
                    disabled={false}
                    showMessage={true}
                    isNumericFormat
                    value={wishValues.grossCost}
                  />
                </Box>
              </Box>
              <Flex
                color={"rgba(255, 255, 255, 0.5)"}
                fontWeight="400"
                fontSize="15px"
                fontFamily={"Plus Jakarta Sans"}
                justifyContent={"space-between"}
                pb="2rem"
              >
                <Text>+ Unboxd fee @5%</Text>
                <Text>
                  <NumberFormat
                    thousandSeparator
                    prefix="₦"
                    value={formik?.values?.grossCost * 1.05}
                    decimalScale={2}
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                </Text>
              </Flex>
              <Box width={{ base: "100%" }}>
                <ButtonCmp
                  text={"PROCEED"}
                  size="md"
                  padding="1.5rem 3rem"
                  isLoading={loading}
                  isDisabled={formik.isValid ? false : true}
                  opacity={"1"}
                  border={"none"}
                  textHoverColor={"#fff"}
                  width="100%"
                  maxWidth="100%"
                  type="submit"
                />
              </Box>
              <Box display="flex" justifyContent={"center"} pt="1.5rem">
                <Text
                  width="210px"
                  color="rgba(255, 255, 255, 0.7)"
                  fontWeight={"400"}
                  fontSize="13px"
                  textAlign={"center"}
                >
                  We add a 5% markup on the price to cover transaction fees.{" "}
                  <span
                    style={{
                      color: "#fff",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPricingModal(!showPricingModal);
                    }}
                  >
                    Learn more
                  </span>
                </Text>
              </Box>
            </Box>
          </Flex>
        </form>
      </FormikProvider>

      <SuggestGiftModal
        isOpen={showSuggestGiftModal}
        onRequestClose={() => {
          setShowSuggestGiftModal(false);
        }}
        // maxWidth="400px"
      >
        <Box position={"absolute"}>
          <SuggestedGiftItemGrid
            suggestedGifts={suggestedGifts}
            onGiftItemClick={(_gift: GiftType) => {
              setImage(_gift.coverImage);
              setShowImageCropper(true);
              setWishValues({
                ...wishValues,
                title: _gift.title,
                grossCost: String(_gift.grossCost.Amount),
              });
              setShowSuggestGiftModal(false);
            }}
          />
        </Box>
      </SuggestGiftModal>

      <AddItemPricingModal
        isOpen={showPricingModal}
        onRequestClose={() => {
          setShowPricingModal(false);
        }}
        maxWidth="400px"
        showCloseIcon={true}
      />

      <SupportModal
        isOpen={showSupportModal}
        onRequestClose={() => {
          setShowSupportModal(false);
        }}
        maxWidth="400px"
        showCloseIcon={true}
      />

      <ActionModal
        isOpen={isExit}
        onRequestClose={() => {
          setIsExit(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        actionTitle="are you sure?"
        actionDesc="You will lose progress on this iteem if you don’t save it before you go to another page"
        yesText="YES, EXIT"
        noText="NO, CANCEL"
        yesAction={() => {
          // navigate(-1);
          setActiveScreen(WeddingWishlistActiveScreen.A_WISHLIST);
        }}
        noAction={() => {
          setIsExit(false);
        }}
      />
      <ImageCropperModal
        isOpen={showImageCropper}
        imageToCrop={image}
        onRequestClose={() => {
          setShowImageCropper(false);
        }}
        onCropComplete={(url: any) => {
          setImage(url);
          setFile(url);
        }}
        onBackButtonClick={() => {
          setShowImageCropper(false);
          setShowImageModal(true);
        }}
        maxWidth="500px"
        showCloseIcon={false}
      />
    </>
  );
};

export const SuggestedGiftItemGrid: React.FC<{
  suggestedGifts: GiftType[];
  onGiftItemClick: (gift: GiftType) => any;
}> = ({ suggestedGifts, onGiftItemClick }) => {
  return (
    <Flex
      flexWrap={"wrap"}
      height={{ base: "400px", md: "450px" }}
      overflowY={"scroll"}
      ml={{ base: "20px", md: "0px" }}
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "rgba(255, 255, 255, 0.5)",
          height: "6px",
          borderRadius: "24px",
        },
      }}
    >
      {suggestedGifts.map((cv) => (
        <Flex key={cv._id} m={"10px"}>
          <SuggestedGiftItem
            coverImage={cv.coverImage}
            title={cv.title}
            price={cv.netCost.Amount}
            onClick={() => {
              onGiftItemClick(cv);
            }}
          />
        </Flex>
      ))}
    </Flex>
  );
};

export const SuggestedGiftItem: React.FC<{
  coverImage: string;
  title: string;
  price: number;
  onClick: () => void;
}> = ({ coverImage, title, price, onClick }) => {
  return (
    <Flex
      flexDir={"column"}
      bgColor={"#16181D"}
      width={{ base: "98px", md: "179px" }}
      height={{ base: "115px", md: "200px" }}
      border={"1px solid #4a4a4a"}
      borderRadius={{ base: "18px", md: "18px 18px 0px 0px" }}
      cursor={"pointer"}
      onClick={onClick}
    >
      <Flex
        flexDir={"column"}
        height={"70%"}
        borderRadius={"18px 18px 0px 0px"}
        backgroundSize="cover"
        bgImage={coverImage}
      ></Flex>
      <Flex flexDir={"column"} height={"30%"} ml={"15px"}>
        <Text
          color={"rgba(255, 255, 255, 0.5)"}
          fontSize={{ base: "10px", md: "15px" }}
          fontWeight="400"
          noOfLines={1}
        >
          {title}
        </Text>
        <Text
          color={"rgba(255, 255, 255, 0.5)"}
          fontSize={{ base: "12px", md: "18px" }}
          fontWeight="400"
        >
          ₦
          <NumberFormat
            thousandSeparator
            value={price}
            decimalScale={2}
            defaultValue="0.00"
            allowNegative={false}
            placeholder="0.00"
            displayType="text"
            disabled
          />
        </Text>
      </Flex>
    </Flex>
  );
};

export default AddGift;
