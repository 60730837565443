import localforage from "localforage";

export const logout = async () => {
  const authContext = localStorage.getItem("authContext");
  localStorage.clear();
  await localforage.dropInstance({ name: "Pending Invite Closed" });
  window.location.href = `/login${
    authContext ? `?context=${authContext}` : ""
  }`;
};
