import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./links";
import DashboardLayout from "Layout/Dashboard";
// import MyWedding from "pages/Dashboard/my-wedding-2";
import WeddingForm from "pages/Dashboard/Wedding";
import GiftExchange, {
  CreateGiftExchange,
  SingleGiftExchange,
} from "pages/Dashboard/Groups/GiftExchange";
import { FundGift, Wishlist as WishlistContributor } from "pages/Contributor";
import { NotFound } from "pages/Others";
import AddBankAccount from "pages/Dashboard/AddBankAccount";
import ContributorLayout from "Layout/Contributor";
import { hotjar } from "react-hotjar";
import { Suspense, useEffect } from "react";
import React from "react";
import PageLoader from "compositions/PageLoader";
import EditGiftExchange from "pages/Dashboard/Groups/GiftExchange/edit";
import AddCollection from "pages/Dashboard/AddCollection";
import EditCollection from "pages/Dashboard/EditCollection";
import Analytics from "pages/Dashboard/Analytics";
import SingleAnalytics from "pages/Dashboard/Analytics/SingleAnalytics";
import { Overview } from "pages/Dashboard";

const Login = React.lazy(() => import("pages/Auth/Login"));
const Register = React.lazy(() => import("pages/Auth/Register"));
const VerifyCode = React.lazy(() => import("pages/Auth/VerifyCode"));
const WelcomeBack = React.lazy(() => import("pages/Auth/WelcomeBack"));
const ForgotPassword = React.lazy(() => import("pages/Auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("pages/Auth/ResetPassword"));

const AnotherOverview = React.lazy(
  () => import("pages/Dashboard/AnotherOverview")
);
const Settings = React.lazy(() => import("pages/Dashboard/Settings"));
const Wallet = React.lazy(() => import("pages/Dashboard/Wallet"));
const Myevent = React.lazy(() => import("pages/Dashboard/Myevent"));

const Wishlist = React.lazy(() => import("pages/Dashboard/Wishlist"));
const SingleEvent = React.lazy(() => import("pages/Dashboard/SingleEvent"));
const AddWish = React.lazy(() => import("pages/Dashboard/AddWish"));
const EditWish = React.lazy(() => import("pages/Dashboard/EditWish"));
const EditWishlist = React.lazy(() => import("pages/Dashboard/EditWishlist"));
const RequestPayout = React.lazy(() => import("pages/Dashboard/RequestPayout"));
const ChooseAccount = React.lazy(() => import("pages/Dashboard/ChooseAccount"));
const VerifyRequest = React.lazy(() => import("pages/Dashboard/VerifyRequest"));
const Wedding = React.lazy(() => import("pages/Dashboard/Wedding"));
const UserProfile = React.lazy(() => import("pages/Profile/UserProfile"));

const RouteCmp = () => {
  useEffect(() => {
    hotjar.initialize(3242238, 6);
  }, []);
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path={ROUTES.login} element={<Login />} />
        <Route path={ROUTES.signup} element={<Register />} />
        <Route path={ROUTES.verify} element={<VerifyCode />} />
        <Route path={ROUTES.ouch} element={<WelcomeBack />} />
        <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
        <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.dashboard} element={<DashboardLayout />}>
          <Route index element={<AnotherOverview />} />
          <Route path={ROUTES.analytics} element={<Analytics />} />
          <Route path={ROUTES.singleAnalytics} element={<SingleAnalytics />} />
          <Route path={ROUTES.wishlist} element={<Wishlist />} />
          <Route path={ROUTES["add-collection"]} element={<AddCollection />} />
          <Route
            path={ROUTES["edit-collection"]}
            element={<EditCollection />}
          />
          <Route path={ROUTES["add-wish"]} element={<AddWish />} />
          <Route path={ROUTES["edit-wish"]} element={<EditWish />} />
          <Route path={ROUTES.event} element={<SingleEvent />} />
          <Route path={ROUTES["edit-wishlist"]} element={<EditWishlist />} />
          <Route path={ROUTES.wallet} element={<Wallet />} />
          <Route path={ROUTES.Myevent} element={<Myevent />} />
          <Route path={ROUTES.settings} element={<Settings />} />
          <Route path={ROUTES.wedding} element={<WeddingForm />} />
          <Route path={ROUTES.myWishlist} element={<Overview />} />
          <Route path={ROUTES["create-wedding"]} element={<Wedding />} />
          <Route path={ROUTES.secretSanta} element={<GiftExchange />} />
          <Route
            path={ROUTES.createSecretSantaGift}
            element={<CreateGiftExchange />}
          />
          <Route
            path={ROUTES.editSecretSantaGift}
            element={<EditGiftExchange />}
          />
          <Route
            path={ROUTES.singleSecretSanta}
            element={<SingleGiftExchange />}
          />
          <Route path={ROUTES["request-payout"]} element={<RequestPayout />} />
          <Route
            path={ROUTES["add-bank-account"]}
            element={<AddBankAccount />}
          />
          <Route path={ROUTES["choose-account"]} element={<ChooseAccount />} />
          <Route path={ROUTES["verify-request"]} element={<VerifyRequest />} />
        </Route>
        <Route path={ROUTES.contributor} element={<ContributorLayout />}>
          <Route index element={<WishlistContributor />} />
          <Route path={ROUTES.fundGift} element={<FundGift />} />
        </Route>
        <Route path={ROUTES.userProfile} element={<UserProfile />} />
        <Route path={ROUTES.notFound} element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteCmp;
