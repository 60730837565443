import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import SuccessModal from "components/Modals/Contributor/SuccessModal";
import { useAppDispatch, useAppSelector } from "hooks";
import PayWithFlutterwave from "pages/Contributor/Payment";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import {
  contributorActions,
  contributorSelectors,
  dashboardSelectors,
} from "store/slices";

const OpenWishlistDrawer: React.FC<{
  onClose: () => void;
  isOpen: boolean;
  giftExchangeRecipientWishlistData: any;
  userExchangeData: any;
}> = ({
  onClose,
  isOpen,
  giftExchangeRecipientWishlistData,
  userExchangeData,
}) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const userDetails = useAppSelector(dashboardSelectors.getUser);
  const createGivenData = useAppSelector(contributorSelectors.createGiven);

  const { createGiven } = contributorActions;

  const givenData: any = createGivenData?.payload;
  const dispatch = useAppDispatch();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={"xs"} trapFocus={false}>
        <DrawerOverlay />
        <DrawerContent bgColor={"#22242C"}>
          <DrawerCloseButton outline={"none"} boxShadow="none !important" />
          <DrawerBody pt="3rem">
            <Flex gap="5px" justifyContent={"flex-start"} flexWrap="wrap">
              {giftExchangeRecipientWishlistData?.length &&
                giftExchangeRecipientWishlistData?.map((v: any, i: number) => {
                  return (
                    <Box w="133px" pb="1rem" key={i}>
                      <Box
                        position={"relative"}
                        backgroundSize="cover"
                        width="133px"
                        height={"133px"}
                        borderRadius={"10px"}
                        bgImage={v?.coverImage}
                      ></Box>
                      <Flex
                        flexDir={"column"}
                        color="#fff"
                        fontWeight={"400"}
                        alignItems="center"
                        py="10px"
                      >
                        <Text fontSize={"11px"} textTransform="capitalize">
                          {v?.title}
                        </Text>
                        <Text fontSize={"13px"}>
                          <NumberFormat
                            thousandSeparator
                            prefix="₦"
                            value={v?.netCost.Amount}
                            decimalScale={2}
                            defaultValue="0.00"
                            allowNegative={false}
                            placeholder="0.00"
                            displayType="text"
                            disabled
                          />
                        </Text>
                      </Flex>
                      <Box textAlign={"center"}>
                        <ButtonCmp
                          text={"FULFILL"}
                          isDisabled={pageLoading}
                          size="md"
                          padding="1rem"
                          opacity={"1"}
                          border={"none"}
                          textHoverColor={"#fff"}
                          backgroundColor="#338149"
                          backgroundHoverColor="#338149"
                          width="100%"
                          maxWidth="70px"
                          height="42px"
                          type="submit"
                          fontSize="12px"
                          borderRadius="10px"
                          onClick={() => {
                            const payload = {
                              giftExchangeGiftID: v?._id,
                              giftExchangeWishlistID: v?.giftExchangeWishlistID,
                              recipientID:
                                userExchangeData?.match?.recipient?.recipientID,
                              typeOfGiver: "user",
                              amount: v?.netCost?.Amount,
                            };
                            setPageLoading(true);
                            dispatch(
                              createGiven({
                                data: payload,
                                type: "giftexchangegift",
                              })
                            )
                              .then((res: any) => {
                                const response = res;
                                if (
                                  response.payload.statusCode === 200 &&
                                  response.payload.message ===
                                    "proceed to payment gateway"
                                ) {
                                  setPageLoading(false);
                                  setShowPaymentModal(true);
                                } else {
                                  toast({
                                    status: "error",
                                    description: response?.payload?.message,
                                  });
                                }
                              })
                              .catch(() => {
                                return;
                              })
                              .finally(() => setPageLoading(false));
                          }}
                        />
                      </Box>
                      {showPaymentModal && (
                        <PayWithFlutterwave
                          email={userDetails?.email!}
                          amount={v?.netCost?.Amount}
                          reference={givenData?.reference}
                          event={v?.title!}
                          success={() => {
                            setShowPaymentModal(false);
                            setShowSuccess(true);
                            // setPageLoading(true);
                          }}
                        />
                      )}
                    </Box>
                  );
                })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <SuccessModal
        isOpen={showSuccess}
        onRequestClose={() => {
          window.location.reload();
          setShowSuccess(false);
        }}
        maxWidth="330px"
        showCloseIcon={false}
      />
    </>
  );
};

export default OpenWishlistDrawer;
