
const prepareArray = (arr: any[]): any[] => {
  return arr.map(prepareVal).filter(v => v !== null);
};

const prepareVal = (val: any) => {
  if (!val) {
    if (val === 0 || val === false) return val;
  }else {
    if (typeof val === "object") {
      if (Array.isArray(val)) {
        if (Array.isArray(val)) return prepareArray(val);
      }else return prepareObject(val);
    }else return val;
  }
  return null;
};

const prepareObject = (obj: {[key: string]: any}) => {
  let res: {[key: string]: any} = {};
  for (const [key, val] of Object.entries(obj)) {
    const valRes = prepareVal(val);
    if (valRes !== null) res[key] = valRes;
  }
  return res;
};

const convertObject = (obj: {[key: string]: any}) => {
  let res = "";
  if (Object.keys(obj).length === 0) return res;
  for (const [key, val] of Object.entries(obj)) res += `${res ? '&' : ''}${key}=${val}`;
  return res;
};

const prepareRequest = ({baseRoute, ext, params, query}: Partial<BASE_API_REQ>) =>
  (baseRoute || process.env.REACT_APP_BASE_URL) + 
    (ext ? `/${ext}` : '') +
    (params ? `/${params}` : '') +
    (query ? `?${convertObject(prepareObject(query))}` : '');

export type BASE_API_REQ = {
  baseRoute: string; // doesn't ends with /
  ext: string;
  params: string;
  query: Record<string, string | number | undefined>;
  body: FormData | string | Record<string, any>,
};

export type ResponseType<T> = {
  statusCode: number, 
  message: string, 
  payload: T,
  errors: string | null,
  token: string | null,
};

export type responseCallback<T> = (response: ResponseType<T>) => void;

const REQ_METHOD = <T>(
  reqMethod: 'POST' | 'GET' | 'PUT' | 'DELETE',
  baseApiReq: Partial<BASE_API_REQ>,
  responseCallback: responseCallback<T>
) => {
  const { body } = baseApiReq;
  const reqUrl = prepareRequest(baseApiReq);
  const bodyIsOriginallyStringOrFormData = (typeof body === "string") || (body instanceof FormData);
  const reqBody = body ? (bodyIsOriginallyStringOrFormData ? body : JSON.stringify(prepareObject(body!))) : "";

  const accessToken = localStorage.getItem("accessToken");
  const payload = {
    method: reqMethod,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(!bodyIsOriginallyStringOrFormData ? { "Content-Type": "application/json"} : {} ),
    },
    ...(body ? {body: reqBody} : {}),
  };

  (async () => {
    const res = await fetch(reqUrl, payload);
    const data = (await res.json()) as ResponseType<T>;
    responseCallback(data);
  })();
};


export const POST = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>) => 
  REQ_METHOD<T>("POST", baseApiReq, responseCallback);

export const PUT = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>) => 
  REQ_METHOD<T>("PUT", baseApiReq, responseCallback);

export const DELETE = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>) => 
  REQ_METHOD<T>("DELETE", baseApiReq, responseCallback);

export const GET = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>) => 
  REQ_METHOD<T>("GET", baseApiReq, responseCallback);
