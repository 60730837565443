import { ISVGS } from "./types";

const WalletIcon = (props: ISVGS) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.649519 2.75045H16.0924V0.642663C16.0924 0.547575 16.0713 0.453671 16.0307 0.367722C15.99 0.281774 15.9307 0.205923 15.8572 0.145641C15.7837 0.0853588 15.6977 0.0421472 15.6054 0.0191229C15.5132 -0.0039014 15.4169 -0.00616452 15.3237 0.0124965L0.543487 2.44159C0.433429 2.4635 0.331027 2.51385 0.24647 2.58762C0.161913 2.6614 0.0981491 2.75603 0.0615234 2.8621C0.248714 2.7881 0.448229 2.75021 0.649519 2.75045Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <path
      d="M17.3565 3.71484H0.648477C0.478044 3.71484 0.314592 3.78255 0.194078 3.90306C0.0735635 4.02358 0.00585938 4.18703 0.00585938 4.35746V15.9246C0.00585938 16.095 0.0735635 16.2585 0.194078 16.379C0.314592 16.4995 0.478044 16.5672 0.648477 16.5672H17.3565C17.527 16.5672 17.6904 16.4995 17.8109 16.379C17.9315 16.2585 17.9992 16.095 17.9992 15.9246V4.35746C17.9992 4.18703 17.9315 4.02358 17.8109 3.90306C17.6904 3.78255 17.527 3.71484 17.3565 3.71484ZM13.5209 11.4263C13.2667 11.4263 13.0182 11.3509 12.8069 11.2097C12.5955 11.0684 12.4308 10.8677 12.3335 10.6329C12.2362 10.398 12.2108 10.1396 12.2604 9.89028C12.31 9.64097 12.4324 9.41197 12.6121 9.23222C12.7919 9.05248 13.0209 8.93007 13.2702 8.88048C13.5195 8.83089 13.7779 8.85634 14.0128 8.95362C14.2476 9.05089 14.4483 9.21563 14.5895 9.42698C14.7308 9.63834 14.8061 9.88682 14.8061 10.141C14.8061 10.4819 14.6707 10.8088 14.4297 11.0498C14.1887 11.2908 13.8618 11.4263 13.5209 11.4263Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <path
      d="M0 8.99603V3.23255C0 2.73734 0.229736 2.49033 0.619323 2.42928C2.03308 2.20718 4.98029 2.08789 4.98029 2.08789C4.98029 2.08789 4.64572 2.73051 3.88262 2.73051V3.71452C4.62564 3.71452 5.14094 4.65836 5.14094 4.65836L2.14875 8.05219L0 8.99603Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_wallet"
        x1="9.23077"
        y1="0"
        x2="9.23077"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.fill || "#8DB782"} />
        <stop offset="1" stopColor={props.fill || "#8DB782"} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default WalletIcon;
