import { useEffect, useState } from "react";
import { Box, Flex, Select, Text, useToast } from "@chakra-ui/react";

// import { times } from "data/times";
import { getDateFromDayAndTime } from "utils/date";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { WeddingEventSchedule, WeddingEventType } from "apis";
import CustomDateCmp from "components/InputsCmp/CustomDateCmp";
// import { BasicSelect } from "components/InputsCmp/CustomSelect";
import CustomInputCmp, { BasicInput } from "components/InputsCmp/CustomInputCmp";
import SingleColorPalette from "components/InputsCmp/SingleColorPalette";
import { updateWeddingEventSchedule } from "utils/workbench/APIs/routes/wedding";
import { UpdateWeddingCollection, WeddingCollection } from "utils/workbench/STOREs/collections/wedding.collection";
import { to12HoursTime } from "utils/to12HourTime";


const EditEvent: React.FC<{
  eventSchedule: WeddingEventSchedule;
  onSaveAction: (eventSchedules: WeddingEventSchedule[]) => void;
  events: WeddingEventSchedule[];
  onRequestClose: () => void;
}> = ({ eventSchedule, events, onRequestClose, onSaveAction }) => {
  const {weddingExists} = WeddingCollection();

  const [ eventType, setEventType ] = useState(eventSchedule.type);
  const [ address, setAddress ] = useState(eventSchedule.address);
  const [ day, setDay ] = useState(eventSchedule.date);
  const [ time, setTime ] = useState(
    (
      eventSchedule.time.endsWith("am") ||
      eventSchedule.time.endsWith("pm")
    ) ?
      eventSchedule.time.substring(
      0,
      eventSchedule.time.length - 2
    ) :
    eventSchedule.time
  );
  const [ date, setDate ] = useState<Date | null>((day && time) ? getDateFromDayAndTime(day, time) : null);
  const [color1, setColor1] = useState(eventSchedule?.colors?.color1 || "");
  const [color2, setColor2] = useState(eventSchedule?.colors?.color2 || "");
  const [brideDressCode, setBrideDressCode] = useState(eventSchedule.brideDressCode);
  const [groomDressCode, setGroomDressCode] = useState(eventSchedule.groomDressCode);
  const [ color1Name, setColor1Name ] = useState(eventSchedule?.colors?.color1Name);
  const [ color2Name, setColor2Name ] = useState(eventSchedule?.colors?.color2Name);
  const [isValid, setIsValid] = useState(false);

  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const checkValid = (_eventType: string, _address: string, _date: string, _time: string) => {
    setIsValid(!!_eventType && !!_address && !!_date && !!_time);
  };

  useEffect(() => {
    checkValid(eventType, address, day, time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    const currentEventIndex = events.findIndex((event) => event._id === eventSchedule._id);
    const currentEvent: Omit<WeddingEventSchedule, "date" | "time" | "type"> & {
      type: WeddingEventType,
      date: {
        day: string;
        time: string;
      };
      eventScheduleID: string;
    } = {
      eventScheduleID: eventSchedule._id,
      _id: eventSchedule._id,
      weddingID: eventSchedule.weddingID,
      type: eventType as WeddingEventType,
      address,
      date: {
        day: `${day}`,
        time,
      },
      colors: {
        color1,
        color1Name,
        color2,
        color2Name,
      },
      brideDressCode,
      groomDressCode,
    };
    events[currentEventIndex] = {
      ...currentEvent,
      date: currentEvent.date.day,
      time: currentEvent.date.time,
    };
    onSaveAction(events);
    onRequestClose();

    currentEvent.date.time = to12HoursTime(currentEvent.date.time);

    if (weddingExists) {
      updateWeddingEventSchedule(
        currentEvent,
        (response) => {
          if (response.statusCode === 200) {
            UpdateWeddingCollection(
              (weddingCollection) => {
                weddingCollection.weddingDataObj = response.payload;
              }
            );
          }

          if (response.statusCode === 400 || response.errors) toast({ status: "error", description: response.message });
        }
      );
    }
  };
  return (
    <Box
      w={"full"}
      textAlign={"center"}
      padding="2rem 1rem"
      fontFamily={"Plus Jakarta Sans"}
    >
      <Box py="10px" color="#fff">
        <Text fontSize={"20px"} textAlign={"center"}>
          Edit Event
        </Text>
      </Box>
      <Flex justifyContent={"center"} py="1rem">
        <Flex flexDirection={"column"} width={"100%"}>
          {/* <Box display={"flex"} flexDirection="column">
            <BasicSelect
              placeholder="choose event type"
              name="eventType"
              value={
                (eventSchedule.type === "White Wedding" || eventSchedule.type === WeddingEventType.WHITE_WEDDING) ? WeddingEventType.WHITE_WEDDING : 
                (eventSchedule.type === "Traditional Wedding" || eventSchedule.type === WeddingEventType.TRADITIONAL_WEDDING) ? WeddingEventType.TRADITIONAL_WEDDING :
                (eventSchedule.type === "Nikai" || eventSchedule.type === WeddingEventType.NIKAI) ? WeddingEventType.NIKAI :
                (eventSchedule.type === "After Party" || eventSchedule.type === WeddingEventType.AFTER_PARTY) ? WeddingEventType.AFTER_PARTY :
                (eventSchedule.type === "Engagement" || eventSchedule.type === WeddingEventType.ENGAGEMENT) ? WeddingEventType.ENGAGEMENT  :
                (eventSchedule.type === "Reception" || eventSchedule.type === WeddingEventType.RECEPTION) ? WeddingEventType.RECEPTION  :
                WeddingEventType.OTHERS
              }
              handleChange={(e) => {
                setEventType(e.target.value);
                checkValid(e.target.value, address, day, time);
              }}
              showMessage={true}
              items={[
                { text: "White Wedding", value: "white_wedding" },
                { text: "Traditional Wedding", value: "traditional_wedding" },
                { text: "Nikai", value: "nikai" },
                { text: "After Party", value: "after_party" },
                { text: "Engagement", value: "engagement" },
                { text: "Reception", value: "reception" },
                { text: "Others", value: "others" },
              ]}
            />
          </Box> */}
          <Box display={"flex"} flexDirection="column">
            <BasicInput
              placeholder="choose event type"
              name="eventType"
              disabled={
                eventType === "White Wedding" ||
                eventType === "Traditional Wedding" ||
                eventType === "Nikai" ||
                eventType === "After Party" ||
                eventType === "Engagement" ||
                eventType === "Reception"
              }
              showMessage={true}
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
                checkValid(
                  e.target.value,
                  address,
                  day,
                  time
                );
              }}
              hasDropdown={true}
              rightElement={
                <Select 
                  border="none"
                  w={"40px"} 
                  h={"40px"} 
                  value={eventType}
                  onChange={(e) => {
                    setEventType(e.target.value === "Others" ? "" : e.target.value);
                    checkValid(e.target.value, address, day, time);
                  }}
                >
                  <option value={'White Wedding'}>White Wedding</option>
                  <option value={'Traditional Wedding'}>Traditional Wedding</option>
                  <option value={'Nikai'}>Nikai</option>
                  <option value={'After Party'}>After Party</option>
                  <option value={'Engagement'}>Engagement</option>
                  <option value={'Reception'}>Reception</option>
                  <option value={'Others'}>Others</option>
                </Select>
              }
            />
          </Box>
          <Box display={"flex"} flexDirection="column">
            <BasicInput
              placeholder="event address"
              name="address"
              disabled={false}
              showMessage={true}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
                checkValid(eventType, e.target.value, day, time);
              }}
            />
          </Box>
          <Box display={"flex"} gap="5px">
            <Box w="100%">
              <CustomDateCmp
                placeholder="Set date"
                name="date"
                disabled={false}
                value={date}
                onChangeAction={(value) => {
                  const strDay = (value as unknown as Date).toDateString().substring(0, 15);
                  setDay(strDay);
                  setDate(value);
                  checkValid(eventType, address, strDay, time);
                }}
                showMessage={true}
              />
            </Box>
            <Box w="100%">
              {/* <BasicSelect
                placeholder="Select Time"
                name="time"
                handleChange={(e) => {
                  setTime(e.target.value);
                  checkValid(eventType, address, day, e.target.value);
                }}
                value={time}
                showMessage={true}
                items={times}
              /> */}
              <CustomInputCmp
                placeholder="Select Time"
                name="time"
                onChange={(e) => {
                  setTime(e.target.value);
                  checkValid(eventType, address, day, e.target.value);
                }}
                type="time"
                value={time}
                padding="2.6rem 1rem"
                showMessage={true}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            pt={"15px"}
            pb={"8px"}
          >
            <Text>Colors of the day</Text>
          </Box>
          <Box
            display={"flex"}
            position={"relative"}
          >
            <SingleColorPalette
              placeholder="color name"
              name="colorText"
              disabled={false}
              showMessage={true}
              colorValue={color1}
              setColorValue={(c1) => {
                setColor1(c1);
              }}
              textValue={color1Name}
              onTextChange={(value) => {
                setColor1Name(value);
              }}
            />
            <SingleColorPalette
              placeholder="color name"
              name="colorText"
              disabled={false}
              showMessage={true}
              colorValue={color2}
              setColorValue={(c2) => {
                setColor2(c2);
              }}
              textValue={color2Name}
              onTextChange={(value) => {
                setColor2Name(value);
              }}
            />
          </Box>
          <Box
                display={"flex"}
                justifyContent={"center"}
                pt={"15px"}
                pb={"8px"}
              >
                <Text>Dress code</Text>
              </Box>
              <Box 
                display={"flex"} 
                position={"relative"}
              >
                <BasicInput
                  placeholder="for bride"
                  name="outfit"
                  disabled={false}
                  showMessage={true}
                  value={brideDressCode}
                  onChange={(e) => {
                    setBrideDressCode(e.target.value);
                    checkValid(
                      eventType,
                      address,
                      date?.toDateString()!,
                      time
                    );
                  }}
                  />
                <BasicInput
                  placeholder="for groom"
                  name="outfit"
                  disabled={false}
                  showMessage={true}
                  value={groomDressCode}
                  onChange={(e) => {
                    setGroomDressCode(e.target.value);
                    checkValid(
                      eventType,
                      address,
                      date?.toDateString()!,
                      time
                    );
                  }}
                  />
              </Box>          
          <Box
            position="sticky"
            padding="1rem"
            bgColor={"#1F2229"}
            zIndex="3"
            textAlign="center"
            display="flex"
            gap="5px"
            bottom={{ base: "-20px", sm: "-80px" }}
            justifyContent={"center"}
          >
            <ButtonCmp
              text={"Save"}
              onClick={handleSubmit}
              size="md"
              opacity={"1"}
              border={"none"}
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="150px"
              type="submit"
              isDisabled={!isValid}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default EditEvent;
