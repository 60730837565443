import { css } from "@emotion/react";

export const GlobalStyles = css`
  body {
    height: 100%;
    overflow: auto;
    background-color: #22242c;
    color: #fff;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  .no-scrollbar::-webkit-scrollbar-thumb {
    display: none;
  }
`;
