import { BaseStore, UpdateCollectionStore } from "../baseStore";
import { AsoEbi } from "utils/workbench/APIs/routes/asoebi";


export type AsoEbiCollection = {
  asoEbis: AsoEbi[];
};


const _AsoEbiCollection = BaseStore<AsoEbiCollection>(
  {
    asoEbis: [],
  }
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsoEbiCollection = () => _AsoEbiCollection((selectedState) => ({...selectedState}));
export const UpdateAsoEbiCollection = UpdateCollectionStore(_AsoEbiCollection);
