import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { createApi } from "unsplash-js";
import CustomInputCmp from "components/InputsCmp/CustomInputCmp";
import React, { useEffect, useState } from "react";
import ModalCmp from "../ModalCmp/ModalCmp";
import toast from "react-hot-toast";
import LogoLoader from "components/LogoLoader";

const unsplashApi = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY as string,
});

const UnsplashSearchModal: React.FC<{
  selectUnsplashImage: (imageUrl: string) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  selectUnsplashImage,
}) => {
  const [query, setQuery] = useState("");
  const [photos, setPhotos] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getImages = () => {
      setLoading(true);
      unsplashApi.search
        .getPhotos({
          query,
          orientation: "landscape",
          perPage: +process.env.REACT_APP_UNSPLASH_PER_PAGE! as number,
        })
        .then((result: any) => {
          if (result.response) {
            setPhotos(result.response!.results);
            setLoading(false);
          } else if (result.status === 401) {
            toast.success("An error occured. Kindly try again later");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    };

    if (query) {
      const debounce = setTimeout(getImages, 1000);

      setLoading(false);
      return () => clearTimeout(debounce);
    }
  }, [query]);
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px", height: "600px" }}
        shouldFocusAfterRender={false}
        contentLabel="Image Modal"
        id="image-modal"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon || true}
      >
        <Box
          flexDir={"column"}
          display="flex"
          gap="20px"
          textAlign={"center"}
          padding="2rem 1rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Box>
            <Text py="10px" fontSize={"20px"} textAlign={"center"} color="#fff">
              Unsplash
            </Text>
            <Box>
              <CustomInputCmp
                background="#000"
                placeholder="Search for an image"
                name={"unsplash-image"}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                showMessage={false}
              />
            </Box>
            <Flex justifyContent={"center"} flexWrap="wrap" gap="3px" pt="1rem">
              {loading && (
                <Flex justifyContent={"center"}>
                  {/* <SpinnerCmp
                    enabled={true}
                    color={"#000"}
                    size={20}
                    secondaryColor={"#fff"}
                  /> */}
                  <LogoLoader />
                </Flex>
              )}
              {photos &&
                photos.map((photo, i) => {
                  return (
                    <Box
                      key={i}
                      onClick={() => {
                        selectUnsplashImage(photo.urls.regular);
                      }}
                      cursor="pointer"
                      flexBasis={{ sm: "0 0 33%" }}
                      width={{ sm: "33%" }}
                    >
                      <Image
                        src={photo.urls.small}
                        alt={photo.description || ""}
                      />
                      <Text py="0.5rem" color={"#fff"} fontSize="14px">
                        By{" "}
                        <span style={{ textDecoration: "underline" }}>
                          {/* <Link href={photo.user.links.html} target="_blank"> */}
                          {photo.user.username}
                          {/* </Link> */}
                        </span>
                      </Text>
                    </Box>
                  );
                })}
            </Flex>
          </Box>
        </Box>
      </ModalCmp>
    </>
  );
};

export default UnsplashSearchModal;
