import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetAnEvent,
  GetEventResponse,
  GetSearchResult,
  GetSearchResultResponse,
  GetUser,
  GetUserResponse,
  MRequest,
} from "apis";
import { RootState } from "store/reducers";

const entity = "event";

const getAnEvent = createAsyncThunk(
    "event/get",
    async (params: { eventID: string }) => {
      const res = await MRequest.get<typeof GetAnEvent.RES>(
        `${GetAnEvent.ROUTE}?id=${params.eventID}`
      );
      return res.data;
    }
  );

  const getSearchResult = createAsyncThunk(
    "event/getSearchResult",
    async (params: { eventID: string }) => {
      const res = await MRequest.get<typeof GetSearchResult.RES>(
        `${GetSearchResult.ROUTE}?id=${params.eventID}`
      );
      return res.data;
    }
  );

const getUser = createAsyncThunk(
  "event/getUser",
  async (id: typeof GetUser.PARAMS) => {
    const res = await MRequest.get<typeof GetUser.RES>(
      `${GetUser.ROUTE}/${id}`
    );
    return res.data;
  }
);

const getPublicUser = createAsyncThunk(
  "event/getPublicUser",
  async (username: typeof GetUser.PARAMS) => {
    const res = await MRequest.get<typeof GetUser.RES>(
      `${GetUser.ROUTE}/profile/public?username=${username}`
    );
    return res.data;
  }
);

export type EventInitialStateType = {
  getEventResponse: GetEventResponse | null;
  getSearchResultResponse: GetSearchResultResponse | null;
  getUserResponse: GetUserResponse | null;
  getPublicUserResponse: GetUserResponse | null;
};

const initialState: EventInitialStateType = {
  getEventResponse: null,
  getSearchResultResponse: null,
  getUserResponse: null,
  getPublicUserResponse: null,
};

const eventSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAnEvent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getEventResponse = action.payload;
      }
    );
    builder.addCase(
      getAnEvent.rejected,
      (state, action: PayloadAction<any>) => {
        state.getEventResponse = action.payload;
      }
    );
    builder.addCase(
      getSearchResult.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getSearchResultResponse = action.payload;
      }
    );
    builder.addCase(
      getSearchResult.rejected,
      (state, action: PayloadAction<any>) => {
        state.getSearchResultResponse = action.payload;
      }
    );
    builder.addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.getUserResponse = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.getUserResponse = action.payload;
    });
    builder.addCase(
      getPublicUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getPublicUserResponse = action.payload;
      }
    );
    builder.addCase(
      getPublicUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.getPublicUserResponse = action.payload;
      }
    );
  },
});

export const eventActions = {
  ...eventSlice.actions,
  getAnEvent,
  getUser,
  getPublicUser,
  getSearchResult,
  
};

export const eventSelectors = {
  getAnEvent: (state: RootState) => state.event.getEventResponse,
  getSearchResult: (state: RootState) => state.event.getSearchResultResponse,
  getUser: (state: RootState) => state.event.getUserResponse?.payload,
  getPublicUser: (state: RootState) =>
    state.event.getPublicUserResponse?.payload,
};

export const eventReducer = eventSlice.reducer;
