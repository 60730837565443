export const searchThroughArrayOfObj = (arr: any[], searchText: string) => {
  const result = arr.filter((obj) =>
    Object.values(obj).some((val: any) => {
      if (typeof val === "string") {
        return val.toLowerCase().includes(searchText.toLowerCase());
      }
      return null;
    })
  );

  return result;
};
