import {
  Flex,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Divider,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { walletActions, walletSelectors } from "store/slices";
import { useEffect, useState } from "react";
// import { useEffect, useState, useMemo } from "react";
import NumberFormat from "react-number-format";
import { LogoLoader } from "components";

const BalanceCmp = React.lazy(() => import("components/BalanceCmp"));
const EmptyCardCmp = React.lazy(() => import("components/EmptyCardCmp"));
const WishlistCardCmp = React.lazy(
  () => import("components/WishlistCardCmp/WishlistCardCmp")
);
const WalletHistoryCmp = React.lazy(
  () => import("components/WalletHistoryCmp")
);
const WithdrawalHistoryCmp = React.lazy(
  () => import("components/WithdrawalHistoryCmp")
);
// const GuestListCmp = React.lazy(
//   () => import("components/GuestListCmp")
// );

const Wallet = () => {
  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const getWalletsData: any = useAppSelector(
    walletSelectors.getWishlistWallets
  );

  const { getWishlistWallets } = walletActions;

  const walletsDataPayload: any =
    localStorage.getItem("userType") === "Organisation"
      ? getWalletsData?.payload?.events
      : getWalletsData?.payload?.wishlists;

  const [currentWishlistData, setCurrentWishlistData] = useState<any>(
    walletsDataPayload && walletsDataPayload[1]
  );
  const [currentWishlistIndex, setCurrentWishlistIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);

  
  const dispatch = useAppDispatch();
  
  const [hasWedding, setHasWedding] = useState(false);
  const [focusOnWeddingWishlist, setFocusOnWeddingWishlist] = useState(false);
  const [weddingWishlistData, setWeddingWishlistData] = useState({} as any);
  const [hasAsoEbi, setHasAsoEbi] = useState(false);
  const [focusOnAsoEbi, setFocusOnAsoEbi] = useState(false);
  const [asoEbi, setAsoEbi] = useState<{
    coverImage: string,
    payments: {
      amount: number;
      paid: number;
      deduction: number;
      typeOfGiving: string;
      forItem: string;
      giver: string;
      date: string;
    }[];
    ubxdid: string;
    hashtag: string;
    walletBalance: number;
    withdrawals: [];
    guestCount: number;
    _id: string;
}>({
    coverImage: "",
    payments: [],
    withdrawals: [],
    guestCount: 0,
    ubxdid: "",
    hashtag: "",
    walletBalance: 0,
    _id: "",
});

  useEffect(() => {
    setLoading(true);
    dispatch(getWishlistWallets({otherParam: ""}))
      .then((res: any) => {
        const response = res.payload;
        if (response && response.statusCode === 200) {
          setWeddingWishlistData(response.payload?.weddingWishlist);
          setHasWedding(!!Object.keys(response.payload?.weddingWishlist).length);
          setHasAsoEbi(response.payload.hasAsoEbi);
          setAsoEbi(response.payload.asoEbi);
          setFocusOnAsoEbi(response.payload.hasAsoEbi);
          setFocusOnWeddingWishlist(!!Object.keys(response.payload?.weddingWishlist).length && !response.payload.hasAsoEbi);
          if (localStorage.getItem("userType") === "Organisation") {
            setCurrentWishlistData(response.payload.events[0]);
          } else {
            setCurrentWishlistData(response.payload.wishlists[0]);
          }
        } else {
          return toast({
            status: "error",
            description: response.payload.message || "There is an error",
          });
        }
        setLoading(false);
      })
      .finally(() => setLoading(false));
    // setCurrentWishlistData(walletsDataPayload[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getWishlistWallets]);

  const EmptyWithdrawalCmp: React.FC<{ desc: string; title: string }> = ({
    desc,
    title,
  }) => {
    return (
      <>
        <Flex h="300px" justifyContent={"center"} alignItems="center">
          <Flex
            flexDirection={"column"}
            gap="10px"
            fontFamily={"Plus Jakarta Sans"}
            alignItems="center"
          >
            <Image
              src={"https://media.giphy.com/media/26hkhPJ5hmdD87HYA/giphy.gif"}
              alt="Nothing yet"
              width="100px"
              height="100px"
              borderRadius={"50%"}
            />
            <Flex flexDirection={"column"} gap="10px" alignItems="center">
              <Text fontSize={"20px"} fontWeight="400">
                {title}
              </Text>
              <Text
                width="178px"
                margin="0 auto"
                textAlign="center"
                fontSize={"12px"}
                fontWeight="400"
                opacity="0.6"
              >
                {desc}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </>
    );
  };
  return (
    <>
      {isLoading ? (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      ) : (
        <>
          <Flex
            gap={{ base: "15px", md: "40px" }}
            flexDir={{ base: "column", md: "row" }}
            // border={'1px solid red'}
          >
            <Flex
              display={{ base: "none", md: "flex" }}
              flexDir={"column"}
              width="100%"
              gap="20px"
              flex="0 0 48%"
              // border={'1px solid green'}
            >
              {
                hasWedding ? 
                (
                  <>
                    <Text
                      color={"#FDD384"}
                      fontSize={"14px"}
                    >Wedding Wallets <span style={{fontSize: "10px"}} >&#9660;</span></Text>
                    {
                      hasAsoEbi ? (
                        <WishlistCardCmp
                          key={'asoebix'}
                          style={{
                            border:
                              focusOnAsoEbi
                                ? "2px solid #fff"
                                : "2px solid transparent",
                          }}
                          widthLg={"100%"}
                          minWLg={"100%"}
                          flexLg={"0 0 100%"}
                          flexBase={"0 0 100%"}
                          flex={"0 0 100%"}
                          minW={"100%"}
                          widthMd={"100%"}
                          wishlistTitle={'Aso Ebi'}
                          icon={''}
                          ubxdid={asoEbi.ubxdid}
                          hashtag={asoEbi.hashtag}
                          amount={asoEbi.guestCount || 0}
                          amountText={
                            (asoEbi.guestCount === 1) ? "GUEST" : "GUESTS"
                          }
                          // isCollection={true}
                          // amount={v.walletBalance.Naira}
                          // amountText={
                          //   v.date
                          //     ? `${getNumberOfDays(v.date)} days to go`
                          //     : "No due date"
                          // }
                          bgImage={asoEbi.coverImage}
                          removeMenu={true}
                          noVerticalMargin="0"
                          onClick={() => {
                            // setCurrentWishlistData(v);
                            // setCurrentWishlistIndex(i);
                            setFocusOnAsoEbi(true);
                            setFocusOnWeddingWishlist(false);
                          }}
                          slug={"v.slug"}
                          wishlistData={asoEbi}
                          hideCard={true}
                        />
                      ) : 
                      (
                        <></>
                      )
                    }
                    
                    <WishlistCardCmp
                      key={'weddingx'}
                      style={{
                        border:
                          focusOnWeddingWishlist
                            ? "2px solid #fff"
                            : "2px solid transparent",
                      }}
                      widthLg="100%"
                      minWLg="100%"
                      flexLg="0 0 100%"
                      flexBase="0 0 100%"
                      flex="0 0 100%"
                      minW="100%"
                      widthMd="100%"
                      wishlistTitle={weddingWishlistData.title}
                      icon={weddingWishlistData?.listcategory?.emoji}
                      amount={weddingWishlistData?.noOfWishes}
                      amountText={weddingWishlistData?.noOfWishes <= 1
                          ? "ITEM"
                          : "ITEMS"
                      }
                      // isCollection={true}
                      // amount={v.walletBalance.Naira}
                      // amountText={
                      //   v.date
                      //     ? `${getNumberOfDays(v.date)} days to go`
                      //     : "No due date"
                      // }
                      bgImage={weddingWishlistData?.coverImage}
                      removeMenu={true}
                      noVerticalMargin="0"
                      onClick={() => {
                        // setCurrentWishlistData(v);
                        // setCurrentWishlistIndex(i);
                        setFocusOnWeddingWishlist(true);
                        setFocusOnAsoEbi(false);
                      }}
                      slug={weddingWishlistData?.slug}
                      wishlistData={weddingWishlistData}
                      hideCard={true}
                    />

                    <Text
                      color={"#FDD384"}
                      fontSize={"14px"}
                    >Other Wallets <span style={{fontSize: "10px"}} >&#9660;</span></Text>
                  </>
                ) : 
                (<></>)
              }
              {walletsDataPayload &&
                walletsDataPayload.length > 0 &&
                walletsDataPayload.map((v: any, i: number) => {
                  return (
                    <WishlistCardCmp
                      key={i}
                      style={{
                        border:
                          !focusOnAsoEbi && !focusOnWeddingWishlist && currentWishlistIndex === i
                            ? "2px solid #fff"
                            : "2px solid transparent",
                      }}
                      widthLg="100%"
                      minWLg="100%"
                      flexLg="0 0 100%"
                      flexBase="0 0 100%"
                      flex="0 0 100%"
                      minW="100%"
                      widthMd="100%"
                      wishlistTitle={
                        localStorage.getItem("userType") === "Organisation"
                          ? `#${v?.hashtag}`
                          : v.title
                      }
                      icon={v?.listcategory?.emoji}
                      amount={v.noOfWishes}
                      amountText={
                        localStorage.getItem("userType") === "Organisation"
                          ? ""
                          : v.noOfWishes <= 1
                          ? "ITEM"
                          : "ITEMS"
                      }
                      // isCollection={true}
                      // amount={v.walletBalance.Naira}
                      // amountText={
                      //   v.date
                      //     ? `${getNumberOfDays(v.date)} days to go`
                      //     : "No due date"
                      // }
                      bgImage={v.coverImage}
                      removeMenu={true}
                      noVerticalMargin="0"
                      onClick={() => {
                        setCurrentWishlistData(v);
                        setCurrentWishlistIndex(i);
                        setFocusOnAsoEbi(false);
                        setFocusOnWeddingWishlist(false);
                      }}
                      slug={v.slug}
                      wishlistData={v}
                      hideCard={true}
                    />
                  );
                })}
              {(!walletsDataPayload || walletsDataPayload.length === 0) &&
                [1, 2, 3, 4, 5, 6].map((_, i) => {
                  return (
                    <EmptyCardCmp
                      flexBase="0 0 100%"
                      flex="0 0 100%"
                      minW="100%"
                      widthMd="100%"
                      key={`empty-${i}`}
                    />
                  );
                })}
            </Flex>
            {
              hasWedding ?
              (
                <Box
                display={{ base: "flex", md: "none" }}
                // border={"1px solid green"}
                justifyContent={"space-between"}
                px={"25px"}
              >
                <Text
                color={"#FDD384"}
                textDecoration={(focusOnAsoEbi || focusOnWeddingWishlist) ? "underline" : "none"}
                onClick={() => {
                  if (hasAsoEbi) {
                    setFocusOnAsoEbi(true);
                    setFocusOnWeddingWishlist(false);
                  }else {
                    setFocusOnAsoEbi(false);
                    setFocusOnWeddingWishlist(true);
                  }
                }}
                >Wedding Wallets </Text>
                <Text
                  color={"#FDD384"}
                  textDecoration={(focusOnAsoEbi || focusOnWeddingWishlist)  ? "none" : "underline"}
                  onClick={() => {
                    setFocusOnAsoEbi(false);
                    setFocusOnWeddingWishlist(false);
                  }}
                >Other Wallets</Text>
              </Box>
              ) :
              (<></>)
            }
            <Box
              display={{ base: "flex", md: "none" }}
              // border={"1px solid yellow"}
              marginRight={{ base: "-20px", md: "unset" }}
            >
              {
                hasWedding && (focusOnWeddingWishlist || focusOnAsoEbi) ? 
                (
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={1.1}
                    speed={500}
                    loop={false}
                    touchRatio={1.5}
                    style={{ width: "100%" }}
                    onSlideChange={(swiper) => {
                      if (swiper.activeIndex === 0) {
                        setFocusOnAsoEbi(true);
                        setFocusOnWeddingWishlist(false);
                      }
                      if (swiper.activeIndex === 1) {
                        setFocusOnAsoEbi(false);
                        setFocusOnWeddingWishlist(true);
                      }
                    }}
                  >
                    {
                      hasAsoEbi ? 
                      (
                        <SwiperSlide key={'asoebimini'}>
                          <WishlistCardCmp
                            style={{
                              border:
                                focusOnAsoEbi
                                  ? "2px solid #fff"
                                  : "2px solid transparent",
                            }}
                            widthMd="100%"
                            minW="100%"
                            flex="0 0 100%"
                            wishlistTitle={'Aso Ebi'}
                            icon={""}
                            amount={asoEbi.guestCount || 0}
                            amountText={
                              asoEbi.guestCount === 1 ? "GUEST" : "GUESTS"
                            }
                            bgImage={asoEbi?.coverImage}
                            walletBalance={asoEbi?.walletBalance}
                            removeMenu={true}
                            showWalletBal
                            onClick={() => {
                              // setCurrentWishlistData(v);
                              // setCurrentWishlistIndex(i);
                            }}
                            payoutBtnAction={() => {
                              navigate(
                                `/request-payout/aso_ebi_${asoEbi._id}`,
                                {
                                  state: {
                                    walletBalance: asoEbi?.walletBalance,
                                  },
                                }
                              );
                            }}
                            // slug={v.slug}
                            wishlistData={asoEbi}
                            />
                        </SwiperSlide>
                      ): 
                      (
                        <></>
                      )
                    }
                    <SwiperSlide
                    key={'weddingmini'}
                    >
                      <WishlistCardCmp
                        style={{
                          border:
                            focusOnWeddingWishlist
                              ? "2px solid #fff"
                              : "2px solid transparent",
                        }}
                        widthMd="100%"
                        minW="100%"
                        flex="0 0 100%"
                        wishlistTitle={weddingWishlistData.title}
                        icon={weddingWishlistData?.listcategory?.emoji}
                        amount={weddingWishlistData?.noOfWishes}
                        amountText={weddingWishlistData?.noOfWishes <= 1
                            ? "ITEM"
                            : "ITEMS"
                        }
                        bgImage={weddingWishlistData?.coverImage}
                        walletBalance={weddingWishlistData?.walletBalance?.Naira}
                        removeMenu={true}
                        showWalletBal
                        onClick={() => {
                          // setCurrentWishlistData(v);
                          // setCurrentWishlistIndex(i);
                          
                        }}
                        payoutBtnAction={() => {
                          navigate(
                            `/request-payout/${weddingWishlistData?.wishlistID}`,
                            {
                              state: {
                                walletBalance: weddingWishlistData?.walletBalance?.Naira,
                              },
                            }
                          );
                        }}
                        // slug={v.slug}
                        wishlistData={asoEbi}
                        />
                    </SwiperSlide>
                  </Swiper>
                ) :
                (
                  <Swiper
                    spaceBetween={15}
                    slidesPerView={1.1}
                    speed={500}
                    loop={false}
                    touchRatio={1.5}
                    style={{ width: "100%" }}
                    onSlideChange={(swiper) => {
                      setCurrentWishlistData(
                        walletsDataPayload[swiper.activeIndex]
                      );
                      setCurrentWishlistIndex(swiper.activeIndex);
                    }}
                  >
                    {walletsDataPayload &&
                      walletsDataPayload.length > 0 &&
                      walletsDataPayload.map((v: any, i: number) => {
                        return (
                          <SwiperSlide
                            key={`wallets-${i}`}
                          >
                            <WishlistCardCmp
                              key={i}
                              style={{
                                border:
                                  currentWishlistIndex === i
                                    ? "2px solid #fff"
                                    : "2px solid transparent",
                              }}
                              widthMd="100%"
                              minW="100%"
                              flex="0 0 100%"
                              wishlistTitle={v?.title}
                              icon={v?.listcategory?.emoji}
                              amount={v?.noOfWishes}
                              amountText={
                                walletsDataPayload.length <= 1 ? "ITEM" : "ITEMS"
                              }
                              bgImage={v?.coverImage}
                              walletBalance={v?.walletBalance?.Naira}
                              removeMenu={true}
                              showWalletBal
                              onClick={() => {
                                setCurrentWishlistData(v);
                                setCurrentWishlistIndex(i);
                                setFocusOnAsoEbi(false);
                                setFocusOnWeddingWishlist(false);
                              }}
                              payoutBtnAction={() => {
                                navigate(
                                  `/request-payout/${walletsDataPayload[i]?.wishlistID}`,
                                  {
                                    state: {
                                      walletBalance:
                                        currentWishlistData?.walletBalance?.Naira ||
                                        walletsDataPayload[0]?.walletBalance?.Naira,
                                    },
                                  }
                                );
                              }}
                              slug={v.slug}
                              wishlistData={v}
                            />
                          </SwiperSlide>
                        );
                      })}
                    {(!walletsDataPayload || walletsDataPayload.length === 0) &&
                      [1, 2, 3, 4, 5, 6].map((_, i) => {
                        return (
                          <SwiperSlide key={`e-${i}`}>
                            <EmptyCardCmp
                              widthMd="100%"
                              minW="100%"
                              flex="0 0 100%"
                            />
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                )
              }
            </Box>
            <Flex
              flexDir={"column"}
              gap="15px"
              flex={{ base: "0 0 100%", md: "0 0 48%" }}
              height={{ base: "unset", md: "90vh" }}
              position={{ base: "unset", md: "sticky" }}
              top={{ base: "unset", md: "0" }}
            >
              <Box
                display={{ base: "none", md: "block" }}
                position="sticky"
                height={"fit-content"}
                top="0"
                zIndex={"200"}
              >
                <BalanceCmp
                  isOrganization={
                    localStorage.getItem("userType") === "Organisation"
                  }
                  widthMd="100%"
                  text={
                    focusOnAsoEbi ? "Aso Ebi" : 
                    focusOnWeddingWishlist ? weddingWishlistData?.title 
                    : currentWishlistData?.title
                  }
                  amount={
                    <NumberFormat
                      thousandSeparator
                      // prefix="₦"
                      value={
                        focusOnAsoEbi ? 
                        asoEbi.walletBalance :
                        focusOnWeddingWishlist ?
                        weddingWishlistData.walletBalance.Naira
                        :
                        (
                          localStorage.getItem("userType") === "Organisation"
                            ? currentWishlistData?.walletBalance
                            : currentWishlistData?.walletBalance &&
                              currentWishlistData?.walletBalance?.Naira
                        )
                      }
                      decimalScale={2}
                      defaultValue={"0.00"}
                      allowNegative={false}
                      placeholder={"0.00"}
                      displayType="text"
                      disabled
                    />
                  }
                  showToggle
                  h="unset"
                  showPayoutBtn
                  payoutBtnAction={() => {
                    navigate(
                      `/request-payout/${
                        focusOnAsoEbi ? `aso_ebi_${asoEbi._id}` :
                        focusOnWeddingWishlist ? weddingWishlistData?.wishlistID :
                        (
                          currentWishlistData?.wishlistID ||
                          walletsDataPayload[1]?.wishlistID ||
                          currentWishlistData?.eventID ||
                          walletsDataPayload[1]?.eventID
                        )
                      }`,
                      {
                        state: {
                          walletBalance:
                            focusOnAsoEbi ? asoEbi.walletBalance : 
                            focusOnWeddingWishlist ? weddingWishlistData?.walletBalance?.Naira : 
                            (
                              currentWishlistData?.walletBalance?.Naira ||
                              walletsDataPayload[1]?.walletBalance?.Naira ||
                              currentWishlistData?.walletBalance ||
                              walletsDataPayload[1]?.walletBalance
                            ),
                        },
                      }
                    );
                  }}
                />
                {}
              </Box>
              <Box
                fontFamily={"Plus Jakarta Sans"}
                overflowY="scroll"
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <Tabs
                  isFitted
                  isManual
                  borderColor={"rgba(255, 255, 255, 0.7)"}
                >
                  <TabList>
                    <Tab
                      color={"rgba(255, 255, 255, 0.7)"}
                      _selected={{
                        color: "white",
                        fontWeight: "400",
                        borderColor: "white",
                      }}
                    >
                      {
                        (
                          focusOnAsoEbi ? 
                          asoEbi.payments?.length :
                          focusOnWeddingWishlist ? 
                          weddingWishlistData?.contributions?.length :
                          currentWishlistData?.contributions?.length
                        ) >= 0 && (
                        <span
                          style={{
                            color: "#45AA61",
                            fontSize: "11px",
                            fontWeight: "400",
                          }}
                        >
                          {
                            (
                              focusOnAsoEbi ? 
                              asoEbi.payments?.length :
                              focusOnWeddingWishlist ? 
                              weddingWishlistData?.contributions?.length :
                              currentWishlistData?.contributions?.length
                            )
                          } new &nbsp;
                        </span>
                      )}
                      {localStorage.getItem("userType") === "Organisation"
                        ? "Ticket Sales"
                        : "Contributions"}
                    </Tab>
                    <Tab
                      color={"rgba(255, 255, 255, 0.7)"}
                      _selected={{
                        color: "white",
                        fontWeight: "400",
                        borderColor: "white",
                      }}
                    >
                      Withdrawals
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <Flex flexDir={"column"} gap="5px">
                        {(
                          focusOnAsoEbi ? 
                          asoEbi.payments?.length :
                          focusOnWeddingWishlist ? 
                          weddingWishlistData?.contributions?.length :
                          currentWishlistData?.contributions?.length
                        ) ? (
                          (
                            focusOnAsoEbi ? 
                            asoEbi.payments :
                            focusOnWeddingWishlist ? 
                            weddingWishlistData?.contributions :
                            currentWishlistData?.contributions
                          ).map(
                            (v: any, i: number) => {
                              return (
                                <>
                                  <WalletHistoryCmp
                                    isOrganization={
                                      localStorage.getItem("userType") ===
                                      "Organisation"
                                    }
                                    key={i}
                                    name={
                                      localStorage.getItem("userType") ===
                                      "Organisation"
                                        ? v.celebrantFullname
                                        : v.giver
                                    }
                                    item={v.forItem}
                                    date={v.date}
                                    amount={
                                      <>
                                        <NumberFormat
                                          thousandSeparator
                                          prefix="₦"
                                          value={v.paid}
                                          decimalScale={2}
                                          defaultValue={"0.00"}
                                          allowNegative={false}
                                          placeholder="0.00"
                                          displayType="text"
                                          disabled
                                        />
                                      </>
                                    }
                                    quantity={v.quantity}
                                    charge={
                                      <>
                                        <NumberFormat
                                          thousandSeparator
                                          prefix="₦"
                                          value={v.deduction}
                                          decimalScale={2}
                                          defaultValue={"0.00"}
                                          allowNegative={false}
                                          placeholder="0.00"
                                          displayType="text"
                                          disabled
                                        />
                                      </>
                                    }
                                    rate={"5"}
                                    total={
                                      <>
                                        <NumberFormat
                                          thousandSeparator
                                          prefix="₦"
                                          value={v.amount}
                                          decimalScale={2}
                                          defaultValue={"0.00"}
                                          allowNegative={false}
                                          placeholder="0.00"
                                          displayType="text"
                                          disabled
                                        />
                                      </>
                                    }
                                  />
                                  {i !==
                                  (
                                    focusOnAsoEbi ? 
                                    asoEbi.payments?.length :
                                    focusOnWeddingWishlist ? 
                                    weddingWishlistData?.contributions?.length :
                                    currentWishlistData?.contributions?.length
                                  ) && <Divider opacity="0.5" />}
                                </>
                              );
                            }
                          )
                        ) : (
                          <EmptyWithdrawalCmp
                            title="Nothing yet!"
                            desc="There are no contributions to your wishlist yet"
                          />
                        )}
                      </Flex>
                    </TabPanel>
                    <TabPanel>
                      <Flex flexDir={"column"} gap="5px">
                        {
                        (
                          focusOnAsoEbi ? 
                          asoEbi.withdrawals?.length :
                          currentWishlistData?.withdrawals?.length
                        ) ? (
                          (
                            focusOnAsoEbi ? 
                            asoEbi.withdrawals :
                            focusOnWeddingWishlist ? 
                            weddingWishlistData?.withdrawals :
                            currentWishlistData?.withdrawals
                          ).map(
                            (v: any, i: number) => {
                              return (
                                <>
                                  <WithdrawalHistoryCmp
                                    key={i}
                                    accountNo={v.accountNumber}
                                    bankName={v.bankName}
                                    date={v.date}
                                    amount={
                                      <>
                                        <NumberFormat
                                          thousandSeparator
                                          // prefix="₦"
                                          value={v.amount.Amount}
                                          decimalScale={2}
                                          defaultValue={"0.00"}
                                          allowNegative={false}
                                          placeholder="0.00"
                                          displayType="text"
                                          disabled
                                        />
                                      </>
                                    }
                                    status={v.status}
                                  />
                                  {i !==
                                    (
                                      focusOnAsoEbi ? 
                                      asoEbi.withdrawals?.length :
                                      focusOnWeddingWishlist ? 
                                      weddingWishlistData?.withdrawals?.length :
                                      currentWishlistData?.withdrawals?.length
                                    ) -
                                      1 && <Divider opacity="0.5" />}
                                </>
                              );
                            }
                          )
                        ) : (
                          <EmptyWithdrawalCmp
                            title="Nothing yet!"
                            desc="All withdrawals from your wallet to a bank account will be logged here"
                          />
                        )}
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default Wallet;
