import { useEffect, useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import { AiOutlinePlus } from "react-icons/ai";
import { Flex, Box, Text, Icon, useToast } from "@chakra-ui/react";

import { WeddingPageContext } from "apis";
import { useAppDispatch } from "hooks";

import { LogoLoader } from "components";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import AddWishlist from "./components/AddWishlist";
import { weddingActions } from "store/slices";
import { getDashboard } from "utils/workbench/APIs/routes/dashboard";
import {
  UpdateWeddingCollection,
  WeddingCollection,
} from "utils/workbench/STOREs/collections/wedding.collection";

const WishlistView: React.FC<{
  dashboardWishlists: {
    _id: string;
    coverImage: string;
    title: string;
    noOfGifts: number;
    listCategoryEmoji: string;
  }[];
  selectedWishlistID: string;
  setSelectedWishlistID: (selectedWishlistID: string) => void;
  setAddedWishlist: (addedWishlist: boolean) => void;
}> = ({
  dashboardWishlists,
  selectedWishlistID,
  setSelectedWishlistID,
  setAddedWishlist,
}) => {
  return (
    <Flex
      flexWrap={"wrap"}
      gap="10px"
      justifyContent={"center"}
      overflowY={"auto"}
    >
      {dashboardWishlists.map((v: any, i: number) => {
        return (
          <Box
            key={i}
            maxW="48%"
            height="143px"
            flex={"0 0 50%"}
            position="relative"
            bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${v.coverImage})`}
            backgroundSize="cover"
            backgroundRepeat={"no-repeat"}
            borderRadius="15px"
            cursor={"pointer"}
            border={selectedWishlistID === v._id ? "1px solid #CEFABB" : "none"}
            onClick={() => {
              setSelectedWishlistID(v._id);
              setAddedWishlist(true);
            }}
          >
            {selectedWishlistID === v._id && (
              <Flex
                bgColor={"#0F441E"}
                justifyContent="center"
                alignItems={"center"}
                borderRadius={"50%"}
                w="40px"
                h="40px"
                position="absolute"
                right="10px"
                top="10px"
              >
                <CheckIcon color="#80BA7C" w={4} h={4} />
              </Flex>
            )}
            <Text
              position={"absolute"}
              bottom="18px"
              px="1rem"
              fontSize={"14px"}
              fontWeight="400"
            >
              {v.title.length > 15 ? `${v.title.slice(0, 15)}...` : v.title}
            </Text>
          </Box>
        );
      })}
    </Flex>
  );
};

const CashGiftView: React.FC<{
  isCashGift: boolean;
  setCashGift: (isCashGift: boolean) => void;
}> = ({ isCashGift, setCashGift }) => {
  return (
    <Flex pt={"30px"} justifyContent={"center"}>
      <Flex
        width={"18px"}
        height={"18px"}
        border={"2px solid #44A560"}
        mt={"5px"}
        justifyContent={"center"}
        alignItems={"center"}
        cursor={"pointer"}
        onClick={() => {
          setCashGift(!isCashGift);
        }}
      >
        {isCashGift ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 7.00009L9 19.0001L3.5 13.5001L4.91 12.0901L9 16.1701L19.59 5.59009L21 7.00009Z"
              fill="white"
            />
          </svg>
        ) : (
          <></>
        )}
      </Flex>
      <Box width={"18px"}></Box>
      <Text width={"260px"}>
        I prefer to receive cash gift from my family and friends
      </Text>
    </Flex>
  );
};

const WishlistAndCashGiftView: React.FC<{
  children?: JSX.Element;
}> = ({ children }) => {
  return (
    <Box>
      <Text width={"250px"} color={"#9A9797"} mb={"20px"}>
        You have also chosen to receive cash gift
      </Text>
      <>{children}</>
    </Box>
  );
};

const StepSix: React.FC<{
  nextBtnAction: () => void;
  prevBtnAction: () => void;
}> = ({ prevBtnAction }) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const { weddingDataObj } = WeddingCollection();
  const [loading, setLoading] = useState(false);
  const [selectedWishlistID, setSelectedWishlistID] = useState("");
  const [addedWishlist, setAddedWishlist] = useState(false);
  const dispatch = useAppDispatch();
  const { addWishlistToWedding } = weddingActions;
  const [addWishlistLoading, setAddWishlistLoading] = useState(false);

  const [dashboardWishlists, setDashboardWishlists] = useState(
    [] as {
      _id: string;
      coverImage: string;
      title: string;
      noOfGifts: number;
      listCategoryEmoji: string;
    }[]
  );

  useEffect(
    () => {
      setLoading(true);
      getDashboard((response) => {
        if (response.statusCode === 200) {
          const weddingWishlists = response.payload.wishlists.filter(
            (list) => list.listCategoryEmoji === "👰"
          );
          setDashboardWishlists(weddingWishlists);
          for (const wedding of weddingWishlists) {
            if (
              weddingDataObj.wishlistID &&
              wedding._id === weddingDataObj.wishlistID
            ) {
              setSelectedWishlistID(weddingDataObj.wishlistID);
            }
          }
        }
        if (response.statusCode === 400 || response.errors)
          toast({ status: "error", description: response.message });
        setLoading(false);
      });
    },
    // eslint-disable-next-line
    []
  );

  const [tabState, setTabState] = useState<1 | 2 | 3>(1);
  const [isCashGift, setCashGift] = useState(false);

  const [showEditorModal, setShowEditorModal] = useState(false);

  const TabsCmp = {
    1: (
      <WishlistView
        dashboardWishlists={dashboardWishlists}
        selectedWishlistID={selectedWishlistID}
        setSelectedWishlistID={setSelectedWishlistID}
        setAddedWishlist={setAddedWishlist}
      />
    ),
    2: <CashGiftView isCashGift={isCashGift} setCashGift={setCashGift} />,
    3: (
      <WishlistAndCashGiftView>
        <WishlistView
          dashboardWishlists={dashboardWishlists}
          selectedWishlistID={selectedWishlistID}
          setSelectedWishlistID={setSelectedWishlistID}
          setAddedWishlist={setAddedWishlist}
        />
      </WishlistAndCashGiftView>
    ),
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap="3px"
        pt="10px"
        pb="4px"
        display={{ base: "none", sm: "unset" }}
      >
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize={"12px"}
          fontWeight="400"
        >
          Step 6
        </Text>
        <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
          Add wishlist
        </Text>
        <Text color="rgba(255, 255, 255, 0.5)" mt="8px">
          Help your family and friends know what you need
        </Text>
      </Flex>
      <Flex justifyContent={"center"} py="1rem">
        <Flex flexDirection={"column"} gap="6px" width={"100%"}>
          <Flex justifyContent={"space-between"} mb={"10px"}>
            <Text
              color={tabState === 1 ? "#39A069" : "none"}
              borderBottom={tabState === 1 ? "2px solid #39A069" : "none"}
              cursor={"pointer"}
              onClick={() => {
                setTabState(1);
              }}
            >
              Wishlist
            </Text>
            <Text
              color={tabState === 2 ? "#39A069" : "none"}
              borderBottom={tabState === 2 ? "2px solid #39A069" : "none"}
              cursor={"pointer"}
              onClick={() => {
                setTabState(2);
              }}
            >
              Cash gift
            </Text>
            <Text
              color={tabState === 3 ? "#39A069" : "none"}
              borderBottom={tabState === 3 ? "2px solid #39A069" : "none"}
              cursor={"pointer"}
              onClick={() => {
                setTabState(3);
              }}
            >
              Wishlist & Cash gift
            </Text>
          </Flex>
          <Flex flexDirection={"column"} height={"350px"} overflowY={"auto"}>
            {loading ? (
              <Flex justifyContent={"center"} alignItems="center" py="1rem">
                <LogoLoader />
              </Flex>
            ) : (
              <>{TabsCmp[tabState]}</>
            )}
            <Box
              cursor="pointer"
              fontWeight={"400"}
              fontSize="15px"
              color="#CEFABB"
              textAlign={"center"}
              py="1rem"
              display={tabState === 1 || tabState === 3 ? "flex" : "none"}
              alignItems="center"
              justifyContent={"center"}
              gap="4px"
              onClick={() => setShowEditorModal(true)}
            >
              <Icon as={AiOutlinePlus} />
              <Text> create new wishlist </Text>
            </Box>
          </Flex>
          <Box
            position="sticky"
            padding="1rem"
            bgColor={"#1F2229"}
            zIndex="3"
            textAlign="center"
            display="flex"
            gap="5px"
            bottom={{ base: "-20px", sm: "-80px" }}
            justifyContent={"center"}
          >
            <ButtonCmp
              text={"Previous"}
              size="md"
              color="#44A560"
              backgroundColor="transparent"
              opacity={"1"}
              border={"1px solid #44A560"}
              textHoverColor={"#fff"}
              width="100%"
              height="55px"
              maxWidth="150px"
              type="submit"
              onClick={() => {
                prevBtnAction();
              }}
            />
            <ButtonCmp
              text={"Finish"}
              size="md"
              opacity={"1"}
              border={"none"}
              textHoverColor={"#fff"}
              width="100%"
              height="55px"
              maxWidth="150px"
              isDisabled={!selectedWishlistID}
              type="submit"
              isLoading={addWishlistLoading}
              onClick={() => {
                if (addedWishlist) setAddWishlistLoading(true);

                dispatch(
                  addWishlistToWedding({
                    ...(tabState === 2
                      ? {}
                      : { wishlistID: selectedWishlistID }),
                    weddingID: weddingDataObj?._id,
                    includeCashGift:
                      tabState === 1
                        ? false
                        : tabState === 2
                        ? isCashGift
                        : true,
                  })
                )
                  .then((res: any) => {
                    const response = res;
                    if (
                      response &&
                      response.payload &&
                      response.payload.statusCode === 200
                    ) {
                      setAddWishlistLoading(false);
                    }
                  })
                  .finally(() => {
                    UpdateWeddingCollection((weddingCollection) => {
                      weddingCollection.weddingPageContext =
                        WeddingPageContext.DASHBOARD;
                    });
                    setAddWishlistLoading(false);
                  });
              }}
            />
          </Box>
        </Flex>
      </Flex>

      <AddWishlist
        isOpen={showEditorModal}
        logoLoading={loading}
        setLogoLoading={setLoading}
        setSelectedWishlistID={setSelectedWishlistID}
        onClose={() => {
          setShowEditorModal(false);
          setAddedWishlist(true);
        }}
        setDashboardWishlists={setDashboardWishlists}
      />
    </>
  );
};

export default StepSix;
