import { ISVGS } from "./types";

const WishlistIcon = (props: ISVGS) => (
  <svg
    width="85"
    height="113"
    viewBox="0 0 85 113"
    fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0625 84.5859H84.25V0.335938H49.1458V42.4609L35.1042 28.4193L21.0625 42.4609V0.335938H14.0417C6.31875 0.335938 0 6.65469 0 14.3776V98.6276C0 106.351 6.31875 112.669 14.0417 112.669H84.25V98.6276H21.0625C16.85 98.6276 14.0417 95.8193 14.0417 91.6068C14.0417 87.3943 16.85 84.5859 21.0625 84.5859Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
  </svg>
);

export default WishlistIcon;
