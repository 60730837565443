import { Box, Text, Flex } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ModalCmp from "components/ModalCmp/ModalCmp";
import { useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageCropperModal.css";
import { ArrowBackIcon } from "@chakra-ui/icons";
// import { PhotoSmall, PhotoBig } from "assets/svgs";
// import {
//   Slider,
//   SliderTrack,
//   SliderFilledTrack,
//   SliderThumb
// } from "@chakra-ui/react";

// const MIN_ZOOM = 0.0;

const ImageCropperModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  onCropComplete: (url: any) => void;
  onBackButtonClick?: any;
  maxWidth: string;
  showCloseIcon?: boolean;
  imageToCrop: string;
}> = ({
  isOpen,
  onRequestClose,
  onCropComplete,
  maxWidth,
  showCloseIcon,
  imageToCrop,
}) => {
  const [cropper, setCropper] = useState<any>();
  const [, setCropData] = useState("");
  // const [zoom, setZoom] = useState(MIN_ZOOM);

// useEffect(() => {
//     if (!isOpen){
//       setZoom(MIN_ZOOM)
//     }
//   }, [setZoom, isOpen]);

//   console.log(zoom);
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px", height: "600px" }}
        shouldFocusAfterRender={false}
        contentLabel="Image Cropper Modal"
        id="image-cropper-modal"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        showCloseIcon={showCloseIcon}
      >
        <Box w="100%" h="300px" px="10px">
          <Box position={"relative"} py="20px" color="#fff">
            <ArrowBackIcon
              color="#fff"
              position="absolute"
              top="35%"
              h={8}
              w={8}
              cursor="pointer"
              onClick={() => {
                  onRequestClose();
                }}
            />
            <Text fontSize={"20px"} textAlign={"center"}>
              Crop Image
            </Text>
          </Box>
          <Cropper
            style={{ width: "100%", height: "300px" }}
            zoomTo={0.5}
            initialAspectRatio={23 / 15}
            aspectRatio={23 / 15}
            preview=".img-preview"
            src={imageToCrop}
            viewMode={1}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            background={false}
            responsive={true}
            autoCropArea={1}
            zoomOnTouch={false}
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />

          {/* <Box>
            <Flex justifyContent={"center"} py="1rem">
              <img src={PhotoSmall} alt="book" width="25px" height="25px" />
              <Slider
                min={MIN_ZOOM}
                max={1.3}
                step={0.001}
                aria-label="slider"
                colorScheme="#ffffff"
                size="sm"
                w="50%"
                value={zoom}
                onChange={(zoom) => setZoom(zoom)}
                mx="0.5rem"
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb bgColor={"#cefabb"} p="2" />
              </Slider>
              <img src={PhotoBig} alt="book" width="45px" height="45px" />
            </Flex>
          </Box> */}
          <Flex justifyContent={"center"} py="1rem">
            <Box>
              <ButtonCmp
                text={"Save"}
                size="md"
                onClick={() => {
                  if (typeof cropper !== "undefined") {
                    // cropper.getCroppedCanvas().toBlob((blob: any) => {
                    //   const url = URL.createObjectURL(blob);
                    //   setCropData(url);
                    //   onCropComplete(url);
                    // });
                    setCropData(
                      cropper.getCroppedCanvas().toDataURL("image/jpeg")
                    );

                    onCropComplete(
                      cropper.getCroppedCanvas().toDataURL("image/jpeg")
                    );
                  }
                  onRequestClose();
                }}
                opacity={"1"}
                height="50px"
                border={"none"}
                textHoverColor={"#fff"}
                width="fit-content"
                maxWidth="100%"
                type="submit"
              />
            </Box>
          </Flex>
        </Box>
      </ModalCmp>
    </>
  );
};

export default ImageCropperModal;
