import { Box, Text } from "@chakra-ui/react";
import React from "react";
import ModalCmp from "../ModalCmp/ModalCmp";

const AddItemPricingModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({ isOpen, onRequestClose, maxWidth, showCloseIcon }) => {
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Terms"
        id="terms"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box
          textAlign={"center"}
          padding="2rem 1rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Text
            color="#fff"
            fontWeight={"700"}
            fontSize="30px"
            paddingBottom={"1rem"}
          >
            Our Pricing
          </Text>
          <Box textAlign={"left"}>
            <Text fontWeight={"400"} fontSize={"20px"}>
              Setup wishlist for free
            </Text>
            <Text paddingBottom={"2rem"} fontWeight={"400"} fontSize={"16px"}>
              We do not charge you any fee to create a wishlist for any
              occasion.
            </Text>
            <Text fontWeight={"400"} fontSize={"20px"}>
              5% charge for contributors
            </Text>
            <Text paddingBottom={"1rem"} fontWeight={"400"} fontSize={"16px"}>
              If you view your wishlist from the shareable link, you will notice
              an increase in the amount you set. The increase is a 5% markup to
              all of your items. This covers the transaction fees and is paid
              for by people who contribute to your wishlist. This addition helps
              you get the exact amount you set for each item without any
              deductions from unboxd.
            </Text>
            <Text paddingBottom={"2rem"}>
              For example, if you add an item to your wishlist and set the price
              at N1,000. When you share your wishlist, what your loved ones will
              see is N1,050. The N50.00 is the 5% addition and it is paid for by
              your loved ones.
            </Text>
            <Text fontWeight={"400"} fontSize={"20px"}>
              N100.00 for payout
            </Text>
            <Text paddingBottom={"1rem"} fontWeight={"400"} fontSize={"16px"}>
              The first payout from your wallet is free. But sebsequent payouts
              to your local bank account attracts an N100.00 fee.
            </Text>
          </Box>
        </Box>
      </ModalCmp>
    </>
  );
};

export default AddItemPricingModal;
