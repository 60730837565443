import { Box } from "@chakra-ui/react";

const EmptyCardCmp: React.FC<{
	widthMd?: string;
	minW?: string;
	flex?: string;
	height?: string;
	widthBase?: string;
	minWidthBase?: string;
	flexBase?: string;
	bgColor?: string;
}> = ({
	bgColor,
	widthMd,
	minW,
	flex,
	height,
	widthBase,
	minWidthBase,
	flexBase,
}) => {
	return (
		<Box
			w={{ base: `${widthBase || "100%"}`, md: `${widthMd || "48%"}` }}
			minW={{ base: `${minWidthBase || "100%"}`, md: `${minW || "48%"}` }}
			flex={{
				base: `${flexBase || "0 0 48%"}`,
				md: `${flex || "0 0 48%"}`,
			}}
			h={{ base: "240px", sm: height || "227px" }}
			minH={{ base: "240px", sm: height || "227px" }}
			bg={bgColor || "linear-gradient(180deg, #16181D, #16181D33)"}
			pos={"inherit"}
			borderRadius={{ base: "10px", md: "18px" }}
			my={"12px"}
		></Box>
	);
};

export default EmptyCardCmp;
