import { Box, Text, Flex } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { useState } from "react";
import ModalCmp from "../ModalCmp/ModalCmp";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const DateRangeModal: React.FC<{
  isOpen: boolean;
  onRequestClose: (state?: any) => void;
  maxWidth: string;
  showCloseIcon?: boolean;
  callbackFn?: (obj: any) => void;
  saveFn?: (obj: any) => void;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  callbackFn,
  saveFn,
}) => {
  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: undefined,
      key: "selection",
    },
  ]);

  const [active, setActive] = useState(true);

  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Date"
        id="date"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box fontWeight={"500"} padding="2rem 1rem">
          <Text fontSize="20px" color={"#fff"}>
            Date picker
          </Text>
          <Text fontSize={"12px"} color="rgba(255, 255, 255, 0.5)">
            choose a date range to filter data
          </Text>
          <Box pt="1rem" textAlign={"center"}>
            <DateRange
              editableDateInputs={true}
              startDatePlaceholder={"mm/dd/yyy"}
              endDatePlaceholder={"mm/dd/yyy"}
              //   disabledDay={(date: Date) => {
              //     return date.getTime() < new Date().getTime();
              //   }}
              onChange={(item: any) => {
                setState([item.selection]);
                setActive(
                  item?.selection?.startDate.getTime() >
                    item?.selection.endDate.getTime()
                    ? true
                    : false
                );
                if (callbackFn) {
                  callbackFn(item.selection);
                }
              }}
              moveRangeOnFirstSelection={true}
              ranges={state}
            />
          </Box>
          <Flex
            gap="10px"
            alignItems={"center"}
            justifyContent="flex-end"
            pt="1rem"
          >
            <Text
              cursor={"pointer"}
              onClick={() => {
                onRequestClose(state);
              }}
            >
              cancel
            </Text>
            <ButtonCmp
              text={"Save"}
              size="md"
              padding="1.5rem 1rem"
              // onClick={payoutBtnAction}
              // isLoading={loggingIn}
              onClick={() => {
                onRequestClose();
                if (saveFn) {
                  saveFn(state);
                }
              }}
              isDisabled={active}
              opacity={"1"}
              height="48px"
              border={"none"}
              width="64px"
              maxWidth="100%"
              type="submit"
            />
          </Flex>
        </Box>
      </ModalCmp>
    </>
  );
};

export default DateRangeModal;
