import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { ItemPlaceholder } from "assets/svgs";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { FormikFormInput } from "components/InputsCmp/CustomInputCmp";
import ActionModal from "components/Modals/ActionModal";
import AddItemPricingModal from "components/Modals/AddItemPricingModal";
import ImageCropperModal from "components/Modals/ImageCropperModal";
import UploadImage from "components/UploadImage";
import { FormikProvider, useFormik } from "formik";
import { useAppDispatch } from "hooks";
import { useCallback, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { secretSantaActions } from "store/slices";
import { WISHLISTVALIDATIONS } from "validators/WishlistValidator";
import * as yup from "yup";

const CreateGiftExchange = () => {
  const addWishValidationSchema = yup.object({
    title: WISHLISTVALIDATIONS.title,
    grossCost: WISHLISTVALIDATIONS.grossCost,
  });

  const { id } = useParams<{ id: string }>();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const navigate = useNavigate();

  const [showImageModal, setShowImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [file, setFile] = useState<File | string>("");
  const [isExit, setIsExit] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const dispatch = useAppDispatch();
  const { addWishItemToGiftExchange } = secretSantaActions;

  const fromUnsplash = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setShowImageCropper(true);
    setFile(imageUrl);
    setShowImageModal(false);
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    setImage(URL.createObjectURL(file));
    setShowImageCropper(true);
    setShowImageModal(false);
  }, []);

  const formik = useFormik<{ title: string; grossCost: any }>({
    initialValues: {
      title: "",
      grossCost: "",
    },
    validationSchema: addWishValidationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!image) {
        toast({
          status: "error",
          description: "Pick an image",
        });
        return;
      }

      const payload = new FormData();

      payload.append("title", values.title);
      payload.append("coverImage", file);
      payload.append("giftExchangeID", id!);
      payload.append("grossCost", values.grossCost);
      payload.append(
        "netCost",
        `${(Number(values.grossCost) * 5) / 100 + Number(values.grossCost)}`
      );

      // const payload = {
      //   title: values.title,
      //   coverImage: file,
      //   giftExchangeID: id!,
      //   grossCost: values.grossCost,
      //   netCost: `${
      //     (Number(values.grossCost) * 5) / 100 + Number(values.grossCost)
      //   }`,
      // };

      setLoading(true);
      dispatch(addWishItemToGiftExchange(payload))
        .then((res: any) => {
          const response = res;
          setLoading(false);
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            resetForm();
            navigate(`/secret-santa/${id}`);
            toast({
              status: "success",
              description: "Gift item added successfully",
            });
          } else {
            toast({
              status: "error",
              description: response.payload.message,
            });
          }
        })
        .catch(() => {
          toast({
            status: "error",
            description: "There is an error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            color="#fff"
            h={8}
            w={8}
            cursor="pointer"
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <FormikProvider value={formik}>
          <form
            noValidate
            onSubmit={formik.handleSubmit}
            style={{ width: "100%" }}
          >
            <Flex
              flexDirection={{ base: "column" }}
              gap="30px"
              alignItems={{ base: "center", lg: "flex-start" }}
              justifyContent="center"
              w="345px"
              m="0 auto"
            >
              <UploadImage
                fromGallery={fromGallery}
                fromUnsplash={fromUnsplash}
                image={image}
                instruction={"upload item image"}
                placeholder={ItemPlaceholder}
                showImageModal={showImageModal}
                setShowImageModal={setShowImageModal} setShowImageCropper={function (_: boolean): void {
                  throw new Error("Function not implemented.");
                } }              />
              <Box width="343px">
                <Box display={"flex"} flexDirection="column" gap="1px">
                  <Box>
                    <FormikFormInput
                      placeholder="item title"
                      name="title"
                      disabled={false}
                      showMessage={true}
                    />
                  </Box>
                  <Box>
                    <FormikFormInput
                      type="text"
                      placeholder="price"
                      name="grossCost"
                      disabled={false}
                      showMessage={true}
                      isNumericFormat
                    />
                  </Box>
                </Box>
                <Flex
                  color={"rgba(255, 255, 255, 0.5)"}
                  fontWeight="400"
                  fontSize="15px"
                  fontFamily={"Plus Jakarta Sans"}
                  justifyContent={"space-between"}
                  pb="2rem"
                >
                  <Text>+ Unboxd fee @5%</Text>
                  <Text>
                    <NumberFormat
                      thousandSeparator
                      prefix="₦"
                      value={formik?.values?.grossCost * 1.05}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  </Text>
                </Flex>
                <Box width={{ base: "100%" }}>
                  <ButtonCmp
                    text={"PROCEED"}
                    size="md"
                    padding="1.5rem 3rem"
                    isLoading={loading}
                    isDisabled={formik.isValid ? false : true}
                    opacity={"1"}
                    border={"none"}
                    textHoverColor={"#fff"}
                    width="100%"
                    maxWidth="100%"
                    type="submit"
                  />
                </Box>
                <Box display="flex" justifyContent={"center"} pt="1.5rem">
                  <Text
                    width="210px"
                    color="rgba(255, 255, 255, 0.7)"
                    fontWeight={"400"}
                    fontSize="13px"
                    textAlign={"center"}
                  >
                    We add a 5% markup on the price to cover transaction fees.{" "}
                    <span
                      style={{
                        color: "#fff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowPricingModal(!showPricingModal);
                      }}
                    >
                      Learn more
                    </span>
                  </Text>
                </Box>
              </Box>
            </Flex>
          </form>
        </FormikProvider>
        <AddItemPricingModal
          isOpen={showPricingModal}
          onRequestClose={() => {
            setShowPricingModal(false);
          }}
          maxWidth="400px"
          showCloseIcon={true}
        />
        <ActionModal
          isOpen={isExit}
          onRequestClose={() => {
            setIsExit(false);
          }}
          maxWidth="400px"
          showCloseIcon={false}
          actionTitle="are you sure?"
          actionDesc="You will lose progress on this iteem if you don’t save it before you go to another page"
          yesText="YES, EXIT"
          noText="NO, CANCEL"
          yesAction={() => {
            navigate(-1);
          }}
          noAction={() => {
            setIsExit(false);
          }}
        />
        <ImageCropperModal
          isOpen={showImageCropper}
          imageToCrop={image}
          onRequestClose={() => {
            setShowImageCropper(false);
          }}
          onCropComplete={(url: any) => {
            setImage(url);
            setFile(url);
          }}
          onBackButtonClick={() => {
            setShowImageCropper(false);
            setShowImageModal(true);
          }}
          maxWidth="500px"
          showCloseIcon={false}
        />
      </Box>
    </>
  );
};

export default CreateGiftExchange;
