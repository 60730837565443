const createPageName = (data: string) => {
  let pageName = "";
  if (data.includes("/")) {
    pageName = data.split("/")[data.split("/").length - 1].split("-").join(" ");
    if (isValidObjectID(pageName) || isAsoEbiID(pageName)) {
      pageName = data
        .split("/")
        [data.split("/").length - 2].split("-")
        .join(" ");
    }
  } else {
    pageName = data.slice(1).charAt(0).toUpperCase() + data.slice(2);
  }
  return pageName;
};

export const isValidObjectID = (id: string) =>
  !!id.match(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i);

export const isAsoEbiID = (asoEbiID: string) => {
  let isAsoEbi = false;
  let weddingID = "";
  const arr = asoEbiID.split('_', 3);
  if (arr.length) {
    if (arr[0] === 'aso' && arr[1] === 'ebi') {
      isAsoEbi = true;
      weddingID =  arr[2];
    }
  }
  return { isAsoEbi, weddingID };
};

export default createPageName;
