import { WeddingEventType, WeddingResponse } from "apis";
import { DELETE, GET, POST, PUT, responseCallback } from "../baseAPI";


type GetWeddingParams = Partial<{
  id: string;
  userID: string;
}>;
export const getWedding = ({id, userID}: GetWeddingParams, responseCallback: responseCallback<WeddingResponse>) => GET(
  {
    ext: 'wedding',
    query: {id, userID},
  },
  responseCallback,
);


type UpdateWeddingParams = Object;
export const updateWedding = (body: UpdateWeddingParams, responseCallback: responseCallback<WeddingResponse>) => PUT(
  {
    ext: 'wedding',
    body,
  },
  responseCallback,
);


type CreateWeddingParams = UpdateWeddingParams;
export const createWedding = (body: CreateWeddingParams, responseCallback: responseCallback<WeddingResponse>) => POST(
  {
    ext: 'wedding',
    body,
  },
  responseCallback,
);


type CreateWeddingEventScheduleParams = {
  weddingID: string;
  eventSchedules: {
      type: WeddingEventType;
      address: string;
      colors: {
        color1: string;
        color1Name: string;
        color2: string;
        color2Name: string;
      };
      date: {
          day: string;
          time: string;
      };
  }[];
};
export const createWeddingEventSchedule = (body: CreateWeddingEventScheduleParams, responseCallback: responseCallback<WeddingResponse>) => POST(
  {
    ext: 'wedding/schedule',
    body,
  },
  responseCallback,
);


type UpdateWeddingEventScheduleParams = {
  eventScheduleID: string;
  type: WeddingEventType;
  address: string;
  colors: {
    color1: string;
    color1Name: string;
    color2: string;
    color2Name: string;
  };
  date: {
      day: string;
      time: string;
  };
};
export const updateWeddingEventSchedule = (body: UpdateWeddingEventScheduleParams, responseCallback: responseCallback<WeddingResponse>) => PUT(
  {
    ext: 'wedding/schedule',
    body,
  },
  responseCallback,
);


type DeleteWeddingEventScheduleParams = {
  eventScheduleID: string;
};
export const deleteWeddingEventSchedule = (body: DeleteWeddingEventScheduleParams, responseCallback: responseCallback<WeddingResponse>) => DELETE(
  {
    ext: 'wedding/schedule',
    body,
  },
  responseCallback,
);

type CreateWeddingFAQParams = {
  weddingID: string;
  faqs: {
    question: string;
    answer: string;
  }[];
};
export const createWeddingFAQ = (body: CreateWeddingFAQParams, responseCallback: responseCallback<WeddingResponse>) => POST(
  {
    ext: 'wedding/faq',
    body,
  },
  responseCallback,
);

type UpdateWeddingFAQParams = {
  weddingID: string;
  faqs: {
    question: string;
    answer: string;
  }[];
};
export const updateWeddingFAQs = (body: UpdateWeddingFAQParams, responseCallback: responseCallback<WeddingResponse>) => PUT(
  {
    ext: 'wedding/faqs/new',
    body,
  },
  responseCallback,
);


type EditWeddingFAQParams = {
  faqID: string;
  question: string;
  answer: string;
};
export const editWeddingFAQ = (body: EditWeddingFAQParams, responseCallback: responseCallback<WeddingResponse>) => PUT(
  {
    ext: 'wedding/faq',
    body,
  },
  responseCallback,
);


type DeleteWeddingFAQParams = {
  faqID: string;
};
export const deleteWeddingFAQ = (body: DeleteWeddingFAQParams, responseCallback: responseCallback<WeddingResponse>) => DELETE(
  {
    ext: 'wedding/faq',
    body,
  },
  responseCallback,
);

