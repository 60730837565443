/* eslint-disable no-useless-escape */
import * as yup from "yup";
import "yup-phone";

export const CONTRIBUTORVALIDATIONS = {
  fundAmount: yup
    .number()
    .required("Amount is required!")
    .test("Check minimum value", "Minimum of N100", function (value: any) {
      if (value < 100) {
        return false;
      }
      return true;
    })
    .test("Check maximum value", "Maximum of N500000", function (value: any) {
      if (value > 500000) {
        return false;
      }
      return true;
    }),
  phone: yup.string().phone(undefined, undefined, "Invalid phone number"),
};
