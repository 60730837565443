import { Flex, Image, Text } from "@chakra-ui/react";
import { HistoryImg } from "assets/svgs";

const WalletHistoryCmp: React.FC<{
  name: string;
  item: string;
  date: string;
  amount: string | JSX.Element;
  charge: string | JSX.Element;
  rate: string | JSX.Element;
  total: string | JSX.Element;
  isOrganization?: boolean;
  quantity? : string;
}> = ({
  name,
  item,
  date,
  amount,
  charge,
  rate,
  total,
  isOrganization,
  quantity,
}) => {
  return (
    <>
      <Flex
        padding="0.6rem 0"
        fontFamily={"Plus Jakarta Sans"}
        justifyContent={"space-between"}
      >
        <Flex gap="10px" flex="0 0 50%">
          <Image width={"29px"} height="29px" src={HistoryImg} alt="History" />
          <Flex flexDir={"column"} gap="2px">
            <Text fontSize={"14px"} fontWeight="400" color={"#fff"}>
              {name}
            </Text>
            <Text
              fontSize={"13px"}
              fontWeight="400"
              color={"#fff"}
              opacity="0.5"
            >
              {isOrganization ? "" : `for ${item}`}
            </Text>
            <Text
              fontSize={"12px"}
              fontWeight="400"
              color={"#FFF"}
              opacity="0.5"
            >
              {date}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir={"column"} gap="2px" textAlign="right">
          <Text fontSize={"15px"} fontWeight="400" color={"#fff"}>
            {total}
          </Text>
          {isOrganization ? (
            <Text
              fontSize={"13px"}
              textTransform={"uppercase"}
              fontWeight="400"
              color={"#fff"}
              opacity="0.5"
            >
              {`${quantity}X ${item}`}
            </Text>
          ) : (
            <>
              <Text
                fontSize={"13px"}
                fontWeight="400"
                color={"#fff"}
                opacity="0.5"
              >
                paid {amount}
              </Text>
              <Text
                fontSize={"13px"}
                fontWeight="400"
                color={"#fff"}
                opacity="0.5"
              >
                deduction ({rate}%) {charge}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default WalletHistoryCmp;
