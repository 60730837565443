export const ROUTES = {
  login: "/login",
  signup: "/register",
  verify: "/verify-user",
  ouch: "/ouch",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  wallet: "/wallet",
  Myevent: "/Myevent",
  wishlist: "/add-wishlist",
  "add-collection": "/add-collection",
  "edit-collection": "/collection/:slug/edit",
  "add-wish": "/wishlist/:slug/add-gift",
  "edit-wish": "/wishlist/:slug/edit-gift/:id",
  event: "/wishlist/:slug",
  "edit-wishlist": "/wishlist/:slug/edit",
  settings: "/settings",
  "request-payout": "/request-payout/:wishlistID",
  "choose-account": "/choose-account/:wishlistID",
  "add-bank-account": "add-account/:wishlistID",
  "verify-request": "/verify-request/:wishlistID",
  dashboard: "/",
  wedding: "/my-wedding",
  myWishlist: "/my-wishlist",
  "create-wedding": "/my-wedding/create",
  secretSanta: "/secret-santa",
  giftExchange: "/gift-exchange",
  createSecretSantaGift: "/secret-santa/create-gift/:id",
  editSecretSantaGift: "/secret-santa/edit-gift/:id",
  singleSecretSanta: "/secret-santa/:id",
  contributor: "/@:username/:slug",
  fundGift: "/@:username/:slug/:giftId",
  userProfile: "/@:username",
  notFound2: "/not-found",
  notFound: "*",
  analytics: "/analytics",
  singleAnalytics: "/analytics/single/:listcategoryID",
};
