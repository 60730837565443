import { WeddingEventSchedule, WeddingEventType } from "apis";

import { useState, useEffect } from "react";
import { BsBookmark } from "react-icons/bs";
import { Flex, Box, Text, Icon, useToast, Select } from "@chakra-ui/react";

/* import { times } from "data/times"; */
import EditEvent from "./components/EditEvent";
import fastColorChecker from "utils/FastColorChecker";
import { EditorModal } from "components/Modals/Editor";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ActionModal from "components/Modals/ActionModal";
import CustomDateCmp from "components/InputsCmp/CustomDateCmp";
/* import { BasicSelect } from "components/InputsCmp/CustomSelect"; */
import CustomInputCmp, {
  BasicInput,
} from "components/InputsCmp/CustomInputCmp";
import SingleColorPalette from "components/InputsCmp/SingleColorPalette";
import { makeEventTypeReadable } from "utils/displayWeddingEventType";
import { getDateFromDayAndTime } from "utils/date";
import { AiOutlinePlus } from "react-icons/ai";
import {
  createWeddingEventSchedule,
  deleteWeddingEventSchedule,
} from "utils/workbench/APIs/routes/wedding";
import {
  UpdateWeddingCollection,
  WeddingCollection,
} from "utils/workbench/STOREs/collections/wedding.collection";
import { to12HoursTime } from "utils/to12HourTime";

const StepTwo: React.FC<{
  nextBtnAction: () => void;
  prevBtnAction: () => void;
}> = ({ nextBtnAction, prevBtnAction }) => {
  const { weddingDataObj, weddingExists } = WeddingCollection();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });
  //   const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addedEvent, setAddEvent] = useState<
    (WeddingEventSchedule & { isDisabled: boolean })[]
  >([]);
  const [editableEventSchedule, setEditableEventSchedule] = useState<
    WeddingEventSchedule & { isDisabled: boolean }
  >({
    _id: "",
    weddingID: "",
    type: "",
    address: "",
    date: "",
    time: "",
    colors: {
      color1: "",
      color1Name: "",
      color2: "",
      color2Name: "",
    },
    brideDressCode: "",
    groomDressCode: "",
    isDisabled: true,
  });
  const [showDelete, setShowDelete] = useState(false);
  const [itemToDeleteID, setItemToDeleteID] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  // const [showEventTypePopover, setShowEventTypePopover] = useState(false);

  const [eventType, setEventType] = useState("");
  const [address, setAddress] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color1Name, setColor1Name] = useState("");
  const [color2Name, setColor2Name] = useState("");
  const [brideDressCode, setBrideDressCode] = useState("");
  const [groomDressCode, setGroomDressCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [currentEventOpen, setCurrentEventOpen] = useState(
    !weddingDataObj?.schedules?.length
  );

  const checkValid = (
    _eventType: string,
    _address: string,
    _date: string,
    _time: string
  ) => {
    setIsValid(!!_eventType && !!_address && !!_date && !!_time);
  };

  useEffect(
    () => {
      if (
        weddingDataObj &&
        weddingDataObj?.coverImage &&
        weddingDataObj?.schedules.length > 0
      ) {
        setAddEvent(
          weddingDataObj?.schedules.map((sch) => ({
            ...sch,
            isDisabled: false,
          }))
        );
        checkValid(eventType, address, date, time);
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleSubmit = () => {
    if (weddingExists) nextBtnAction();

    if (isValid) {
      if (!weddingExists) nextBtnAction();
      setLoading(true);
      const payload = {
        weddingID: weddingDataObj?._id,
        eventSchedules: [
          {
            type: eventType as WeddingEventType,
            address: address,
            colors: {
              color1,
              color2,
              color1Name,
              color2Name,
            },
            date: {
              day: `${new Date(date!).toDateString()}`,
              time,
            },
            brideDressCode,
            groomDressCode,
          },
        ],
      };

      setAddEvent((prevState) => {
        const { date, ...rest } = payload.eventSchedules[0];
        (rest as WeddingEventSchedule).date =
          payload.eventSchedules[0].date.day;
        (rest as WeddingEventSchedule).time =
          payload.eventSchedules[0].date.time;
        return [
          ...prevState,
          {
            ...(rest as WeddingEventSchedule),
            isDisabled: true,
          },
        ];
      });

      payload.eventSchedules[0].date.time = to12HoursTime(payload.eventSchedules[0].date.time);
      
      if (weddingExists) {
        createWeddingEventSchedule(payload, (response) => {
          if (response.statusCode === 200) {
            UpdateWeddingCollection((weddingCollection) => {
              weddingCollection.weddingDataObj = response.payload;
            });
            setAddEvent(
              response.payload.schedules.map((sch) => ({
                ...sch,
                isDisabled: false,
              }))
            );
          }
          if (response.statusCode === 400 || response.errors)
            toast({ status: "error", description: response.message });
        });
      }

      setEventType("");
      setAddress("");
      setColor1Name("");
      setColor2Name("");
      setColor1("");
      setColor2("");
      setDate("");
      setTime("");
    }
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap="3px"
        pt="10px"
        pb="4px"
        display={{ base: "none", sm: "unset" }}
      >
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize={"12px"}
          fontWeight="400"
        >
          Step 2
        </Text>
        <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
          Event schedule
        </Text>
      </Flex>
      {addedEvent.length ? (
        <Flex mt={"34px"} flexDir={"column"} gap="5px">
          {addedEvent.map((v, i) => {
            return (
              <Flex
                key={v._id ? v._id : `${v._id}${i}`}
                w="100%"
                h="70px"
                bgColor={v?.colors?.color1}
                p="1rem"
                borderRadius={"18px"}
                justifyContent="space-between"
                alignItems={"center"}
                border={`1px dashed ${
                  v?.colors?.color1 || v?.colors?.color2 ? "none" : "white"
                }`}
              >
                <Text
                  // m="0"
                  color={fastColorChecker(v?.colors?.color1, v?.colors?.color2)}
                  fontWeight={"400"}
                  fontSize="17px"
                  fontFamily={"Plus Jakarta Sans"}
                >
                  {makeEventTypeReadable(v.type)}
                </Text>
                <Flex gap="5px">
                  {v.isDisabled ? (
                    <></>
                  ) : (
                    <>
                      <Text
                        color={fastColorChecker(
                          v?.colors?.color1,
                          v?.colors?.color2
                        )}
                        textDecor={"underline"}
                        fontSize="14px"
                        fontWeight={"400"}
                        cursor="pointer"
                        onClick={() => {
                          setItemToDeleteID(v._id);
                          setShowDelete(true);
                        }}
                      >
                        Delete
                      </Text>
                      <Text
                        color={fastColorChecker(
                          v?.colors?.color1,
                          v?.colors?.color2
                        )}
                        textDecor={"underline"}
                        fontSize="14px"
                        fontWeight={"400"}
                        cursor="pointer"
                        onClick={() => {
                          setEditableEventSchedule({ ...v });
                          setShowEditModal(true);
                        }}
                      >
                        edit
                      </Text>
                    </>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      ) : (
        <></>
      )}

      <Flex justifyContent={"center"} py="1rem">
        <Flex flexDirection={"column"} width={"100%"}>
          {currentEventOpen && (
            <>
              {/* <Box display={"flex"} flexDirection="column">
                <BasicSelect
                  name="eventType"
                  placeholder="choose event type"
                  value={eventType}
                  handleChange={(e) => {
                    setEventType(e.target.value);
                    checkValid(
                      e.target.value,
                      address,
                      date,
                      time
                    );
                  }}
                  items={[
                    { text: "White Wedding", value: "white_wedding" },
                    {
                      text: "Traditional Wedding",
                      value: "traditional_wedding",
                    },
                    { text: "Nikai", value: "nikai" },
                    { text: "After Party", value: "after_party" },
                    { text: "Engagement", value: "engagement" },
                    { text: "Reception", value: "reception" },
                    { text: "Others", value: "others" },
                  ]}
                />
              </Box> */}
              <Box display={"flex"} flexDirection="column">
                <BasicInput
                  placeholder="choose event type"
                  name="eventType"
                  disabled={
                    eventType === "White Wedding" ||
                    eventType === "Traditional Wedding" ||
                    eventType === "Nikai" ||
                    eventType === "After Party" ||
                    eventType === "Engagement" ||
                    eventType === "Reception"
                  }
                  showMessage={true}
                  value={eventType}
                  onChange={(e) => {
                    setEventType(e.target.value);
                    checkValid(e.target.value, address, date, time);
                  }}
                  hasDropdown={true}
                  rightElement={
                    <Select
                      border="none"
                      w={"40px"}
                      h={"40px"}
                      value={eventType}
                      onChange={(e) => {
                        setEventType(
                          e.target.value === "Others" ? "" : e.target.value
                        );
                        checkValid(e.target.value, address, date, time);
                      }}
                    >
                      <option style={{ color: "#000" }} value={"White Wedding"}>
                        White Wedding
                      </option>
                      <option value={"Traditional Wedding"}>
                        Traditional Wedding
                      </option>
                      <option value={"Nikai"}>Nikai</option>
                      <option value={"After Party"}>After Party</option>
                      <option value={"Engagement"}>Engagement</option>
                      <option value={"Reception"}>Reception</option>
                      <option value={"Others"}>Others</option>
                    </Select>
                  }
                />
              </Box>
              <Box display={"flex"} flexDirection="column">
                <BasicInput
                  placeholder="event address"
                  name="address"
                  disabled={false}
                  showMessage={true}
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    checkValid(eventType, e.target.value, date, time);
                  }}
                />
              </Box>
              <Box display={"flex"} gap="5px">
                <Box w="100%">
                  <CustomDateCmp
                    placeholder="Set date"
                    name="date"
                    disabled={false}
                    value={date ? getDateFromDayAndTime(date, time) : null}
                    onChangeAction={(value) => {
                      const strDate = (value as unknown as Date)
                        .toDateString()
                        .substring(0, 15);
                      setDate(strDate);
                      checkValid(eventType, address, strDate, time);
                    }}
                    errorMessage={""} // date error
                    showMessage={true}
                  />
                </Box>
                <Box w="100%">
                  <CustomInputCmp
                    placeholder="Select Time"
                    name="time"
                    onChange={(e) => {
                      setTime(e.target.value);
                      checkValid(eventType, address, date, e.target.value);
                    }}
                    type="time"
                    value={time}
                    padding="2.6rem 1rem"
                    showMessage={true}
                  />
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                pt={"15px"}
                pb={"8px"}
              >
                <Text>Colors of the day</Text>
              </Box>
              <Box display={"flex"} position={"relative"}>
                <SingleColorPalette
                  placeholder="color name"
                  name="color1Text"
                  disabled={false}
                  showMessage={true}
                  colorValue={color1}
                  setColorValue={(c1) => {
                    setColor1(c1);
                  }}
                  textValue={color1Name}
                  onTextChange={(value) => {
                    setColor1Name(value);
                  }}
                />
                <SingleColorPalette
                  placeholder="color name"
                  name="color2Text"
                  disabled={false}
                  showMessage={true}
                  colorValue={color2}
                  setColorValue={(c2) => {
                    setColor2(c2);
                  }}
                  textValue={color2Name}
                  onTextChange={(value) => {
                    setColor2Name(value);
                  }}
                />
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                pt={"15px"}
                pb={"8px"}
              >
                <Text>Dress code</Text>
              </Box>
              <Box display={"flex"} position={"relative"}>
                <BasicInput
                  placeholder="for bride"
                  name="outfit"
                  disabled={false}
                  showMessage={true}
                  value={brideDressCode}
                  onChange={(e) => {
                    setBrideDressCode(e.target.value);
                    checkValid(eventType, address, date, time);
                  }}
                />
                <BasicInput
                  placeholder="for groom"
                  name="outfit"
                  disabled={false}
                  showMessage={true}
                  value={groomDressCode}
                  onChange={(e) => {
                    setGroomDressCode(e.target.value);
                    checkValid(eventType, address, date, time);
                  }}
                />
              </Box>
            </>
          )}

          <Flex justifyContent="flex-end" py="">
            <Box
              cursor="pointer"
              fontWeight={"400"}
              fontSize="15px"
              color="#CEFABB"
              textAlign={"center"}
              py="0.25rem"
              display={"flex"}
              alignItems="center"
              justifyContent={"end"}
              gap="4px"
              borderBottom="1px solid"
            >
              <Icon as={currentEventOpen ? BsBookmark : AiOutlinePlus} />
              <Text
                opacity={isValid ? "" : "0.5"}
                onClick={async () => {
                  if (!currentEventOpen) {
                    setCurrentEventOpen(!currentEventOpen);
                    return;
                  }
                  // await validateForm(); TODO: validate form
                  if (isValid) {
                    setCurrentEventOpen(!currentEventOpen);
                    const payload = {
                      weddingID: weddingDataObj?._id,
                      eventSchedules: [
                        {
                          type: eventType as WeddingEventType,
                          address: address,
                          colors: {
                            color1,
                            color2,
                            color1Name,
                            color2Name,
                          },
                          date: {
                            day: `${new Date(date!).toDateString()}`,
                            time: time,
                          },
                          brideDressCode,
                          groomDressCode,
                        },
                      ],
                    };
                    setAddEvent((prevState) => {
                      const { date, ...rest } = payload.eventSchedules[0];
                      (rest as WeddingEventSchedule).date =
                        payload.eventSchedules[0].date.day;
                      (rest as WeddingEventSchedule).time =
                        payload.eventSchedules[0].date.time;
                      return [
                        ...prevState,
                        {
                          ...(rest as WeddingEventSchedule),
                          isDisabled: true,
                        },
                      ];
                    });
                    payload.eventSchedules[0].date.time = to12HoursTime(payload.eventSchedules[0].date.time);
                    
                    if (weddingExists) {
                      createWeddingEventSchedule(payload, (response) => {
                        if (response.statusCode === 200) {
                          UpdateWeddingCollection((weddingCollection) => {
                            weddingCollection.weddingDataObj = response.payload;
                          });
                          setAddEvent(
                            response.payload.schedules.map((sch) => ({
                              ...sch,
                              isDisabled: false,
                            }))
                          );
                        }
                        if (response.statusCode === 400 || response.errors)
                          toast({
                            status: "error",
                            description: response.message,
                          });
                      });
                    }

                    // resetForm
                    setEventType("");
                    setAddress("");
                    setColor1Name("");
                    setColor2Name("");
                    setColor1("");
                    setColor2("");
                    setDate("");
                    setTime("");
                    setBrideDressCode("");
                    setGroomDressCode("");
                  }
                }}
              >
                {currentEventOpen
                  ? "save event"
                  : `add ${
                      weddingDataObj?.schedules?.length ? "another" : "an"
                    } event`}
              </Text>
            </Box>
          </Flex>
          <Box
            position="sticky"
            padding="1rem"
            bgColor={"#1F2229"}
            zIndex="3"
            textAlign="center"
            display="flex"
            gap="5px"
            bottom={{ base: "-20px", sm: "-80px" }}
            justifyContent={"center"}
          >
            <ButtonCmp
              text={"Previous"}
              size="md"
              color="#44A560"
              backgroundColor="transparent"
              opacity={"1"}
              border={"1px solid #44A560"}
              textHoverColor={"#fff"}
              width="100%"
              height="55px"
              maxWidth="150px"
              type="submit"
              onClick={() => {
                prevBtnAction();
              }}
            />
            <ButtonCmp
              text={"Next"}
              size="md"
              opacity={"1"}
              border={"none"}
              isLoading={loading}
              isDisabled={
                !weddingExists &&
                !isValid &&
                addedEvent?.length - weddingDataObj?.schedules?.length > 0
              }
              textHoverColor={"#fff"}
              width="100%"
              height="55px"
              maxWidth="150px"
              type="submit"
              onClick={handleSubmit}
            />
          </Box>
        </Flex>
        <EditorModal
          isOpen={showEditModal}
          onRequestClose={() => {
            setShowEditModal(false);
          }}
          name={"editEvent"}
          maxWidth="480px"
          content={
            <EditEvent
              eventSchedule={editableEventSchedule}
              onSaveAction={
                setAddEvent as (eventSchedules: WeddingEventSchedule[]) => void
              }
              events={addedEvent}
              onRequestClose={() => {
                setShowEditModal(false);
              }}
            />
          }
        />
      </Flex>
      <ActionModal
        isOpen={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        actionTitle="delete event item?"
        actionDesc="You will not be able to recover this item after you confirm this action"
        yesText="YES, DELETE"
        noText="NO, CANCEL"
        yesAction={() => {
          setAddEvent(addedEvent.filter((v) => v._id !== itemToDeleteID));

          deleteWeddingEventSchedule(
            { eventScheduleID: itemToDeleteID },
            (response) => {
              if (response.statusCode === 200) {
                UpdateWeddingCollection((weddingCollection) => {
                  weddingCollection.weddingDataObj = response.payload;
                });
              }

              if (response.statusCode === 400 || response.errors)
                toast({ status: "error", description: response.message });
            }
          );

          setShowDelete(false);
          setItemToDeleteID("");
        }}
        noAction={() => {
          setShowDelete(false);
        }}
      />
    </>
  );
};

export default StepTwo;
