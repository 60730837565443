import { Badge, Box, Flex, Icon, Show, Text, useToast } from "@chakra-ui/react";

import { dashboardData } from "data/dummy";
import { useAppDispatch, useAppSelector } from "hooks";
import localforage from "localforage";
import React from "react";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import { useMeasure } from "react-use";
import {
  dashboardActions,
  dashboardSelectors,
  secretSantaActions,
  wishlistActions,
} from "store/slices";
import { IoIosShareAlt } from "react-icons/io";
// import { getNumberOfDays } from "utils/date";
import ShowPendingInviteModal from "components/Modals/ShowPendingInviteModal";

const LogoLoader = React.lazy(() => import("../../components/LogoLoader"));
const BalanceCmp = React.lazy(() => import("../../components/BalanceCmp"));
const ButtonCmp = React.lazy(
  () => import("../../components/ButtonCmp/ButtonCmp")
);
const EmptyCardCmp = React.lazy(() => import("../../components/EmptyCardCmp"));
const ActionModal = React.lazy(
  () => import("../../components/Modals/ActionModal")
);
const WishlistCardCmp = React.lazy(
  () => import("../../components/WishlistCardCmp/WishlistCardCmp")
);

// const getLeftMarginWithDiv = (divName: any, buttonName: any) => {
//   let divClassWidth = document.getElementsByClassName(divName).offsetWidth;
//   let buttonClassWidth = document.getElementsByClassName(buttonName).offsetWidth;

//   return {divClassWidth, buttonClassWidth};
// }

const Overview = () => {
  const pendingInviteClose = localforage.createInstance({
    name: "Pending Invite Closed",
  });
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [overviewContainerRef, { width: containerWidth }] = useMeasure<any>();
  const [createWishlistButtonRef, { width: buttonWidth }] = useMeasure<any>();

  const userDetails = useAppSelector(dashboardSelectors.getUser);
  const getDashboardData = useAppSelector(dashboardSelectors.getDashboard);

  const { deleteAWishlist, deleteACollection } = wishlistActions;

  const dashboardDataPayload: any = getDashboardData?.payload;

  const dispatch = useAppDispatch();

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const navigate = useNavigate();

  const [metaData] = useState(JSON.parse(localStorage.getItem("metaData")!));
  const [showPendingInviteModal, setShowPendingInviteModal] = useState(false);

  const { getDashboard } = dashboardActions;
  const { acceptInviteGiftExchange } = secretSantaActions;

  const [loading, setLoading] = useState(false);

  const [inviteLoading, setInviteLoading] = useState(false);
  const [, setSharePageModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getDashboard())
      .then(async (res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setLoading(false);
        }
        if (metaData) {
          const isInviteClosed = await pendingInviteClose.getItem(
            "pendingInvite"
          );

          if (!isInviteClosed) {
            const delay = setTimeout(() => {
              setShowPendingInviteModal(true);
            }, 1000);
            pendingInviteClose.setItem("pendingInvite", true);
            return () => clearTimeout(delay);
          }
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getDashboard, metaData]);

  // This was the re-render culprit
  // useEffect(() => {
  //   if (userDetails) {
  //     dispatch(getUser(userDetails?._id!));
  //   }
  // }, [dispatch, getUser, userDetails]);

  const cwMarginLeft = `calc(${containerWidth / 2}px - ${buttonWidth / 2}px)`;

  return (
    <>
      {loading ? (
        <Flex justifyContent={"center"} py="1rem">
          <LogoLoader />
        </Flex>
      ) : (
        <>
          <Flex flexDir={"column"} ref={overviewContainerRef} mb={"30px"}>
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"space-between"}
              alignItems={{ base: "flex-start", md: "center" }}
              mb={"33px"}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems="center"
                w="100%"
              >
                <Text
                  fontSize={{ base: "18px", md: "25px" }}
                  lineHeight={"35px"}
                  fontWeight={400}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  Hello,
                  {userDetails
                    ? ` ${userDetails?.firstname.charAt(0).toUpperCase()}${
                        userDetails?.firstname.slice(1).length > 10
                          ? `${userDetails?.firstname.slice(1, 9)}...`
                          : userDetails?.firstname.slice(1)
                      }`
                    : ""}
                  👋🏾
                </Text>

                <Show breakpoint="(max-width: 767px)">
                  <ButtonCmp
                    text={
                      <>
                        <Icon as={IoIosShareAlt} h={5} w={5} />
                      </>
                    }
                    size="md"
                    padding="0.2rem 0rem"
                    onClick={() => {
                      setSharePageModal(true);
                    }}
                    // isLoading={loggingIn}
                    opacity={"1"}
                    border={"none"}
                    backgroundColor="rgb(68, 165, 96)"
                    textHoverColor={"#fff"}
                    color={"#fff"}
                    // width="130px"
                    width="60px"
                    height="50px"
                    maxWidth="100%"
                    type="submit"
                  />
                </Show>
              </Flex>
              <BalanceCmp
                baseMt="15px"
                baseH="fit-content"
                showNotification
                cardClick
                amount={
                  <NumberFormat
                    thousandSeparator
                    // prefix="₦"
                    value={
                      localStorage.getItem("userType") === "Organisation"
                        ? dashboardDataPayload?.generalWallet
                        : dashboardDataPayload?.walletBalance?.Naira
                    }
                    decimalScale={2}
                    defaultValue="0.00"
                    allowNegative={false}
                    placeholder="0.00"
                    displayType="text"
                    disabled
                  />
                }
              />
            </Flex>
            <Flex flexDir={"column"}>
              <Text
                mx={"auto"}
                mb={"17px"}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={"18px"}
                lineHeight={"24px"}
                display={"flex"}
                alignItems={"center"}
              >
                my{" "}
                {localStorage.getItem("userType") === "Organisation"
                  ? "events"
                  : // ? "collections"
                    "wishlists"}
                <Badge
                  color={"#80BA7C"}
                  bg={"#0F441E"}
                  ml={"2"}
                  borderRadius={"50%"}
                  fontSize={"12px"}
                  fontWeight={500}
                  w={"22px"}
                  h={"22px"}
                  textAlign={"center"}
                >
                  {localStorage.getItem("userType") === "Organisation"
                    ? dashboardDataPayload?.eventsCount
                    : dashboardDataPayload?.wishlistsCount}
                </Badge>
              </Text>

              {/* {!dashboardDataPayload?.wishlists.length && (
                <Flex
                  flexDir={{ base: "row", lg: "row" }}
                  justifyContent={{ md: "space-between" }}
                  flexWrap={"wrap"}
                  gap={{ base: "10px", lg: "unset" }}
                >
                  {[1, 2, 3, 4, 5, 6].map((v, i) => {
                    return <EmptyCardCmp key={i} />;
                  })}
                </Flex>
              )} */}

              {dashboardDataPayload && (
                <Flex
                  flexDir={{ base: "row", lg: "row" }}
                  justifyContent={{ md: "space-between" }}
                  flexWrap={"wrap"}
                  gap={{ base: "10px", lg: "unset" }}
                >
                  {localStorage.getItem("userType") === "Organisation" ? (
                    <>
                      {dashboardDataPayload?.events.map((v: any, i: number) => {
                        return v ? (
                          <WishlistCardCmp
                            key={i}
                            removeMenu={true}
                            wishlistTitle={`#${v.hashtag}`}
                            icon={v.listCategoryEmoji}
                            // isCollection={true}
                            // amount={v.grossPaid.Amount}
                            // amountText={
                            //   v.date
                            //     ? `${getNumberOfDays(v.date)} days to go`
                            //     : "No due date"
                            // }
                            bgImage={v.coverImage}
                            onDeleteClick={() => {
                              setDeleteId(v._id);
                              setShowDelete(true);
                            }}
                            onEditClick={() => {
                              navigate(`/collection/${v.slug}/edit`);
                            }}
                            onClick={() => {
                              if (
                                localStorage.getItem("userType") ===
                                "Organisation"
                              ) {
                                navigate(`/Myevent`, {
                                  state: { eventID: v._id },
                                });
                              } else {
                                navigate(`/collection/${v.slug}`);
                              }
                            }}
                            emojiCardWidth="40px"
                            wishCardWidth="70px"
                            slug={v.slug}
                            wishlistData={v}
                            hideCard
                          />
                        ) : (
                          <></>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {dashboardDataPayload?.wishlists.map(
                        (v: any, i: number) => {
                          return (
                            <WishlistCardCmp
                              key={i}
                              wishlistTitle={v.title}
                              icon={v.listCategoryEmoji}
                              amount={v.noOfGifts}
                              amountText={v.noOfGifts <= 1 ? "ITEM" : "ITEMS"}
                              bgImage={v.coverImage}
                              onDeleteClick={() => {
                                setDeleteId(v._id);
                                setShowDelete(true);
                              }}
                              onEditClick={() => {
                                navigate(`/wishlist/${v.slug}/edit`);
                              }}
                              onClick={() => {
                                navigate(`/wishlist/${v.slug}`);
                              }}
                              emojiCardWidth="40px"
                              wishCardWidth="70px"
                              slug={v.slug}
                              wishlistData={v}
                              hideCard
                            />
                          );
                        }
                      )}
                    </>
                  )}

                  {localStorage.getItem("userType") === "Organisation" ? (
                    <>
                      {dashboardData
                        .slice(dashboardDataPayload?.wishlistCashFunds.length)
                        .map((item: any) => (
                          <EmptyCardCmp key={item.id} />
                        ))}
                    </>
                  ) : (
                    <>
                      {dashboardData
                        .slice(dashboardDataPayload?.wishlists.length)
                        .map((item: any) => (
                          <EmptyCardCmp key={item.id} />
                        ))}
                    </>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
          <Box
            ref={createWishlistButtonRef}
            fontFamily={"Plus Jakarta Sans"}
            textAlign={{ base: "center" }}
            display="flex"
            alignItems={{ base: "center" }}
            flexDir="column"
            gap="10px"
            position="fixed"
            bottom="10px"
            marginLeft={cwMarginLeft}
            // bottom={{
            //   base: "calc(100vh - 90vh)",
            //   sm: "0",
            //   lg: "-60px",
            // }}
          >
            {localStorage.getItem("userType") === "Organisation" ? (
              <>
                {!dashboardDataPayload?.events.length && (
                  <Text color="#fff" fontSize={"18px"} width="300px">
                    Nothing here! Click button to create a collection
                  </Text>
                )}
              </>
            ) : (
              <>
                {!dashboardDataPayload?.wishlists.length && (
                  <Text color="#fff" fontSize={"18px"} width="300px">
                    Nothing here! Click button to create a wishlist of gifts for
                    any occassion
                  </Text>
                )}
              </>
            )}
            {localStorage.getItem("userType") === "Organisation" ? (
              <></>
            ) : (
              <Box>
                <ButtonCmp
                  text={`${
                    localStorage.getItem("userType") === "Organisation"
                      ? "Add collection"
                      : "Create wishlist"
                  }`}
                  size="md"
                  padding="0.8rem 0rem"
                  onClick={() => {
                    if (localStorage.getItem("userType") === "Organisation") {
                      navigate("/add-collection");
                    } else {
                      navigate("/add-wishlist");
                    }
                  }}
                  // isLoading={loggingIn}
                  opacity={"1"}
                  border={"none"}
                  backgroundColor="#EDFFE5"
                  textHoverColor={"#000"}
                  color={"#000"}
                  width="160px"
                  height="50px"
                  maxWidth="100%"
                  type="submit"
                />
              </Box>
            )}
          </Box>
          <ActionModal
            isOpen={showDelete}
            onRequestClose={() => {
              setShowDelete(false);
            }}
            maxWidth="400px"
            showCloseIcon={false}
            actionTitle={`delete ${
              localStorage.getItem("userType") === "Organisation"
                ? "collection"
                : "wishlist"
            }?`}
            actionDesc={`You will not be able to recover the ${
              localStorage.getItem("userType") === "Organisation"
                ? "collection"
                : "wishlist"
            } after you confirm this action`}
            yesText="YES, DELETE"
            noText="NO, CANCEL"
            yesAction={() => {
              if (localStorage.getItem("userType") === "Organisation") {
                dispatch(deleteACollection({ collectionID: deleteId }))
                  .then((res: any) => {
                    const response = res;
                    if (
                      response &&
                      response.payload &&
                      response.payload.statusCode === 200
                    ) {
                      dispatch(getDashboard());
                      toast({
                        status: "success",
                        description: "Collection deleted successfully",
                      });
                      return navigate("/");
                    }
                  })
                  .catch(() => {
                    toast({
                      status: "error",
                      description: "There is an error",
                    });
                  });
              } else {
                dispatch(deleteAWishlist({ wishlistID: deleteId }))
                  .then((res: any) => {
                    const response = res;
                    if (
                      response &&
                      response.payload &&
                      response.payload.statusCode === 200
                    ) {
                      dispatch(getDashboard());
                      toast({
                        status: "success",
                        description: "Wishlist deleted successfully",
                      });
                      return navigate("/");
                    }
                  })
                  .catch(() => {
                    toast({
                      status: "error",
                      description: "There is an error",
                    });
                  });
              }
              setShowDelete(false);
            }}
            noAction={() => {
              setShowDelete(false);
            }}
          />
          <ShowPendingInviteModal
            isOpen={showPendingInviteModal}
            onRequestClose={() => {
              setShowPendingInviteModal(false);
            }}
            maxWidth="300px"
            showCloseIcon={false}
            exchangeTitle={metaData?.giftExchangeID?.title}
            wishlistImg={metaData?.giftExchangeID?.coverImage}
            giftExchangeID={metaData?.giftExchangeID?._id}
            inviteLoading={inviteLoading}
            joinAction={() => {
              setInviteLoading(true);
              dispatch(
                acceptInviteGiftExchange({
                  giftExchangeID: metaData?.giftExchangeID?._id,
                })
              )
                .then(async (res: any) => {
                  const response = res;
                  if (
                    response &&
                    response.payload &&
                    response.payload.statusCode === 200
                  ) {
                    setInviteLoading(false);
                    toast({
                      status: "success",
                      description: "Joined successfully",
                    });
                    return navigate(
                      `/secret-santa/${metaData?.giftExchangeID?._id}`
                    );
                  }
                })
                .finally(() => setLoading(false));
            }}
          />
        </>
      )}
    </>
  );
};

export default Overview;
