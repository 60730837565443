import { Box, Text, Flex, Divider, useToast } from "@chakra-ui/react";
import CustomInputCmp from "components/InputsCmp/CustomInputCmp";
import { useAppDispatch, useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { walletActions, walletSelectors } from "store/slices";
import { searchThroughArrayOfObj } from "utils/searchThroughArrayOfObj";
import ModalCmp from "../ModalCmp/ModalCmp";

const ShowSearchBankModal: React.FC<{
  setBank: (bankData: any) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({ isOpen, onRequestClose, maxWidth, showCloseIcon, setBank }) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const { getBanks } = walletActions;
  const [query, setQuery] = useState("");
  const [, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const banksData: any = useAppSelector(walletSelectors.getBanksResponse);

  const [bankSearchData, setBankSearchData] = useState<any>();

  useEffect(() => {
    setLoading(true);
    dispatch(getBanks())
      .then((res: any) => {
        const response = res;

        setLoading(false);
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          setBankSearchData(response.payload.payload);
        } else {
          return toast({
            status: "error",
            description: response.payload.message,
          });
        }
      })
      .catch(() => {
        toast({
          status: "error",
          description: "There is an error",
        });
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px", height: "600px" }}
        shouldFocusAfterRender={false}
        contentLabel="Search"
        id="search"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box
          textAlign={"center"}
          padding="2rem 1rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Box
            margin={"0 -0.5rem"}
            position="sticky"
            top="0"
            bgColor={"rgb(31, 34, 40)"}
            zIndex="100"
          >
            <Text
              color="#fff"
              fontWeight={"700"}
              fontSize="30px"
              paddingBottom={"1rem"}
            >
              banks
            </Text>
            <Box width={"100%"}>
              <CustomInputCmp
                background="#22242C"
                placeholder="search banks"
                name={"search-bank"}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  const results = searchThroughArrayOfObj(
                    banksData?.payload,
                    query
                  );
                  setBankSearchData(results);
                }}
                showMessage={false}
              />
            </Box>
          </Box>
          <Flex
            flexDir={"column"}
            gap={"15px"}
            justifyContent="center"
            alignItems={"center"}
          >
            <Flex
              flexDir={"column"}
              gap="5px"
              width={"100%"}
              fontFamily="Plus Jakarta Sans"
            >
              {/* {loading && (
                <Flex justifyContent={"center"}>
                  <SpinnerCmp
                    enabled={true}
                    color={"#000"}
                    size={20}
                    secondaryColor={"#fff"}
                  />
                </Flex>
              )} */}

              {bankSearchData &&
                bankSearchData?.map((v: any, i: number) => {
                  return (
                    <>
                      {" "}
                      <Text
                        key={i}
                        textAlign={"left"}
                        py="0.4rem"
                        fontWeight={"400"}
                        fontSize="16px"
                        cursor="pointer"
                        onClick={() => {
                          setBank(v);
                        }}
                      >
                        {v.name}
                      </Text>
                      {i !== bankSearchData?.length - 1 && (
                        <Divider opacity="0.3" />
                      )}
                    </>
                  );
                })}
            </Flex>
          </Flex>
        </Box>
      </ModalCmp>
    </>
  );
};

export default ShowSearchBankModal;
