import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetDashboard,
  GetDashboardResponse,
  GetUser,
  GetUserResponse,
  MRequest,
} from "apis";
import { RootState } from "store/reducers";

const entity = "dashboard";

const getDashboard = createAsyncThunk("dashboard/get", async () => {
  const res = await MRequest.get<typeof GetDashboard.RES>(GetDashboard.ROUTE);
  return res.data;
});

const getUser = createAsyncThunk(
  "dashboard/getUser",
  async (id: typeof GetUser.PARAMS) => {
    const res = await MRequest.get<typeof GetUser.RES>(
      `${GetUser.ROUTE}/${id}`
    );
    return res.data;
  }
);

const getPublicUser = createAsyncThunk(
  "dashboard/getPublicUser",
  async (username: typeof GetUser.PARAMS) => {
    const res = await MRequest.get<typeof GetUser.RES>(
      `${GetUser.ROUTE}/profile/public?username=${username}`
    );
    return res.data;
  }
);

export type DashboardInitialStateType = {
  getDashboardResponse: GetDashboardResponse | null;
  getUserResponse: GetUserResponse | null;
  getPublicUserResponse: GetUserResponse | null;
};

const initialState: DashboardInitialStateType = {
  getDashboardResponse: null,
  getUserResponse: null,
  getPublicUserResponse: null,
};

const dashboardSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getDashboard.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getDashboardResponse = action.payload;
      }
    );
    builder.addCase(
      getDashboard.rejected,
      (state, action: PayloadAction<any>) => {
        state.getDashboardResponse = action.payload;
      }
    );
    builder.addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.getUserResponse = action.payload;
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.getUserResponse = action.payload;
    });
    builder.addCase(
      getPublicUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getPublicUserResponse = action.payload;
      }
    );
    builder.addCase(
      getPublicUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.getPublicUserResponse = action.payload;
      }
    );
  },
});

export const dashboardActions = {
  ...dashboardSlice.actions,
  getDashboard,
  getUser,
  getPublicUser,
};

export const dashboardSelectors = {
  getDashboard: (state: RootState) => state.dashboard.getDashboardResponse,
  getUser: (state: RootState) => state.dashboard.getUserResponse?.payload,
  getPublicUser: (state: RootState) =>
    state.dashboard.getPublicUserResponse?.payload,
};

export const dashboardReducer = dashboardSlice.reducer;
