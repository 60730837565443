import { ArrowForwardIcon } from "@chakra-ui/icons";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import React from "react";

const CircularProgressCmp: React.FC<{
  value: number;
  label: string | any;
  color?: string;
  size?: string;
  fontSizeLabel?: string;
  fontWeightLabel?: string;
  fontColorLabel?: string;
  widthLabel?: string;
  isContributor?: boolean;
}> = ({
  value,
  label,
  color,
  size,
  fontSizeLabel,
  fontWeightLabel,
  fontColorLabel,
  widthLabel,
  isContributor,
}) => {
  return (
    <>
      <CircularProgress
        value={value}
        color={color || "#F7D38F"}
        thickness={"3px"}
        trackColor="rgba(255,255,255,0.32)"
        size={size || "30px"}
      >
        <CircularProgressLabel
          color={fontColorLabel}
          fontSize={fontSizeLabel}
          fontWeight={fontWeightLabel}
          w={widthLabel}
        >
          {isContributor ? (
            <ArrowForwardIcon w="3" h="3" color="#fff" />
          ) : (
            label
          )}
        </CircularProgressLabel>
      </CircularProgress>
    </>
  );
};

export default CircularProgressCmp;
