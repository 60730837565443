/* eslint-disable no-useless-escape */
import * as yup from "yup";

export const WISHLISTVALIDATIONS = {
  day: yup.string().required("Day is required!"),
  month: yup.string().required("Month is required!"),
  year: yup.string().required("Year is required!"),
  wishlist_title: yup
    .string()
    .required("Wishlist title is required!")
    .max(30, "Maximum of 30 characters allowed"),
  collection_title: yup
    .string()
    .required("Collection title is required!")
    .max(30, "Maximum of 30 characters allowed"),
  welcomeNote: yup.string().required("Wishlist title is required!").optional(),
  date: yup.string().when("dateChecked", {
    is: (val: any) => val === false,
    then: yup.string().required("Date is required").nullable(),
  }),
  category: yup.string(),
  partners_name: yup.string(),
  title: yup
    .string()
    // .required("Title is required!")
    .max(30, "Maximum of 30 characters allowed"),
  goalChecked: yup.boolean().optional(),
  dateChecked: yup.boolean().optional(),
  grossCost: yup.string().when("goalChecked", {
    is: (val: any) => val === false,
    then: yup
      .string()
      .required("Price is required!")
      .test("Check minimum value", "Minimum of N1000", function (value: any) {
        if (value < 999) {
          return false;
        }
        return true;
      })
      .test(
        "Check minimum value",
        "Zero value not allowed",
        function (value: any) {
          if (value <= 0) {
            return false;
          }
          return true;
        }
      ),
  }),
};
