import { ISVGS } from "./types";

const WeddingIcon = (props: ISVGS) => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.2349 4.384C8.31606 4.384 8.39723 4.35238 8.45899 4.28953L10.3741 2.33948C10.9312 1.77223 10.8619 0.802085 10.2771 0.310571C9.6927 -0.181333 8.91827 -0.052891 8.43049 0.443307L8.23529 0.64163L8.0401 0.443307C7.54282 -0.062651 6.73552 -0.145416 6.1935 0.310571C5.57149 0.833317 5.53903 1.77301 6.09531 2.33948L8.0108 4.28953C8.03979 4.31933 8.07457 4.34306 8.11308 4.3593C8.15159 4.37553 8.19302 4.38393 8.2349 4.384ZM16.4706 20H0V15.8184C0.383258 16.3732 0.817986 17.1891 1.90045 17.1891C3.5392 17.1891 3.69321 15.3152 4.43439 15.3152C5.18032 15.3152 5.32285 17.1891 6.96833 17.1891C8.60509 17.1891 8.76267 15.3152 9.50226 15.3152C10.2446 15.3152 10.3955 17.1891 12.0362 17.1891C13.6742 17.1891 13.8274 15.3152 14.5701 15.3152C15.2036 15.3152 15.4083 16.6777 16.4706 17.079V20ZM0 14.176V13.7536C0 13.0665 0.570136 12.5043 1.26697 12.5043H15.2036C15.9005 12.5043 16.4706 13.0665 16.4706 13.7536V15.4397C16.0869 14.8846 15.6554 14.0659 14.5701 14.0659C12.9286 14.0659 12.7821 15.9398 12.0362 15.9398C11.295 15.9398 11.143 14.0659 9.50226 14.0659C7.85916 14.0659 7.71267 15.9398 6.96833 15.9398C6.22834 15.9398 6.07195 14.0659 4.43439 14.0659C2.79683 14.0659 2.64005 15.9398 1.90045 15.9398C1.26657 15.9398 1.06425 14.577 0 14.176ZM13.9367 11.255H2.53394V8.01004C2.91799 8.56519 3.35351 9.38112 4.43439 9.38112C6.07195 9.38112 6.22834 7.5072 6.96833 7.5072C7.71267 7.5072 7.85916 9.38112 9.50226 9.38112C11.143 9.38112 11.295 7.5072 12.0362 7.5072C12.6725 7.5072 12.8728 8.87048 13.9367 9.27142V11.255ZM2.53394 6.36802V6.13885C2.53394 5.51733 3.04983 5.00864 3.68015 5.00864H12.7904C13.4208 5.00864 13.9367 5.51733 13.9367 6.13885V7.63096C13.5526 7.0762 13.1183 6.25792 12.0362 6.25792C10.3955 6.25792 10.2446 8.13184 9.50226 8.13184C8.76267 8.13184 8.60509 6.25792 6.96833 6.25792C5.32285 6.25792 5.18032 8.13184 4.43439 8.13184C3.80249 8.13184 3.5974 6.76896 2.53394 6.36802Z"
      fill={props.active ? "#8DB782" : `${props.fill || "#8DB782"}`}
      fillOpacity={props.active ? undefined : "0.5"}
    />
    <defs>
      <linearGradient
        id="paint0_linear_wedding"
        x1="9.23077"
        y1="0"
        x2="9.23077"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.fill || "#8DB782"} />
        <stop offset="1" stopColor={props.fill || "#8DB782"} stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default WeddingIcon;
