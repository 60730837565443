import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ShareImg } from "assets/svgs";
import BalanceCmp from "components/BalanceCmp";
import DaysLeftCmp from "components/DaysLeftCmp/DaysLeftCmp";
import IconCardCmp from "components/IconCardCmp/IconCardCmp";
import ShareModal from "components/Modals/ShareModal";
import SharePageModal from "components/Modals/SharePageModal";
import ShareWeddingModal from "components/Modals/ShareWeddingModal";
import { useAppSelector } from "hooks";
// import { useAppDispatch, useAppSelector } from "hooks";
import { MenuItemCmp } from "Layout/Dashboard";
import { useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import NumberFormat from "react-number-format";
import { dashboardSelectors } from "store/slices";
// import { dashboardSelectors, wishlistActions } from "store/slices";

const WishlistCardCmp: React.FC<{
  widthMd?: string;
  minW?: string;
  flex?: string;
  height?: string;
  widthBase?: string;
  minWidthBase?: string;
  flexBase?: string;
  wishlistTitle: string;
  icon?: string;
  amount?: number;
  amountText?: string;
  bgImage: string;
  removeMenu?: boolean;
  noVerticalMargin?: string;
  widthLg?: string;
  minWLg?: string;
  flexLg?: string;
  onClick?: () => void;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  onShareClick?: () => void;
  onDownloadClick?: () => void;
  payoutBtnAction?: () => void;
  style?: any;
  wishCardWidth?: string;
  walletBalance?: any;
  emojiCardWidth?: string;
  showWalletBal?: boolean;
  weddingType?: boolean;
  slug?: string;
  wishlistData?: any;
  pillBgColor?: string;
  pillTextColor?: string;
  hideCard?: boolean;
  payoutBtnText?: string;
  isCompleted?: boolean;
  toggleDisabled?: boolean;
  isCollection?: boolean;
  ubxdid?: string;
  hashtag?: string;
}> = ({
  widthLg,
  minWLg,
  flexLg,
  widthMd,
  minW,
  flex,
  height,
  widthBase,
  minWidthBase,
  flexBase,
  wishlistTitle,
  icon,
  amount,
  amountText,
  bgImage,
  removeMenu,
  noVerticalMargin,
  onClick,
  onDeleteClick,
  onEditClick,
  onShareClick,
  onDownloadClick,
  payoutBtnAction,
  style,
  emojiCardWidth,
  wishCardWidth,
  showWalletBal,
  walletBalance,
  weddingType,
  slug,
  wishlistData,
  pillBgColor,
  pillTextColor,
  hideCard,
  payoutBtnText,
  isCompleted,
  toggleDisabled,
  isCollection,
  ubxdid,
  hashtag,
}) => {
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharePageModal, setSharePageModal] = useState(false);
  const [shareWeddingModal, setShareWeddingModal] = useState(false);
  const userDetails: any = useAppSelector(dashboardSelectors.getUser);
  const isIndividual = localStorage.getItem("userType") === "Individual";

  // const { getAWishlist } = wishlistActions;
  // const dispatch = useAppDispatch();
  return (
    <>
      <Box
        style={{ ...style }}
        w={{
          base: `${widthBase || "100%"}`,
          md: `${widthMd || "48%"}`,
          lg: `${widthLg || "48%"}`,
        }}
        minW={{
          base: `${minWidthBase || "100%"}`,
          md: `${minW || "48%"}`,
          lg: `${minWLg || "48%"}`,
        }}
        flex={{
          base: `${flexBase || "0 0 48%"}`,
          md: `${flex || "0 0 48%"}`,
          flex: `${flexLg || "0 0 48%"}`,
        }}
        h={height || "227px"}
        borderRadius="18px"
        pos={"relative"}
        my={noVerticalMargin || "12px"}
        cursor="pointer"
      >
        <Box
          width="100%"
          h={"inherit"}
          maxHeight={"223px"}
          borderRadius={"18px"}
          bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${bgImage})`}
          backgroundSize="cover"
          position={"relative"}
          onClick={onClick}
        >
          {!removeMenu && (
            <Box
              width="30px"
              height="30px"
              borderRadius={"50%"}
              backdropFilter="blur(30px)"
              bgColor="rgba(0, 0, 0, 0.3)"
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              position="absolute"
              right="15px"
              top="10px"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <HStack spacing={{ base: "0", md: "6" }} zIndex="10">
                <Flex alignItems={"center"}>
                  <Menu offset={[-80, 2]}>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: "none" }}
                    >
                      <HStack>
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius={"50%"}
                          bgColor="rgba(0, 0, 0, 0.3)"
                          display={"flex"}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Box color={"#fff"}>
                            <Icon
                              as={FiMoreHorizontal}
                              width={"12px"}
                              height={"12px"}
                            />
                          </Box>
                        </Box>
                      </HStack>
                    </MenuButton>
                    <MenuList
                      minWidth={"7rem"}
                      bg={"#16181D"}
                      borderColor={"#22242c"}
                    >
                      <MenuItemCmp onClick={onEditClick} name={"Edit"} />
                      <MenuItemCmp onClick={onDeleteClick} name={"Delete"} />
                    </MenuList>
                  </Menu>
                </Flex>
              </HStack>
            </Box>
          )}
          {!removeMenu && weddingType && (
            <Box
              width="30px"
              height="30px"
              borderRadius={"50%"}
              backdropFilter="blur(30px)"
              bgColor="rgba(0, 0, 0, 0.3)"
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              position="absolute"
              right="15px"
              top="10px"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <HStack spacing={{ base: "0", md: "6" }} zIndex="10">
                <Flex alignItems={"center"}>
                  <Menu offset={[-80, 2]}>
                    <MenuButton
                      py={2}
                      transition="all 0.3s"
                      _focus={{ boxShadow: "none" }}
                    >
                      <HStack>
                        <Box
                          width="30px"
                          height="30px"
                          borderRadius={"50%"}
                          bgColor="rgba(0, 0, 0, 0.3)"
                          display={"flex"}
                          justifyContent="center"
                          alignItems={"center"}
                        >
                          <Box color={"#fff"}>
                            <Icon
                              as={FiMoreHorizontal}
                              width={"12px"}
                              height={"12px"}
                            />
                          </Box>
                        </Box>
                      </HStack>
                    </MenuButton>
                    <MenuList
                      minWidth={"7rem"}
                      bg={"#16181D"}
                      borderColor={"#22242c"}
                    >
                      <MenuItemCmp onClick={onShareClick} name={"Share"} />
                      <MenuItemCmp
                        onClick={onDownloadClick}
                        name={"Download"}
                      />
                    </MenuList>
                  </Menu>
                </Flex>
              </HStack>
            </Box>
          )}
          <Flex
            position={"absolute"}
            bottom={{ base: `${showWalletBal ? "35px" : "20px"}`, sm: "20px" }}
            justifyContent={"space-between"}
            width="100%"
            px="1rem"
            alignItems={"center"}
          >
            {!showWalletBal ? (
              <>
                <Flex flexDir={"column"}>
                  <Text
                    fontFamily={"Helvetica"}
                    fontSize={{ base: "20px", md: "20px" }}
                    fontWeight={"600"}
                    cursor="pointer"
                    width={{
                      base: "190px",
                      md: "210px",
                      lg: "220px",
                      xl: "230px",
                    }}
                    textOverflow={"ellipsis"}
                    overflow="hidden"
                    whiteSpace={"nowrap"}
                  >
                    {wishlistTitle}
                  </Text>
                  {isCollection ? (
                    <Text
                      color="#fff"
                      fontSize={"12px"}
                      fontWeight="400"
                      lineHeight={"15px"}
                    >
                      {
                        <NumberFormat
                          thousandSeparator
                          prefix="₦"
                          value={amount}
                          decimalScale={2}
                          defaultValue="0.00"
                          allowNegative={false}
                          placeholder="0.00"
                          displayType="text"
                          disabled
                        />
                      }{" "}
                      received | {amountText}
                    </Text>
                  ) : (
                    <Text
                      color="#CEFABB"
                      fontSize={"12px"}
                      fontWeight="400"
                      lineHeight={"15px"}
                    >
                      {amount} {amountText}
                    </Text>
                  )}
                  {!hideCard && (
                    <Flex gap="5px">
                      <DaysLeftCmp
                        width={wishCardWidth}
                        num={amount!}
                        text={amountText!}
                        bgColor={pillBgColor}
                        color={pillTextColor}
                      />
                      {!weddingType && icon && (
                        <IconCardCmp width={emojiCardWidth} icon={icon} />
                      )}
                    </Flex>
                  )}
                </Flex>
                <Flex gap="5px">
                  {/* <DaysLeftCmp
                    width={wishCardWidth}
                    num={amount}
                    text={amountText}
                  /> */}
                  {!weddingType && (
                    // <IconCardCmp width={emojiCardWidth} icon={icon} />
                    <Image
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (ubxdid && hashtag) {
                          setShareWeddingModal(true);
                        }else {
                          if (isCollection) {
                            setSharePageModal(true);
                          } else {
                            setShowShareModal(true);
                          }
                        }
                      }}
                      width="26px"
                      src={ShareImg}
                      alt="Share"
                    />
                  )}
                </Flex>
              </>
            ) : (
              <>
                <BalanceCmp
                  widthMd="100%"
                  text={wishlistTitle}
                  amount={
                    <NumberFormat
                      thousandSeparator
                      // prefix="₦"
                      value={walletBalance}
                      decimalScale={2}
                      defaultValue="0.00"
                      allowNegative={false}
                      placeholder="0.00"
                      displayType="text"
                      disabled
                    />
                  }
                  padding="0"
                  bg="none"
                  showToggle
                  h="unset"
                  showPayoutBtn
                  payoutBtnAction={payoutBtnAction}
                  payoutBtnText={payoutBtnText}
                  isCompleted={isCompleted}
                  toggleDisabled={toggleDisabled}
                />
              </>
            )}
          </Flex>
        </Box>
      </Box>
      <ShareModal
        context="wishlistcard"
        showWelcomeNote={isIndividual}
        shareTitle={!isIndividual}
        isOpen={showShareModal}
        onRequestClose={() => {
          setShowShareModal(false);
        }}
        // customFn={() => {
        //   if (userDetails) {
        //     dispatch(
        //       getAWishlist({
        //         userID: userDetails._id!,
        //         slug: slug!,
        //       })
        //     );
        //   }
        // }}
        wishlist={wishlistData}
        maxWidth="400px"
        showCloseIcon={false}
      />
      <SharePageModal
        isOpen={sharePageModal}
        onRequestClose={() => {
          setSharePageModal(false);
        }}
        isCollection={true}
        eventTitle={`${userDetails?.fullname}'s Page`}
        maxWidth="400px"
        showCloseIcon={false}
        slug={slug}
      />
      <ShareWeddingModal
        ubxdid={ubxdid!}
        hashtag={hashtag!}
        isOpen={shareWeddingModal}
        onRequestClose={() => {
          setShareWeddingModal(false);
        }}
        maxWidth="400px"
      />
    </>
  );
};

export default WishlistCardCmp;
