
const trimMoney = (amount: number) => {
  const strAmount = Math.ceil(amount).toString();
  if (amount < 1000){
    return strAmount;
  }
  if (amount >= 1000 && amount < 1_00_000) {
    if (strAmount[1] !== '0') {
      if (amount >= 10_000) {
        return `${strAmount.slice(0, 2)}${Number(strAmount[2]) ? "." + strAmount[2] : ""}k`;
      }
      return `${strAmount.slice(0, 1)}.${strAmount[1]}k`;
    }
    return `${strAmount.length > 4 ? strAmount.slice(0, 2) : strAmount.slice(0, 1)}k`;
  }else if (amount >= 1_00_000 && amount < 1_000_000) {
    return `${strAmount.slice(0, 3)}k`;
  }else if (amount >= 1_000_000 && amount < 1_000_000_000) {
    if (strAmount[1] !== '0') {
      return `${strAmount.slice(0, 1)}.${strAmount[1]}m`;
    }
    return `${strAmount.slice(0, 1)}m`;
  }else if (amount >= 1_000_000_000 && amount < 1_000_000_000_000) {
    if (strAmount[1] !== '0') {
      return `${strAmount.slice(0, 1)}.${strAmount[1]}b`;
    }
    return `${strAmount.slice(0, 1)}b`;
  }else if (amount >= 1_000_000_000_000 && amount < 1_000_000_000_000_000) {
    if (strAmount[1] !== '0') {
      return `${strAmount.slice(0, 1)}.${strAmount[1]}tr`;
    }
    return `${strAmount.slice(0, 1)}tr`;
  }

  return amount;
};
export default trimMoney;
