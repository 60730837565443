export const ColorCodes = {
  White: "#FFFFFF",
  Black: "#000000",
  Red: "#FF0000",
  Lime: "#00FF00",
  Blue: "#0000FF",
  Yellow: "#FFFF00",
  Cyan: "#00FFFF",
  Magenta: "#FF00FF",
  Silver: "#C0C0C0",
  Gray: "#808080",
  Maroon: "#800000",
  Olive: "#808000",
  Green: "#008000",
  Purple: "#800080",
  Teal: "#008080",
  Navy: "#000080",
  AliceBlue: "#F0F8FF",
  AntiqueWhite: "#FAEBD7",
  Aqua: "#00FFFF",
  Aquamarine: "#7FFFD4",
  Azure: "#F0FFFF",
  Beige: "#F5F5DC",
  Bisque: "#FFE4C4",
  BlanchedAlmond: "#FFEBCD",
  BlueViolet: "#8A2BE2",
  Brown: "#A52A2A",
  BurlyWood: "#DEB887",
  CadetBlue: "#5F9EA0",
  Chartreuse: "#7FFF00",
  Chocolate: "#D2691E",
  Coral: "#FF7F50",
  CornflowerBlue: "#6495ED",
  Cornsilk: "#FFF8DC",
  Crimson: "#DC143C",
  DarkBlue: "#00008B",
  DarkCyan: "#008B8B",
  DarkGoldenRod: "#B8860B",
  DarkGray: "#A9A9A9",
  DarkGreen: "#006400",
  DarkKhaki: "#BDB76B",
  DarkMagenta: "#8B008B",
  DarkOliveGreen: "#556B2F",
  DarkOrange: "#FF8C00",
  DarkOrchid: "#9932CC",
  DarkRed: "#8B0000",
  DarkSalmon: "#E9967A",
  DarkSeaGreen: "#8FBC8F",
  DarkSlateBlue: "#483D8B",
  DarkSlateGray: "#2F4F4F",
  DarkTurquoise: "#00CED1",
  DarkViolet: "#9400D3",
  DeepPink: "#FF1493",
  DeepSkyBlue: "#00BFFF",
  DimGray: "#696969",
  DodgerBlue: "#1E90FF",
  FireBrick: "#B22222",
  FloralWhite: "#FFFAF0",
  ForestGreen: "#228B22",
  Fuchsia: "#FF00FF",
  Gainsboro: "#DCDCDC",
  GhostWhite: "#F8F8FF",
  Gold: "#FFD700",
  GoldenRod: "#DAA520",
  GreenYellow: "#ADFF2F",
  HoneyDew: "#F0FFF0",
  HotPink: "#FF69B4",
  IndianRed: "#CD5C5C",
  Indigo: "#4B0082",
  Ivory: "#FFFFF0",
  Khaki: "#F0E68C",
  Lavender: "#E6E6FA",
  LavenderBlush: "#FFF0F5",
  LawnGreen: "#7CFC00",
  LemonChiffon: "#FFFACD",
  LightBlue: "#ADD8E6",
  LightCoral: "#F08080",
  LightCyan: "#E0FFFF",
  LightGoldenRodYellow: "#FAFAD2",
  LightGray: "#D3D3D3",
  LightGreen: "#90EE90",
  LightPink: "#FFB6C1",
  LightSalmon: "#FFA07A",
  LightSeaGreen: "#20B2AA",
  LightSkyBlue: "#87CEFA",
  LightSlateGray: "#778899",
  LightSteelBlue: "#B0C4DE",
  LightYellow: "#FFFFE0",
  LimeGreen: "#32CD32",
  Linen: "#FAF0E6",
  MediumAquaMarine: "#66CDAA",
  MediumBlue: "#0000CD",
  MediumOrchid: "#BA55D3",
  MediumPurple: "#9370DB",
  MediumSeaGreen: "#3CB371",
  MediumSlateBlue: "#7B68EE",
  MediumSpringGreen: "#00FA9A",
  MediumTurquoise: "#48D1CC",
  MediumVioletRed: "#C71585",
  MidnightBlue: "#191970",
  MintCream: "#F5FFFA",
  MistyRose: "#FFE4E1",
  Moccasin: "#FFE4B5",
  NavajoWhite: "#FFDEAD",
  OldLace: "#FDF5E6",
  OliveDrab: "#6B8E23",
  Orange: "#FFA500",
  OrangeRed: "#FF4500",
  Orchid: "#DA70D6",
  PaleGoldenRod: "#EEE8AA",
  PaleGreen: "#98FB98",
  PaleTurquoise: "#AFEEEE",
  PaleVioletRed: "#DB7093",
  PapayaWhip: "#FFEFD5",
  PeachPuff: "#FFDAB9",
  Peru: "#CD853F",
  Pink: "#FFC0CB",
  Plum: "#DDA0DD",
  PowderBlue: "#B0E0E6",
  RebeccaPurple: "#663399",
  RosyBrown: "#BC8F8F",
  RoyalBlue: "#4169E1",
  SaddleBrown: "#8B4513",
  Salmon: "#FA8072",
  SandyBrown: "#F4A460",
  SeaGreen: "#2E8B57",
  SeaShell: "#FFF5EE",
  Sienna: "#A0522D",
  SkyBlue: "#87CEEB",
  SlateBlue: "#6A5ACD",
  SlateGray: "#708090",
  Snow: "#FFFAFA",
  SpringGreen: "#00FF7F",
  SteelBlue: "#4682B4",
  Tan: "#D2B48C",
  Thistle: "#D8BFD8",
  Tomato: "#FF6347",
  Turquoise: "#40E0D0",
  Violet: "#EE82EE",
  Wheat: "#F5DEB3",
  WhiteSmoke: "#F5F5F5",
  YellowGreen: "#9ACD32",
  Amethyst: "#9966CC",
  Apricot: "#FBCEB1",
  BrickRed: "#CB4154",
  Periwinkle: "#CCCCFF",
  Auburn: "#A52A2A",
  BabyBlue: "#89CFF0",
  Burgundy: "#800020",
  Celadon: "#ACE1AF",
  Charcoal: "#36454F",
  Citrine: "#E4D00A",
  Cobalt: "#0047AB",
  CoralPink: "#F88379",
  CrimsonRed: "#990000",
  Emerald: "#50C878",
  GoldenYellow: "#FFD700",
  IndigoBlue: "#4B0082",
  Mauve: "#E0B0FF",
  OliveGreen: "#556B2F",
  PastelPurple: "#B39EB5",
  Raspberry: "#E30B5D",
  Sapphire: "#0F52BA",
  Scarlet: "#FF2400",
  Tangerine: "#F28500",
  TurquoiseBlue: "#00CED1",
  Verdigris: "#43BFC7",
  AbsoluteZero: "#0048BA",
  AcidGreen: "#B0BF1A",
  Aero: "#7CB9E8",
  AfricanViolet: "#B284BE",
  AirForceBlue: "#5D8AA8",
  Alabaster: "#F2F0E6",
  AlizarinCrimson: "#E32636",
  AlloyOrange: "#C46210",
  Almond: "#EFDECD",
  Amaranth: "#E52B50",
  Amazon: "#3B7A57",
  Amber: "#FFBF00",
  AmberSAE: "#FF7E00",
  AmericanRose: "#FF033E",
  AndroidGreen: "#A4C639",
  AntiqueBrass: "#CD9575",
  AntiqueBronze: "#665D1E",
  AntiqueFuchsia: "#915C83",
  AntiqueRuby: "#841B2D",
  AoEnglish: "#008000",
  AppleGreen: "#8DB600",
  ArcticLime: "#D0FF14",
  ArmyGreen: "#4B5320",
  ArylideYellow: "#E9D66B",
  AshGrey: "#B2BEB5",
  Asparagus: "#87A96B",
  AtomicTangerine: "#FF9966",
  Aureolin: "#FDEE00",
  Avocado: "#568203",
  AztecGold: "#C39953",
  AzureMist: "#F0FFFF",
  BabyBlueEyes: "#A1CAF1",
  BabyPink: "#F4C2C2",
  BabyPowder: "#FEFEFA",
  BakerMillerPink: "#FF91AF",
  BallBlue: "#21ABCD",
  BananaMania: "#FAE7B5",
  BananaYellow: "#FFE135",
  BangladeshGreen: "#006A4E",
  BarbiePink: "#E0218A",
  BarnRed: "#7C0A02",
  BattleshipGrey: "#848482",
  Bazaar: "#98777B",
  BeauBlue: "#BCD4E6",
  Beaver: "#9F8170",
  BigDipOruby: "#9C2542",
  BigFootFeet: "#E88E5A",
  Bistre: "#3D2B1F",
  BistreBrown: "#967117",
  BitterLemon: "#CAE00D",
  BitterLime: "#BFFF00",
  Bittersweet: "#FE6F5E",
  BittersweetShimmer: "#BF4F51",
  BlackBean: "#3D0C02",
  BlackCoral: "#54626F",
  BlackLeatherJacket: "#253529",
  BlackOlive: "#3B3C36",
  BlackShadows: "#BFAFB2",
  BlastOffBronze: "#A57164",
  BleuDeFrance: "#318CE7",
  BlizzardBlue: "#ACE5EE",
  Blond: "#FAF0BE",
  BlueBell: "#A2A2D0",
  BlueCrayola: "#1F75FE",
  BlueGray: "#6699CC",
  BlueGreen: "#0D98BA",
  BlueLagoon: "#5E93A1",
  BlueMunsell: "#0093AF",
  BlueNCS: "#0087BD",
  BluePigment: "#333399",
  BlueRYB: "#0247FE",
  BlueSapphire: "#126180",
  BlueYonder: "#5072A7",
  Blueberry: "#4F86F7",
  Bluebonnet: "#1C1CF0",
  Blush: "#DE5D83",
  Bole: "#79443B",
  Bone: "#E3DAC9",
  BoogerBuster: "#DDE26A",
  BostonUniversityRed: "#CC0000",
  BottleGreen: "#006A4E",
  Boysenberry: "#873260",
  BrandeisBlue: "#0070FF",
  Brass: "#B5A642",
  BrightCerulean: "#1DACD6",
  BrightGreen: "#66FF00",
  BrightLavender: "#BF94E4",
  BrightLilac: "#D891EF",
  BrightMaroon: "#C32148",
  BrightNavyBlue: "#1974D2",
  BrightPink: "#FF007F",
  BrightTurquoise: "#08E8DE",
  BrightUbe: "#D19FE8",
  BrightYellow: "#FFAA1D",
  BrilliantAzure: "#3399FF",
  BrilliantLavender: "#F4BBFF",
  BrilliantRose: "#FF55A3",
  BrinkPink: "#FB607F",
  BritishRacingGreen: "#004225",
  Bronze: "#CD7F32",
  BronzeYellow: "#737000",
  BrownSugar: "#AF6E4D",
  BrunswickGreen: "#1B4D3E",
  BubbleGum: "#FFC1CC",
  Bubbles: "#E7FEFF",
  Buff: "#F0DC82",
  BulgarianRose: "#480607",
  Burlywood: "#DEB887",
  BurntOrange: "#CC5500",
  BurntSienna: "#E97451",
  BurntUmber: "#8A3324",
  ButtonBlue: "#24A0ED",
  Byzantine: "#BD33A4",
  Byzantium: "#702963",
  Cadet: "#536872",
  CadetGrey: "#91A3B0",
  CadmiumGreen: "#006B3C",
  CadmiumOrange: "#ED872D",
  CadmiumRed: "#E30022",
  CadmiumYellow: "#FFF600",
  CafeAuLait: "#A67B5B",
  CafeNoir: "#4B3621",
  CalPolyGreen: "#1E4D2B",
  CambridgeBlue: "#A3C1AD",
  Camel: "#C19A6B",
  CameoPink: "#EFBBCC",
  CamouflageGreen: "#78866B",
  Canary: "#FFFF99",
  CanaryYellow: "#FFEF00",
  CandyAppleRed: "#FF0800",
  CandyPink: "#E4717A",
  Capri: "#00BFFF",
  CaputMortuum: "#592720",
  Caramel: "#FFD59A",
  Cardinal: "#C41E3A",
  CaribbeanGreen: "#00CC99",
  Carmine: "#960018",
  CarmineMP: "#D70040",
  CarminePink: "#EB4C42",
  CarmineRed: "#FF0038",
  CarnationPink: "#FFA6C9",
  Carnelian: "#B31B1B",
  CarolinaBlue: "#56A0D3",
  CarrotOrange: "#ED9121",
  CastletonGreen: "#00563F",
  CatalinaBlue: "#062A78",
  Catawba: "#703642",
  CedarChest: "#C95A49",
  Ceil: "#92A1CF",
  CeladonBlue: "#007BA7",
  CeladonGreen: "#2F847C",
  Celeste: "#B2FFFF",
  CelticBlue: "#246BCE",
  Cerise: "#DE3163",
  Cerulean: "#007BA7",
  CeruleanBlue: "#2A52BE",
  CeruleanFrost: "#6D9BC3",
  CeruleanCrayola: "#1DACD6",
  CGBlue: "#007AA5",
  CGRed: "#E03C31",
  Chamoisee: "#A0785A",
  Champagne: "#F7E7CE",
  ChampagnePink: "#F1DDCF",
  CharlestonGreen: "#232B2B",
  Charm: "#DFFF00",
  CharmPink: "#E68FAC",
  ChartreuseYellow: "#DFFF00",
  Cherry: "#DE3163",
  CherryBlossomPink: "#FFB7C5",
  Chestnut: "#954535",
  ChinaPink: "#DE6FA1",
  ChinaRose: "#A8516E",
  ChineseRed: "#AA381E",
  ChineseViolet: "#856088",
  ChineseYellow: "#FFB200",
  ChlorophyllGreen: "#4AFF00",
  ChocolateCosmos: "#58111A",
  ChromeYellow: "#FFA700",
  Cinereous: "#98817B",
  Cinnabar: "#E34234",
  Cinnamon: "#D2691E",
  CinnamonSatin: "#CD607E",
  CitrineBrown: "#933709",
  Citron: "#9FA91F",
  Claret: "#7F1734",
  ClassicRose: "#FBCCE7",
  CobaltBlue: "#0047AB",
  CocoaBrown: "#D2691E",
  Coconut: "#965A3E",
  Coffee: "#6F4E37",
  ColumbiaBlue: "#B9D9EB",
  CongoPink: "#F88379",
  CoolBlack: "#002E63",
  CoolGrey: "#8C92AC",
  Copper: "#B87333",
  CopperCrayola: "#DA8A67",
  CopperPenny: "#AD6F69",
  CopperRed: "#CB6D51",
  CopperRose: "#996666",
  Coquelicot: "#FF3800",
  CoralRed: "#FF4040",
  Cordovan: "#893F45",
  Corn: "#FBEC5D",
  CornellRed: "#B31B1B",
  CosmicCobalt: "#2E2D88",
  CosmicLatte: "#FFF8E7",
  CoyoteBrown: "#81613C",
  CottonCandy: "#FFBCD9",
  Cream: "#FFFDD0",
  CrimsonGlory: "#BE0032",
  CyanAzure: "#4E82B4",
  CyanBlueAzure: "#4682BF",
  CyanCobaltBlue: "#28589C",
  CyanCornflowerBlue: "#188BC2",
  CyanProcess: "#00B7EB",
  CyberGrape: "#58427C",
  CyberYellow: "#FFD300",
  Cyclamen: "#F56FA1",
  DarkBlueGray: "#666699",
  DarkBrown: "#654321",
  DarkByzantium: "#5D3954",
  DarkCandyAppleRed: "#A40000",
  DarkCerulean: "#08457E",
  DarkCharcoal: "#333333",
  DarkChestnut: "#986960",
  DarkCoral: "#CD5B45",
  DarkElectricBlue: "#536878",
  DarkGoldenrod: "#B8860B",
  DarkGunmetal: "#1F262A",
  DarkImperialBlue: "#00416A",
  DarkImperialBlue1: "#00147E",
  DarkJungleGreen: "#1A2421",
  DarkLava: "#483C32",
  DarkLavender: "#734F3C",
  DarkLiver: "#534B4F",
  DarkLiverHorses: "#543D37",
  DarkMediumGray: "#A9A9A9",
  DarkMidnightBlue: "#003366",
  DarkMossGreen: "#4A5D23",
  DarkPastelBlue: "#779ECB",
  DarkPastelGreen: "#03C03C",
  DarkPastelPurple: "#966FD6",
  DarkPastelRed: "#C23B22",
  DarkPink: "#E75480",
  DarkPowderBlue: "#003399",
  DarkPuce: "#4F3A3C",
  DarkPurple: "#301934",
  DarkRaspberry: "#872657",
  DarkScarlet: "#560319",
  DarkSienna: "#3C1414",
  DarkSkyBlue: "#8CBED6",
  DarkSpringGreen: "#177245",
  DarkTan: "#918151",
  DarkTangerine: "#FFA812",
  DarkTerraCotta: "#CC4E5C",
  DarkVanilla: "#D1BEA8",
  DarkYellow: "#9B870C",
  DartmouthGreen: "#00693E",
  DavyGrey: "#555555",
  DebianRed: "#D70A53",
  DeepAquamarine: "#40826D",
  DeepCarmine: "#A9203E",
  DeepCarminePink: "#EF3038",
  DeepCarrotOrange: "#E9692C",
  DeepCerise: "#DA3287",
  DeepChampagne: "#FAD6A5",
  DeepChestnut: "#B94E48",
  DeepCoffee: "#704241",
  DeepFuchsia: "#C154C1",
  DeepGreen: "#056608",
  DeepGreenCyanTurquoise: "#0E7C61",
  DeepJungleGreen: "#004B49",
  DeepKoamaru: "#333366",
  DeepLemon: "#F5C71A",
  DeepLilac: "#9955BB",
  DeepMagenta: "#CC00CC",
  DeepMauve: "#D473D4",
  DeepMossGreen: "#355E3B",
  DeepPeach: "#FFCBA4",
  DeepPuce: "#A95C68",
  DeepRed: "#850101",
  DeepRuby: "#843F5B",
  DeepSaffron: "#FF9933",
  DeepSpaceSparkle: "#4A646C",
  DeepTaupe: "#7E5E60",
  DeepTuscanRed: "#66424D",
  DeepViolet: "#330066",
  Deer: "#BA8759",
  Denim: "#1560BD",
  DenimBlue: "#2243B6",
  DesaturatedCyan: "#669999",
  Desert: "#C19A6B",
  DesertSand: "#EDC9AF",
  Desire: "#EA3C53",
  Diamond: "#B9F2FF",
  DingyDungeon: "#C53151",
  Dirt: "#9B7653",
  DogwoodRose: "#D71868",
  DollarBill: "#85BB65",
  DonkeyBrown: "#664C28",
  Drab: "#967117",
  DukeBlue: "#00009C",
  DustStorm: "#E5CCC9",
  DutchWhite: "#EFDFBB",
  EarthYellow: "#E1A95F",
  Ebony: "#555D50",
  Ecru: "#C2B280",
  EerieBlack: "#1B1B1B",
  Eggplant: "#614051",
  Eggshell: "#F0EAD6",
  EgyptianBlue: "#1034A6",
  ElectricBlue: "#7DF9FF",
  ElectricCrimson: "#FF003F",
  ElectricCyan: "#00FFFF",
  ElectricGreen: "#00FF00",
  ElectricIndigo: "#6F00FF",
  ElectricLavender: "#F4BBFF",
  ElectricLime: "#CCFF00",
  ElectricPurple: "#BF00FF",
  ElectricUltramarine: "#3F00FF",
  ElectricViolet: "#8F00FF",
  ElectricYellow: "#FFFF33",
  Eminence: "#6C3082",
  EnglishGreen: "#1B4D3E",
  EnglishLavender: "#B48395",
  EnglishRed: "#AB4B52",
  EnglishVermillion: "#CC474B",
  EnglishViolet: "#563C5C",
  EtonBlue: "#96C8A2",
  Eucalyptus: "#44D7A8",
  FacebookBlue: "#1877F2",
  Fallow: "#C19A6B",
  FaluRed: "#801818",
  Fandango: "#B53389",
  FandangoPink: "#DE5285",
  FashionFuchsia: "#F400A1",
  Fawn: "#E5AA70",
  Feldgrau: "#4D5D53",
  Feldspar: "#FDD5B1",
  FernGreen: "#4F7942",
  FerrariRed: "#FF2800",
  FieldDrab: "#6C541E",
  FieryRose: "#FF5470",
  FireEngineRed: "#CE2029",
  Flame: "#E25822",
  FlamingoPink: "#FC8EAC",
  Flattery: "#6B4423",
  Flavescent: "#F7E98E",
  Flax: "#EEDC82",
  Flirt: "#A2006D",
  FluorescentOrange: "#FFBF00",
  FluorescentPink: "#FF1493",
  FluorescentYellow: "#CCFF00",
  Folly: "#FF004F",
  FrenchBeige: "#A67B5B",
  FrenchBistre: "#856D4D",
  FrenchBlue: "#0072BB",
  FrenchFuchsia: "#FD3F92",
  FrenchLilac: "#86608E",
  FrenchLime: "#9EFD38",
  FrenchMauve: "#D473D4",
  FrenchPink: "#FD6C9E",
  FrenchPlum: "#811453",
  FrenchPuce: "#4E1609",
  FrenchRaspberry: "#C72C48",
  FrenchRose: "#F64A8A",
  FrenchSkyBlue: "#77B5FE",
  FrenchViolet: "#8806CE",
  FrenchWine: "#AC1E44",
  FreshAir: "#A6E7FF",
  Frostbite: "#E936A7",
  FuchsiaCrayola: "#C154C1",
  FuchsiaPink: "#FF77FF",
  FuchsiaPurple: "#CC397B",
  FuchsiaRose: "#C74375",
  Fulvous: "#E48400",
  FuzzyWuzzy: "#CC6666",
  Gamboge: "#E49B0F",
  GambogeOrangeBrown: "#996600",
  GargoyleGas: "#FFDF46",
  GenericViridian: "#007F66",
  GiantsOrange: "#FE5A1D",
  Ginger: "#B06500",
  Glaucous: "#6082B6",
  Glitter: "#E6E8FA",
  GlossyGrape: "#AB83A1",
  GOGreen: "#00AB66",
  GoldCrayola: "#E6BE8A",
  GoldFusion: "#85754E",
  GoldMetallic: "#D4AF37",
  GoldWebGolden: "#FFD700",
  GoldenBrown: "#996515",
  GoldenPoppy: "#FCC200",
  Goldenrod: "#DAA520",
  GraniteGray: "#676767",
  GrannySmithApple: "#A8E4A0",
  Grape: "#6F2DA8",
  GrayAsparagus: "#465945",
  GrayHTMLCSSGray: "#808080",
  GrayX11Gray: "#BEBEBE",
  GrayBlue: "#8C92AC",
  GreenCrayola: "#1CAC78",
  GreenCSS: "#008000",
  GreenMunsell: "#00A877",
  GreenNCS: "#009F6B",
  GreenPantone: "#00AD43",
  GreenPigment: "#00A550",
  GreenRYB: "#66B032",
  GreenBlue: "#1164B4",
  GreenCyan: "#009966",
  GreenLizard: "#A7F432",
  GreenSheen: "#6EAEA1",
  Grullo: "#A99A86",
  GuppieGreen: "#00FF7F",
  Gunmetal: "#2a3439",
  HalayàÚbe: "#663854",
  HanBlue: "#446CCF",
  HanPurple: "#5218FA",
  HansaYellow: "#E9D66B",
  Harlequin: "#3FFF00",
  HarlequinGreen: "#46CB18",
  HarvardCrimson: "#C90016",
  HarvestGold: "#DA9100",
  HeartGold: "#808000",
  HeatWave: "#FF7A00",
  HeidelbergRed: "#960018",
  Heliotrope: "#DF73FF",
  HeliotropeGray: "#AA98A9",
  HeliotropeMagenta: "#AA00BB",
  HollywoodCerise: "#F400A1",
  Honeydew: "#F0FFF0",
  HonoluluBlue: "#006DB0",
  HookerSGreen: "#49796B",
  HotMagenta: "#FF1DCE",
  HunterGreen: "#355E3B",
  Iceberg: "#71A6D2",
  Icterine: "#FCF75E",
  IlluminatingEmerald: "#319177",
  Imperial: "#602F6B",
  ImperialBlue: "#002395",
  ImperialPurple: "#66023C",
  ImperialRed: "#ED2939",
  Inchworm: "#B2EC5D",
  Independence: "#4C516D",
  IndiaGreen: "#138808",
  IndianYellow: "#E3A857",
  IndigoDye: "#091F92",
  IndigoWeb: "#4B0082",
  InternationalKleinBlue: "#002FA7",
  InternationalOrange: "#FF4F00",
  Iris: "#5A4FCF",
  Irresistible: "#B3446C",
  Isabelline: "#F4F0EC",
  ItalianSkyBlue: "#B2FFFF",
  Jade: "#00A86B",
  JapaneseCarmine: "#9D2933",
  JapaneseIndigo: "#264348",
  JapaneseLaurel: "#5B3256",
  JapaneseViolet: "#5B3256",
  Jasmine: "#F8DE7E",
  Jasper: "#D73B3E",
  JazzberryJam: "#A50B5E",
  JellyBean: "#DA614E",
  Jet: "#343434",
  Jonquil: "#F4CA16",
  JordyBlue: "#8AB9F1",
  JuneBud: "#BDDA57",
  JungleGreen: "#29AB87",
  KellyGreen: "#4CBB17",
  KenyanCopper: "#7C1C05",
  Keppel: "#3AB09E",
  KeyLime: "#E8F48C",
  Kobe: "#882D17",
  Kobi: "#E79FC4",
  Kobicha: "#6B4423",
  KombuGreen: "#354230",
  KSUPurple: "#512888",
  KUCrimson: "#E8000D",
  LaSalleGreen: "#087830",
  LanguidLavender: "#D6CADD",
  LapisLazuli: "#26619C",
  LaserLemon: "#FFFF66",
  LaurelGreen: "#A9BA9D",
  Lava: "#CF1020",
  LavenderBlue: "#CCCCFF",
  LavenderFloral: "#B57EDC",
  LavenderGray: "#C4C3D0",
  LavenderIndigo: "#9457EB",
  LavenderMagenta: "#EE82EE",
  LavenderMist: "#E6E6FA",
  LavenderPink: "#FBAED2",
  LavenderPurple: "#967BB6",
  LavenderRose: "#FBA0E3",
  Lemon: "#FFF700",
  LemonCurry: "#CCA01D",
  LemonGlacier: "#FDFF00",
  LemonLime: "#E3FF00",
  LemonMeringue: "#F6EABE",
  LemonYellow: "#FFF44F",
  Lenurple: "#BA93D8",
  Liberty: "#545AA7",
  Licorice: "#1A1110",
  LightApricot: "#FDD5B1",
  LightBrilliantRed: "#FE2E2E",
  LightBrown: "#B5651D",
  LightCarminePink: "#E66771",
  LightCobaltBlue: "#88ACE0",
  LightCornflowerBlue: "#93CCEA",
  LightCrimson: "#F56991",
  LightDeepPink: "#FF5CCD",
  LightFrenchBeige: "#C8AD7F",
  LightFuchsiaPink: "#F984EF",
  LightGoldenrodYellow: "#FAFAD2",
  LightGrayishMagenta: "#CC99CC",
  LightHotPink: "#FFB3DE",
  LightKhaki: "#F0E68C",
  LightMediumOrchid: "#D39BCB",
  LightMossGreen: "#ADDFAD",
  LightOrange: "#FFA474",
  LightOrchid: "#E6A8D7",
  LightPastelPurple: "#B19CD9",
  LightPeriwinkle: "#C5CBE1",
  LightRedOchre: "#E97451",
  LightSalmonPink: "#FF9999",
  LightSilver: "#D8D8D8",
  LightTaupe: "#B38B6D",
  LightThulianPink: "#E68FAC",
  Lilac: "#C8A2C8",
  LilacLuster: "#AE98AA",
  LimeWebX11Green: "#00FF00",
  Limerick: "#9DC209",
  LincolnGreen: "#195905",
  LinkWater: "#D9E4F5",
  LiseranPurple: "#DE6FA1",
  LittleBoyBlue: "#6CA0DC",
  LittleGirlPink: "#F8B9D4",
  Liver: "#674C47",
  LiverChestnut: "#987456",
  LiverDogs: "#B86D29",
  LiverOrgan: "#6C2E1F",
  LiverPurple: "#A5694F",
  LiverRed: "#C73E3A",
  LiverTan: "#6C4035",
  LiverYellow: "#7A4E37",
  Livid: "#6699CC",
  Lotion: "#FEFDFA",
  LotionBlue: "#15F2FD",
  LotionPink: "#FFB7D5",
  LotionYellow: "#FFFDD6",
  Lumber: "#FFE4CD",
  Lust: "#E62020",
  MacaroniAndCheese: "#FFBD88",
  MagentaCrayola: "#FF55A3",
  MagentaDye: "#CA1F7B",
  MagentaPantone: "#D0417E",
  MagentaProcess: "#FF0090",
  MagentaHaze: "#9F4576",
  MagicMint: "#AAF0D1",
  MagicPotion: "#FF4466",
  Magnolia: "#F8F4FF",
  Mahogany: "#C04000",
  Maize: "#FBEC5D",
  MaizeCrayola: "#F2C649",
  MajorelleBlue: "#6050DC",
  Makin: "#006400",
  Malachite: "#0BDA51",
  Manatee: "#979AAA",
  MangoGreen: "#96FF00",
  MangoTango: "#FF8243",
  MangoYellow: "#FFC000",
  Mantis: "#74C365",
  MardiGras: "#880085",
  Marigold: "#EAA221",
  MaroonCrayola: "#C32148",
  MaroonWeb: "#800000",
  MaroonX11: "#B03060",
  MauveTaupe: "#915F6D",
  Mauvelous: "#EF98AA",
  MaximumBlue: "#47ABCC",
  MaximumBlueGreen: "#30BFBF",
  MaximumBluePurple: "#ACACE6",
  MaximumGreen: "#5E8C31",
  MaximumGreenYellow: "#D9E650",
  MaximumPurple: "#733380",
  MaximumRed: "#D92121",
  MaximumRedPurple: "#A63A79",
  MaximumYellow: "#FAFA37",
  MaximumYellowRed: "#F2BA49",
  MayGreen: "#4C9141",
  MayaBlue: "#73C2FB",
  MeatBrown: "#E5B73B",
  MediumAquamarine: "#66DDAA",
  MediumCandyAppleRed: "#E2062C",
  MediumCarmine: "#AF4035",
  MediumChampagne: "#F3E5AB",
  MediumElectricBlue: "#035096",
  MediumJungleGreen: "#1C352D",
  MediumLavenderMagenta: "#DDA0DD",
  MediumPersianBlue: "#0067A5",
  MediumRedViolet: "#BB3385",
  MediumRuby: "#AA4069",
  MediumSkyBlue: "#80DAEB",
  MediumSpringBud: "#C9DC87",
  MediumTaupe: "#674C47",
  MediumTealBlue: "#0054B4",
  Melon: "#FEBAAD",
  Menthol: "#C1F9A2",
  MetallicBlue: "#32527B",
  MetallicBronze: "#A97142",
  MetallicBrown: "#AC4313",
  MetallicGold: "#D3AF37",
  MetallicGreen: "#296E01",
  MetallicOrange: "#DA680F",
  MetallicPink: "#EDC9AF",
  MetallicRed: "#A62C2B",
  MetallicSeaweed: "#0A7E8C",
  MetallicSilver: "#A8A9AD",
  MetallicSunburst: "#9C7C38",
  MetallicViolet: "#5B0A91",
  MetallicYellow: "#FDCC0D",
  MexicanPink: "#E4007C",
  MiddleBlue: "#7ED4E6",
  MiddleBlueGreen: "#8DD9CC",
  MiddleBluePurple: "#8B72BE",
  MiddleCarmine: "#D96CBE",
  MiddleGray: "#8B8680",
  MiddleGreen: "#4D8C57",
  MiddleGreenYellow: "#ACBF60",
  MiddlePurple: "#D982B5",
  MiddleRed: "#E58E73",
  MiddleRedPurple: "#A55353",
  MiddleYellow: "#FFEB00",
  MiddleYellowRed: "#ECB176",
  Midnight: "#702670",
  MidnightGreenEagleGreen: "#004953",
  MikadoYellow: "#FFC40C",
  MimiPink: "#FFDAE9",
  Mindaro: "#E3F988",
  Ming: "#36747D",
  MinionYellow: "#F5E050",
  Mint: "#3EB489",
  MintGreen: "#98FF98",
  MistyMoss: "#BBB477",
  ModeBeige: "#967117",
  MorningBlue: "#8DA399",
  MossGreen: "#8A9A5B",
  MountainMeadow: "#30BA8F",
  MountbattenPink: "#997A8D",
  MughalGreen: "#306030",
  Mulberry: "#C54B8C",
  MulberryCrayola: "#C8509B",
  "Mummy'sTomb": "#828E84",
  Mustard: "#FFDB58",
  MustardBrown: "#CD7A00",
  MustardGreen: "#6E6E30",
  MustardYellow: "#E1AD01",
  MyrtleGreen: "#317873",
  Mystic: "#D65282",
  MysticMaroon: "#AD4379",
  MysticRed: "#FF5500",
  NadeshikoPink: "#F6ADC6",
  NapierGreen: "#2A8000",
  NaplesYellow: "#FADA5E",
  NavyBlue: "#1974D2",
  NavyPurple: "#9457EB",
  NeonBlue: "#4666FF",
  NeonCarrot: "#FFA343",
  NeonFuchsia: "#FE59C2",
  NeonGreen: "#39FF14",
  NewCar: "#214FC6",
  NewYorkPink: "#D7837F",
  Nickel: "#727472",
  NintendoRed: "#E4000F",
  NonPhotoBlue: "#A4DDED",
  Nyanza: "#E9FFDB",
  OceanBlue: "#4F42B5",
  OceanBoatBlue: "#0077BE",
  OceanGreen: "#48BF91",
  Ochre: "#CC7722",
  OgreOdor: "#FD5240",
  OldBurgundy: "#43302E",
  OldGold: "#CFB53B",
  OldHeliotrope: "#563C5C",
  OldLavender: "#796878",
  OldMauve: "#673147",
  OldMossGreen: "#867E36",
  OldRose: "#C08081",
  OldSilver: "#848482",
  Olivine: "#9AB973",
  Onyx: "#0F0F0F",
  Opal: "#A8C3BC",
  OperaMauve: "#B784A7",
  OrangeCrayola: "#FF7538",
  OrangePantone: "#FF5800",
  OrangePeel: "#FF9F00",
  OrangeSoda: "#FA5B3D",
  OrangeWeb: "#FFA500",
  OrangeYellow: "#F5BD1F",
  OrchidPink: "#F2BDCD",
  OrchidPurple: "#B76E79",
  OriolesOrange: "#FB4F14",
  OuterSpace: "#2D383A",
  OutrageousOrange: "#FF6E4A",
  OxfordBlue: "#002147",
  Oxley: "#6D9A79",
  "OU CrimsonRed": "#990000",
  Pacifica: "#1CA9C9",
  PakistanGreen: "#006600",
  PalatinateBlue: "#273BE2",
  PalatinatePurple: "#682860",
  PaleAqua: "#BCD4E6",
  PaleBlue: "#AFEEEE",
  PaleBrown: "#987654",
  PaleCarmine: "#AF4035",
  PaleCerulean: "#9BC4E2",
  PaleChestnut: "#DDADAF",
  PaleCopper: "#DA8A67",
  PaleCornflowerBlue: "#ABCDEF",
  PaleCyan: "#87D3F8",
  PaleGold: "#E6BE8A",
  PaleGoldenrod: "#EEE8AA",
  PaleLavender: "#DCD0FF",
  PaleMagenta: "#F984E5",
  PaleMagentaPink: "#FF99CC",
  PalePink: "#FADADD",
  PalePlum: "#DDA0DD",
  PaleRedViolet: "#DB7093",
  PaleRobinEggBlue: "#96DED1",
  PaleSilver: "#C9C0BB",
  PaleSpringBud: "#ECEBBD",
  PaleTaupe: "#BC987E",
  PaleViolet: "#CC99FF",
  PansyPurple: "#78184A",
  PaoloVeroneseGreen: "#009B7D",
  ParadisePink: "#E63E62",
  ParrotPink: "#D998A0",
  PastelBlue: "#AEC6CF",
  PastelBrown: "#836953",
  PastelGray: "#CFCFC4",
  PastelGreen: "#77DD77",
  PastelMagenta: "#F49AC2",
  PastelOrange: "#FFB347",
  PastelPink: "#DEA5A4",
  PastelRed: "#FF6961",
  PastelViolet: "#CB99C9",
  PastelYellow: "#FDFD96",
  Patriarch: "#800080",
  "Payne'sGrey": "#536878",
  Peach: "#FFE5B4",
  PeachCrayola: "#FFCBA4",
  PeachOrange: "#FFCC99",
  PeachYellow: "#FADFAD",
  Pear: "#D1E231",
  Pearl: "#EAE0C8",
  PearlAqua: "#88D8C0",
  PearlyPurple: "#B768A2",
  Peridot: "#E6E200",
  PeriwinkleCrayola: "#C3CDE6",
  PermanentGeraniumLake: "#E12C2C",
  PersianBlue: "#1C39BB",
  PersianGreen: "#00A693",
  PersianIndigo: "#32127A",
  PersianOrange: "#D99058",
  PersianPink: "#F77FBE",
  PersianPlum: "#701C1C",
  PersianRed: "#CC3333",
  PersianRose: "#FE28A2",
  Persimmon: "#EC5800",
  PewterBlue: "#8BA8B7",
  PhilippineBlue: "#0038A8",
  PhilippineBronze: "#6E3A07",
  PhilippineBrown: "#5D1916",
  PhilippineGold: "#B17304",
  PhilippineGoldenYellow: "#FFDF00",
  PhilippineGray: "#8C8C8C",
  PhilippineGreen: "#008543",
  PhilippineIndigo: "#00349E",
  PhilippineOrange: "#FF7300",
  PhilippinePink: "#FA1A8E",
  PhilippineRed: "#CE1127",
  PhilippineSilver: "#B3B3B3",
  PhilippineViolet: "#81007F",
  PhilippineYellow: "#FFD100",
  Phlox: "#DF00FF",
  PhthaloBlue: "#000F89",
  PhthaloGreen: "#123524",
  PictonBlue: "#45B1E8",
  PictorialCarmine: "#C30B4E",
  PiggyPink: "#FDDDE6",
  PineGreen: "#01796F",
  Pineapple: "#563C0D",
  PinkPantone: "#D74894",
  PinkFlamingo: "#FC74FD",
  PinkLace: "#FFDDF4",
  PinkLavender: "#D8B2D1",
  PinkOrange: "#FF9966",
  PinkPearl: "#E7ACCF",
  PinkRaspberry: "#980036",
  PinkSherbet: "#F78FA7",
  Pistachio: "#93C572",
  PixiePowder: "#391285",
  Platinum: "#E5E4E2",
  PlumWeb: "#DDA0DD",
  PlumpPurple: "#5946B2",
  PolishedPine: "#5DA493",
  PompAndPower: "#86608E",
  Popstar: "#BE4F62",
  PortlandOrange: "#FF5A36",
  PrincetonOrange: "#F58025",
  Prune: "#701C1C",
  PrussianBlue: "#003153",
  PsychedelicPurple: "#DF00FF",
  Puce: "#CC8899",
  PuceRed: "#722F37",
  PullmanBrown: "#644117",
  PullmanGreen: "#3B331C",
  Pumpkin: "#FF6D3A",
  PurpleWeb: "#800080",
  PurpleMunsell: "#9F00C5",
  PurpleX11: "#A020F0",
  PurpleHeart: "#69359C",
  PurpleMountainMajesty: "#9678B6",
  PurpleNavy: "#4E5180",
  PurplePizzazz: "#FE4EDA",
  PurplePlum: "#9C51B6",
  PurpleTaupe: "#50404D",
  Purpureus: "#9A4EAE",
  Quartz: "#51484F",
  QueenBlue: "#436B95",
  QueenPink: "#E8CCD7",
  QuinacridoneMagenta: "#8E3A59",
  Rackley: "#5D8AA8",
  RadicalRed: "#FF355E",
  RaisinBlack: "#242124",
  Rajah: "#FBAB60",
  RaspberryGlace: "#915F6D",
  RaspberryPink: "#E25098",
  RaspberryRose: "#B3446C",
  RawSienna: "#D68A59",
  RawUmber: "#826644",
  RazzleDazzleRose: "#FF33CC",
  Razzmatazz: "#E3256B",
  RazzmicBerry: "#8D4E85",
  RedCrayola: "#EE204D",
  RedMunsell: "#F2003C",
  RedNCS: "#C40233",
  RedPantone: "#ED2939",
  RedPigment: "#ED1C24",
  RedRyb: "#FE2712",
  RedBrown: "#A52A2A",
  RedDevil: "#860111",
  RedOrange: "#FF5349",
  RedPurple: "#E40078",
  RedSalsa: "#FD3A4A",
  RedViolet: "#C71585",
  Redwood: "#A45A52",
  Regalia: "#522D80",
  RegistrationBlack: "#000000",
  ResolutionBlue: "#002387",
  Rhythm: "#777696",
  RichBlack: "#004040",
  RichBlackFOGRA29: "#010B13",
  RichBlackFOGRA39: "#010203",
  RichBrilliantLavender: "#F1A7FE",
  RichCarmine: "#D70040",
  RichElectricBlue: "#0892D0",
  RichLavender: "#A76BCF",
  RichLilac: "#B666D2",
  RichMaroon: "#B03060",
  RifleGreen: "#414833",
  RoastCoffee: "#704241",
  RobinEggBlue: "#00CCCC",
  RocketMetallic: "#8A7F80",
  RomanSilver: "#838996",
  RootBeer: "#290E05",
  Rose: "#FF007F",
  RoseBonbon: "#F9429E",
  RoseDust: "#9E5E6F",
  RoseEbony: "#674846",
  RoseGold: "#B76E79",
  RoseMadder: "#E32636",
  RosePink: "#FF66CC",
  RoseQuartz: "#AA98A9",
  RoseRed: "#C21E56",
  RoseTaupe: "#905D5D",
  RoseVale: "#AB4E52",
  Rosewood: "#65000B",
  RossoCorsa: "#D40000",
  RoyalAzure: "#0038A8",
  RoyalBlueWeb: "#005BBF",
  RoyalFuchsia: "#CA2C92",
  RoyalPurple: "#7851A9",
  RoyalYellow: "#FADA5E",
  Ruber: "#CE4676",
  RubineRed: "#D10056",
  Ruby: "#E0115F",
  RubyRed: "#9B111E",
  Ruddy: "#FF0028",
  RuddyBrown: "#BB6528",
  RuddyPink: "#E18E96",
  Rufous: "#A81C07",
  Russet: "#80461B",
  RussianGreen: "#679267",
  RussianViolet: "#32174D",
  Rust: "#B7410E",
  RustyRed: "#DA2C43",
  SacramentoStateGreen: "#00563F",
  SafetyOrange: "#FF6700",
  SafetyOrangeBlazeOrange: "#FF6700",
  SafetyYellow: "#EED202",
  Saffron: "#F4C430",
  Sage: "#BCB88A",
  StPatricksBlue: "#23297A",
  SalmonPink: "#FF91A4",
  Sand: "#C2B280",
  SandDune: "#967117",
  SapGreen: "#507D2A",
  SapphireBlue: "#0067A5",
  SasquatchSocks: "#FF4681",
  SatinSheenGold: "#CBA135",
  ScarletCrayola: "#FD0E35",
  SchaussPink: "#FF91AF",
  SchoolBusYellow: "#FFD800",
  ScreaminGreen: "#76FF7A",
  SeaBlue: "#006994",
  SeaFoamGreen: "#9FE2BF",
  SeaGreenCrayola: "#00FFCD",
  SeaSerpent: "#4BC7CF",
  Seashell: "#FFF5EE",
  SelectiveYellow: "#FFBA00",
  Sepia: "#704214",
  Shadow: "#8A795D",
  ShadowBlue: "#778BA5",
  Shampoo: "#FFCFF1",
  ShamrockGreen: "#009E49",
  SheenGreen: "#8FD400",
  ShimmeringBlush: "#D98695",
  ShockingPink: "#FC0FC0",
  ShockingPinkCrayola: "#FF6FFF",
  SilverChalice: "#ACACAC",
  SilverLakeBlue: "#5D89BA",
  SilverPink: "#C4AEAD",
  SilverSand: "#BFC1C2",
  Sinopia: "#CB410B",
  Skobeloff: "#007474",
  SkyBlueCrayola: "#76D7EA",
  SkyMagenta: "#CF71AF",
  SlimyGreen: "#299617",
  SmashedPumpkin: "#FF6D3A",
  Smitten: "#C84186",
  Smoke: "#738276",
  SmokeyTopaz: "#933D41",
  SmokyBlack: "#100C08",
  SmokyTopaz: "#933D41",
  Soap: "#CEC8EF",
  SolidPink: "#893843",
  SonicSilver: "#757575",
  SpaceCadet: "#1D2951",
  SpanishBistre: "#807532",
  SpanishBlue: "#0070B8",
  SpanishCarmine: "#D10047",
  SpanishCrimson: "#E51A4C",
  SpanishGray: "#989898",
  SpanishGreen: "#009150",
  SpanishOrange: "#E86100",
  SpanishPink: "#F7BFBE",
  SpanishRed: "#E60026",
  SpanishSkyBlue: "#00FFFF",
  SpanishViolet: "#4C2882",
  SpanishViridian: "#007F5C",
  SpanishYellow: "#F6B511",
  SpicyMix: "#8B5f4D",
  SpiroDiscoBall: "#0FC0FC",
  SpringBud: "#A7FC00",
  SpringFrost: "#87FF2A",
  SpringGreenCrayola: "#ECEBBD",
  StarCommandBlue: "#007BB8",
  SteelPink: "#CC33CC",
  SteelTeal: "#5F8A8B",
  StilDeGrainYellow: "#FADA5E",
  Stizza: "#990000",
  Stormcloud: "#4F666A",
  Straw: "#E4D96F",
  Strawberry: "#FC5A8D",
  SugarPlum: "#914E75",
  SunburntCyclops: "#FF404C",
  Sunglow: "#FFCC33",
  Sunny: "#F2F27A",
  Sunray: "#E3AB57",
  Sunset: "#FAD6A5",
  SunsetOrange: "#FD5E53",
  SuperPink: "#CF6BA9",
  SweetBrown: "#A83731",
  TanCrayola: "#D99A6C",
  Tangelo: "#F94D00",
  TangerineYellow: "#FFCC00",
  TangoPink: "#E4717A",
  TartOrange: "#FB4D46",
  Taupe: "#483C32",
  TaupeGray: "#8B8589",
  TeaGreen: "#D0F0C0",
  TeaRose: "#F4C2C2",
  TeaRoseAlternate: "#F88379",
  TealBlue: "#367588",
  TealDeer: "#99E6B3",
  TealGreen: "#00827F",
  Telemagenta: "#CF3476",
  Tenné: "#CD5700",
  TerraCotta: "#E2725B",
  ThulianPink: "#DE6FA1",
  TickleMePink: "#FC89AC",
  TiffanyBlue: "#0ABAB5",
  TigersEye: "#E08D3C",
  Timberwolf: "#DBD7D2",
  TitaniumYellow: "#EEE600",
  Toolbox: "#746CC0",
  Topaz: "#FFC87C",
  TractorRed: "#FD0E35",
  TrolleyGrey: "#808080",
  TropicalRainForest: "#00755E",
  TropicalViolet: "#CDA4DE",
  TrueBlue: "#0073CF",
  TuftsBlue: "#3E8EDE",
  Tulip: "#FF878D",
  Tumbleweed: "#DEAA88",
  TurkishRose: "#B57281",
  TurquoiseGreen: "#A0D6B4",
  Tuscan: "#FAD6A5",
  TuscanBrown: "#6F4E37",
  TuscanRed: "#7C4848",
  TuscanTan: "#A67B5B",
  Tuscany: "#C09999",
  TwilightLavender: "#8A496B",
  TyrianPurple: "#66023C",
  UABlue: "#0033AA",
  UARed: "#D9004C",
  UCLABlue: "#536895",
  UCLAGold: "#FFB300",
  UFOGreen: "#3CD070",
  Ultramarine: "#120A8F",
  UltramarineBlue: "#4166F5",
  UltraPink: "#FF6FFF",
  UltraRed: "#FC6C85",
  Umber: "#635147",
  UnbleachedSilk: "#FFDDCA",
  UnitedNationsBlue: "#5B92E5",
  UniversityOfCaliforniaGold: "#B78727",
  UnmellowYellow: "#FFFF66",
  UPForestGreen: "#014421",
  UPMaroon: "#7B1113",
  UpsdellRed: "#AE2029",
  Urobilin: "#E1AD21",
  UtahCrimson: "#D3003F",
  VanDykeBrown: "#664228",
  Vanilla: "#F3E5AB",
  VanillaIce: "#F38FA9",
  VegasGold: "#C5B358",
  VenetianRed: "#C80815",
  Vermilion: "#E34234",
  VerseGreen: "#18880D",
  VeryLightAzure: "#74BBFB",
  VeryLightBlue: "#6666FF",
  VeryLightMalachiteGreen: "#64E986",
  VeryLightTangelo: "#FFB077",
  VeryPaleOrange: "#FFDFBF",
  VeryPaleYellow: "#FFFFBF",
  VioletBlue: "#324AB2",
  VioletColorWheel: "#7F00FF",
  VioletRYB: "#8601AF",
  VioletWeb: "#EE82EE",
  VioletBlueCrayola: "#766EC8",
  VioletRed: "#F75394",
  Viridian: "#40826D",
  ViridianGreen: "#009698",
  VistaBlue: "#7C9ED9",
  VividAmber: "#CC9900",
  VividAuburn: "#922724",
  VividBurgundy: "#9F1D35",
  VividCerise: "#DA1D81",
  VividCerulean: "#00AAEE",
  VividCrimson: "#CC0033",
  VividGamboge: "#FF9900",
  VividLimeGreen: "#A6D608",
  VividMalachite: "#00CC33",
  VividMulberry: "#B80CE3",
  VividOrange: "#FF5F00",
  VividOrangePeel: "#FFA000",
  VividOrchid: "#CC00FF",
  VividRaspberry: "#FF006C",
  VividRed: "#F70D1A",
  VividRedTangelo: "#DF6124",
  VividSkyBlue: "#00CCFF",
  VividTangelo: "#F07427",
  VividTangerine: "#FFA089",
  VividVermilion: "#E56024",
  VividViolet: "#9F00FF",
  VividYellow: "#FFE302",
  WarmBlack: "#004242",
  WildBlueYonder: "#A2ADD0",
  WildOrchid: "#D470A2",
  WildStrawberry: "#FF43A4",
  WildWatermelon: "#FC6C85",
  WillpowerOrange: "#FD5800",
  WindsorTan: "#A75502",
  Wine: "#722F37",
  WineDregs: "#673147",
  WinterSky: "#FF007C",
  WintergreenDream: "#56887D",
  Wisteria: "#C9A0DC",
  WoodBrown: "#C19A6B",
  Xanadu: "#738678",
  YaleBlue: "#0F4D92",
  YankeesBlue: "#1C2841",
  YellowCrayola: "#FCE883",
  YellowMunsell: "#EFCC00",
  YellowNCS: "#FFD300",
  YellowPantone: "#FEDF00",
  YellowProcess: "#FFEF00",
  YellowRYB: "#FEFE33",
  YellowGreenCrayola: "#C5E384",
  YellowGreenColorWheel: "#30B21A",
  YellowOrange: "#FFAE42",
  YellowOrangeColorWheel: "#FF9505",
  YellowSunshine: "#FFF700",
  YInMnBlue: "#2E5090",
  Zaffre: "#0014A8",
  Zomp: "#39A78E",
};

export const materialColors = {
  red: {
    "50": "#ffebee",
    "100": "#ffcdd2",
    "200": "#ef9a9a",
    "300": "#e57373",
    "400": "#ef5350",
    "500": "#f44336",
    "600": "#e53935",
    "700": "#d32f2f",
    "800": "#c62828",
    "900": "#b71c1c",
    "a100": "#ff8a80",
    "a200": "#ff5252",
    "a400": "#ff1744",
    "a700": "#d50000",
  },
  pink: {
    "50": "#fce4ec",
    "100": "#f8bbd0",
    "200": "#f48fb1",
    "300": "#f06292",
    "400": "#ec407a",
    "500": "#e91e63",
    "600": "#d81b60",
    "700": "#c2185b",
    "800": "#ad1457",
    "900": "#880e4f",
    a100: "#ff80ab",
    a200: "#ff4081",
    a400: "#f50057",
    a700: "#c51162",
  },
  purple: {
    "50": "#f3e5f5",
    "100": "#e1bee7",
    "200": "#ce93d8",
    "300": "#ba68c8",
    "400": "#ab47bc",
    "500": "#9c27b0",
    "600": "#8e24aa",
    "700": "#7b1fa2",
    "800": "#6a1b9a",
    "900": "#4a148c",
    a100: "#ea80fc",
    a200: "#e040fb",
    a400: "#d500f9",
    a700: "#aa00ff",
  },
  deepPurple: {
    "50": "#ede7f6",
    "100": "#d1c4e9",
    "200": "#b39ddb",
    "300": "#9575cd",
    "400": "#7e57c2",
    "500": "#673ab7",
    "600": "#5e35b1",
    "700": "#512da8",
    "800": "#4527a0",
    "900": "#311b92",
    a100: "#b388ff",
    a200: "#7c4dff",
    a400: "#651fff",
    a700: "#6200ea",
  },
  indigo: {
    "50": "#e8eaf6",
    "100": "#c5cae9",
    "200": "#9fa8da",
    "300": "#7986cb",
    "400": "#5c6bc0",
    "500": "#3f51b5",
    "600": "#3949ab",
    "700": "#303f9f",
    "800": "#283593",
    "900": "#1a237e",
    a100: "#8c9eff",
    a200: "#536dfe",
    a400: "#3d5afe",
    a700: "#304ffe",
  },
  blue: {
    "50": "#e3f2fd",
    "100": "#bbdefb",
    "200": "#90caf9",
    "300": "#64b5f6",
    "400": "#42a5f5",
    "500": "#2196f3",
    "600": "#1e88e5",
    "700": "#1976d2",
    "800": "#1565c0",
    "900": "#0d47a1",
    a100: "#82b1ff",
    a200: "#448aff",
    a400: "#2979ff",
    a700: "#2962ff",
  },
  lightBlue: {
    "50": "#e1f5fe",
    "100": "#b3e5fc",
    "200": "#81d4fa",
    "300": "#4fc3f7",
    "400": "#29b6f6",
    "500": "#03a9f4",
    "600": "#039be5",
    "700": "#0288d1",
    "800": "#0277bd",
    "900": "#01579b",
    a100: "#80d8ff",
    a200: "#40c4ff",
    a400: "#00b0ff",
    a700: "#0091ea",
  },
  cyan: {
    "50": "#e0f7fa",
    "100": "#b2ebf2",
    "200": "#80deea",
    "300": "#4dd0e1",
    "400": "#26c6da",
    "500": "#00bcd4",
    "600": "#00acc1",
    "700": "#0097a7",
    "800": "#00838f",
    "900": "#006064",
    a100: "#84ffff",
    a200: "#18ffff",
    a400: "#00e5ff",
    a700: "#00b8d4",
  },
  teal: {
    "50": "#e0f2f1",
    "100": "#b2dfdb",
    "200": "#80cbc4",
    "300": "#4db6ac",
    "400": "#26a69a",
    "500": "#009688",
    "600": "#00897b",
    "700": "#00796b",
    "800": "#00695c",
    "900": "#004d40",
    a100: "#a7ffeb",
    a200: "#64ffda",
    a400: "#1de9b6",
    a700: "#00bfa5",
  },
  green: {
    "50": "#e8f5e9",
    "100": "#c8e6c9",
    "200": "#a5d6a7",
    "300": "#81c784",
    "400": "#66bb6a",
    "500": "#4caf50",
    "600": "#43a047",
    "700": "#388e3c",
    "800": "#2e7d32",
    "900": "#1b5e20",
    a100: "#b9f6ca",
    a200: "#69f0ae",
    a400: "#00e676",
    a700: "#00c853",
  },
  lightGreen: {
    "50": "#f1f8e9",
    "100": "#dcedc8",
    "200": "#c5e1a5",
    "300": "#aed581",
    "400": "#9ccc65",
    "500": "#8bc34a",
    "600": "#7cb342",
    "700": "#689f38",
    "800": "#558b2f",
    "900": "#33691e",
    a100: "#ccff90",
    a200: "#b2ff59",
    a400: "#76ff03",
    a700: "#64dd17",
  },
  lime: {
    "50": "#f9fbe7",
    "100": "#f0f4c3",
    "200": "#e6ee9c",
    "300": "#dce775",
    "400": "#d4e157",
    "500": "#cddc39",
    "600": "#c0ca33",
    "700": "#afb42b",
    "800": "#9e9d24",
    "900": "#827717",
    a100: "#f4ff81",
    a200: "#eeff41",
    a400: "#c6ff00",
    a700: "#aeea00",
  },
  yellow: {
    "50": "#fffde7",
    "100": "#fff9c4",
    "200": "#fff59d",
    "300": "#fff176",
    "400": "#ffee58",
    "500": "#ffeb3b",
    "600": "#fdd835",
    "700": "#fbc02d",
    "800": "#f9a825",
    "900": "#f57f17",
    a100: "#ffff8d",
    a200: "#ffff00",
    a400: "#ffea00",
    a700: "#ffd600",
  },
  amber: {
    "50": "#fff8e1",
    "100": "#ffecb3",
    "200": "#ffe082",
    "300": "#ffd54f",
    "400": "#ffca28",
    "500": "#ffc107",
    "600": "#ffb300",
    "700": "#ffa000",
    "800": "#ff8f00",
    "900": "#ff6f00",
    a100: "#ffe57f",
    a200: "#ffd740",
    a400: "#ffc400",
    a700: "#ffab00",
  },
  orange: {
    "50": "#fff3e0",
    "100": "#ffe0b2",
    "200": "#ffcc80",
    "300": "#ffb74d",
    "400": "#ffa726",
    "500": "#ff9800",
    "600": "#fb8c00",
    "700": "#f57c00",
    "800": "#ef6c00",
    "900": "#e65100",
    a100: "#ffd180",
    a200: "#ffab40",
    a400: "#ff9100",
    a700: "#ff6d00",
  },
  deepOrange: {
    "50": "#fbe9e7",
    "100": "#ffccbc",
    "200": "#ffab91",
    "300": "#ff8a65",
    "400": "#ff7043",
    "500": "#ff5722",
    "600": "#f4511e",
    "700": "#e64a19",
    "800": "#d84315",
    "900": "#bf360c",
    a100: "#ff9e80",
    a200: "#ff6e40",
    a400: "#ff3d00",
    a700: "#dd2c00",
  },
  brown: {
    "50": "#efebe9",
    "100": "#d7ccc8",
    "200": "#bcaaa4",
    "300": "#a1887f",
    "400": "#8d6e63",
    "500": "#795548",
    "600": "#6d4c41",
    "700": "#5d4037",
    "800": "#4e342e",
    "900": "#3e2723",
  },
  grey: {
    "50": "#fafafa",
    "100": "#f5f5f5",
    "200": "#eeeeee",
    "300": "#e0e0e0",
    "400": "#bdbdbd",
    "500": "#9e9e9e",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
  },
  blueGrey: {
    "50": "#eceff1",
    "100": "#cfd8dc",
    "200": "#b0bec5",
    "300": "#90a4ae",
    "400": "#78909c",
    "500": "#607d8b",
    "600": "#546e7a",
    "700": "#455a64",
    "800": "#37474f",
    "900": "#263238",
  },
  black: "#000000",
  white: "#ffffff",
  darkText: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.38)",
    dividers: "rgba(0, 0, 0, 0.12)",
  },
  lightText: {
    primary: "rgba(255, 255, 255, 1)",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
    dividers: "rgba(255, 255, 255, 0.12)",
  },
  darkIcons: { 
    active: "rgba(0, 0, 0, 0.54)", 
    inactive: "rgba(0, 0, 0, 0.38)" },
  lightIcons: {
    active: "rgba(255, 255, 255, 1)",
    inactive: "rgba(255, 255, 255, 0.5)",
  },
};

export const ConventionalColorNames: {[key: string]: string} = {
  "#ffebee": "Misty Rose",
  "#ffcdd2": "Light Pink",
  "#ef9a9a": "Salmon",
  "#e57373": "Light Salmon",
  "#ef5350": "Red",
  "#f44336": "Red",
  "#e53935": "Dark Red",
  "#d32f2f": "Dark Red",
  "#c62828": "Red",
  "#b71c1c": "Red",
  "#ff8a80": "Light Red",
  "#ff5252": "Light Red",
  "#ff1744": "Medium Red",
  "#d50000": "Medium Red",
  "#fce4ec": "Misty Rose",
  "#f8bbd0": "Light Pink",
  "#f48fb1": "Light Pink",
  "#f06292": "Pink",
  "#ec407a": "Pink",
  "#e91e63": "Pink",
  "#d81b60": "Dark Pink",
  "#c2185b": "Dark Pink",
  "#ad1457": "Dark Pink",
  "#880e4f": "Dark Pink",
  "#ff80ab": "Light Pink",
  "#ff4081": "Light Pink",
  "#f50057": "Medium Pink",
  "#c51162": "Medium Pink",
  "#f3e5f5": "Lavender",
  "#e1bee7": "Lavender",
  "#ce93d8": "Lavender",
  "#ba68c8": "Lavender",
  "#ab47bc": "Lavender",
  "#9c27b0": "Purple",
  "#8e24aa": "Purple",
  "#7b1fa2": "Dark Purple",
  "#6a1b9a": "Dark Purple",
  "#4a148c": "Dark Purple",
  "#ea80fc": "Light Purple",
  "#e040fb": "Light Purple",
  "#d500f9": "Medium Purple",
  "#aa00ff": "Medium Purple",
  "#ede7f6": "Lavender",
  "#d1c4e9": "Lavender",
  "#b39ddb": "Lavender",
  "#9575cd": "Medium Purple",
  "#7e57c2": "Medium Purple",
  "#673ab7": "Purple",
  "#5e35b1": "Purple",
  "#512da8": "Purple",
  "#4527a0": "Purple",
  "#311b92": "Dark Purple",
  "#b388ff": "Light Purple",
  "#7c4dff": "Light Purple",
  "#651fff": "Light Purple",
  "#6200ea": "Purple",
  "#e8eaf6": "Lavender",
  "#c5cae9": "Lavender",
  "#9fa8da": "Lavender",
  "#7986cb": "Light Purple",
  "#5c6bc0": "Medium Purple",
  "#3f51b5": "Indigo",
  "#3949ab": "Indigo",
  "#303f9f": "Indigo",
  "#283593": "Indigo",
  "#1a237e": "Dark Indigo",
  "#8c9eff": "Light Blue",
  "#536dfe": "Light Blue",
  "#3d5afe": "Blue",
  "#304ffe": "Blue",
  "#e3f2fd": "Light Blue",
  "#bbdefb": "Light Blue",
  "#90caf9": "Light Blue",
  "#64b5f6": "Medium Blue",
  "#42a5f5": "Medium Blue",
  "#2196f3": "Blue",
  "#1e88e5": "Blue",
  "#1976d2": "Blue",
  "#1565c0": "Blue",
  "#0d47a1": "Dark Blue",
  "#82b1ff": "Light Blue",
  "#448aff": "Blue",
  "#2979ff": "Blue",
  "#2962ff": "Blue",
  "#e1f5fe": "Light Blue",
  "#b3e5fc": "Light Blue",
  "#81d4fa": "Light Blue",
  "#4fc3f7": "Light Blue",
  "#29b6f6": "Light Blue",
  "#03a9f4": "Light Blue",
  "#039be5": "Light Blue",
  "#0288d1": "Blue",
  "#0277bd": "Blue",
  "#01579b": "Dark Blue",
  "#80d8ff": "Light Blue",
  "#40c4ff": "Light Blue",
  "#00b0ff": "Blue",
  "#0091ea": "Blue",
  "#e0f7fa": "Light Blue",
  "#b2ebf2": "Light Blue",
  "#80deea": "Light Blue",
  "#4dd0e1": "Light Blue",
  "#26c6da": "Light Blue",
  "#00bcd4": "Cyan",
  "#00acc1": "Cyan",
  "#0097a7": "Cyan",
  "#00838f": "Cyan",
  "#006064": "Dark Cyan",
  "#84ffff": "Light Cyan",
  "#18ffff": "Light Cyan",
  "#00e5ff": "Light Cyan",
  "#00b8d4": "Light Cyan",
  "#e0f2f1": "Light Cyan",
  "#b2dfdb": "Light Cyan",
  "#80cbc4": "Light Cyan",
  "#4db6ac": "Light Cyan",
  "#26a69a": "Light Cyan",
  "#009688": "Cyan",
  "#00897b": "Cyan",
  "#00796b": "Cyan",
  "#00695c": "Cyan",
  "#004d40": "Dark Cyan",
  "#a7ffeb": "Light Green",
  "#64ffda": "Light Green",
  "#1de9b6": "Medium Green",
  "#00bfa5": "Medium Green",
  "#e8f5e9": "Light Green",
  "#c8e6c9": "Light Green",
  "#a5d6a7": "Light Green",
  "#81c784": "Light Green",
  "#66bb6a": "Light Green",
  "#4caf50": "Green",
  "#43a047": "Green",
  "#388e3c": "Green",
  "#2e7d32": "Green",
  "#1b5e20": "Dark Green",
  "#b9f6ca": "Light Green",
  "#69f0ae": "Light Green",
  "#00e676": "Green",
  "#00c853": "Green",
  "#f1f8e9": "Light Green",
  "#dcedc8": "Light Green",
  "#c5e1a5": "Light Green",
  "#aed581": "Light Green",
  "#9ccc65": "Light Green",
  "#8bc34a": "Lime",
  "#7cb342": "Lime",
  "#689f38": "Lime",
  "#558b2f": "Lime",
  "#33691e": "Dark Lime",
  "#ccff90": "Light Lime",
  "#b2ff59": "Light Lime",
  "#76ff03": "Light Lime",
  "#64dd17": "Medium Lime",
  "#f9fbe7": "Light Yellow",
  "#f0f4c3": "Light Yellow",
  "#e6ee9c": "Light Yellow",
  "#dce775": "Light Yellow",
  "#d4e157": "Light Yellow",
  "#cddc39": "Lime",
  "#c0ca33": "Lime",
  "#afb42b": "Lime",
  "#9e9d24": "Lime",
  "#827717": "Dark Lime",
  "#f4ff81": "Light Yellow",
  "#eeff41": "Light Yellow",
  "#c6ff00": "Yellow",
  "#aeea00": "Yellow",
  "#fffde7": "Light Yellow",
  "#fff9c4": "Light Yellow",
  "#fff59d": "Light Yellow",
  "#fff176": "Light Yellow",
  "#ffee58": "Yellow",
  "#ffeb3b": "Yellow",
  "#fdd835": "Yellow",
  "#fbc02d": "Yellow",
  "#f9a825": "Yellow",
  "#f57f17": "Yellow",
  "#ffff8d": "Light Yellow",
  "#ffff00": "Yellow",
  "#ffea00": "Yellow",
  "#ffd600": "Yellow",
  "#fff8e1": "Light Yellow",
  "#ffecb3": "Light Yellow",
  "#ffe082": "Light Yellow",
  "#ffd54f": "Light Yellow",
  "#ffca28": "Yellow",
  "#ffc107": "Amber",
  "#ffb300": "Amber",
  "#ffa000": "Amber",
  "#ff8f00": "Amber",
  "#ff6f00": "Amber",
  "#ffe57f": "Light Amber",
  "#ffd740": "Light Amber",
  "#ffc400": "Amber",
  "#ffab00": "Amber",
  "#fff3e0": "Light Orange",
  "#ffe0b2": "Light Orange",
  "#ffcc80": "Light Orange",
  "#ffb74d": "Light Orange",
  "#ffa726": "Light Orange",
  "#ff9800": "Orange",
  "#fb8c00": "Orange",
  "#f57c00": "Orange",
  "#ef6c00": "Orange",
  "#e65100": "Orange",
  "#ffd180": "Light Orange",
  "#ffab40": "Light Orange",
  "#ff9100": "Orange",
  "#ff6d00": "Orange",
  "#fbe9e7": "Light Orange",
  "#ffccbc": "Light Orange",
  "#ffab91": "Light Orange",
  "#ff8a65": "Light Orange",
  "#ff7043": "Light Orange",
  "#ff5722": "Orange",
  "#f4511e": "Orange",
  "#e64a19": "Orange",
  "#d84315": "Orange",
  "#bf360c": "Orange",
  "#ff9e80": "Light Orange",
  "#ff6e40": "Light Orange",
  "#ff3d00": "Orange",
  "#dd2c00": "Orange",
  "#efebe9": "Light Gray",
  "#d7ccc8": "Light Gray",
  "#bcaaa4": "Light Gray",
  "#a1887f": "Gray",
  "#8d6e63": "Gray",
  "#795548": "Brown",
  "#6d4c41": "Brown",
  "#5d4037": "Brown",
  "#4e342e": "Brown",
  "#3e2723": "Dark Brown",
  "#fafafa": "White",
  "#f5f5f5": "White",
  "#eeeeee": "White",
  "#e0e0e0": "White",
  "#bdbdbd": "Gray",
  "#9e9e9e": "Gray",
  "#757575": "Gray",
  "#616161": "Gray",
  "#424242": "Gray",
  "#212121": "Dark Gray",
  "#eceff1": "Light Gray",
  "#cfd8dc": "Light Gray",
  "#b0bec5": "Light Gray",
  "#90a4ae": "Light Gray",
  "#78909c": "Gray",
  "#607d8b": "Gray",
  "#546e7a": "Gray",
  "#455a64": "Gray",
  "#37474f": "Gray",
  "#263238": "Dark Gray",
  "#000000": "Black",
  "#ffffff": "White",
  "rgba(0, 0, 0, 0.87)": "Black",
  "rgba(0, 0, 0, 0.54)": "Black",
  "rgba(0, 0, 0, 0.38)": "Black",
  "rgba(0, 0, 0, 0.12)": "Black",
  "rgba(255, 255, 255, 1)": "White",
  "rgba(255, 255, 255, 0.7)": "White",
  "rgba(255, 255, 255, 0.5)": "White",
  "rgba(255, 255, 255, 0.12)": "White"
};

