export const dashboardData = [
  {
    id: 1,
    name: "seun",
  },
  {
    id: 2,
    name: "zayon",
  },
  {
    id: 3,
    name: "muraina",
  },
  {
    id: 4,
    name: "adeola",
  },
  {
    id: 5,
    name: "adesola",
  },
  {
    id: 6,
    name: "ayo",
  },
];

export const giftExchangeData = {
  gifts: [
    {
      name: "Ayo",
      item: "Now",
      date: "Thu Oct 27, 2022",
      amount: "4",
    },
    {
      name: "Temi",
      item: "Tolu",
      date: "Thu Oct 27, 2022",
      amount: "3",
    },
    {
      name: "Ayo",
      item: "Now",
      date: "Thu Oct 27, 2022",
      amount: "4",
    },
    {
      name: "Temi",
      item: "Tolu",
      date: "Thu Oct 27, 2022",
      amount: "3",
    },
    {
      name: "Ayo",
      item: "Now",
      date: "Thu Oct 27, 2022",
      amount: "4",
    },
    {
      name: "Temi",
      item: "Tolu",
      date: "Thu Oct 27, 2022",
      amount: "3",
    },
    {
      name: "Ayo",
      item: "Now",
      date: "Thu Oct 27, 2022",
      amount: "4",
    },
    {
      name: "Temi",
      item: "Tolu",
      date: "Thu Oct 27, 2022",
      amount: "3",
    },
  ],
  matches: [
    {
      accountNumber: "0976787678",
      bankName: "Gtbank",
      date: "Thu Oct 27, 2022",
      amount: "4",
      status: "pending",
    },
    {
      accountNumber: "0765656767",
      bankName: "Access",
      date: "Thu Oct 27, 2022",
      amount: "5",
      status: "reviewed",
    },
    {
      accountNumber: "0976787678",
      bankName: "Gtbank",
      date: "Thu Oct 27, 2022",
      amount: "4",
      status: "pending",
    },
    {
      accountNumber: "0765656767",
      bankName: "Access",
      date: "Thu Oct 27, 2022",
      amount: "5",
      status: "reviewed",
    },
    {
      accountNumber: "0976787678",
      bankName: "Gtbank",
      date: "Thu Oct 27, 2022",
      amount: "4",
      status: "pending",
    },
    {
      accountNumber: "0765656767",
      bankName: "Access",
      date: "Thu Oct 27, 2022",
      amount: "5",
      status: "reviewed",
    },
    {
      accountNumber: "0976787678",
      bankName: "Gtbank",
      date: "Thu Oct 27, 2022",
      amount: "4",
      status: "pending",
    },
    {
      accountNumber: "0765656767",
      bankName: "Access",
      date: "Thu Oct 27, 2022",
      amount: "5",
      status: "reviewed",
    },
    {
      accountNumber: "0976787678",
      bankName: "Gtbank",
      date: "Thu Oct 27, 2022",
      amount: "4",
      status: "pending",
    },
    {
      accountNumber: "0765656767",
      bankName: "Access",
      date: "Thu Oct 27, 2022",
      amount: "5",
      status: "reviewed",
    },
  ],
  createdAt: "2022-10-25T18:39:40.367Z",
};
