import { Box, Flex, Input, Text, useMediaQuery, useOutsideClick } from "@chakra-ui/react";
import React, { useState } from "react";
import "./CustomInput.css";
import { IInput } from "./types";
import { SwatchesPicker } from "react-color";
import { Document as FlexDocument } from "flexsearch";
import { ColorCodes, ConventionalColorNames } from "./color.codes";


const SearchEngine = new FlexDocument<
  {
    id: string;
    colorName: string; 
    colorCode: string; 
  }
>(
  {
    document: {
      id: "id",
      index: [
        "colorName",
      ]
    }
  }
);

for (const [ colorName, colorCode ] of Object.entries(ColorCodes)) {
  SearchEngine.add(
    {
      id: `${colorName}__${colorCode}`,
      colorName,
      colorCode,
    }
  );
}

const SingleColorPalette: React.FC<
  IInput & {
    colorValue: string;
    textValue: string;
    setColorValue: (colorValue: string) => void;
    onTextChange: (value: string) => void;
  }
> = ({
  placeholder,
  id,
  background,
  required,
  type,
  name,
  onTextChange,
  disabled,
  textValue,
  errorMessage,
  showMessage,
  color,
  colorValue,
  setColorValue,
  borderRadius,
}) => {
  const ref = React.useRef<any>();
  const [isActive, setIsActive] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [activeColor, setActiveColor] = useState("#333");

  const [isLargeScreen] = useMediaQuery('(min-width: 800px)');

  useOutsideClick({
    ref: ref,
    handler: () => setShowColorPicker(false),
  });

  function handleTextChange(text: string) {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <>
      <Flex
        position={"relative"}
        fontFamily={"Plus Jakarta Sans"}
        // display="flex"
        // flexDirection={"column"}
        id="custom-input-wrapper"
        outline={"none"}
        borderRadius={borderRadius || "18px"}
        background={background || "#16181D"}
        border={errorMessage ? "1px solid #FF8585 !important" : `none`}
        overflow={"hidden"}
      >
        <Box
          // border={'1px solid'}
          position={"absolute"}
          top="20%"
          left="10px"
          zIndex={"3"}
        >
          <Box
            w="40px"
            h="40px"
            bgColor={colorValue || "green"}
            cursor="pointer"
            borderRadius={"50%"}
            onClick={() => {
              setShowColorPicker(true);
            }}
          ></Box>
        </Box>
        <Input
          id={id || "input-select-field"}
          fontFamily={"Plus Jakarta Sans"}
          autoComplete="off"
          padding={"1.9rem 1rem 1.9rem 3.5rem"}
          background={"transparent"}
          required={required || false}
          type={type || "text"}
          name={name}
          disabled={disabled || false}
          value={textValue}
          onChange={(e: any) => {
            onTextChange(e.target.value.replace(/[`~!@#$%^&*()-_+{}[\]\\|,.//?;':"]/g, ""));
            handleTextChange(e.target.value.replace(/[`~!@#$%^&*()-_+{}[\]\\|,.//?;':"]/g, ""));
            const res = SearchEngine.search(
              e.target.value.toLowerCase().replace(/ /g, "").replace(/[`~!@#$%^&*()-_+{}[\]\\|,.//?;':"]/g, ""));
            if (res.length) {
              setColorValue((res as any)[0].result[0].split("__")[1])
            }
          }}
          // border={'1px solid'}
          border={0}
          _hover={{
            border: 0,
          }}
          _focus={{
            boxShadow: "none",
            border: errorMessage ? "0" : "0.5px solid #9a9b9e",
            borderRadius: borderRadius || "18px",
          }}
          _disabled={{ opacity: 0.9 }}
          fontSize={"15px"}
          color={color || "#ffffffd7"}
          fontWeight="400"
        />
        <label
          id="input-color-label"
          htmlFor={type || "text"}
          className={isActive ? "Active" : ""}
          style={{
            transform: `${ textValue && "translate(15px, 2px) scale(0.8)" }`,
            left: isLargeScreen ? "46px" : "40px",
            position: "absolute",
            // border: "1px solid",
            width: "85px",
            height: "35px",
            zIndex: `${textValue && "2"}`,
          }}
        >
          {placeholder}
        </label>
      </Flex>
      <Box textAlign={"left"} padding="0.3rem 0">
        <Text color="#FF8585" fontSize={"12px"} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ""}
        </Text>
      </Box>
      <Box
        zIndex={"51"}
        position={"absolute"}
        bottom="10px"
        top={"0"}
        left="20%"
        display={showColorPicker ? "unset" : "none"}
        ref={ref}
      >
        <SwatchesPicker
          width={isLargeScreen ? 320 : 280}
          color={activeColor}
          onChange={(color) => {
            setColorValue(color.hex);
            setActiveColor(color.hex);
            setShowColorPicker(false);
            const colorText = ConventionalColorNames[color.hex];
            if (colorText) onTextChange(colorText.toLowerCase());
          }}
        />
      </Box>
    </>
  );
};

export default SingleColorPalette;
