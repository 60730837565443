import { API_URLS } from "apis/urls";
import {
  LoginResponse,
  SignUpResponse,
  UserDetailsResponse,
  VerifyResponse,
  OTPResponse,
  ResetPasswordResponse,
  GetListCategoriesResponse,
  CreateWishlistResponse,
  GetAWishlistResponse,
  AddWishResponse,
  GetDashboardResponse,
  CheckUsernameResponse,
  GetWishResponse,
  GetWishlistWalletResponse,
  GetWalletTotalBalanceResponse,
  GetBankAccountsResponse,
  PostBankSearchResponse,
  VerifyBankAccountResponse,
  CreateBankAccountResponse,
  VerifyPayoutRequestResponse,
  CreatePayoutResponse,
  GetBanksResponse,
  CheckEmailResponse,
  ChangePasswordResponse,
  UpdateEmailResponse,
  UpdateUsernameResponse,
  UpdateUserProfileResponse,
  VerifyPasswordUserResponse,
  GetUserResponse,
  GetDrawerResponse,
  SearchDrawerResponse,
  CreateGivenResponse,
  AWeddingResponse,
  DeactivateAccountResponse,
  GetAllUsersGiftExchangesResponse,
  AddWishItemToGiftResponse,
  GetSingleUserExchangeRespone,
  AcceptInviteGiftExchangeResponse,
  GetExternalWishResponse,
  CreateSuggestedUrlResponse,
  GetEventResponse,
  GetSearchResultResponse,
  CreateAdmittedResponse,
  AWeddingDashboardResponse,
  WeddingEventType,
} from "apis/types";

// Auth
export class PostLogin {
  static ROUTE = API_URLS.auth.login;
  static BODY: {
    email: string;
    password: string;
    context?: string;
  };
  static RES: {
    data: LoginResponse;
  };
}

export class PostVerify {
  static ROUTE = API_URLS.auth.verify;
  static BODY: {
    email: string;
    otp: string;
    type: string;
  };
  static RES: {
    data: VerifyResponse;
  };
}

export class PostOtp {
  static ROUTE = API_URLS.auth.sendOtp;
  static BODY: {
    email: string;
    type?: string;
  };
  static RES: {
    data: OTPResponse;
  };
}

export class PostResetPassword {
  static ROUTE = API_URLS.auth.resetPassword;
  static BODY: {
    email: string;
    newPassword: string;
    confirmPassword: string;
    otp: string;
  };
  static RES: {
    data: ResetPasswordResponse;
  };
}

// interface IUsernameExistsResponseType {
//   _id: string;
// }

// export class GetUsernameExists {
//   static ROUTE = API_URLS.auth.usernameExists;
//   static BODY: string;
//   static RES: {
//     data: null | IUsernameExistsResponseType;
//   };
// }

export class PostSignUp {
  static ROUTE = API_URLS.auth.register;
  static BODY: {
    firstname?: string;
    lastname?: string;
    email: string;
    password: string;
    organisationName?: string;
    organisationCategoryID?: string;
    context: string | null;
  };
  static RES: {
    data: null | SignUpResponse;
  };
}

export class GetOrgCategory {
  static ROUTE = API_URLS.auth.orgCategory;
  static RES: {
    data: any;
  };
}

// Users
export class GetUserInfo {
  static ROUTE = API_URLS.user.users;
  static PARAMS: string;
  static RES: {
    data: UserDetailsResponse;
  };
}

// export class UpdateUserInfo {
//   static ROUTE = API_URLS.user.user;
//   static PARAMS: string;
//   static DATA: {
//     firstname?: string;
//     lastname?: string;
//     country?: string;
//     applicableTitles?: string[];
//     avatar?: any;
//   };
//   static RES: UserDetailsResponse;
// }

export class GetListOfCategories {
  static ROUTE = API_URLS.wishlist.getWishlistCategories;
  static RES: {
    data: null | GetListCategoriesResponse;
  };
}

export class PostCreateWishlist {
  static ROUTE = API_URLS.wishlist.createWishlist;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | CreateWishlistResponse;
  };
}

export class PostCreateSuggestedUrl {
  static ROUTE = API_URLS.wishlist.suggestedUrl;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | CreateSuggestedUrlResponse;
  };
}

export class PostCreateAdmitted {
  static ROUTE = API_URLS.generalEvents.createAdmitted;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | CreateAdmittedResponse;
  };
}

export class PostCreateCollection {
  static ROUTE = API_URLS.collection.createCollection;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | CreateWishlistResponse;
  };
}

export class GetListOfCategoriesCollection {
  static ROUTE = API_URLS.collection.getCollectionCategories;
  static RES: {
    data: null | GetListCategoriesResponse;
  };
}

export class DeleteACollection {
  static ROUTE = API_URLS.collection.deleteCollection;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class UpdateACollection {
  static ROUTE = API_URLS.collection.updateACollection;
  static BODY: any;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class GetACollection {
  static ROUTE = API_URLS.collection.getACollection;
  static RES: {
    data: null | GetAWishlistResponse | any;
  };
}
export class GetAnEvent {
  static ROUTE = API_URLS.event.getAnEvent;
  static RES: {
    data: null | GetEventResponse | any;
  };
}

export class GetSearchResult {
  static ROUTE = API_URLS.event.getSearchResult;
  static RES: {
    data: null | GetSearchResultResponse | any;
  };
}

export class GetAWishlist {
  static ROUTE = API_URLS.wishlist.getAWishlist;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class UpdateAWishlist {
  static ROUTE = API_URLS.wishlist.getAWishlist;
  static BODY: any;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class GetWish {
  static ROUTE = API_URLS.wishlist.addWish;
  static RES: {
    data: null | GetWishResponse;
  };
}

export class GetExternalWish {
  static ROUTE = API_URLS.wishlist.externalProduct;
  static RES: {
    data: null | GetExternalWishResponse;
  };
}

export class AddWish {
  static ROUTE = API_URLS.wishlist.addWish;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | AddWishResponse;
  };
}

export class UpdateWish {
  static ROUTE = API_URLS.wishlist.addWish;
  static BODY: any;
  static RES: {
    data: null | GetWishResponse;
  };
}

export class DeleteWish {
  static ROUTE = API_URLS.wishlist.deleteWish;
  // static BODY: {
  //   listcategoryID: string;
  //   title: string;
  //   coverImage: Blob | string;
  //   date: string;
  // };
  static BODY: any;
  static RES: {
    data: null | AddWishResponse;
  };
}

export class SaveWishNote {
  static ROUTE = API_URLS.wishlist.saveWishNote;
  static BODY: any;
  static RES: {
    data: null | CreateWishlistResponse;
  };
}

export class GetDashboard {
  static ROUTE = API_URLS.dashboard.getDashboard;
  static RES: {
    data: null | GetDashboardResponse;
  };
}

export class GetUser {
  static ROUTE = API_URLS.dashboard.getUser;
  static PARAMS: string;
  static RES: {
    data: null | GetUserResponse;
  };
}

export class GetDrawer {
  static ROUTE = API_URLS.generalEvents.getDrawer;
  static PARAMS: string;
  static RES: {
    data: null | GetDrawerResponse;
  };
}

export class SearchDrawer {
  static ROUTE = API_URLS.generalEvents.searchDrawer;
  static PARAMS: string;
  static RES: {
    data: null | SearchDrawerResponse;
  };
}

export class GetWishlistWallets {
  static ROUTE = API_URLS.wallets.getWishlistWallet;
  static RES: {
    data: null | GetWishlistWalletResponse | any;
  };
}

export class VerifyPayoutAmount {
  static ROUTE = API_URLS.wallets.verifyPayoutAmount;
  static BODY: any;
  static RES: {
    data: null | CreateWishlistResponse;
  };
}

export class VerifyPayoutRequest {
  static ROUTE = API_URLS.wallets.verifyPayoutRequest;
  static BODY: any;
  static RES: {
    data: null | VerifyPayoutRequestResponse;
  };
}

export class GetWalletTotalBalance {
  static ROUTE = API_URLS.wallets.getWalletTotalBalance;
  static RES: {
    data: null | GetWalletTotalBalanceResponse;
  };
}

export class GetBankAccounts {
  static ROUTE = API_URLS.wallets.getBankAccounts;
  static RES: {
    data: null | GetBankAccountsResponse;
  };
}

export class PostBankSearch {
  static ROUTE = API_URLS.wallets.postBankSearch;
  static BODY: any;
  static RES: {
    data: null | PostBankSearchResponse;
  };
}

export class VerifyBankAccount {
  static ROUTE = API_URLS.wallets.verifyBankAccount;
  static BODY: any;
  static RES: {
    data: null | VerifyBankAccountResponse;
  };
}

export class CreateBankAccount {
  static ROUTE = API_URLS.wallets.createBankAccount;
  static BODY: any;
  static RES: {
    data: null | CreateBankAccountResponse;
  };
}

export class CreatePayout {
  static ROUTE = API_URLS.wallets.createPayout;
  static BODY: any;
  static RES: {
    data: null | CreatePayoutResponse;
  };
}

export class CreateTransactionPayout {
  static ROUTE = API_URLS.wallets.createTransactionPayout;
  static BODY: any;
  static RES: {
    data: null | CreatePayoutResponse;
  };
}

export class GetBanks {
  static ROUTE = API_URLS.wallets.getBanks;
  static RES: {
    data: null | GetBanksResponse;
  };
}

export class CheckUsername {
  static ROUTE = API_URLS.settings.checkUsername;
  static RES: {
    data: null | CheckUsernameResponse;
  };
}

export class CheckEmail {
  static ROUTE = API_URLS.settings.checkEmail;
  static RES: {
    data: null | CheckEmailResponse;
  };
}

export class UpdateUserProfile {
  static ROUTE = API_URLS.settings.updateUserProfile;
  static BODY: any;
  static RES: {
    data: null | UpdateUserProfileResponse;
  };
}

export class UpdateUsername {
  static ROUTE = API_URLS.settings.updateUsername;
  static BODY: any;
  static RES: {
    data: null | UpdateUsernameResponse;
  };
}

export class UpdateEmail {
  static ROUTE = API_URLS.settings.updateEmail;
  static BODY: any;
  static RES: {
    data: null | UpdateEmailResponse;
  };
}

export class VerifyPasswordUser {
  static ROUTE = API_URLS.settings.verifyPasswordUser;
  static BODY: any;
  static RES: {
    data: null | VerifyPasswordUserResponse;
  };
}
export class ChangePassword {
  static ROUTE = API_URLS.settings.changePassword;
  static BODY: any;
  static RES: {
    data: null | ChangePasswordResponse;
  };
}

export class DeactivateAccount {
  static ROUTE = API_URLS.settings.deactivateAccount;
  static BODY: any;
  static RES: {
    data: null | DeactivateAccountResponse;
  };
}

//CONTRIBUTOR REQUEST TYPES
export class GetAWishlistPublic {
  static ROUTE = API_URLS.contributor.getWishlistPublic;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class GetAGiftPublic {
  static ROUTE = API_URLS.contributor.getAGiftPublic;
  static RES: {
    data: null | GetAWishlistResponse;
  };
}

export class CreateGiven {
  static ROUTE = API_URLS.contributor.createGiven;
  static BODY: any;
  static RES: {
    data: null | CreateGivenResponse;
  };
}

export class UpdateGiven {
  static ROUTE = API_URLS.contributor.createGiven;
  static BODY: any;
  static RES: {
    data: null | CreateGivenResponse;
  };
}

// WEDDING REQUEST TYPES
export class CreateWedding {
  static ROUTE = API_URLS.wedding.aWedding;
  static BODY: any;
  static RES: {
    data: null | AWeddingResponse;
  };
}

export enum WeddingPageContext {
  DASHBOARD = "dashboard",
  EDIT = "edit",
}

export class GetWedding {
  static ROUTE = API_URLS.wedding.aWedding;
  static RES: {
    data: null | AWeddingResponse;
  };
}

export class GetWeddingDashboard {
  static ROUTE = API_URLS.wedding.weddingDashboard;
  static RES: {
    data: null | AWeddingDashboardResponse;
  };
}

export class CreateWeddingSchedule {
  static ROUTE = API_URLS.wedding.weddingSchedule;
  static BODY: {
    weddingID: string;
    eventSchedules: {
      type: WeddingEventType;
      address: string;
      date: {
        day: string;
        time: string;
      };
      colors: {
        name: string;
        color1: string;
        color2: string;
      };
    }[];
  };
  static RES: {
    data: null | AWeddingResponse;
  };
}

export class EditWeddingSchedule {
  static ROUTE = API_URLS.wedding.weddingSchedule;
  static BODY: {
    eventScheduleID: string;
    type?: WeddingEventType;
    address?: string;
    date?: {
      day: string;
      time: string;
    };
    colors?: {
      name: string;
      color1: string;
      color2: string;
    };
  };
  static RES: {
    data: null | AWeddingResponse;
  };
}

export class AddWishlistToWedding {
  static ROUTE = API_URLS.wedding.wishlistToWedding;
  static BODY: any;
  static RES: {
    data: null | AWeddingResponse;
  };
}

export class AddFaqToWedding {
  static ROUTE = API_URLS.wedding.faqToWedding;
  static BODY: any;
  static RES: {
    data: null | AWeddingResponse;
  };
}

export class GetAllUsersGiftExchanges {
  static ROUTE = API_URLS.groups.getAllUsersGiftExchange;
  static RES: {
    data: null | GetAllUsersGiftExchangesResponse;
  };
}

export class AddWishItemToGiftExchange {
  static ROUTE = API_URLS.groups.getAllUsersGiftExchange;
  static BODY: any;
  static RES: {
    data: null | AddWishItemToGiftResponse;
  };
}

export class GetGiftInGiftExchange {
  static ROUTE = API_URLS.groups.getAllUsersGiftExchange;
  static BODY: any;
  static RES: {
    data: null | AddWishItemToGiftResponse;
  };
}

export class GetGiftExchangeRecipientWishlist {
  static ROUTE = API_URLS.groups.getGiftExchangeRecipientWishlist;
  static BODY: any;
  static RES: {
    data: null | any;
  };
}

export class GetSingleUserExchange {
  static ROUTE = API_URLS.groups.getSingleUserExchange;
  static RES: {
    data: null | GetSingleUserExchangeRespone;
  };
}

export class AcceptInviteGiftExchange {
  static ROUTE = API_URLS.groups.acceptInviteGiftExchange;
  static BODY: any;
  static RES: {
    data: null | AcceptInviteGiftExchangeResponse;
  };
}

export class DeleteGiftFromExchange {
  static ROUTE = API_URLS.groups.getAllUsersGiftExchange;
  static BODY: any;
  static RES: {
    data: null;
  };
}

//analytics request types
export class GetAnalyticsContributionChart {
  static ROUTE = API_URLS.analytics.getAnalyticsContributionChart;
  static RES: {
    data: null | any;
  };
}

export class GetAnalyticsContributionOverview {
  static ROUTE = API_URLS.analytics.getAnalyticsContributionOverview;
  static RES: {
    data: null | any;
  };
}

export class GetAnalyticsTopContributors {
  static ROUTE = API_URLS.analytics.getAnalyticsTopContributors;
  static RES: {
    data: null | any;
  };
}

export class GetAnalyticsSingleContributionChart {
  static ROUTE = API_URLS.analytics.getAnalyticsSingleContributionChart;
  static RES: {
    data: null | any;
  };
}

export class GetAnalyticsSingleContributionOverview {
  static ROUTE = API_URLS.analytics.getAnalyticsSingleContributionOverview;
  static RES: {
    data: null | any;
  };
}
