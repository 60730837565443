import { Box, Input } from "@chakra-ui/react";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import React, { useCallback, useRef, useState } from "react";
import ModalCmp from "../ModalCmp/ModalCmp";
import UnsplashSearchModal from "./UnsplashSearchModal";

const ImageModal: React.FC<{
  fromUnsplash: (imageUrl: string) => void;
  fromGallery: (imageUrl: File) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  maxWidth: string;
  showCloseIcon?: boolean;
}> = ({
  isOpen,
  onRequestClose,
  maxWidth,
  showCloseIcon,
  fromUnsplash,
  fromGallery,
}) => {
  const [unsplashModal, setUnsplashModal] = useState(false);

  const selectUnsplashImage = useCallback(
    (url: string) => {
      setUnsplashModal(false);
      fromUnsplash(url);
    },
    [fromUnsplash]
  );

  const inputFile = useRef<HTMLInputElement | any>();

  const changeFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files) {
        fromGallery(e.target.files[0]);
      }
    },
    [fromGallery]
  );
  return (
    <>
      <ModalCmp
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyles={{ maxWidth: maxWidth || "350px" }}
        shouldFocusAfterRender={false}
        contentLabel="Image Modal"
        id="image-modal"
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        showCloseIcon={showCloseIcon}
      >
        <Box
          flexDir={"column"}
          display="flex"
          gap="20px"
          textAlign={"center"}
          padding="2rem 1rem"
          fontFamily={"Plus Jakarta Sans"}
        >
          <Box>
            <ButtonCmp
              text={"Choose from gallery"}
              size="md"
              padding="1.5rem 3rem"
              onClick={() => {
                inputFile.current.click();
              }}
              opacity={"1"}
              border={"none"}
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="100%"
              type="submit"
            />
            <Input
              type={"file"}
              name="select-file"
              ref={inputFile}
              display="none"
              accept="image/png, image/jpeg"
              onChange={changeFile}
            />
          </Box>
          <Box>
            <ButtonCmp
              text={"Search on Unsplash"}
              size="md"
              padding="1.5rem 3rem"
              onClick={() => {
                setUnsplashModal(true);
              }}
              opacity={"1"}
              border={"none"}
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="100%"
              type="submit"
            />
          </Box>
        </Box>
      </ModalCmp>
      <UnsplashSearchModal
        selectUnsplashImage={selectUnsplashImage}
        isOpen={unsplashModal}
        onRequestClose={() => {
          setUnsplashModal(false);
        }}
        maxWidth="700px"
        showCloseIcon={false}
      />
    </>
  );
};

export default ImageModal;
