import {
  Box,
  Input,
  InputGroup,
  Text,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import "./CustomInput.css";
import { IInput } from "./types";
import { useFormikContext } from "formik";
import NumberInput from "react-number-format";
import { ReactComponent as ChevronDown } from "assets/svgs/chevron_down.svg";

const CustomInputCmp: React.FC<IInput> = ({
  placeholder,
  id,
  background,
  required,
  type,
  name,
  disabled,
  value,
  values,
  onChange,
  errorMessage,
  showMessage,
  color,
  padding,
  borderRadius,
  isNumericFormat,
  setFieldValue,
  hasDropdown,
  hideCaret,
  rightElement,
}) => {
  const [isActive, setIsActive] = useState(false);

  function handleTextChange(text: string) {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }
  return (
    <>
      <Box
        position={"relative"}
        fontFamily={"Plus Jakarta Sans"}
        display="flex"
        flexDirection={"column"}
        id="custom-input-wrapper"
        outline="none"
        borderRadius={borderRadius || "18px"}
        background={background || "#16181D"}
        border={errorMessage ? "1px solid #FF8585 !important" : `none`}
        // {...(rightElement ? {} : {overflow: "hidden"})}
        // overflow={"hidden"}
      >
        {isNumericFormat ? (
          <NumberInput
            id="input-select-field"
            className="number-format-input"
            fontFamily={"Plus Jakarta Sans"}
            autoComplete="off"
            style={{
              caretColor: `${hideCaret ? "transparent" : "unset"}`,
              padding: "1.5rem 1rem",
              background: "transparent",
              outline: "none",
              border: "0",
              borderRadius: borderRadius || "18px",
              color: color || "#ffffffd7",
              fontWeight: "400",
              fontSize: "15px",
            }}
            required={required || false}
            type={"text"}
            name={name}
            disabled={disabled || false}
            value={values ? values[name] : value}
            onValueChange={(e: any) => {
              setFieldValue(name, e.value);
            }}
            decimalScale={2}
            allowNegative={false}
            thousandSeparator
            prefix="₦"
          />
        ) : hasDropdown ? (
          <InputGroup>
            <Input
              id={id || "input-select-field"}
              fontFamily={"Plus Jakarta Sans"}
              sx={{ caretColor: `${hideCaret ? "transparent" : ""}` }}
              autoComplete="off"
              padding={padding || "1.9rem 1rem"}
              background={"transparent"}
              // placeholder={placeholder}
              required={required || false}
              type={type || "text"}
              name={name}
              disabled={disabled || false}
              value={values ? values[name] : value}
              onChange={(e: any) => {
                onChange?.(e);
                handleTextChange(e.target.value);
              }}
              border={0}
              _hover={{
                border: 0,
              }}
              _focus={{
                boxShadow: "none",
                border: errorMessage ? "0" : "0.5px solid #9a9b9e",
                borderRadius: borderRadius || "18px",
              }}
              _disabled={{ opacity: 0.9 }}
              fontSize={"15px"}
              color={color || "#ffffffd7"}
              fontWeight="400"

            />
            <InputRightElement
              mt={3}
              children={
                rightElement || <ChevronDown style={{ opacity: "0.5" }} />
              }
            />
          </InputGroup>
        ) : (
          <Input
            sx={{ caretColor: `${hideCaret ? "transparent" : ""}` }}
            id={id || "input-field"}
            className={"input-reg-field"}
            fontFamily={"Plus Jakarta Sans"}
            autoComplete="off"
            padding={"1.9rem 1rem"}
            background={"transparent"}
            // placeholder={placeholder}
            required={required || false}
            type={type || "text"}
            name={name}
            disabled={disabled || false}
            value={values ? values[name] : value}
            onChange={(e: any) => {
              onChange?.(e);
              handleTextChange(e.target.value);
            }}
            border={0}
            _hover={{
              border: 0,
            }}
            _focus={{
              boxShadow: "none",
              border: errorMessage ? "0" : "0.5px solid #9a9b9e",
              borderRadius: borderRadius || "18px",
            }}
            _disabled={{ opacity: 0.9 }}
            fontSize={"15px"}
            color={color || "#ffffffd7"}
            fontWeight="400"
          />
        )}
        <label
          id="input-label"
          htmlFor={type || "text"}
          className={isActive ? "Active" : ""}
          style={{
            transform: `${
              (value || (values && values[name])) &&
              "translate(15px, 2px) scale(0.8)"
            }`,
            zIndex: `${(value || (values && values[name])) && "2"}`,
          }}
        >
          {placeholder}
        </label>
      </Box>
      <Box textAlign={"left"} padding="0.3rem 0">
        <Text color="#FF8585" fontSize={"12px"} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ""}
        </Text>
      </Box>
    </>
  );
};

export const FormikFormInput: FC<
  IInput & {
    name: string;
    showMessage: boolean;
    value?: any;
    isNumericFormat?: boolean;
    hideCaret?: boolean;
  }
> = ({ name, showMessage, value, isNumericFormat, hideCaret, ...rest }) => {
  const { handleChange, values, errors, setFieldValue } =
    useFormikContext<any>();
  const hasError = errors[name] || "";

  return (
    <CustomInputCmp
      {...rest}
      isNumericFormat={isNumericFormat}
      setFieldValue={setFieldValue}
      name={name}
      onChange={handleChange}
      value={(values as any)[name as any]}
      showMessage={showMessage}
      errorMessage={hasError}
      hideCaret={hideCaret}
    />
  );
};

export const BasicInput: FC<IInput> = ({
  name,
  showMessage,
  value,
  onChange,
  setFieldValue,
  isNumericFormat,
  hideCaret,
  ...rest
}) => {
  return (
    <CustomInputCmp
      {...rest}
      isNumericFormat={isNumericFormat}
      setFieldValue={setFieldValue}
      name={name}
      onChange={onChange}
      value={value}
      showMessage={showMessage}
      // errorMessage={hasError}
      hideCaret={hideCaret}
    />
  );
};

export const BasicSizedInput: FC<{
  name: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  onMouseUp?: React.MouseEventHandler;
}> = ({ name, value, placeholder, onChange, onKeyDown, onMouseUp }) => {
  const [isActive, setIsActive] = useState(false);

  function handleTextChange(text: string) {
    if (text !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }

  return (
    <Box
      position={"relative"}
      fontFamily={"Plus Jakarta Sans"}
      display="flex"
      flexDirection={"column"}
      // id="custom-input-wrapper"
      outline="none"
      borderRadius={"18px"}
      background={"#16181D"}
      height={"70px"}
      // border={errorMessage ? "1px solid #FF8585 !important" : `none`}
      overflow={"hidden"}
    >
      <Textarea
        // mt={'10px'}
        // sx={{ caretColor: `${hideCaret ? "transparent" : ""}` }}
        id={"input-field"}
        className={"input-reg-field"}
        fontFamily={"Plus Jakarta Sans"}
        autoComplete="off"
        maxLength={100}
        padding={"2rem 1rem"}
        background={"transparent"}
        name={name}
        size={"lg"}
        value={value}
        {...(onKeyDown ? { onKeyDown } : {})}
        {...(onMouseUp ? { onMouseUp } : {})}
        onChange={(e) => {
          onChange?.(e);
          handleTextChange(e.target.value);
        }}
        border={0}
        _hover={{
          border: 0,
        }}
        _focus={{
          boxShadow: "none",
          // border: errorMessage ? "0" : "0.5px solid #9a9b9e",
          borderRadius: "18px",
          // borderRadius: borderRadius || "18px",
        }}
        _disabled={{ opacity: 0.9 }}
        fontSize={"15px"}
        color={"#ffffffd7"}
        fontWeight="400"
      />
      <label
        id="input-label"
        htmlFor={"text"}
        className={isActive ? "Active" : ""}
        style={{
          transform: `${value && "translate(15px, 12px) scale(0.8)"}`,
          zIndex: `${value && "2"}`,
        }}
      >
        {placeholder}
      </label>
    </Box>
  );
};

export default CustomInputCmp;
