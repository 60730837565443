import { WeddingPageContext, WeddingResponse } from "apis"
import { BaseStore, UpdateCollectionStore } from "../baseStore";


export type WeddingCollection = {
  weddingID: string;
  weddingExists: boolean;
  weddingDataObj: WeddingResponse;
  weddingPageContext: WeddingPageContext;
};


const _WeddingCollection = BaseStore<WeddingCollection>(
  {
    weddingID: "",
    weddingExists: false,
    weddingDataObj: {
      userID: "",
      coverImage: "",
      quote: "",
      hashtag: "",
      faqs: [],
      schedules: [],
      _id: "",
      wishlist: [],
      wishlistID: "",
      cashfundID: "",
      shouldIncludeAccessCardAddress: false,
      shouldIncludePhoneNumber: false,
      rsvpRelationshipValues: [],
      weddingInfo: {
        text: "",
        hasCountdown: false,
      },
      logo: {
        url: "",
        requiresBackground: false,
      },
    },
    weddingPageContext: WeddingPageContext.EDIT,
  }
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WeddingCollection = () => _WeddingCollection((selectedState) => ({...selectedState}));
export const UpdateWeddingCollection = UpdateCollectionStore(_WeddingCollection);
