import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { LogoLoader } from "components";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ActionModal from "components/Modals/ActionModal";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { walletActions, walletSelectors } from "store/slices";
import { getNameInitials } from "utils/getNameInitials";

const ChooseAccount = () => {
  const [isExit, setIsExit] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const data = useLocation().state as any;
  const { wishlistID } = useParams<{ wishlistID: string }>();

  const { getBankAccounts, verifyPayoutRequest } = walletActions;

  const getBankAccountsData: any = useAppSelector(
    walletSelectors.getBankAccounts
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getBankAccounts())
      .then((res: any) => {
        const response = res;
        if (
          response &&
          response.payload &&
          response.payload.statusCode === 200
        ) {
          return setLoading(false);
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch, getBankAccounts]);
  return (
    <>
      <Box
        bgColor={"#16181D33"}
        borderRadius="16px"
        minH="90vh"
        p={{ base: "1rem", md: "2rem" }}
      >
        <Box py="1rem">
          <ArrowBackIcon
            color="#fff"
            h={8}
            w={8}
            cursor="pointer"
            onClick={() => {
              setIsExit(!isExit);
            }}
          />
        </Box>
        <Box maxWidth="360px" width="100%" margin="0 auto" pt="1rem">
          <Box display={"flex"} flexDirection="column" gap="1px">
            <Box textAlign={"center"} fontFamily="Plus Jakarta Sans">
              <Text
                color={"#fff"}
                fontWeight="700"
                fontSize={"30px"}
                pb="0.5rem"
              >
                choose account
              </Text>

              {getBankAccountsData?.payload.length > 0 && (
                <Text
                  color="rgba(255, 255, 255, 0.8)"
                  fontSize={"16px"}
                  pb="1rem"
                  maxWidth="240px"
                  margin="0 auto"
                >
                  which account do you want to send your cash gift to??
                </Text>
              )}
            </Box>
            {loading ? (
              <Flex justifyContent={"center"} py="1rem">
                <LogoLoader />
              </Flex>
            ) : getBankAccountsData?.payload.length > 0 ? (
              <Box maxWidth="320px" width="100%" margin={"0 auto"}>
                <Text
                  color={"#fff"}
                  fontWeight="400"
                  fontSize={"20px"}
                  fontFamily="Plus Jakarta Sans"
                  pb="0.6rem"
                >
                  recent accounts
                </Text>
                <Flex flexDir={"column"} gap="10px">
                  {getBankAccountsData?.payload.map((v: any, i: number) => {
                    return (
                      <>
                        <Box
                          key={i}
                          bgColor={"#16181D"}
                          height="90px"
                          borderRadius={"16px"}
                          p="1rem"
                          cursor={"pointer"}
                          onClick={() => {
                            dispatch(
                              verifyPayoutRequest({
                                bankAccountID: v._id,
                                amount: data.amount,
                              })
                            );
                            navigate(`/verify-request/${wishlistID}`, {
                              state: {
                                ...v,
                                wishlistID,
                                amount: data?.amount!,
                                type: location?.state?.type,
                              },
                            });
                          }}
                        >
                          <Flex gap="10px" alignItems={"center"}>
                            <Box
                              width="40px"
                              height={"40px"}
                              borderRadius="50%"
                              bgColor={"#E7D7BB"}
                              display="flex"
                              justifyContent={"center"}
                              alignItems="center"
                            >
                              <Text
                                color={"#6D4C1D"}
                                fontWeight="400"
                                fontSize={"15px"}
                              >
                                {getNameInitials(v.accountName)}
                              </Text>
                            </Box>
                            <Box
                              flexDir={"column"}
                              gap="5px"
                              fontFamily={"Plus Jakarta Sans"}
                            >
                              <Text
                                fontSize={"15px"}
                                color="#fff"
                                fontWeight={"400"}
                              >
                                {v.accountName.length > 20
                                  ? `${v.accountName.slice(0, 17)}...`
                                  : v.accountName}
                              </Text>
                              <Text
                                fontSize={"15px"}
                                color="#fff"
                                fontWeight={"400"}
                              >
                                {v.accountNumber}
                              </Text>
                              <Text
                                fontSize={"15px"}
                                color="#fff"
                                fontWeight={"400"}
                              >
                                {v.bankName}
                              </Text>
                            </Box>
                          </Flex>
                        </Box>
                      </>
                    );
                  })}
                </Flex>
                <Box width={{ base: "100%" }} pt="2rem">
                  <ButtonCmp
                    text={"ADD NEW ACCOUNT"}
                    size="md"
                    padding="1.5rem 3rem"
                    onClick={() => {
                      navigate(`/add-account/${wishlistID}`, { state: data });
                    }}
                    //   isLoading={loading}
                    //   isDisabled={formik.isValid ? false : true}
                    opacity={"1"}
                    border={"none"}
                    textHoverColor={"#fff"}
                    width="100%"
                    maxWidth="100%"
                    type="submit"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                maxWidth="320px"
                width="100%"
                margin={"0 auto"}
                display="flex"
                flexDir={"column"}
                alignItems="center"
                pt="2rem"
              >
                <Image
                  src={
                    "https://media.giphy.com/media/26hkhPJ5hmdD87HYA/giphy.gif"
                  }
                  alt="No account"
                  width="100px"
                  height="100px"
                  borderRadius={"50%"}
                />
                <Box fontFamily={"Plus Jakarta Sans"} textAlign="center">
                  <Text fontSize={"24px"} fontWeight="400" color="#fff">
                    No account yet
                  </Text>
                  <Text
                    fontSize={"15px"}
                    fontWeight="400"
                    color="#fff"
                    opacity="0.7"
                    maxWidth={"164px"}
                  >
                    click button below to add an account for payout
                  </Text>
                </Box>
                <Box width={{ base: "100%" }} pt="2rem">
                  <ButtonCmp
                    text={"ADD NEW ACCOUNT"}
                    onClick={() => {
                      navigate(`/add-account/${wishlistID}`);
                    }}
                    size="md"
                    padding="1.5rem 3rem"
                    //   isLoading={loading}
                    //   isDisabled={formik.isValid ? false : true}
                    opacity={"1"}
                    border={"none"}
                    textHoverColor={"#fff"}
                    width="100%"
                    maxWidth="100%"
                    type="submit"
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent={"center"} pt="1.5rem">
            <Text
              width="210px"
              color="rgba(255, 255, 255, 0.7)"
              fontWeight={"400"}
              fontSize="13px"
              textAlign={"center"}
            >
              having issues? <span style={{ color: "#fff" }}>Contact us</span>
            </Text>
          </Box>
        </Box>
      </Box>
      <ActionModal
        isOpen={isExit}
        onRequestClose={() => {
          setIsExit(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        actionTitle="are you sure?"
        actionDesc="You will lose progress on this item if you don’t save it before you go to another page"
        yesText="YES, EXIT"
        noText="NO, CANCEL"
        yesAction={() => {
          navigate(-1);
        }}
        noAction={() => {
          setIsExit(false);
        }}
      />
    </>
  );
};

export default ChooseAccount;
