import {
  Flex,
  Box,
  Img,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useState, useCallback, useEffect } from "react";

import { YardsCheckBox } from "./StepFour";
import ImageModal from "components/Modals/ImageModal";
import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import { CameraIcon, ItemPlaceholder } from "assets/svgs";
import ImageCropperModal from "components/Modals/ImageCropperModal";
import {
  BasicInput,
  BasicSizedInput,
} from "components/InputsCmp/CustomInputCmp";
import {
  createWedding,
  updateWedding,
} from "utils/workbench/APIs/routes/wedding";
import {
  UpdateWeddingCollection,
  WeddingCollection,
} from "utils/workbench/STOREs/collections/wedding.collection";

const StepOne: React.FC<{
  nextBtnAction: () => void;
}> = ({ nextBtnAction }) => {
  const { weddingDataObj, weddingExists } = WeddingCollection();
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const [showImageModal, setShowImageModal] = useState(false);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [file, setFile] = useState<File | string>("");
  const [logoFile, setLogoFile] = useState<File | string>("");
  const [coverImageFile, setCoverImageFile] = useState<File | string>("");
  const [loading, setLoading] = useState(false);

  const [imageUpdate, setImageUpdate] = useState("");
  const [imageModalContext, setImageModalContext] = useState<
    "coverImage" | "logo"
  >("logo");
  const [coverImage, setCoverImage] = useState(weddingDataObj.coverImage || "");
  const [image, setImage] = useState(weddingDataObj.coverImage || "");
  const [logo, setLogo] = useState(weddingDataObj?.logo?.url || "");
  const [coverImageKey, setCoverImageKey] = useState(
    Math.random().toString(36).substring(2, 7)
  );

  const [logoRequiresBackground, setLogoRequiresBackground] = useState(
    weddingDataObj?.logo?.requiresBackground || false
  );
  const [hashtag, setHashtag] = useState(weddingDataObj.hashtag || "");
  const [quote, setQuote] = useState(weddingDataObj.quote || "");
  const [weddingInfoText, setWeddingInfoText] = useState(
    weddingDataObj.weddingInfo.text
  );
  const [hasCountdown, setHasCountdown] = useState(
    weddingDataObj.weddingInfo.hasCountdown
  );
  const [infoTextCurrentPosition, setInfoTextCurrentPosition] = useState(0);

  const [localUpdate, setLocalUpdate] = useState(false);

  const [isValid, setIsValid] = useState(!!hashtag);

  const [isLowerthan336] = useMediaQuery("(max-width:336px)");

  const addCountdown = (txt: string, index: number) => {
    let res = "";
    if (index < txt.length) {
      res = txt.substring(0, index) + "[:countdown]" + txt.substring(index);
    } else {
      res = txt + "[:countdown]";
    }
    return res;
  };
  const removeCountdown = (txt: string) => {
    const index = txt.indexOf("[:countdown]");
    let res = txt;
    if (index !== -1) {
      res = txt.substring(0, index) + txt.substring(index + 12);
    }
    return res;
  };

  const checkValid = (_image: string, _hashtag: string) => {
    const curentValid = !!_image && !!_hashtag;
    setIsValid(curentValid);
  };

  useEffect(() => {
    if (imageModalContext && imageUpdate) {
      if (imageModalContext === "logo") {
        setLogo(imageUpdate);
        setLogoFile(file);
      }
      if (imageModalContext === "coverImage") {
        setCoverImage(imageUpdate);
        setCoverImageFile(file);
      }
      setCoverImageKey(Math.random().toString(36).substring(2, 7));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageModalContext, imageUpdate]);

  const fromUnsplash = useCallback((imageUrl: string) => {
    setImage(imageUrl);
    setShowImageCropper(true);
    setFile(imageUrl);
    setShowImageModal(false);
    checkValid(image, hashtag);
    setImageUpdate(imageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fromGallery = useCallback((file: File) => {
    setFile(file);
    const _image = URL.createObjectURL(file);
    setImage(_image);
    setShowImageCropper(true);
    setShowImageModal(false);
    checkValid(image, hashtag);
    setImageUpdate(_image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (weddingDataObj && weddingDataObj?.coverImage) {
      setImage(weddingDataObj?.coverImage);
      setFile(weddingDataObj?.coverImage);
      setCoverImageFile(weddingDataObj?.coverImage);
      setLogoFile(weddingDataObj?.logo?.url);
      checkValid(image, hashtag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingDataObj]);

  const handleSubmit = () => {
    if (!coverImage) {
      toast({
        status: "error",
        description: "Pick a cover image",
      });
      return;
    }

    if (!hashtag) {
      toast({
        status: "error",
        description: "create a hashtag",
      });
      return;
    }

    const payload = new FormData();

    if (hashtag) payload.append("hashtag", hashtag);
    if (weddingDataObj.quote || quote) payload.append("quote", quote);
    if (weddingInfoText) payload.append("weddingInfoText", weddingInfoText);
    if (hasCountdown) payload.append("hasCountdown", `${hasCountdown}`);
    payload.append("coverImage", coverImageFile);
    payload.append("logoRequiresBackground", `${logoRequiresBackground}`);
    payload.append("logo", logoFile);

    if (weddingExists) {
      if (localUpdate) {
        updateWedding(payload, (response) => {
          if (response.statusCode === 200) {
            UpdateWeddingCollection((weddingCollection) => {
              weddingCollection.weddingDataObj = response.payload;
            });
          } else if (response.errors || response.statusCode === 400) {
            toast({ status: "error", description: response.message });
          }
        });
      }
      nextBtnAction();
    } else {
      setLoading(true);
      createWedding(payload, (response) => {
        if (response.statusCode === 201)
          UpdateWeddingCollection((weddingCollection) => {
            weddingCollection.weddingID = response.payload._id;
            weddingCollection.weddingExists = true;
            weddingCollection.weddingDataObj = response.payload;
          });
        if (response.errors)
          toast({ status: "error", description: "There is an error" });
        setLoading(false);
      });
      nextBtnAction();
    }
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap="3px"
        pt="10px"
        pb="4px"
        display={{ base: "none", sm: "unset" }}
      >
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize={"12px"}
          fontWeight="400"
        >
          Step 1
        </Text>
        <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
          Wedding details
        </Text>
      </Flex>
      <Flex justifyContent={"center"} py="1rem">
        <Flex flexDirection={"column"}>
          <Flex w="100%" alignItems={"center"} h={"100px"}>
            <Flex
              position={"relative"}
              borderRadius={"50px"}
              border={"2px solid #16181D"}
              justifyContent={"center"}
              alignItems={"center"}
              key={coverImageKey}
              backdropFilter={logoRequiresBackground ? "blur(20px)" : "none"}
            >
              {logo ? (
                <img
                  src={logo}
                  style={{
                    borderRadius: "50px",
                    width: "80px",
                    height: "80px",
                    backdropFilter: logoRequiresBackground
                      ? "blur(20px)"
                      : "none",
                  }}
                  alt="url"
                />
              ) : (
                <></>
              )}
              <Flex
                {...(logo ? { top: 55 } : {})}
                {...(logo ? { right: 0 } : {})}
                {...(logo ? { position: "absolute" } : {})}
                h={"25px"}
                w={"25px"}
                borderRadius={"50%"}
                bgColor={"rgba(217, 217, 217, 0.5)"}
                backdropFilter="blur(30px)"
                justifyContent={"center"}
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                  setLocalUpdate(true);
                  setShowImageModal(true);
                  setImageModalContext("logo");
                }}
              >
                <Img
                  src={CameraIcon}
                  alt="Upload"
                  width={logo ? "15px" : "10px"}
                  height={logo ? "15px" : "10px"}
                />
              </Flex>
            </Flex>
            <Box w={"20px"} h={"20px"}></Box>
            <Flex flexDirection={"column"} justifyContent={"center"}>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text color={"rgba(255, 255, 255, 0.80)"}>Upload logo</Text>
                {logo ? (
                  <Text
                    fontSize={"12px"}
                    textDecoration={"underline"}
                    color={"#EB5454"}
                    cursor={"pointer"}
                    onClick={() => {
                      setLogo("");
                      setLocalUpdate(true);
                    }}
                  >
                    remove logo
                  </Text>
                ) : (
                  <></>
                )}
              </Flex>
              <Text
                style={{ fontSize: "8px", color: "rgba(255, 255, 255, 0.5)" }}
              >
                *optional
              </Text>
              <Flex gap={"10px"}>
                <Text fontSize={"14px"} color={"rgba(255, 255, 255, 0.80)"}>
                  Add a background to your logo
                </Text>
                <YardsCheckBox
                  selected={logoRequiresBackground}
                  setSelected={() => {
                    setLogoRequiresBackground(!logoRequiresBackground);
                    setLocalUpdate(true);
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <Box margin={"0 auto"}>
            <Box
              position="relative"
              bgImage={`linear-gradient(180deg, rgba(22, 24, 29, 0) 0%, #16181D 100%), url(${
                coverImage ? coverImage : ItemPlaceholder
              })`}
              backgroundSize={"cover"}
              backgroundRepeat={"no-repeat"}
              width={isLowerthan336 ? "280px" : "345px"}
              maxW="345px"
              height={"227px"}
              borderRadius={image ? "20px" : "20px"}
            >
              <Flex
                justifyContent={"space-between"}
                alignItems="center"
                px="1rem"
                position="absolute"
                width="100%"
                bottom="18px"
              >
                <Text>upload cover image</Text>
                <Flex
                  borderRadius={"50%"}
                  h="45px"
                  w="45px"
                  bgColor={"rgba(217, 217, 217, 0.5)"}
                  backdropFilter="blur(30px)"
                  justifyContent={"center"}
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    setLocalUpdate(true);
                    setShowImageModal(true);
                    setImageModalContext("coverImage");
                  }}
                >
                  <Img
                    src={CameraIcon}
                    alt="Upload"
                    width="20px"
                    height="20px"
                  />
                </Flex>
              </Flex>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="column" mt={"16px"}>
            <Box>
              <BasicInput
                placeholder="wedding hashtag e.g #tadesina90"
                name="hashtag"
                disabled={false}
                showMessage={true}
                value={hashtag}
                onChange={(e) => {
                  setLocalUpdate(true);
                  setHashtag(e.target.value.trim());
                  checkValid(image, e.target.value.trim());
                }}
              />
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="column">
            <Box>
              <BasicInput
                placeholder="Wedding quote e.g  Two souls, one heart"
                name="quote"
                disabled={false}
                showMessage={true}
                value={quote}
                onChange={(e) => {
                  setLocalUpdate(true);
                  if (e.target.value.length <= 30) {
                    setQuote(e.target.value);
                  }
                }}
              />
            </Box>
          </Box>
          <Box display={"flex"} flexDirection="column">
            <Box>
              <BasicSizedInput
                placeholder="Wedding info e.g In 8 days, we tie the knot"
                name="wedding-info"
                value={weddingInfoText}
                onChange={(e) => {
                  setLocalUpdate(true);
                  setWeddingInfoText(e.target.value);
                  setHasCountdown(
                    e.target.value.indexOf("[:countdown]") !== -1
                  );
                }}
                onKeyDown={(e) => {
                  const input = e.target as HTMLTextAreaElement;
                  if (input.selectionStart < weddingInfoText.length)
                    setInfoTextCurrentPosition(input.selectionStart);
                }}
                onMouseUp={(e) => {
                  const input = e.target as HTMLTextAreaElement;
                  if (input.selectionStart < weddingInfoText.length)
                    setInfoTextCurrentPosition(input.selectionStart);
                }}
              />
            </Box>
          </Box>
          <Flex alignItems={"center"} justifyContent={"end"}>
            <Text
              textDecoration={hasCountdown ? "line-through" : "underline"}
              onClick={() => {
                let tmp = `${weddingInfoText}`;
                if (tmp.indexOf("[:countdown]") !== -1) {
                  tmp = removeCountdown(tmp);
                  setWeddingInfoText(tmp);
                } else {
                  tmp = addCountdown(tmp, infoTextCurrentPosition);
                  setWeddingInfoText(tmp);
                }
                setHasCountdown(tmp.indexOf("[:countdown]") !== -1);
              }}
              cursor={"pointer"}
            >
              {hasCountdown ? "remove" : "+ add"} countdown
            </Text>
          </Flex>
          <Box width={{ base: "100%" }} textAlign="center" mt={"40px"}>
            <ButtonCmp
              text={"Next"}
              size="md"
              height="55px"
              padding="1.5rem 3rem"
              isLoading={loading}
              isDisabled={!isValid}
              opacity={"1"}
              border={"none"}
              textHoverColor={"#fff"}
              width="100%"
              maxWidth="345px"
              type="submit"
              onClick={handleSubmit}
            />
          </Box>
        </Flex>
        <ImageModal
          isOpen={showImageModal}
          fromUnsplash={fromUnsplash}
          fromGallery={fromGallery}
          onRequestClose={() => {
            setShowImageModal(false);
            setLocalUpdate(true);
          }}
          maxWidth="400px"
          showCloseIcon={false}
        />
        <ImageCropperModal
          isOpen={showImageCropper}
          imageToCrop={image}
          onRequestClose={() => {
            setShowImageCropper(false);
          }}
          onCropComplete={(url: any) => {
            setImage(url);
          }}
          onBackButtonClick={() => {
            setShowImageCropper(false);
            setShowImageModal(true);
          }}
          maxWidth="500px"
          showCloseIcon={false}
        />
      </Flex>
    </>
  );
};

export default StepOne;
