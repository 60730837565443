import { WeddingDataPayload } from "pages/Dashboard/Wedding/types";

export interface AuthResponse {
  fullname: string;
  DOB: string;
  createdAt: string;
  email: string;
  firstname: string;
  isVerified: boolean;
  lastname: string;
  password: string;
  status: string;
  username: string;
  avatar: string;
  _id: string;
}

export interface AccountResponse {
  _id: string;
  account_name: string;
}

export interface SignUpResponse extends AuthResponse {}

export interface LoginResponse {
  message: string;
  token: string;
  payload: AuthResponse;
}

export interface VerifyResponse {
  payload: AuthResponse;
  token: string;
}

export interface OTPResponse {}

export interface ResetPasswordResponse {}

export interface SocialLoginObject {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
  expiry_date: number;
}

export interface UserDetailsResponse {
  device: {
    ip: "string";
  };
  has_basic_info: boolean;
  _id: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  country: string;
  access_level: number;
  is_active: boolean;
  is_verified: boolean;
  is_email_verified: boolean;
  is_deleted: boolean;
  is_twofa_enable: boolean;
  is_twofa_started: boolean;
  plan: string;
  stripe_billing_id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  last_login: string;
  token_validity: string;
  verification_token: string;
  id: string;
  google?: SocialLoginObject;
  instagram?: SocialLoginObject;
  facebook?: SocialLoginObject;
}

export interface GetListCategoriesResponse {
  payload: Array<{
    _id: string;
    name: string;
    emoji: string;
    categoryFields:
      | []
      | Array<{ fieldName: string; fieldType: string; _id: string }>;
    adminId: string;
  }>;
  message: string;
  statusCode: number;
}

export interface GetDashboardResponse {
  payload: {
    firstname: string;
    hasWedding: boolean
    walletBalance: number;
    wishlistsCount: number;
    wishlists: Array<{
      _id: string;
      coverImage: string;
      title: string;
      noOfGifts: number;
      listCategoryEmoji: string;
    }>;
  };
  message: string;
  statusCode: number;
}

export interface CreateWishlistResponse {
  payload: {
    _id: string;
    isDeleted: boolean;
    title: string;
    coverImage: string;
    listCategoryID: string;
    date: string;
    userId: string;
    gifts: Array<any>;
    slug: string;
  };
}

export interface CreateSuggestedUrlResponse {
  payload: {
    _id: string;
    isDeleted: boolean;
    brand: string;
    date: string;
    userID: string;
    slug: string;
    url: string;
  };
}

export interface GetEventResponse {
  payload: {
    firstname: string;
    walletBalance: number;
    wishlistsCount: number;
    wishlists: Array<{
      _id: string;
      coverImage: string;
      title: string;
      noOfGifts: number;
      listCategoryEmoji: string;
    }>;
  };
  message: string;
  statusCode: number;
}

export interface GetSearchResultResponse {
  payload: {
    firstname: string;
    walletBalance: number;
    wishlistsCount: number;
    wishlists: Array<{
      _id: string;
      coverImage: string;
      title: string;
      noOfGifts: number;
      listCategoryEmoji: string;
    }>;
  };
  message: string;
  statusCode: number;
}

export interface GetAWishlistResponse {
  payload: {
    _id: string;
    title: string;
    coverImage: string;
    listcategoryID: string;
    date: string;
    userID: string;
    gifts: Array<any>;
    slug: string;
    isDeleted: boolean;
    welcomeNote: string;
    thankYouNote: string;
    listcategoryFields: Array<any>;
    statusCode: number;
  };
}

export interface GetWishResponse {
  payload: {
    _id: string;
    title: string;
    coverImage: string;
    listcategoryID: string;
    date: string;
    userID: string;
    gifts: Array<any>;
    slug: string;
    isDeleted: boolean;
    welcomeNote: string;
    thankYouNote: string;
    listcategoryFields: Array<any>;
    statusCode: number;
    netCost: any;
    grossCost: any;
  };
}

export interface GetExternalWishResponse {}

export interface AddWishResponse {
  payload: Array<{
    _id: string;
    title: string;
    quantity: number;
    coverImage: string;
    grossCost: any;
    netCost: any;
    grossPaid: any;
    netPaid: any;
    userID: string;
    wishlistID: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    statusCode: number;
  }>;
}

export interface GetWishlistWalletResponse {
  payload: Array<{
    wishlistID: string;
    title: string;
    coverImage: string;
    listCategory: { emoji: string; name: string };
    noOfWishes: string;
    walletBalance: number;
    contributions: Array<any>;
    withdrawals: Array<any>;
  }>;
}

export interface VerifyPayoutAmountResponse {
  payload: "";
}

export interface GetWalletTotalBalanceResponse {
  payload: {
    balance: {
      Naira: number;
      _id: string;
    };
  };
}

export interface GetBankAccountsResponse {
  payload: Array<{
    _id: string;
    bankCode: string;
    bankName: string;
    accountNumber: number;
    userId: string;
    createdAt: string;
    updatedAt: string;
  }>;
}

export interface PostBankSearchResponse {
  payload: Array<{
    code: string;
    name: string;
  }>;
}

export interface VerifyBankAccountResponse {
  payload: {
    account_number: string;
    account_name: string;
  };
}

export interface CreateBankAccountResponse {
  payload: {
    bankCode: string;
    bankName: string;
    accountNumber: number;
    accountName: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface VerifyPayoutRequestResponse {
  payload: {
    userId: string;
    email: string;
    amount: {
      Amount: number;
      Country: string;
      Denomination: string;
    };
    fee: {
      Amount: number;
      Country: string;
      Denomination: string;
    };
    bankName: string;
    accountNumber: string;
    accountName: string;
  };
}

export interface CreatePayoutResponse {
  payload: {
    id: number;
    account_number: string;
    bank_code: string;
    full_name: string;
    created_at: string;
    currency: string;
    debit_currency: string;
    amount: number;
    fee: number;
    status: string;
    reference: string;
    meta: {
      goodwillMessage: string;
    };
    narration: string;
    approver: string | null;
    complete_message: string;
    requires_approval: number;
    is_approved: number;
    bank_name: string;
  };
}
export interface CreateTransactionPayoutResponse {
  payload: {
    id: number;
    account_number: string;
    bank_code: string;
    full_name: string;
    created_at: string;
    currency: string;
    debit_currency: string;
    amount: number;
    fee: number;
    status: string;
    reference: string;
    meta: {
      goodwillMessage: string;
    };
    narration: string;
    approver: string | null;
    complete_message: string;
    requires_approval: number;
    is_approved: number;
    bank_name: string;
  };
}
export interface GetBanksResponse {
  payload: Array<{
    _id: string;
    code: string;
    name: string;
    keyword: string;
    createdAt: string;
    updatedAt: string;
  }>;
}

export interface CheckUsernameResponse {
  payload: string;
}

export interface CheckEmailResponse {
  payload: string;
}

export interface UpdateUserProfileResponse {
  payload: Array<{
    isNewUser: boolean;
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    avatar: string;
  }>;
}

export interface UpdateUsernameResponse {
  payload: Array<{
    isNewUser: boolean;
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    avatar: string;
  }>;
}
export interface UpdateEmailResponse {
  payload: Array<{
    isNewUser: boolean;
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
    avatar: string;
  }>;
}
export interface VerifyPasswordUserResponse {
  payload: Array<{
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }>;
}
export interface ChangePasswordResponse {
  payload: Array<{
    isNewUser: boolean;
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }>;
}

export interface DeactivateAccountResponse {
  payload: Array<{
    _id: string;
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    DOB: null;
    status: string;
    isVerified: boolean;
    createdAt: string;
    updatedAt: string;
    __v: number;
  }>;
}

export interface CreateGivenResponse {
  payload: {
    typeOfGiver: string;
    phone: string;
    email: number;
    name: string;
    _id: string;
    createdAt: string;
    updatedAt: string;
  };
}

export interface GetUserResponse {
  payload: AuthResponse | any;
  message: string;
  statusCode: number;
}

export type CelebrantDataType = {
  type: string;
  ticketName: string;
  celebrantFullname: string;
  celebrantEmail: string;
  celebrantPhone: string;
  celebrantInstagramHandle: string;
  celebrantBirthdayDate: string;
};

export interface GetDrawerResponse {
  payload: {
    allTickets: CelebrantDataType[];
  };
}

export interface SearchDrawerResponse {
  payload: CelebrantDataType[];
}

export interface CreateAdmittedResponse {
  payload: {
    allTickets: CelebrantDataType[];
  };
}

export enum WeddingEventType {
  WHITE_WEDDING = "white_wedding",
  TRADITIONAL_WEDDING = "traditional_wedding",
  NIKAI = 'nikai',
  AFTER_PARTY = 'after_party',
  ENGAGEMENT = 'engagement',
  RECEPTION = 'reception',
  OTHERS = "others",
}

export type WishlistResponse = {
  statusCode: number,
  message: string,
  payload: Wishlist,
  errors: null,
  token: null
};

export type Cashfund = {
  _id: string,
  title: string,
  coverImage: string,
  userID: string,
  wishlistID: string,
  isDeleted: boolean,
};

export type Wishlist = {
  _id: string,
  title: string,
  coverImage: string,
  listcategoryID: {
      _id: string,
      name: string,
      adminID: string,
      emoji: string,
  },
  date: Date,
  userID: string,
  creator: string,
  gifts: GiftType[],
  cashfunds: Cashfund[],
  slug: string,
  isDeleted: boolean,
  qrcodeURL: string,
  ubxdid: string,
  welcomeNote: string,
  items: [
    {
      kind: "cashfund" | "gift";
      "item": Cashfund | GiftType;
    }
  ]
};

export interface WeddingResponse {
  userID: string;
  coverImage: string;
  quote: string;
  hashtag: string;
  faqs: {
    _id: string;
    question: string;
    answer: string;
    weddingID: string;
  }[];
  schedules: WeddingEventSchedule[];
  _id: string;
  wishlist: GiftType | Cashfund[],
  wishlistID: string,
  cashfundID: string,
  shouldIncludeAccessCardAddress: boolean;
  shouldIncludePhoneNumber: boolean;
  rsvpRelationshipValues: string[];
  weddingInfo: {
    text: string;
    hasCountdown: boolean;
  };
  logo: {
    url: string;
    requiresBackground: boolean;
  };
}

export type WeddingEventSchedule = {
  _id: string;
  weddingID: string;
  type: string;
  address: string;
  date: string,
  time: string,
  colors: {
    color1: string;
    color1Name: string;
    color2: string;
    color2Name: string;
  };
  brideDressCode: string;
  groomDressCode: string;
};

export interface AWeddingResponse {
  payload: WeddingResponse;
  message: string;
  statusCode: number;
}

export interface AWeddingDashboardResponse {
  payload: WeddingDataPayload;
  message: string;
  statusCode: number;
}

export interface GetAllUsersGiftExchangesResponse {
  payload: Array<{
    _id: string;
    giftExchangeID: string;
    giftExchangeGroupID: string;
    companyID: string;
    email: string;
    hasAddedWish: boolean;
    isJoined: boolean;
    createdAt: string;
    updatedAt: string;
    userID: string;
  }>;
  message: string;
  statusCode: number;
}

export interface AddWishItemToGiftResponse {
  payload: {
    _id: string;
    title: string;
    quantity: string;
    coverImage: string;
    grossCost: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    netCost: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    grossPaid: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    netPaid: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    userID: string;
    giftExchangeID: string;
    giftExchangeWishlistID: string;
    isDeleted: boolean;
  };
  message: string;
  statusCode: number;
}

export interface GetSingleUserExchangeRespone {
  payload: {
    coverImage: string;
    title: string;
    dateOfExchange: string;
    deadline: string;
    companyName: string;
    numberOfParticipants: number;
    priceCap: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    wishlist: Array<{
      coverImage: string;
      title: string;
      quantity: number;
      grossCost: {
        Amount: number;
        Country: string;
        Denomination: string;
        _id: string;
      };
      netCost: {
        Amount: number;
        Country: string;
        Denomination: string;
        _id: string;
      };
      grossPaid: {
        Amount: number;
        Country: string;
        Denomination: string;
        _id: string;
      };
      netPaid: {
        Amount: number;
        Country: string;
        Denomination: string;
        _id: string;
      };
    }>;
  };
  message: string;
  statusCode: number;
}

export interface AddWishItemToGiftResponse {
  payload: {
    _id: string;
    title: string;
    quantity: string;
    coverImage: string;
    grossCost: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    netCost: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    grossPaid: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    netPaid: {
      Amount: number;
      Country: string;
      Denomination: string;
      _id: string;
    };
    userID: string;
    giftExchangeID: string;
    giftExchangeWishlistID: string;
    isDeleted: boolean;
  };
  message: string;
  statusCode: number;
}

export type GiftType = {
  _id: string,
  quantity: number,
  actualPaid: number,
  isDeleted: boolean,
  isArchived: boolean,
  url: string,
  totalCost: number,
  createdAt: Date,
  updatedAt: Date,
  userID: string,
  coverImage: string,
  grossCost: {
      Amount: number,
      Country: "NGN",
      Denomination: "Naira",
      _id: string
  },
  grossPaid: {
    Amount: number,
    Country: "NGN",
    Denomination: "Naira",
    _id: string
  },
  netCost: {
    Amount: number,
    Country: "NGN",
    Denomination: "Naira",
    _id: string
  },
  netPaid: {
    Amount: number,
    Country: "NGN",
    Denomination: "Naira",
    _id: string
  },
  title: string,
  wishlistID: string,
  listcategoryID: string,
};

export interface AcceptInviteGiftExchangeResponse {
  payload: {
    userId: string;
    email: string;
  };
  message: string;
  statusCode: number;
}
