const Colors = {
    black: '#000',
    white: '#fff',
    uRed: '#FF8585',
    uRedHoverTrans: 'rgba(235, 84, 84, 0.1)',
    uLightRed: '#FFA3A3',
    uInputField: '#16181D',
    uGreen: '#44A560',
    uGreenHover: '#338149',
    uGreenHoverTrans: 'rgba(68, 165, 96, 0.1)',
    uLightGreen: '#EDFFE5',
    uLightGreenHover: '#A9F389',
    uGrey: 'rgba(255,255,255,0.5)',
    uBackground: '#22242C',
    uGold: '#F7D38F',
    uPrimary: '#EB5454',
    uDLGreenStroke: '#D6D9E0',
  };
  
  export default Colors;