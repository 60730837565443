import { BsBookmark } from "react-icons/bs";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { Box, Flex, Icon, Select, Stack, Text, useToast } from "@chakra-ui/react";

import ButtonCmp from "components/ButtonCmp/ButtonCmp";
import ActionModal from "components/Modals/ActionModal";

import { BasicInput } from "components/InputsCmp/CustomInputCmp";
import { deleteWeddingFAQ, updateWeddingFAQs } from "utils/workbench/APIs/routes/wedding";
import { UpdateWeddingCollection, WeddingCollection } from "utils/workbench/STOREs/collections/wedding.collection";


// enum QuestionType {
//   OLD_QUESTION = "old_question",
//   NEW_QUESTION = "new_question",
// }

const FAQCmp: React.FC<
{
  faqID: string;
  question: string;
  answer: string;
  setQuestion: (question: string, faqID: string) => void;
  setAnswer: (answer: string, faqID: string) => void;
  handleDelete: () => void;
  canDelete: boolean;
}> = (
  {
    question,
    answer,
    faqID,
    setQuestion,
    setAnswer,
    handleDelete,
    canDelete,
  }
) => {
  return (
    <Flex justifyContent={"center"} py="">
      <Flex flexDirection={"column"} width={"100%"}>
        <Box display={"flex"} flexDirection="column">
          <Stack spacing={"0.5px"}>
            <Box display={"flex"} flexDirection="column">
              <BasicInput
                placeholder="choose question"
                name="question"
                disabled={
                  question === "Are plus ones or kids allowed?" ||
                  question === "Which number can I call for more information?" ||
                  question === "Is there hotel accommodation?" ||
                  question === "Is it mandatory to RSVP?" ||
                  question === "When is the deadline to RSVP?"
                }
                showMessage={true}
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value, faqID);
                }}
                hasDropdown={true}
                rightElement={
                  <Select
                    border="none"
                    w={"40px"}
                    h={"40px"}
                    value={question}
                    onChange={(e) => {
                      setQuestion(e.target.value === "Write your question" ? "" : e.target.value, faqID);
                    }}
                  >
                    <option value={"Are plus ones or kids allowed?"}>Are plus ones or kids allowed?</option>
                    <option value={"Which number can I call for more information?"}>Which number can I call for more information?</option>
                    <option value={"Is there hotel accommodation?"}>Is there hotel accommodation?</option>
                    <option value={"Is it mandatory to RSVP?"}>Is it mandatory to RSVP?</option>
                    <option value={"When is the deadline to RSVP?"}>When is the deadline to RSVP?</option>
                    <option value={"Write your question"}>Write your question</option>
                  </Select>
                }
              />
            </Box>

            <Box>
              <BasicInput
                placeholder="fill answer here"
                name={`answer`}
                onChange={(e) => {
                  setAnswer(e.target.value, faqID);
                }}
                value={answer}
                showMessage={false}
              />
            </Box>
          </Stack>
        </Box>
      </Flex>
      {
        canDelete &&
        (
          <Box mt="3px">
            <Box
              ml="16px"
              display="flex"
              w="50px"
              h="50px"
              borderRadius="26px"
              border="1px solid #FFF"
              borderColor={'rgba(255, 255, 255, 0.5)'}
              color={'rgba(255, 255, 255, 0.5)'}
              _hover={{
                backgroundColor: "#960E0E",
              }}
              alignItems="center"
              justifyContent="center"
              cursor={"pointer"}
              onClick={handleDelete}
            >
              <AiOutlineDelete />
            </Box>
          </Box>
        )
      }
    </Flex>
  );
};



const StepFour: React.FC<{
  nextBtnAction: () => void;
  prevBtnAction: () => void;
}> = ({
  nextBtnAction,
  prevBtnAction,
}) => {
  const toast = useToast({
    position: "top",
    containerStyle: {
      zIndex: 9,
    },
  });

  const { weddingExists, weddingID, weddingDataObj } = WeddingCollection();
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [faqToDeleteID, setfaqToDeleteID] = useState("");

  const [questionsHaveChanged, setQuestionsHaveChanged] = useState(false);
  const [questions, setQuestions] = useState(
    weddingDataObj.faqs.map(
      (_faq) => ({faqID: _faq._id, question: _faq.question, answer: _faq.answer})
    )
  );
  // const [newQuestions, setNewQuestions] = useState<{ faqID: string, question: string, answer: string }[]>([]);
  // const [currentFAQ, setCurrentFAQ] = useState<{ faqID: string, question: string, answer: string }>(
  //   {
  //     faqID: Math.random().toString(), 
  //     question: "", 
  //     answer: "",
  //   }
  // );
  const [currentFAQOpen, setCurrentFAQOpen] = useState(!questions?.length);
  const bottomDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomDivRef.current?.scrollIntoView(
      {
        behavior: "smooth",
        block: "end",
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuestionByID = (question: string, faqID: string) => {
    const questionIndex = questions.findIndex((q) => q.faqID === faqID);
    const _questions = [...questions];
    const oldQuestion = questions[questionIndex];
    _questions[questionIndex] = {
      ...oldQuestion,
      question,
    };
    setQuestions(_questions);
    setQuestionsHaveChanged(true);
  };

  const setAnswerByID = (answer: string, faqID: string) => {
    const questionIndex = questions.findIndex((q) => q.faqID === faqID);
    const _questions = [...questions];
    const oldQuestion = questions[questionIndex];
    _questions[questionIndex] = {
      ...oldQuestion,
      answer,
    };
    setQuestions(_questions);
    setQuestionsHaveChanged(true);
  };

  const handleDelete = (faqID: string) => {
    setQuestions(
      questions.filter(oq => oq.faqID !== faqID)
    );
    deleteWeddingFAQ(
      {faqID},
      (response) => {
        if (response.statusCode === 200) {
          UpdateWeddingCollection(
            (weddingCollection) => {
              weddingCollection.weddingDataObj = response.payload;
            }
          );
        }
        if (response.statusCode === 400 || response.errors) toast({status: "error", description: response.message});
      }
    );
    setQuestionsHaveChanged(true);
  };

  useEffect(() => {
    if (
      weddingDataObj &&
      weddingDataObj?.faqs &&
      weddingDataObj?.faqs.length > 0
    ) {
      setQuestions(
        weddingDataObj.faqs.map(
          (_faq) => ({faqID: _faq._id, question: _faq.question, answer: _faq.answer})
        )
      );
    }
  }, [weddingDataObj]);

  const handleSubmit = () => {
    setLoading(true);

    // check valid
    for (const FAQ of questions) {
      if (!FAQ.question) toast({ status: "error", description: "question not set" });
      if (!FAQ.answer) toast({ status: "error", description: "answer not set" });
      if (!FAQ.question || !FAQ.answer) {
        setLoading(false);
        return;
      }
    }

    if (questionsHaveChanged) {
      updateWeddingFAQs(
        {
          weddingID,
          faqs: questions,
        },
        (response) => {
          if (response.statusCode === 200) {
            UpdateWeddingCollection(
              (weddingCollection) => {
                weddingCollection.weddingDataObj = response.payload;
              }
            );
            setQuestions(
              response.payload.faqs.map(
                (_faq) => ({faqID: _faq._id, question: _faq.question, answer: _faq.answer})
              )
            );
          }
          if (response.statusCode === 400 || response.errors) toast({status: "error", description: response.message});
        }
      );
    }

    setLoading(false);
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap="3px"
        pt="10px"
        pb="4px"
        display={{ base: "none", sm: "unset" }}
      >
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize={"12px"}
          fontWeight="400"
        >
          Step 5
        </Text>
        <Flex flexDir="row" width="100%" justifyContent="space-between">
          <Box>
            <Text fontSize={"20px"} color="#fff" fontWeight={"400"}>
              Wedding FAQs
            </Text>
          </Box>
          <Box borderBottom="1px solid" color="#CEFABB" cursor="pointer">
            <Text
              onClick={() => {
                nextBtnAction();
              }}
            >
              Skip
            </Text>
          </Box>
        </Flex>

        <Text color="rgba(255, 255, 255, 0.5)" mt="8px">
          Answer questions that your invitees might have about the ceremony
        </Text>
      </Flex>
      <Box mt="35px" display="flex" flexDir="column" gap="20px">
        {questions.map((faq) => {
          return (
            <FAQCmp 
              key={faq.faqID}
              faqID={faq.faqID}
              question={faq.question}
              answer={faq.answer}
              setQuestion={setQuestionByID}
              setAnswer={setAnswerByID}
              canDelete={questions.length > 2}
              handleDelete={() => {
                setQuestionsHaveChanged(true);
                setShowDelete(!showDelete);
                setfaqToDeleteID(faq.faqID);
              }}
            />
          );
        })}

        {
          questions.length < 5 &&
          (
            <Flex 
              justifyContent="flex-end" 
              py=""
              ref={bottomDivRef}
            >
              <Box
                cursor="pointer"
                fontWeight={"400"}
                fontSize="15px"
                color="#CEFABB"
                textAlign={"center"}
                py="0.25rem"
                display={"flex"}
                alignItems="center"
                justifyContent={"center"}
                gap="4px"
                borderBottom="1px solid"
              >
                <Icon as={
                  currentFAQOpen ? BsBookmark : AiOutlinePlus
                } />
                <Text
                  opacity={"0.5"}
                  onClick={async () => {
                    if (!currentFAQOpen) {
                      setCurrentFAQOpen(!currentFAQOpen);
                      setQuestions(
                        [
                          ...questions,
                          {
                            faqID: "",
                            answer: "",
                            question: "",
                          }
                        ]
                      );
                      return;
                    }
                    // handleSaveQuestion();
                    handleSubmit();
                    if (currentFAQOpen) {
                      setCurrentFAQOpen(!currentFAQOpen);
                    }
                  }}
                >
                  {
                    currentFAQOpen ? 
                    "save question" :
                    `add ${questions?.length ? 'another' : 'a'} question`
                  }
                </Text>
              </Box>
            </Flex>
          )
        }
        <Box
          position="sticky"
          bgColor={"#1F2229"}
          zIndex="3"
          textAlign="center"
          display="flex"
          gap="10px"
          bottom={{ base: "-20px", sm: "-80px" }}
          justifyContent={"center"}
        >
          <ButtonCmp
            text={"Previous"}
            size="lg"
            color="#44A560"
            backgroundColor="transparent"
            opacity={"1"}
            border={"1px solid #44A560"}
            textHoverColor={"#fff"}
            width="100%"
            height="55px"
            maxWidth="100%"
            type="submit"
            onClick={() => {
              handleSubmit();
              prevBtnAction();
            }}
          />
          <ButtonCmp
            text={"Next"}
            size="lg"
            opacity={"1"}
            border={"none"}
            isLoading={loading}
            textHoverColor={"#fff"}
            width="100%"
            height="55px"
            maxWidth="100%"
            type="submit"
            onClick={() => {
              handleSubmit();
              nextBtnAction();
            }}
            isDisabled={!weddingExists && (questions.length - weddingDataObj.faqs.length > 0)}
          />
        </Box>
      </Box>
      <ActionModal
        isOpen={showDelete}
        onRequestClose={() => {
          setShowDelete(false);
        }}
        maxWidth="400px"
        showCloseIcon={false}
        actionTitle="delete event item?"
        actionDesc="You will not be able to recover this item after you confirm this action"
        yesText="YES, DELETE"
        noText="NO, CANCEL"
        yesAction={() => {
          handleDelete(faqToDeleteID);
          
          setShowDelete(false);
          setfaqToDeleteID("");
        }}
        noAction={() => {
          setShowDelete(false);
        }}
      />
    </>
  );
};

export default StepFour;
