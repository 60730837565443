import { Box, Text } from "@chakra-ui/react";

const StatusCardCmp: React.FC<{
  bgcolor: string;
  text: string;
  textColor: string;
}> = ({ bgcolor, text, textColor }) => {
  return (
    <>
      <Box
        textAlign={"center"}
        padding="4px 8px"
        borderRadius={"8px"}
        bgColor={bgcolor}
      >
        <Text color={textColor}>{text}</Text>
      </Box>
    </>
  );
};

export default StatusCardCmp;
