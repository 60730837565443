import { Box } from "@chakra-ui/react";
import React from "react";

const IconCardCmp: React.FC<{ icon: string; width?: string }> = ({
  icon,
  width,
}) => {
  return (
    <Box
      bgColor={"#44A560"}
      borderRadius="10px"
      padding={"0rem 0.5rem"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={width}
      height="40px"
    >
      {icon}
    </Box>
  );
};

export default IconCardCmp;
