import { Heart } from "./styles";
import { Heart as HeartIcon } from "commons/icons";
import { LogoProps } from "./types";
import { Flex } from "@chakra-ui/react";

const LogoLoader = ({ size = "md" }: LogoProps) => {
  return (
    <Flex
      justifyContent={"center"}
      pos={"relative"}
      alignItems={"center"}
      p={"20px 0"}
    >
      <Heart white size={size}>
        <HeartIcon fill={"#BDBDBD"} offset={"#EEEEEE"} id={1} />
      </Heart>
      <Heart size={size}>
        <HeartIcon fill={"#2E651E"} offset={"#7DC670"} id={2} />
      </Heart>
    </Flex>
  );
};

export default LogoLoader;
