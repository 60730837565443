import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CheckEmailResponse,
  CheckUsername,
  UpdateEmailResponse,
  CheckUsernameResponse,
  MRequest,
  UpdateUsernameResponse,
  UpdateUserProfileResponse,
  ChangePasswordResponse,
  VerifyPasswordUserResponse,
  CheckEmail,
  UpdateUserProfile,
  UpdateUsername,
  UpdateEmail,
  VerifyPasswordUser,
  ChangePassword,
  DeactivateAccount,
  DeactivateAccountResponse,
} from "apis";
import { RootState } from "store/reducers";

const entity = "settings";

const checkUsername = createAsyncThunk(
  `user/check/username`,
  async (params: { username: string }) => {
    const res = await MRequest.get<typeof CheckUsername.RES>(
      `${CheckUsername.ROUTE}?username=${params.username}`
    );
    return res.data;
  }
);

const checkEmail = createAsyncThunk(
  `user/check/email`,
  async (params: { email: string }) => {
    const res = await MRequest.get<typeof CheckEmail.RES>(
      `${CheckEmail.ROUTE}?email=${params.email}`
    );
    return res.data;
  }
);

const updateUserProfile = createAsyncThunk(
  `user/profile`,
  async (data: typeof UpdateUserProfile.BODY) => {
    const res = await MRequest.put<typeof UpdateUserProfile.RES>(
      UpdateUserProfile.ROUTE,
      data
    );
    return res.data;
  }
);

const updateUsername = createAsyncThunk(
  `user/username`,
  async (data: typeof UpdateUsername.BODY) => {
    const res = await MRequest.put<typeof UpdateUsername.RES>(
      UpdateUsername.ROUTE,
      data
    );
    return res.data;
  }
);

const updateEmail = createAsyncThunk(
  `user/email`,
  async (data: typeof UpdateEmail.BODY) => {
    const res = await MRequest.put<typeof UpdateEmail.RES>(
      UpdateEmail.ROUTE,
      data
    );
    return res.data;
  }
);

const verifyPasswordUser = createAsyncThunk(
  `auth/verify-password-user`,
  async (data: typeof VerifyPasswordUser.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof VerifyPasswordUser.RES>(
        VerifyPasswordUser.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const changePassword = createAsyncThunk(
  `auth/change-password-user`,
  async (data: typeof ChangePassword.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof ChangePassword.RES>(
        ChangePassword.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deactivateAccount = createAsyncThunk(
  `auth/deactivate-account-user`,
  async (data: typeof DeactivateAccount.BODY, { rejectWithValue }) => {
    try {
      const res = await MRequest.post<typeof DeactivateAccount.RES>(
        DeactivateAccount.ROUTE,
        data
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export type SettingsInitialStateType = {
  checkUsernameResponse: CheckUsernameResponse | null;
  checkEmailResponse: CheckEmailResponse | null;
  updateUserProfileResponse: UpdateUserProfileResponse | null;
  updateUsernameResponse: UpdateUsernameResponse | null;
  updateEmailResponse: UpdateEmailResponse | null;
  verifyPasswordUserResponse: VerifyPasswordUserResponse | null;
  changePasswordResponse: ChangePasswordResponse | null;
  deactivateAccountResponse: DeactivateAccountResponse | null;
};

const initialState: SettingsInitialStateType = {
  checkUsernameResponse: null,
  checkEmailResponse: null,
  updateUserProfileResponse: null,
  updateUsernameResponse: null,
  updateEmailResponse: null,
  verifyPasswordUserResponse: null,
  changePasswordResponse: null,
  deactivateAccountResponse: null,
};

const settingsSlice = createSlice({
  name: entity,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Check Username
    builder.addCase(
      checkUsername.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.checkUsernameResponse = action.payload;
      }
    );
    builder.addCase(
      checkUsername.rejected,
      (state, action: PayloadAction<any>) => {
        state.checkUsernameResponse = action.payload;
      }
    );

    // Check Email
    builder.addCase(
      checkEmail.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.checkEmailResponse = action.payload;
      }
    );
    builder.addCase(
      checkEmail.rejected,
      (state, action: PayloadAction<any>) => {
        state.checkEmailResponse = action.payload;
      }
    );

    // Update User Profile
    builder.addCase(
      updateUserProfile.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateUserProfileResponse = action.payload;
      }
    );
    builder.addCase(
      updateUserProfile.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateUserProfileResponse = action.payload;
      }
    );

    // Update Username
    builder.addCase(
      updateUsername.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateUsernameResponse = action.payload;
      }
    );
    builder.addCase(
      updateUsername.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateUsernameResponse = action.payload;
      }
    );

    // Update User Email
    builder.addCase(
      updateEmail.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.updateEmailResponse = action.payload;
      }
    );
    builder.addCase(
      updateEmail.rejected,
      (state, action: PayloadAction<any>) => {
        state.updateEmailResponse = action.payload;
      }
    );

    // Verify Password
    builder.addCase(
      verifyPasswordUser.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.verifyPasswordUserResponse = action.payload;
      }
    );
    builder.addCase(
      verifyPasswordUser.rejected,
      (state, action: PayloadAction<any>) => {
        state.verifyPasswordUserResponse = action.payload;
      }
    );

    //Change Password
    builder.addCase(
      changePassword.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.changePasswordResponse = action.payload;
      }
    );
    builder.addCase(
      changePassword.rejected,
      (state, action: PayloadAction<any>) => {
        state.changePasswordResponse = action.payload;
      }
    );

    //deactivate account
    builder.addCase(
      deactivateAccount.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.deactivateAccountResponse = action.payload;
      }
    );
    builder.addCase(
      deactivateAccount.rejected,
      (state, action: PayloadAction<any>) => {
        state.deactivateAccountResponse = action.payload;
      }
    );
  },
});

export const settingsActions = {
  ...settingsSlice.actions,
  checkUsername,
  checkEmail,
  updateUserProfile,
  updateUsername,
  updateEmail,
  verifyPasswordUser,
  changePassword,
  deactivateAccount,
};

export const settingsSelectors = {
  checkUsername: (state: RootState) => state.settings.checkUsernameResponse,
  checkEmail: (state: RootState) => state.settings.checkEmailResponse,
  updateUserProfile: (state: RootState) =>
    state.settings.updateUserProfileResponse,
  updateUsername: (state: RootState) => state.settings.updateUsernameResponse,
  updateEmail: (state: RootState) => state.settings.updateEmailResponse,
  verifyPasswordUser: (state: RootState) =>
    state.settings.verifyPasswordUserResponse,
  changePassword: (state: RootState) => state.settings.changePasswordResponse,
  deactivateAccount: (state: RootState) =>
    state.settings.deactivateAccountResponse,
};

export const settingsReducer = settingsSlice.reducer;
